import BarChart, {CategoryItem, SubjectItem} from "../../Components/layouts/barChart(new)/barChart";
import { useLayoutEffect, useState } from "react";

let RenderProject : React.FC <{}> = (props) => { 


    let CItems : CategoryItem []= [
        {
          Title : "PROGRAMMING LANGUAGES",
          elements : 
          [
            {
              name : "Java",
              percentage : 95,
              color_Theme : "red"
            },
            {
              name : "C/C++",
              percentage : 65,
              color_Theme : "blue"
            },
            {
              name : "JavaScript (Typescript)",
              percentage : 80,
              color_Theme : "yellow"
            }
          ]
        },
        {
            Title  : "MARKUP LANGUAGES",
            elements : 
            [
              {
                name : "HTML",
                percentage : 70,
                color_Theme : "red"
              },
              {
                name : "CSS",
                percentage : 70,
                color_Theme : "blue"
              }
            ]
          },
          {
            Title  : "DATA-STRUCTURES & ALGORITHMS ",
            elements : 
            [
              {
                  name : "Problem solving",
                  percentage : 87,
                  color_Theme : "yellow"
              },
              {
                name : "Asymptotic / Algorithmic Analysis",
                percentage : 80,
                color_Theme : "yellow"
              },
              {
                name : "Trees, Graphs & Other Fundamental Linked Data-Structures",
                percentage : 100,
                color_Theme : "red"
      
              },
              {
                  name : "Hashing",
                  percentage : 100,
                  color_Theme : "red"
        
                },
              {
                name : "Sorting, traversal / Classical Searching Algorithms",
                percentage : 85,
                color_Theme : "blue"
      
              },
              {
                name : "Dynamic, greedy & Divide, recursive, and divide-and-conquer paradigm",
                percentage : 75,
                color_Theme : "red"
      
              },
            ]
          },
        {
            Title : "OOP CONCEPTS",
            elements : 
            [
              {
                name : "Modularity",
                percentage : 100,
                color_Theme : "red"
              },
              {
                name : "Composition",
                percentage : 100,
                color_Theme : "red"
            },
              {
                name : "Abstraction, Encapsulation, Inheritance & Polymorphism",
                percentage : 100,
                color_Theme : "red"
              },
              {
                name : "Design Patterns",
                percentage : 75,
                color_Theme : "red"
              },
              {
                name : "Pointer, references & Dynamic memory management",
                percentage : 100,
                color_Theme : "red"
              }
            ]
        },
        {
          Title  : "WEB FRAMEWORKS",
          elements : 
          [
            {
              name : "React JS",
              percentage : 45,
              color_Theme : "red"
            },
            {
              name : "Sprint Boot",
              percentage : 5,
              color_Theme : "blue"
            }
          ]
        },
   
      ]; 
    
      let DItems : CategoryItem []= [
        {
          Title : "Layout Concept",
          elements : 
          [
            {
              name : "UI Design",
              percentage : 50,
              color_Theme : "red"
    
            },
            {
              name : "C/UI Design++",
              percentage : 50,
              color_Theme : "red"
    
            },
            {
              name : "colouring",
              percentage : 13,
              color_Theme : "red"
    
            },
            {
              name : "scheme",
              percentage : 11,
              color_Theme : "red"
    
            },
            {
              name : "layout",
              percentage : 5,
              color_Theme : "red"
    
            },
          ]
        },
        {
          Title : "Picture Concept",
          elements : 
          [
            {
              name : "UX Design",
              percentage : 11,
              color_Theme : "red"
    
            },
            {
              name : "C/colouring++",
              percentage : 22,
              color_Theme : "red"
    
            },
            {
              name : "colouringscheme",
              percentage : 33,
              color_Theme : "red"
    
            },
            {
              name : "layoutscheme",
              percentage : 44,
              color_Theme : "red"
    
            },
            {
              name : "schemelayout",
              percentage : 55,
              color_Theme : "red"
            },
          ]
        },
    ];
    
    let SubjectItems : SubjectItem[] = [
        {
          Name : "Development",
          CategoryItems : CItems
        },
        {
          Name : "Design",
          CategoryItems : DItems
        }
    ];
  
    return (  <BarChart SubjectItems = {SubjectItems}/> );
}

export default RenderProject;
//  


import Styling from "./Styling.module.css"
import ProfileBanner, {AnimationBannerInformation} from "../../BaseProject/Components/animations/AnimationBannerProfile/AnimationBanner"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import myBrowser from "../../BaseProject/Handlers/myBrowser"
import DynamicText from "../../BaseProject/Components/layouts/Others/dynamicText"

let isLocked = false;
let RenderPage : React.FC <{Title:String, children : React.ReactNode}> = (props) => { 

    let container_Ref = useRef<HTMLDivElement> (null); 
    let widthDimension = useRef<HTMLParagraphElement>(null);
    let [isScaling, set_isScaling] = useState<boolean>(false);
    let [ruler_Size, set_RulerSize] = useState<{sideSize: number, topSide : number}>({sideSize:0, topSide:0});
    let [isPortrait, set_IsPortrait] = useState<boolean>(myBrowser.isPortrait());
    let TopContainer_Ref = useRef<HTMLDivElement>(null); 
    let [is_inView, set_inView] = useState<boolean | undefined>(undefined);
    let contentCover_Ref = useRef<HTMLDivElement>(null);


    // HANDLE Content re-scaling animation
    useLayoutEffect(
        () => { 
            let isTransition = false;
            let scrollTimeout: NodeJS.Timeout | undefined;

            if (container_Ref.current != undefined){
                let TransitionStart_Handler = (event : TransitionEvent) => { 
                    if (event.propertyName === "width"){
                        set_isScaling(true);
                    }
               }


                let transitionEnd = (event : TransitionEvent) => { 
                    console.log('Transitioned property:', event.propertyName);

                    if (event.propertyName === "width"){
                        set_isScaling(false);
                        container_Ref.current?.removeEventListener('transitionstart', TransitionStart_Handler);
                        container_Ref.current?.removeEventListener('transitionend', transitionEnd);
                        isTransition = false;

                    }
                }
                let timeoutId:any;
                let handleDimension_Display = () => { 
                    // HANDLE RESIZE PROMPT
                    if (widthDimension.current != undefined){
                        widthDimension.current.innerText = myBrowser.getBrowserWidth().toString();
                    }

                    // HANDLE RULER
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(() => {
                       set_RulerSizing();
                       set_IsPortrait(myBrowser.isPortrait());
                    }, 500);
                }

                let resizeHandler = () => { 
                    handleDimension_Display();

                    // initialise transiiton handler                    
                    if (container_Ref.current != undefined && !isTransition){
                        isTransition = true;
                        container_Ref.current.addEventListener('transitionstart', TransitionStart_Handler);
                        container_Ref.current.addEventListener('transitionend', transitionEnd);
                    }

                }

                // set sideruler length
                let set_RulerSizing = () => {
                    if (container_Ref.current != undefined){
                        set_RulerSize( { sideSize:container_Ref.current.clientHeight/20, topSide : container_Ref.current.clientWidth/20 } );
                    }
                }
                window.addEventListener("resize", resizeHandler);
                set_RulerSizing();
                resizeHandler();

                return () => {
                    window.removeEventListener("resize", resizeHandler);
                    container_Ref.current?.removeEventListener('transitionstart', TransitionStart_Handler);
                    container_Ref.current?.removeEventListener('transitionend', transitionEnd);
                }
            }
        },[]
    )


    useLayoutEffect(
        () => {
            // hide demo if width < 200
            if (DemoWrapper_Ref.current){
                let wrapperWidth = DemoWrapper_Ref.current.clientWidth;
                let child  = DemoWrapper_Ref.current.firstChild as HTMLDivElement;
                // DemoWrapper_Ref.current.style.opacity= "0";
                if (wrapperWidth <200){
                    child.style.opacity= "0";
                    child.style.height="0";
                }
            }
        }
    )


    let DemoWrapper_Ref = useRef<HTMLDivElement>(null);

    return(
        <>
        <div ref = {DemoWrapper_Ref}  style = {{width:'100%'}}>
            <div className={Styling.IntroductionContainer} >
                <div className={Styling.sideContainers + ' ' +Styling.lineBottom} >
                    <div className={Styling.centerContainer}>
                        <svg className={Styling.Dot + ' ' + Styling.Dot_BottomLeft} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                        <svg className={Styling.Dot + ' ' + Styling.Dot_BottomRigt} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                    </div>
                </div>
                <div className={Styling.sideContainers} >
                    <div ref = {container_Ref}  className={Styling.centerContainer} style = {{padding:"2%", boxSizing:'border-box', position:'relative'}}>
                        <div style = {{height:'100%', transform:'translate(-110%,0%)', position:'absolute', top:'0', left:"0", overflow:'hidden'}}>
                            <div className={Styling.sideRuler_Container}>
                                {
                                    (() => {
                                        let k : React.ReactNode[] = [];
                                        for (let i =1; i < ruler_Size.sideSize; i++) k.push(
                                                <div style = {{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                                    <p> {i}</p>
                                                    <div style = {{display:"inlineblock", width:'5px', height:"1px", background:"rgba(221, 234, 248, .08)"}}/>
                                                </div>
                                            
                                            );
                                        return k;
                                    })()
                                }
                            </div>
                        </div>
                        <div style = {{width:'100%', transform:'translate(0,-110%)', position:'absolute'}}>
                            <div className={Styling.topRuler_Container} style = {{ overflow:'hidden'}}>

                                    {
                                        (() => {
                                            let k : React.ReactNode[] = [];
                                            for (let i =1; i < ruler_Size.topSide; i++) 
                                                k.push( 
                                                    <div style = {{display:'grid', justifyItems:'center'}}>
                                                        <p style = {{display:"inline-block"}}> {i}</p> 
                                                        <div style = {{display:"inlineblock", height:'5px', width:"1px", background:"rgba(221, 234, 248, .08)"}}/>
                                                    </div>
                                                );
                                            return k;
                                        })()
                                    }
                            </div>
                        </div>
                 
                        {
                            <>
                                <div className={Styling.centerLines} style={{left:'30%'}}/>
                                <div className={Styling.centerLines} style={{left:'70%'}}/>
                                {props.children}
                            </>
                        }
                    </div>
                </div>

                <div className={Styling.sideContainers + ' ' + Styling.lineTop + ' ' + Styling.lineBottom}>
                    <div className={Styling.centerContainer}>
                        <div style = {{width:"95%", height:'100%', display:'grid', alignItems:'center'}}>
                            <p className = {Styling.timer} > {props.Title} Demo </p>

                        </div>
                        <svg className={Styling.Dot + ' ' + Styling.Dot_TopLeft} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                        <svg className={Styling.Dot + ' ' + Styling.Dot_TopRight} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                    </div>
                </div>
            </div>
            {/* <div ref = {contentCover_Ref} style = {{transition:'all 1s', background:"rgb(17, 17, 19)", opacity :'0%', position:"absolute", height :"100%", width:"100%", zIndex: -50 }}/> */}
        </div>
        </>
    )

}

export default RenderPage;
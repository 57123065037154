import  {items} from '../../../../contentCarousel/contentCarosel';
import ComponentDemo from "../../../../DemoWrapper/DemoWrapper"; 


let CaroselItem : items =    {
    key : 1,
    Subject : "Directory Encryption",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}
            <p>  
                This program serves as a demonstration of file encryption and decryption functionalities using AES (Advanced Encryption Standard) algorithm.
                Iteration of all files (Inodes) rooted by a given directory will be performed, and all sub-files will be encrypted/ or decrypted based on the 
                given flag using block-based processing, where each file is read in blocks of bytes, then processed.
            </p>

            <p style = {{fontWeight:"800", textDecoration:"underline"}}> Features: </p>

            <ol>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  Encryption and Decryption : </span>
                        The program encrypts and decrypts a file using the AES algorithm.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> File I/O Operations : </span>
                        Utilisation of Input and output streams to read from and write to files, enabling seamless encryption and decryption processes.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> Block-based Processing : </span>
                        The program reads the file in blocks of bytes during encryption and decryption operations.
                    </li>
            </ol>



            <p style = {{fontWeight:"800", textDecoration:"underline"}}> The program addresses the following functionalities : </p>

            <ul>
                <li>
                    <span style = {{textDecoration:'underline'}}>  Main Class (main) : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Entry point of the program.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            demonstrates the usage of file encryption and decryption methods provided by the AES_Encryption class
                        </li>
                    </ol>
                </li>
                <li>
                    <span style = {{textDecoration:'underline'}}>  IO Class : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Responsible for handling input/output operations related to files.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Offers methods for tasks such as determining file sizes, reading files into byte arrays, and writing byte arrays to files.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Ensures the existence of files and directories before performing operations on them.
                        </li>
                    </ol>
                </li>

                <li>
                    <span style = {{textDecoration:'underline'}}>  AES_Encryption Class : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Handles encryption and decryption using the Advanced Encryption Standard (AES) algorithm.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Provides methods for encrypting and decrypting byte arrays using AES encryption with Cipher Block Chaining (CBC) mode and PKCS5 padding.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Utilizes a password and salt value for deriving encryption keys.
                        </li>
                    </ol>
                </li>


                <li>
                    <span style = {{textDecoration:'underline'}}>  FileStructure Class : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Represents the hierarchical structure of directories and files as a tree data structure.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                           Constructs and maintains the file structure tree, organizing files and directories accordingly.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                           Supports functionalities such as creating the file structure, traversing the structure, and printing it for visualization.
                        </li>
                    </ol>
                </li>

                <li>
                    <span style = {{textDecoration:'underline'}}>  Node Class : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Represents a node in the file structure tree.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Encapsulates information about individual files or directories, including their names, paths, types (file or directory), last modified times, and references to parent and child nodes.
                        </li>
                    </ol>
                </li>

            </ul>


            Note: This program serves as a demonstration of fundamental operations, and is integrated into larger projects (i.e :  GIT BackUp Clone project) .
        </>,
    img_Url:"./images/Project_Images/dirEnc.jpg",
    file_List : [
        {
            key : 1,
            FileTitle : "Main.java",
            content :  
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/>package myDrive;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.BufferedInputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.BufferedOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileInputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.InputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.OutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.print<<white/>Stream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> myDrive.IO;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> main {"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> java.io.print<<white/>Stream print<<white/>ln = <<orange/>new<<white/> print<<white/>Stream(<<orange/>system<<white/>.out);"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(<<orange/>String<<white/>[] args) throws Exception "+
            "<<<sp_1, br/>{"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/>byte f[] = <<orange/>new<<white/> byte[1];"+
            "<<<sp_2, br/>f[0] = 12;"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.print<<white/>ln(f[0]);"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>byte[]<<lightblue/>do<<white/>ne = AES_Encryption.encryptFile(f,'password');"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.print<<white/>ln(done[0]);"+
            "<<<sp_2, br/>byte[] unDone = AES_Encryption.decryptFile(done,'password');"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.print<<white/>ln(unDone[0]);"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>test();"+
            "<<<sp_2, br/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>String<<white/> filePath = '/Users/...';"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> test()"+
            "<<<sp_1, br/>{"+
            "<<<sp_2, br/>long fileSize = IO.getFileSize(filePath);"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('File size is : %d \n', fileSize );"+
            "<<<sp_2, br/>try ("+
            "<<<sp_4, br/>InputStream inputStream = <<orange/>new<<white/> BufferedInputStream(<<orange/>new<<white/> FileInputStream(filePath));"+
            "<<<sp_4, br/>OutputStream outputStream = <<orange/>new<<white/> BufferedOutputStream(<<orange/>new<<white/> FileOutputStream('...outfile'));"+
            "<<<sp_4, br/>"+
            "<<<sp_3, br/>) {"+
            "<<<sp_4, br/><<orange/>int<<white/> BLOCK_SIZE = 10;"+
            "<<<sp_4, br/>byte[] buffer = <<orange/>new<<white/> byte[BLOCK_SIZE];"+
            "<<<sp_4, br/><<orange/>int<<white/> bytesRead = -1;"+
            "<<<sp_4, br/><<orange/>int<<white/> counter =0;"+
            "<<<sp_4, br/>"+
            "<<<sp_4, br/><<lightblue/>while<<white/> ((bytesRead = inputStream.read(buffer)) != -1) {"+
            "<<<sp_5, br/>counter += bytesRead;"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln('Encrypting : ' + counter + ' / ' + fileSize);"+
            "<<<sp_5, br/>for(<<orange/>int<<white/> i=0; i < buffer.length;i++)"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.print<<white/>ln('Un-Encrypted[' + i + '] = ' + buffer[i] );"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>byte[] encryptedBuffer = AES_Encryption.encryptFile(buffer, 'password');"+
            "<<<sp_5, br/>"+
            "<<<sp_4, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln('-------');"+
            "<<<sp_5, br/>for(<<orange/>int<<white/> i=0; i < encryptedBuffer.length; i++)"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.print<<white/>ln('Encrypted[' + i + '] = ' + encryptedBuffer[i] );"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln('-------');"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>//					outputStream.write(encryptedBuffer, 0, bytesRead);"+
            "<<white/><<<sp_5, br/>byte[] decrypted = AES_Encryption.decryptFile(encryptedBuffer, 'password');"+
            "<<<sp_5, br/>for(<<orange/>int<<white/> i=0; i < decrypted.length;i++)"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.print<<white/>ln('decrypted[' + i + '] = ' + decrypted[i] );"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_4, br/>}"+
            "<<<sp_4, br/>} <<lightblue/>catch<<white/> (IOException ex) {"+
            "<<<sp_5, br/>ex.print<<white/>StackTrace();"+
            "<<<sp_4, br/>}"+
            "<<<sp_1, br/>}"
        },
        
        {
            key : 2,
            FileTitle : "IO.java",
            content : 

            "<<white/><<<sp_0, br/>package myDrive;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.File;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileInputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileNotFoundException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.InputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.nio.file.Files;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.nio.file.Path;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.nio.file.Paths;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> IO {"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> long getFileSize(<<orange/>String<<white/> filePath)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ((doesFileExist(filePath) == false) && (doesDirectoryExist(filePath) == false)) "+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln('getFileSize failed. ' + filePath + 'does not exist!');"+
            "<<<sp_5, br/><<orange/>return<<white/> -1;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> <<orange/>new<<white/> File(filePath).length();"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Given the filePath to some local file, we return its byte array representation."+
            "<<<sp_2, br/>* @param filePath"+
            "<<<sp_2, br/>* @return byte[] representation of file. "+
            "<<<sp_2, br/>* @throws Exception one reading error"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> byte[] ReadFile(<<orange/>String<<white/> filePath) throws Exception"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.print<<white/>ln('Reading file : ' + filePath);"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (!doesFileExist(filePath))"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('   --> File %s does not exist, cannot readFile!\n', filePath);"+
            "<<<sp_5, br/>throw <<orange/>new<<white/> Exception('File ' + filePath + 'do<<white/>es not exist, cannot readFile!');"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_5, br/>byte[] file_ByteBuffer = <<orange/>new<<white/> byte[ (<<orange/>int<<white/>) <<orange/>new<<white/> File(filePath).length()];"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/>try (FileInputStream inputStream = <<orange/>new<<white/> FileInputStream(filePath)) "+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>"+
            "<<<sp_6, br/>"+
            "<<<sp_6, br/><<orange/>int<<white/> block_ByteSize = inputStream.available(); <<grey/>/* Size to be read without blocking */<<white/>"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('   --> File is %d bytes', file_ByteBuffer.length);"+
            "<<<sp_6, br/><<orange/>int<<white/> bytesRead = 0;"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<lightblue/>while<<white/> (bytesRead < block_ByteSize) "+
            "<<<sp_6, br/>{"+
            "<<<sp_7, br/><<orange/>int<<white/> result = inputStream.read(file_ByteBuffer, bytesRead, block_ByteSize - bytesRead); <<grey/>// -1 is returned at EOF"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> (result == -1) { <<grey/>//EOF Reached"+
            "<<white/><<<sp_8, br/><<orange/>break<<white/>;	"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>bytesRead += result;"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<lightblue/>if<<white/> (file_ByteBuffer != null) {"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('\n   --> Read ' + file_ByteBuffer.length + ' bytes from file ' + filePath);"+
            "<<<sp_6, br/>}	"+
            "<<<sp_6, br/>"+
            "<<<sp_5, br/>} <<lightblue/>catch<<white/> (IOException e) {"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.print<<white/>ln('   ---> Error reading file ' + filePath + ': \n   -->' + e.getMessage());"+
            "<<<sp_7, br/>throw e;"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<orange/>return<<white/> file_ByteBuffer;"+
            "<<<sp_6, br/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Given some directory, fileName and byte[] to be written, we write file into directory with the attached fileName. "+
            "<<<sp_2, br/>* @param "+
            "<<<sp_2, br/>* 		1) directory : String"+
            "<<<sp_2, br/>* 		2) fileName  : String  (i.e. text.txt)"+
            "<<<sp_2, br/>* 		3) file 	 : Byte[] "+
            "<<<sp_2, br/>* @throws Excption IFF write is unsuccessful"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> writeFile(<<orange/>String<<white/> directory, <<orange/>String<<white/> fileName, byte[] file) throws Exception"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('\nWritingFile %s%s\n', directory, fileName);"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (!doesDirectoryExist(directory))"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('   --> directory '%s'<<lightblue/>do<<white/>es not exist, cannot readDirectory!\n', directory);"+
            "<<<sp_5, br/>throw <<orange/>new<<white/> Exception('File ' + directory + '<<lightblue/>do<<white/>es not exist, cannot readFile!');			"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>try {"+
            "<<<sp_5, br/><<grey/>/* Non-Blocking JAVA.NIO method */<<white/>"+
            "<<<sp_5, br/>Path filePath = Paths.get(directory + fileName);"+
            "<<<sp_5, br/>Files.write(filePath, file);"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln('   --> File Written Successfully' );"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}<<lightblue/>catch<<white/>(Exception e)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln('   --> Error writing file\n   --> ' + e.getMessage());"+
            "<<<sp_5, br/>throw <<orange/>new<<white/> Exception('Error writing file\n   -->' + e.getMessage());"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Tests whether the file denoted by this abstract pathname 'directory' is an directory."+
            "<<<sp_2, br/>* @param directory : String"+
            "<<<sp_2, br/>* @return True IFF directory exist; else False. "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> boolean doesDirectoryExist(<<orange/>String<<white/> directory)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>return<<white/> (<<orange/>new<<white/> File(directory).isDirectory());"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/***"+
            "<<<sp_2, br/>* Tests whether the filePath or directory denoted by this abstract filePath exists. "+
            "<<<sp_2, br/>* @param filePath : String"+
            "<<<sp_2, br/>* @return True IFF filePath exist; else False."+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> boolean doesFileExist(<<orange/>String<<white/> filePath)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>return<<white/> ( <<orange/>new<<white/> File(filePath).exists());"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/***"+
            "<<<sp_2, br/>* Given the path of a file/Directory, we return the File Object representation (if any). "+
            "<<<sp_2, br/>* @param filePath : String"+
            "<<<sp_2, br/>* @return File object of path (if exist)"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> File getFile(<<orange/>String<<white/> filePath) throws FileNotFoundException"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>File f = <<orange/>new<<white/> File(filePath);"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (f == null ) throw <<orange/>new<<white/> FileNotFoundException();"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> f;"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"


        },



        {
            key : 3,
            FileTitle : "FileStructure.java",
            content : 

            "<<white/><<<sp_0, br/>package myDrive;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.File;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileNotFoundException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.ArrayDeque;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.ArrayList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.LinkedList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.List;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Queue;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Stack;"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "* Given some rootDirectory by the constructor, we store records of all childs in the directory in a tree "+
            "* data structure"+
            "* @author nege"+
            "*"+
            "*/<<white/>"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** File types */<<white/>"+
            "<<<sp_0, br/>enum FileType {"+
            "<<<sp_3, br/>Directory,"+
            "<<<sp_3, br/>File,"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** File_Node */<<white/>"+
            "<<<sp_0, br/><<orange/>class<<white/> Node {"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>String<<white/> name, path; 				  		  <<grey/>/* Name & path of file */<<white/>"+
            "<<<sp_1, br/>FileType type;			  		  <<grey/>/* Type of file */<<white/>"+
            "<<<sp_1, br/>Node L_Node, R_Node, P_Node, C_Node; 	  <<grey/>/* Represent the left, right children of the currentNode (if any) */<<white/>"+
            "<<<sp_1, br/>long lastModified; 				  		  <<grey/>/* long value representing the time the file was last modified, measured in milliseconds */<<white/> "+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>Node(<<orange/>String<<white/> name){"+
            "<<<sp_3, br/>this.name = name;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>Node(File f){"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.print<<white/>ln('Initialising NOde');"+
            "<<<sp_3, br/>setState(f);"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>Node(File f, Node leftNode, Node rightNode, Node P_Node, Node C_Node){"+
            "<<<sp_3, br/>this(f);"+
            "<<<sp_3, br/>set_LeftNode(leftNode);"+
            "<<<sp_3, br/>set_RightNode(rightNode);			"+
            "<<<sp_3, br/>set_ParentNode(P_Node);"+
            "<<<sp_3, br/>set_ChildNode(C_Node);"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Given some file 'f', we initialize /or change prior states to represent this given file."+
            "<<<sp_2, br/>* @param File : f"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> setState(File f)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (f.isDirectory()) type = FileType.Directory;"+
            "<<<sp_3, br/><<lightblue/>else<<white/> type = FileType.File;		"+
            "<<<sp_3, br/>name = f.getName();"+
            "<<<sp_3, br/>path = f.getAbsolutePath();"+
            "<<<sp_3, br/>lastModif<<white/>ied = f.lastModified();"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('   name = %s\n   type = %s\n   path = %s\n   lastMod<<lightblue/>if<<white/>ied = %d\n', name, type, path, lastMod<<lightblue/>if<<white/>ied );"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> set_ChildNode(Node n) {"+
            "<<<sp_3, br/>C_Node = n;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> set_ParentNode(Node n) {"+
            "<<<sp_3, br/>P_Node = n;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> set_LeftNode(Node n) {"+
            "<<<sp_3, br/>L_Node = n;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> set_RightNode(Node n) {"+
            "<<<sp_3, br/>R_Node = n;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/** Returns T IFF File is a directory, else F ￢ﾇﾒ isFile */<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> boolean isDirectory(){"+
            "<<<sp_3, br/><<orange/>return<<white/> (type == FileType.Directory);"+
            "<<<sp_1, br/>}"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/* File-Structure Class */<<white/>"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> FileStructure {"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>private<<white/> Node rootDir;"+
            "<<<sp_1, br/>FileStructure(<<orange/>String<<white/> RootDirectory) throws Exception {"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (!IO.doesDirectoryExist(RootDirectory)) {"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('Directory '%s'does not exist, cannot establish FileStructure!\n', RootDirectory);"+
            "<<<sp_5, br/>throw <<orange/>new<<white/> FileNotFoundException();"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('Initialising Filestructure for '%s'\n', RootDirectory);"+
            "<<<sp_3, br/>createFileStructure(IO.getFile(RootDirectory));	"+
            "<<<sp_3, br/>print<<white/>FileStructure();"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Given the currentNode, we insert rightNode to be the right sibling of currentNode "+
            "<<<sp_2, br/>* @param Node : currentNode"+
            "<<<sp_2, br/>* @param Node : setNode"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> insert_RightSibling(Node currentNode, Node rightNode)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (currentNode.R_Node == null) currentNode.R_Node = rightNode;"+
            "<<<sp_3, br/><<lightblue/>else<<white/> {"+
            "<<<sp_5, br/>rightNode.L_Node = currentNode;"+
            "<<<sp_5, br/>rightNode.R_Node = currentNode.R_Node;"+
            "<<<sp_5, br/>currentNode.R_Node.L_Node = rightNode;"+
            "<<<sp_5, br/>currentNode.R_Node = rightNode;"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/** "+
            "<<<sp_2, br/>* Perform a breadth-first traversal of the sub-folders rooted by 'RootDir' and establish "+
            "<<<sp_2, br/>* its tree representation of this directory. "+
            "<<<sp_2, br/>* "+
            "<<<sp_2, br/>* On return, rootDir is established and all node can be traversed. "+
            "<<<sp_2, br/>* "+
            "<<<sp_2, br/>* @param File : RootDir for this FileStructure"+
            "<<<sp_2, br/>* @throws FileNotFoundException "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>void<<white/> createFileStructure(File RootDir) throws FileNotFoundException "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>rootDir = <<orange/>new<<white/> Node(RootDir);"+
            "<<<sp_3, br/>Queue<Node> q = <<orange/>new<<white/> ArrayDeque<>();"+
            "<<<sp_3, br/>q.add(rootDir);"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>while<<white/> (q.size() > 0) {"+
            "<<<sp_5, br/>Node currentRoot = q.poll();"+
            "<<<sp_5, br/>File currentFile = IO.getFile(currentRoot.path);"+
            "<<<sp_5, br/>Node currentChildNode = null;"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(File f : currentFile.listFiles()) "+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/>currentChildNode = <<orange/>new<<white/> Node(f); 		"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (currentRoot.C_Node == null) currentRoot.C_Node = currentChildNode;"+
            "<<<sp_7, br/><<lightblue/>else<<white/> insert_RightSibling(currentRoot.C_Node, currentChildNode);		"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (f.isDirectory()) q.add(currentChildNode); "+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Given some Root_Node, Return a linkedList of all successive children (if any), else null is returned"+
            "<<<sp_2, br/>* @param Node : root"+
            "<<<sp_2, br/>* @return LinkedList <Node> on |children| > 1, else null;"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> List <Node> getChildren(Node root){"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (root.C_Node == null) <<orange/>return<<white/> null;"+
            "<<<sp_3, br/>List<Node> n = <<orange/>new<<white/> ArrayList<Node>();"+
            "<<<sp_3, br/>n.add(root.C_Node);"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>while<<white/> (true) {"+
            "<<<sp_5, br/>Node R_Sib = n.get(n.size()-1).R_Node;"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (R_Sib == null) <<orange/>break<<white/>;			"+
            "<<<sp_5, br/>n.add(R_Sib);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> n;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/** Print the fileStructure by the global rootDir  */<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> print<<white/>FileStructure() {"+
            "<<<sp_3, br/>print<<white/>DepthFirst(rootDir);"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/** Print the fileStructure rootedDir 'rDir' "+
            "<<<sp_2, br/>* 	@param Node : rootedDir"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>void<<white/> print<<white/>DepthFirst(Node rDir) {"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.print<<white/>('\n\nprint<<white/>ingFileStructure - \n');"+
            "<<<sp_3, br/>Stack <Node> s = <<orange/>new<<white/> Stack<>();"+
            "<<<sp_3, br/><<orange/>int<<white/> depth= 0;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>s.add(rDir);"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>while<<white/> (s.size() > 0) {"+
            "<<<sp_5, br/>print<<white/>(depth, ( s.lastElement().name + ' --> [T = ' + s.lastElement().type + '] [L.M : ' + s.lastElement().lastModfied +']'));"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (s.lastElement().C_Node != null) {"+
            "<<<sp_7, br/>s.add(s.lastElement().C_Node);"+
            "<<<sp_7, br/>depth += 5;"+
            "<<<sp_5, br/>} <<lightblue/>else<<white/> {"+
            "<<<sp_7, br/><<lightblue/>while<<white/> (!s.isEmpty()) {"+
            "<<<sp_9, br/>Node ci = s.pop();"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (ci.R_Node != null)"+
            "<<<sp_9, br/>{"+
            "<<<sp_11, br/>s.add(ci.R_Node);"+
            "<<<sp_11, br/><<orange/>break<<white/>;"+
            "<<<sp_9, br/>}<<lightblue/>else<<white/> depth -= 5;"+
            "<<<sp_7, br/>}"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}		"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<grey/>/** Alternative Pesudo Depth first Algorithm :"+
            "<<<sp_6, br/>* 		DeathFirst (root) :"+
            "<<<sp_6, br/>* 			Let s = traversal stack;"+
            "<<<sp_6, br/>* 			s.add(root) ;"+
            "<<<sp_6, br/>* "+
            "<<<sp_6, br/>* 			while ( |s| > 0), we do :"+
            "<<<sp_6, br/>*  			let currentRoot <￢ﾀﾔ s.pop(); "+
            "<<<sp_6, br/>*  			while ( T ), we do :"+
            "<<<sp_6, br/>* 					if (currentRoot.hasRightChild) s.add(currentRoot.rightChild); "+
            "<<<sp_6, br/>* 					if (currentRoot.hasChild())  currentRoot <￢ﾀﾔ currentRoot.child() ;"+
            "<<<sp_6, br/>* 					else  break; "+
            "<<<sp_6, br/>* 				End while "+
            "<<<sp_6, br/>* 			End while"+
            "<<<sp_6, br/>*/<<white/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(<<orange/>String<<white/> [] args) throws Exception {"+
            "<<<sp_3, br/>FileStructure f = <<orange/>new<<white/> FileStructure('/Users/nege/Dropbox/ProgrammingDevelopment/Java/JavaProject(2022_sep)/MyDrive');"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> print<<white/>(<<orange/>int<<white/> space, <<orange/>String<<white/> s)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < space; i++) <<orange/>system<<white/>.out.print<<white/>(' ');"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.print<<white/>(s+'\n');"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"


        },

        {
            key : 4,
            FileTitle : "AES_Encryption.java",
            content : 
            "<<white/><<<sp_0, br/>package myDrive;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.Cipher;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.CipherOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.SecretKey;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.SecretKeyFactory;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.spec.IvParameterSpec;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.spec.PBEKeySpec;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.spec.SecretKeySpec;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.BufferedInputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.BufferedOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.ByteArrayOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileInputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.InputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.OutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.nio.charset.StandardCharsets;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.security.InvalidAlgorithmParameterException;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.security.InvalidKeyException;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.security.NoSuchAlgorithmException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.security.NoSuchProviderException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.security.spec.InvalidKeySpecException;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.security.spec.KeySpec;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Base64;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.BadPaddingException;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.IllegalBlockSizeException;  "+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.crypto.NoSuchPaddingException;  "+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> AES_Encryption   "+
            "<<<sp_0, br/>{  "+
            "<<<sp_3, br/><<grey/>/* Private variable declaration */<<white/>  "+
            "<<<sp_3, br/><<orange/>private<<white/> <<orange/>static<<white/> <<orange/>final<<white/> <<orange/>String<<white/> SECRET_KEY = '123456789';  "+
            "<<<sp_3, br/><<orange/>private<<white/> <<orange/>static<<white/> <<orange/>final<<white/> <<orange/>String<<white/> SALTVALUE = 'someUniqueHash';  <<grey/>/*unique value to be added to the end of the password, hence create a different hash value*/<<white/>"+
            "<<<sp_2, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(<<orange/>String<<white/>[] args)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln('Here');"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/>long fileSize = IO.getFileSize('/Users/nege/Dropbox/ProgrammingDevelopment/Java/JavaProject(2022_sep)/MyDrive/encryptedtest.html');"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.<<orange/>printf<<white/>('File size is : %d \n', fileSize );"+
            "<<<sp_3, br/>try ("+
            "<<<sp_7, br/>InputStream inputStream = <<orange/>new<<white/> BufferedInputStream(<<orange/>new<<white/> FileInputStream('/Users/nege/Dropbox/ProgrammingDevelopment/Java/JavaProject(2022_sep)/MyDrive/encryptedtest.html'));"+
            "<<<sp_7, br/>OutputStream outputStream = <<orange/>new<<white/> BufferedOutputStream(<<orange/>new<<white/> FileOutputStream('/Users/nege/Dropbox/ProgrammingDevelopment/Java/JavaProject(2022_sep)/MyDrive/decryptedtest.html'));"+
            "<<<sp_7, br/>"+
            "<<<sp_5, br/>) {"+
            "<<<sp_7, br/><<orange/>int<<white/> BLOCK_SIZE = 10;"+
            "<<<sp_7, br/>byte[] buffer = <<orange/>new<<white/> byte[BLOCK_SIZE];"+
            "<<<sp_7, br/><<orange/>int<<white/> bytesRead = -1;"+
            "<<<sp_7, br/><<orange/>int<<white/> counter =0;"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/><<lightblue/>while<<white/> ((bytesRead = inputStream.read(buffer)) != -1) "+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/>counter += bytesRead;"+
            "<<grey/><<<sp_0, br/>//					System.out.println('Encrypting : ' + counter + ' / ' + fileSize);"+
            "<<white/><<<sp_8, br/>for(<<orange/>int<<white/> i=0; i < buffer.length;i++) <<orange/>system<<white/>.out.print<<white/>ln('UnEncrypted Buffer[' + i + '] = ' + buffer[i] );"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.print<<white/>ln('\n----' );"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/>encryptWithAesGcm(buffer, 'password', false);"+
            "<<<sp_8, br/>byte[] encryptedBuffer = encryptWithAesGcm(buffer, 'password', true);"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.print<<white/>ln('\n----' );"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/>for(<<orange/>int<<white/> i=0; i < encryptedBuffer.length;i++) <<orange/>system<<white/>.out.print<<white/>ln('encryptedBuffer[' + i + '] = ' + encryptedBuffer[i] );"+
            "<<grey/><<<sp_0, br/>//"+
            "<<white/><<<sp_9, br/><<orange/>system<<white/>.out.print<<white/>ln('----' );"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/>byte[] decryptedBuffer = decryptFile(encryptedBuffer, 'password');"+
            "<<<sp_8, br/><<orange/>system<<white/>.out.print<<white/>ln(decryptedBuffer.length);"+
            "<<<sp_8, br/>"+
            "<<<sp_8, br/>for(<<orange/>int<<white/> i=0; i < decryptedBuffer.length;i++) <<orange/>system<<white/>.out.print<<white/>ln('decryptedBuffer[' + i + '] = ' + decryptedBuffer[i] );"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/>"+
            "<<grey/><<<sp_0, br/>//					outputStream.write(encryptedBuffer, 0, bytesRead);"+
            "<<white/><<<sp_8, br/>"+
            "<<<sp_8, br/><<orange/>system<<white/>.exit(0);"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/>byte[] encryptedBuffer = encryptWithAesGcm(buffer, 'password', true);"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.print<<white/>ln('encryptedBuffer : ' + encryptedBuffer.length);"+
            "<<<sp_7, br/>outputStream.write(encryptedBuffer, 0, encryptedBuffer.length);"+
            "<<<sp_7, br/>"+
            "<<<sp_5, br/>} <<lightblue/>catch<<white/> (Exception ex) {"+
            "<<<sp_7, br/>ex.print<<white/>StackTrace();"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>"+
            "<<grey/><<<sp_0, br/>//    	try {"+
            "<<white/><<grey/><<<sp_0, br/>//    		encryptWithAesGcm(new byte[] {'h','e','l','s'}, 'password', false);"+
            "<<white/><<grey/><<<sp_0, br/>//			byte[] ok = encryptWithAesGcm(new byte[] {'k','a','y','s'}, 'password', true);"+
            "<<white/><<grey/><<<sp_0, br/>//			for (int i=0; i < ok.length;i++)"+
            "<<white/><<grey/><<<sp_0, br/>//			{"+
            "<<white/><<grey/><<<sp_0, br/>//				System.out.println('i = ' + ok[i]);"+
            "<<white/><<grey/><<<sp_0, br/>//			}"+
            "<<white/><<grey/><<<sp_0, br/>//			System.out.println('\n\n');"+
            "<<white/><<grey/><<<sp_0, br/>//"+
            "<<white/><<grey/><<<sp_0, br/>//			"+
            "<<white/><<grey/><<<sp_0, br/>//			byte[] descrypted = decryptFile(ok, 'password');"+
            "<<white/><<grey/><<<sp_0, br/>//			"+
            "<<white/><<grey/><<<sp_0, br/>//			"+
            "<<white/><<grey/><<<sp_0, br/>//			for (int i=0; i < descrypted.length;i++)"+
            "<<white/><<grey/><<<sp_0, br/>//			{"+
            "<<white/><<grey/><<<sp_0, br/>//				System.out.println('decripyted = ' + (char) descrypted[i]);"+
            "<<white/><<grey/><<<sp_0, br/>//			}"+
            "<<white/><<grey/><<<sp_0, br/>//		} catch (Exception e) {"+
            "<<white/><<grey/><<<sp_0, br/>//			// TODO Auto-generated catch block"+
            "<<white/><<grey/><<<sp_0, br/>//			e.printStackTrace();"+
            "<<white/><<grey/><<<sp_0, br/>//		}"+
            "<<white/><<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>static<<white/> boolean initialised = false;"+
            "<<<sp_1, br/><<orange/>static<<white/> ByteArrayOutputStream byteArrayOutputStream;"+
            "<<<sp_1, br/><<orange/>static<<white/> CipherOutputStream cipherOutputStream;"+
            "<<<br,hidden/>br"+
            "<<orange/>private<<white/> <<orange/>static<<white/> byte[] encryptWithAesGcm(byte[] pla<<orange/>int<<white/>ext, <<orange/>String<<white/> password, boolean close) throws IOException, InvalidKeyException,"+
            "<<<sp_5, br/>InvalidAlgorithmParameterException, NoSuchAlgorithmException, NoSuchProviderException, NoSuchPaddingException, InvalidKeySpecException {"+
            "<<<sp_2, br/><<lightblue/>if<<white/> (initialised == false)"+
            "<<<sp_2, br/>{"+
            "<<<sp_4, br/><<orange/>system<<white/>.out.print<<white/>ln('We initialise decryption stream');"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>byte[] iv = {0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0};  <<grey/>/* | iv | = 16 x 8 bits. cipher hides patterns in the encrypted data that may allow a hacker to decrypt it by guesswork or trial and error */<<white/>"+
            "<<<sp_6, br/>IvParameterSpec ivspec = <<orange/>new<<white/> IvParameterSpec(iv);        "+
            "<<<sp_6, br/><<grey/>/* Create factory for secret keys. */<<white/>  "+
            "<<<sp_6, br/><<grey/>/* Password-Based Key Derivation Function 2 (PBKDF2) makes it harder for someone to guess your account password through a brute-force attack */<<white/>"+
            "<<<sp_6, br/>SecretKeyFactory factory = SecretKeyFactory.getInstance('PBKDF2WithHmacSHA256');  "+
            "<<<sp_6, br/><<grey/>/* PBEKeySpec class implements KeySpec interface. */<<white/>  "+
            "<<<sp_6, br/>KeySpec spec = <<orange/>new<<white/> PBEKeySpec(password.toCharArray(), SALTVALUE.getBytes(), 65536, 256);  "+
            "<<<sp_6, br/>SecretKey tmp = factory.generateSecret(spec);  "+
            "<<<sp_6, br/>SecretKeySpec secretKey = <<orange/>new<<white/> SecretKeySpec(tmp.getEncoded(), 'AES');  "+
            "<<<sp_5, br/>SecretKey randomKey = <<orange/>new<<white/> SecretKeySpec(tmp.getEncoded(), 'AES'); "+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/>Cipher cipher = Cipher.getInstance('AES/CBC/PKCS5Padding');   "+
            "<<<sp_7, br/>cipher.init(Cipher.ENCRYPT_MODE, secretKey, ivspec);  "+
            "<<<sp_7, br/>"+
            "<<<sp_5, br/>byteArrayOutputStream = <<orange/>new<<white/> ByteArrayOutputStream();"+
            "<<<sp_5, br/>cipherOutputStream = <<orange/>new<<white/> CipherOutputStream(byteArrayOutputStream, cipher);"+
            "<<<sp_5, br/>initialised = true;"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.print<<white/>ln('We write');"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<lightblue/>if<<white/> (close == true)"+
            "<<<sp_2, br/>{"+
            "<<<sp_4, br/><<orange/>system<<white/>.out.print<<white/>ln('We close stream');"+
            "<<<sp_4, br/>cipherOutputStream.close();"+
            "<<<sp_4, br/>initialised = false;"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>}<<lightblue/>else<<white/> {"+
            "<<<sp_4, br/>cipherOutputStream.write(pla<<orange/>int<<white/>ext);"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/><<orange/>return<<white/> byteArrayOutputStream.toByteArray();"+
            "<<<sp_4, br/>"+
            "<<<sp_0, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>/***"+
            "<<<sp_4, br/>* Given the byte[] representation of some plain file, and the key (password), we return its encrypted byte[] representation on success."+
            "<<<sp_4, br/>* @param file : byte[]"+
            "<<<sp_4, br/>* @param password : String"+
            "<<<sp_4, br/>* @return byte[] on success; else null"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> byte[] encryptFile(byte[] file, <<orange/>String<<white/> password)   "+
            "<<<sp_3, br/>{  "+
            "<<<sp_3, br/>try   "+
            "<<<sp_3, br/>{  "+
            "<<<sp_5, br/><<grey/>/* Declare a byte array. */<<white/>  "+
            "<<<sp_5, br/>byte[] iv = {0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0};  <<grey/>/* | iv | = 16 x 8 bits. cipher hides patterns in the encrypted data that may allow a hacker to decrypt it by guesswork or trial and error */<<white/>"+
            "<<<sp_5, br/>IvParameterSpec ivspec = <<orange/>new<<white/> IvParameterSpec(iv);        "+
            "<<<sp_5, br/><<grey/>/* Create factory for secret keys. */<<white/>  "+
            "<<<sp_5, br/><<grey/>/* Password-Based Key Derivation Function 2 (PBKDF2) makes it harder for someone to guess your account password through a brute-force attack */<<white/>"+
            "<<<sp_5, br/>SecretKeyFactory factory = SecretKeyFactory.getInstance('PBKDF2WithHmacSHA256');  "+
            "<<<sp_5, br/><<grey/>/* PBEKeySpec class implements KeySpec interface. */<<white/>  "+
            "<<<sp_5, br/>KeySpec spec = <<orange/>new<<white/> PBEKeySpec(password.toCharArray(), SALTVALUE.getBytes(), 65536, 256);  "+
            "<<<sp_5, br/>SecretKey tmp = factory.generateSecret(spec);  "+
            "<<<sp_5, br/>SecretKeySpec secretKey = <<orange/>new<<white/> SecretKeySpec(tmp.getEncoded(), 'AES');  "+
            "<<<sp_5, br/>Cipher cipher = Cipher.getInstance('AES/CBC/PKCS5Padding');   "+
            "<<<sp_5, br/>cipher.init(Cipher.ENCRYPT_MODE, secretKey, ivspec);  "+
            "<<<sp_5, br/><<orange/>return<<white/> cipher.doFinal(file);  <<grey/>/* Return encrypted value */<<white/>"+
            "<<<sp_3, br/>}   "+
            "<<<sp_3, br/><<lightblue/>catch<<white/> (Exception e)   "+
            "<<<sp_3, br/>{  "+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln('Error encrypting: ' + e.to<<orange/>String<<white/>());  "+
            "<<<sp_3, br/>}  "+
            "<<<sp_3, br/><<orange/>return<<white/> null;  "+
            "<<<sp_3, br/>}  "+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>/***"+
            "<<<sp_4, br/>* Given the byte[] representation of some encrypted file, and the key (password), we return its decrypted byte[] representation on success."+
            "<<<sp_4, br/>* @param file : byte[]"+
            "<<<sp_4, br/>* @param password : String"+
            "<<<sp_4, br/>* @return byte[] on success; else null"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> byte[] decryptFile(byte[] file, <<orange/>String<<white/> password)   {  "+
            "<<<sp_5, br/>try   "+
            "<<<sp_5, br/>{  "+
            "<<<sp_7, br/><<grey/>/* Declare a byte array. */<<white/>  "+
            "<<<sp_7, br/>byte[] iv = {0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0};  "+
            "<<<sp_7, br/>IvParameterSpec ivspec = <<orange/>new<<white/> IvParameterSpec(iv);  "+
            "<<<sp_7, br/><<grey/>/* Create factory for secret keys. */<<white/>  "+
            "<<<sp_7, br/>SecretKeyFactory factory = SecretKeyFactory.getInstance('PBKDF2WithHmacSHA256');  "+
            "<<<sp_7, br/><<grey/>/* PBEKeySpec class implements KeySpec interface. */<<white/>  "+
            "<<<sp_7, br/>KeySpec spec = <<orange/>new<<white/> PBEKeySpec(password.toCharArray(), SALTVALUE.getBytes(), 65536, 256);  "+
            "<<<sp_7, br/>SecretKey tmp = factory.generateSecret(spec);  "+
            "<<<sp_7, br/>SecretKeySpec secretKey = <<orange/>new<<white/> SecretKeySpec(tmp.getEncoded(), 'AES');  "+
            "<<<sp_7, br/>Cipher cipher = Cipher.getInstance('AES/CBC/PKCS5PADDING');  "+
            "<<<sp_8, br/>cipher.init(Cipher.DECRYPT_MODE, secretKey, ivspec);  "+
            "<<<sp_8, br/><<orange/>return<<white/> cipher.doFinal(file);  "+
            "<<<sp_5, br/>}   "+
            "<<<sp_5, br/><<lightblue/>catch<<white/> (Exception e)  {  "+
            "<<<sp_7, br/><<orange/>system<<white/>.out.print<<white/>ln('Error occured during decryption: ' + e.to<<orange/>String<<white/>());  "+
            "<<<sp_5, br/>}  "+
            "<<<sp_7, br/><<orange/>return<<white/> null;  "+
            "<<<sp_5, br/>}  "+
            "<<<sp_0, br/>}"

        },


    ]
}

export default CaroselItem;
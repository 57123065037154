import MyResume from "./Components/Pages/MyProjects/projectFiles/React/HeaderDemo/Component/Heading_Demo";
import Styling from "./Styling.module.css";
import MyProjectDemo from "./ComponentDemo/ContentCaroselDemo";
import PageWrapper from "./Components/PageWrapper/PageWrapper";
import MainBanner from "./Components/Pages/MainBanner/MainBanner"
import MyEducation from "./Components/Pages/AboutMe/Education_Training/Education";
import AboutMe from "./Components/Pages/AboutMe/Aboutme";
import ComponentDemo from "./Components/DemoWrapper/DemoWrapper";
import React,  {useRef, useLayoutEffect, useEffect, useState} from "react";
import ScrollBarMenu, {ScrollBarElement} from "./Components/scrollBarMenu/ScrollBarMenu";
import CloseButton from "./BaseProject/Components/layouts/codeDemoWindow/CustomBottoms/macButtons/MacCloseButton";
import Contact from "./Components/Pages/Contact/Contact";
import MyProject from "./Components/Pages/MyProjects/MyProject";
import ContentOverlay from "./Components/ContentOverlay/ContentOverlay";
import Resume from "./BaseProject/MyResume/Resume/Resume";
import ProjectDemo from "./ComponentDemo/Heading_Demo";
import ContentCarosel_Demo from "./ComponentDemo/ContentCaroselDemo";
import PospalDemo from "./Components/Pages/MyProjects/projectFiles/Websites/PospalDemo/PospalDemo";
import ButtonTest from "./Components/Buttons/ExpandButton/Expand_Button";
import myBrowser from "./BaseProject/Handlers/myBrowser";

function App() {

    let refer = React.useRef<HTMLDivElement>(null);


    /** Main menu scrollBar Elements */
    const scrollbar_Elements : ScrollBarElement[]=
    [
        {
            reference : useRef<HTMLDivElement>(null),
            name : "About Me"
        },
        {
            reference : useRef<HTMLDivElement>(null),
            name : "Projects"
        },
        {
            reference : useRef<HTMLDivElement>(null),
            name : "Contact"
        },
    ];

    const scrollbarElementsRef = useRef<ScrollBarElement[]>(scrollbar_Elements);
    let resumeActivator = useRef<HTMLDivElement>(null);


    // obtain current url path
    useEffect(() => {
        const getUrl = () => {
            const hash = window.location.hash;
            if (hash) {
              // Remove the leading '#' character
              const url = hash.substring(1);
              console.log("HASH = " + url);
              set_CurrentURL(url)
            }
          };

        getUrl();
      }, []); 
    

      

    let [currentURL, set_CurrentURL] = useState<String>("/");


    
    // console.log("Current url = :" + currentURL);
    switch(currentURL){
        case "/" :
            return(

                <PageWrapper>
             
 
                    <ContentOverlay name = {"Download Resume"} activationRef={resumeActivator} isPrompt={true}>
                        <Resume/>
                    </ContentOverlay>
              
                    <ScrollBarMenu hasOffset={false} ScrolBarElements={scrollbarElementsRef} mobileWidth={myBrowser.mobile_Width()}/>

                    <div ref = {scrollbar_Elements[0].reference} style = {{width:'100%'}}>
                        <MainBanner/>
                        <AboutMe/>
                    </div>
                    <div ref = {scrollbar_Elements[1].reference} style = {{width:'100%'}}>
                        <MyProject/>
                    </div>
                    
                    <div ref = {scrollbar_Elements[2].reference} style = {{width:'100%'}}>
                        <Contact/>
                    </div>
                    <div id = "resumeActivator" ref = {resumeActivator} />
                </PageWrapper>
            )
        break;
        case "demo_Project" : return <ProjectDemo/>;
        break;
        case "demo_ContentCarosel" : return <ContentCarosel_Demo/>;
        break;
        default : return <> <div style = {{width:'100vw', height :'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}> <h1> Page Not Found! </h1> </div></>;
    }
    
    return <>
        <div style = {{width:"100%", height:"100%", display:"flex", justifyContent:"center"}}>
            <h1> url - {currentURL} </h1>
        </div>
        {/* <PageWrapper>
            <ContentOverlay name = {"Full Resume"} activationRef={resumeActivator} isPrompt={true}>
                <Resume/>
            </ContentOverlay>

            <ScrollBarMenu hasOffset={false} ScrolBarElements={scrollbarElementsRef} mobileWidth={1000}   />
            
            <div ref = {scrollbar_Elements[0].reference} style = {{width:'100%'}}>
                <MainBanner/>
                <AboutMe/>
            </div>

            <div ref = {scrollbar_Elements[1].reference} style = {{width:'100%'}}>
                <MyProject/>
            </div>

            <div ref = {scrollbar_Elements[2].reference} style = {{width:'100%'}}>
                <Contact/>
            </div>

            <div id = "resumeActivator" ref = {resumeActivator} />
        </PageWrapper> */}


    </>
}


export default App;
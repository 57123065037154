import  {items} from '../../../../contentCarousel/contentCarosel';
import ComponentDemo from "../../../../DemoWrapper/DemoWrapper"; 


let CaroselItem : items =    {
    key : 1,
    Subject : "Matrix Sub-Image locate",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}
            <p>  
                This program aims to locate a subimage <span style={{fontWeight:'800'}}>K</span> within a larger image <span style = {{fontWeight:'800'}}>N</span>, represented by an n*n matrix of its RGB values. 
                The subimage K consists of identical pixel values, and is a subbset of K.

                The main objective is to capture a screenshot of the screen, then identify and display the region within the screenshot where the subimage 
                is located using Java GUI components.
            </p>

            <p style = {{textDecoration:"underline", fontWeight:"800"}}>
                Key Features:
            </p>
            <ul>
                <li>
                    <span style = {{textDecoration:"underline"}}> Image Processing :</span> Algorithms to manipulate and compare images.
                </li>
                <li>
                    <span style = {{textDecoration:"underline"}}>Graphical User Interface (GUI):</span> Employs Java GUI packages to display the results of the image recognition process in an interactive and user-friendly manner.
                </li>
                <li>
                    <span style = {{textDecoration:"underline"}}>Efficient Algorithm :</span> Implements a refined pattern searching algorithm to optimize the process of locating the subimage within the larger image.
                </li>
                <li>
                    <span style = {{textDecoration:"underline"}}> User Interaction :</span> Provides a user interface for initiating the image recognition process and displaying the results.
                </li>
            </ul>




            <p style = {{textDecoration:"underline", fontWeight:"800"}}>
                The program consist of two approaches:
            </p>

            <ul>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  Brute force solution : </span>
                        <ol>
                            <li> This approach involves traversing the original image and checking for regions where the pixel values match those of the subimage. </li>
                            <li> It compares each pixel of the subimage with the corresponding pixels in the larger image to identify identical regions.</li>
                            <li> This method is straightforward but may be computationally expensive, especially for large images. </li>
                            <li> O(K * N) time complexity</li>
                        </ol>
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  Refined Solution : </span>
                        <ol>
                            <li> Modification of the Knuth-Morris-Pratt (KMP) pattern searching algorithm was coducted to reduce overall time complexity for matrix matching </li>
                            <li> O(K + N) time complexity</li>
                        </ol>
                    </li> 
            </ul>
      







            <p style = {{fontWeight:"800", textDecoration:"underline"}}> The main components of this project include: </p>
            <ul>

                <li>
                    <span style = {{textDecoration:'underline'}}>  Main Class : </span>
                        Represents the main user interface for interacting with the image recognition system.
                        Provides methods for handling user input, such as capturing screenshots and initiating image recognition tasks.

                        This is where the main function resides.
                </li>
                <li>
                    <span style = {{textDecoration:'underline'}}>  imageRecognition Class : </span>
                    Functions utilised for recognition and processing
                </li>
                <li>
                    <span style = {{textDecoration:'underline'}}>  Capture Class : </span>
                        Utilizes java.awt and java.awt.image packages to capture screenshots & implementation of 
                        functions for capturing, resizing and saving screenshots.
                </li>
                <li>
                    <span style = {{textDecoration:'underline'}}>  drawable Interface : </span>
                        Defines a functional interface for drawing over a BufferedImage, this is to be implemented
                        by classes that need to perform drawing operations on images.
                </li>


                <li>
                    <span style = {{textDecoration:'underline'}}>  GUI Class : </span>
                        Represents a graphical user interface for displaying images within a JFrame.
                </li>
                <li>
                    <span style = {{textDecoration:'underline'}}>  ImageManipulation Class : </span>
                        Provides methods for manipulating images, such as resizing and converting to grayscale.
                </li>
                <li>
                    <span style = {{textDecoration:'underline'}}>  IO Class : </span>
                        Functions related to input/output operations related to files, including reading and writing images and text files.
                </li>

                <li>
                    <span style = {{textDecoration:'underline'}}>  mainImageReco Class : </span>
                        Implements the main logic for image recognition tasks.
                </li>

             

            </ul>






            <p style = {{textDecoration:"underline", fontWeight:"800", marginTop:'25px'}}> ScreenShots</p>
            <div style = {{width:"100%", boxSizing:'border-box'}}>
                <div style = {{width:'100%',  position:'relative'}}>
                    <p style = {{position:'absolute', color :'white', bottom:"0", left:"2%", fontWeight:"800"}}>Program start</p>
                    <img style = {{width:"100%", background:'red', height :'auto'}} src = "./images/myresume_Content/Project_Demo/SubImage_Locate/s1.png" />
                </div>
                <div style = {{width:'100%',position:'relative'}}>
                    <p style = {{position:'absolute',  color :'white', bottom:"0", left:"2%", fontWeight:"800"}}>Log Sub image location</p>
                    <img style = {{width:"100%", background:'red', height :'auto'}} src = "./images/myresume_Content/Project_Demo/SubImage_Locate/s2.png" />
                </div>
                <div style = {{width:'100%',position:'relative'}}>
                    <p style = {{position:'absolute',  color :'white', bottom:"0", left:"2%", fontWeight:"800"}}>Locate Sub image</p>
                    <img style = {{width:"100%", background:'red', height :'auto'}} src = "./images/myresume_Content/Project_Demo/SubImage_Locate/s3.png" />
                </div>
            </div>








            
        </>,
    img_Url:"./images/Project_Images/sub_MatrixL.jpg",    
    file_List : [
        {
            key : 1,
            FileTitle : "Main.java",
            content :  
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.AWTException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.MouseInfo;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Point<<white/>;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Point<<white/>erInfo;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Robot;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.event.KeyEvent;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.event.KeyListener;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.image.BufferedImage;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.swing.JFrame;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.swing.JLabel;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.swing.JPanel;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> mainUIInterface extends JFrame implements KeyListener {"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>JLabel label;"+
            "<<<sp_3, br/><<orange/>String<<white/> dialogue = ''s' to log | 't' to test | 'x' to stop test | 'q' when exit';"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>public<<white/> mainUIInterface() {"+
            "<<<sp_7, br/>super('');"+
            "<<<sp_7, br/>JPanel p = <<orange/>new<<white/> JPanel();"+
            "<<<sp_7, br/>label = <<orange/>new<<white/> JLabel(dialogue);"+
            "<<<sp_7, br/>p.add(label);"+
            "<<<sp_7, br/>add(p);"+
            "<<<sp_7, br/>addKeyListener(this);"+
            "<<<sp_7, br/>setSize(500, 100);"+
            "<<<sp_7, br/>setVisible(true);"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>@Override"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> keyTyped(KeyEvent e) {"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>Thread t;"+
            "<<<sp_3, br/>@Override"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> keyPressed(KeyEvent e) {"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (e.getKeyCode() == KeyEvent.VK_S) {"+
            "<<<sp_8, br/>label.setText(dialogue);"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.print<<white/>ln('s key pressed');"+
            "<<<sp_9, br/>Po<<orange/>int<<white/>erInfo a = MouseInfo.getPointerInfo();"+
            "<<<sp_9, br/>Po<<orange/>int<<white/> b = a.getLocation();"+
            "<<<sp_9, br/><<orange/>int<<white/> x = (<<orange/>int<<white/>) b.getX();"+
            "<<<sp_9, br/><<orange/>int<<white/> y = (<<orange/>int<<white/>) b.getY();"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println(x + ' ' + y);"+
            "<<<sp_9, br/>findIt(x/2,y/2);"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<lightblue/>else<<white/>"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (e.getKeyCode() == KeyEvent.VK_Q) {"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println('q key pressed');"+
            "<<<sp_9, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (e.getKeyCode() == KeyEvent.VK_T) {"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println('t key pressed');"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (fileN == null)"+
            "<<<sp_11, br/>label.setText( '<html><body> ' + dialogue + '<br><br>log a file first</body></html>');"+
            "<<<sp_9, br/><<lightblue/>else<<white/>"+
            "<<<sp_9, br/>{"+
            "<<<sp_10, br/><<lightblue/>if<<white/> (t != null && t.isAlive()) t.interrupt();      "+
            "<<<sp_10, br/>"+
            "<<<sp_10, br/>t = <<orange/>new<<white/> Thread(<<orange/>new<<white/> mainImageReco( <<orange/>new<<white/> mainImageReco.ImageRecoParam(1, fileN, this) ));"+
            "<<<sp_10, br/>t.start();            		"+
            "<<<sp_10, br/>label.setText( '<html><body> ' + dialogue + '<br><br>Current Looking<<lightblue/>for<<white/> '+fileN+ ' </body></html>');"+
            "<<<sp_9, br/>};"+
            "<<<sp_7, br/>}<<lightblue/>else<<white/> <<lightblue/>if<<white/> (e.getKeyCode() == KeyEvent.VK_X) {"+
            "<<<sp_8, br/><<lightblue/>if<<white/> (t != null && t.isAlive()) t.interrupt();            	"+
            "<<<sp_8, br/>label.setText( '<html><body> ' + dialogue + '<br><br>Log '+fileN+ ' has been stopped</body></html>');"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> SetTitle(<<orange/>String<<white/> t) {"+
            "<<<sp_5, br/>label.setText( '<html><body> ' + dialogue + '<br><br>'+t+ ' </body></html>');"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>@Override"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> keyReleased(KeyEvent e) {"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>String<<white/> fileN;"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> findIt(<<orange/>int<<white/> x, <<orange/>int<<white/> y) {"+
            "<<<sp_5, br/>BufferedImage b;"+
            "<<<sp_5, br/>ImageProcessing p = <<orange/>new<<white/> ImageProcessing();"+
            "<<<sp_4, br/>BufferedImage ss =  <<orange/>new<<white/> BufferedImage(20, 20, BufferedImage.TYPE_INT_RGB);		 	"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>try {"+
            "<<<sp_5, br/>"+
            "<<<sp_7, br/>capture.screenCapture('screens', true);"+
            "<<<sp_5, br/>b = IO.readImage('screens');"+
            "<<<sp_5, br/>BufferedImage subImage =  p.geImageSubset(b, x, y, ss.getWidth(), ss.getHeight());"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (subImage == null) "+
            "<<<sp_5, br/>{"+
            "<<<sp_10, br/>label.setText( '<html><body> ' + dialogue + '<br><br>Invalid offset, too close to edge of screen '+fileN+ ' </body></html>');"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<orange/>return<<white/>;"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>fileN = 	IO.generateValidFileName('/image2click');"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>IO.writeImage('/image2click/'+ fileN, (s) -> {<<orange/>return<<white/> s; } , subImage);"+
            "<<<sp_9, br/>label.setText( '<html><body> ' + dialogue + '<br><br>LogFile saved as : '+ fileN +'.png</body></html>');"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<orange/>int<<white/> [] sss = <<orange/>new<<white/> <<orange/>int<<white/>[2];"+
            "<<<sp_7, br/>sss[0] = x; sss[1] = y;"+
            "<<<sp_5, br/>p.highLightArea(sss, ss, b);"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (IOException e) {"+
            "<<<sp_5, br/><<grey/>// TODO Auto-generated catch block"+
            "<<white/><<<sp_5, br/>e.printStackTrace();"+
            "<<<sp_3, br/>}"+
            "<<<sp_2, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(<<orange/>String<<white/>[] args) {"+
            "<<<sp_5, br/>"+
            "<<<sp_7, br/>JFrame f  = <<orange/>new<<white/> mainUIInterface();"+
            "<<<sp_7, br/>f.setAlwaysOnTop(true);"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/>"+
            "<<<sp_3, br/>}"
        },
        {
            key : 2,
            FileTitle : "mainImageReco.java",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.AWTException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Color;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Graphics2D;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.image.BufferedImage;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Robot;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.event.InputEvent;"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> mainImageReco implements Runnable {"+
            "<<<sp_2, br/><<orange/>static<<white/> <<orange/>class<<white/> ImageRecoParam"+
            "<<<sp_2, br/>{	"+
            "<<<sp_4, br/>ImageRecoParam(<<orange/>int<<white/> t, <<orange/>String<<white/> f, mainUIInterface p  ){"+
            "<<<sp_6, br/>type = t;"+
            "<<<sp_6, br/>fileN = f;"+
            "<<<sp_6, br/>this.p = p;"+
            "<<<sp_4, br/>}"+
            "<<<sp_4, br/><<orange/>int<<white/> type;"+
            "<<<sp_4, br/><<orange/>String<<white/> fileN;"+
            "<<<sp_4, br/>mainUIInterface p; "+
            "<<<sp_2, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_2, br/><<orange/>static<<white/> ImageProcessing imageprocess = <<orange/>new<<white/> ImageProcessing();"+
            "<<<sp_2, br/><<orange/>private<<white/> <<orange/>static<<white/> Robot r;"+
            "<<<sp_2, br/><<orange/>private<<white/> ImageRecoParam p;"+
            "<<<sp_2, br/><<orange/>private<<white/> GUI gui = <<orange/>new<<white/> GUI('screen');	"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<grey/>/**"+
            "<<<sp_3, br/>* @param param object { int type = {0=writeScript, 1 = locate(file_name)};,  String file_name; } "+
            "<<<sp_3, br/>*/<<white/>"+
            "<<<sp_2, br/>mainImageReco(ImageRecoParam p){"+
            "<<<sp_4, br/>this.p = p;"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/>"+
            "<<<sp_1, br/>@Override"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> run() "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>try {"+
            "<<<sp_5, br/>Thread.sleep(0);"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>import<<white/> (p.type) {"+
            "<<<sp_5, br/><<lightblue/>case<<white/> 0 :"+
            "<<<sp_7, br/>try"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/>writeScriptHere();"+
            "<<<sp_1, br/>"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<lightblue/>catch<<white/>(AWTException | InterruptedException | IOException e)"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/>e.print<<white/>StackTrace();"+
            "<<<sp_7, br/>}"+
            "<<<sp_5, br/><<orange/>break<<white/>;"+
            "<<<sp_5, br/><<lightblue/>case<<white/> 1 : locate(p.fileN);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>}<<lightblue/>catch<<white/> (InterruptedException e)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('i have been interrupted');"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>gui.closeGui();"+
            "<<<sp_3, br/><<orange/>return<<white/>;"+
            "<<<sp_3, br/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> writeScriptHere() throws AWTException, InterruptedException, IOException "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>locate('1');"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>int<<white/>[] locate(<<orange/>String<<white/> n)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>try {"+
            "<<grey/><<<sp_0, br/>//			BufferedImage b =IO.readImage('/image2click/' + n);"+
            "<<white/><<grey/><<<sp_0, br/>//			IO.writeImage('image2click/dontTouchFolder/' + n + '_69me', (ss) ->{ return ss;}, ImageManipulation.reConfigureImage(b,  b.getWidth()/2,  b.getHeight()/2, true) );"+
            "<<white/><<<sp_5, br/><<orange/>return<<white/> mf( IO.readImage('/image2click/' + n ), n );"+
            "<<<sp_3, br/>}<<lightblue/>catch<<white/>(Exception e)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('Cannot locate ur file : ' + n);"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('restart program ');"+
            "<<<sp_5, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> null;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>int<<white/>[] mf(BufferedImage l, <<orange/>String<<white/> name) throws InterruptedException, AWTException"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>BufferedImage screen;"+
            "<<<sp_3, br/>try {"+
            "<<<sp_5, br/><<orange/>int<<white/> count =0;"+
            "<<<sp_5, br/><<lightblue/>while<<white/>(true)"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/>screen = capture.screenCapture('screen', true);"+
            "<<<sp_7, br/>Thread.sleep(0);"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<orange/>int<<white/>[] f = imageprocess.findMatchingOnce(screen, l);"+
            "<<<sp_7, br/>p.p.SetTitle('Attemping to look log : ' + name +  ' - [' + count++ +']');"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (f != null)"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/>imageprocess.highLightArea(f, l, screen);"+
            "<<<sp_8, br/>p.p.SetTitle('Location ' + name + ' Found!');"+
            "<<<sp_8, br/>gui.display('Found');"+
            "<<<sp_8, br/>Thread.sleep(3000);"+
            "<<<sp_8, br/><<orange/>return<<white/> f;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.print<<white/>ln('Cannot find : ' + name);		"+
            "<<<sp_5, br/>}			"+
            "<<<sp_3, br/>}<<lightblue/>catch<<white/>(IOException |  InterruptedException e )"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln(e.getMessage());"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> null;"+
            "<<<sp_1, br/>}	"+
            "<<<sp_1, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> Click(<<orange/>int<<white/>[] f, <<orange/>int<<white/> w) throws AWTException, InterruptedException"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>Thread.sleep(w);"+
            "<<<sp_3, br/>r.mouseMove(f[0]*2, f[1]*2);"+
            "<<<sp_3, br/>r.mousePress(InputEvent.BUTTON1_DOWN_MASK);"+
            "<<<sp_5, br/>r.mouseRelease(InputEvent.BUTTON1_DOWN_MASK);	"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"
        },
        {
            key : 3,
            FileTitle : "IO.java",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Rectangle;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.image.BufferedImage;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.BufferedOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.BufferedReader;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.DataOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.File;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileNotFoundException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileReader;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileWriter;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.RandomAccessFile;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.nio.ByteBuffer;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.nio.channels.FileChannel;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.imageio.ImageIO;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> IO {"+
            "<<<sp_1, br/><<orange/>static<<white/> <<orange/>String<<white/> directory = <<orange/>system<<white/>.getProperty('user.dir');"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Store given string content onto file-system of path 'user.dir + loc_name' "+
            "<<<sp_2, br/>* '.txt' format is assumed by default and therefore is omitted in name parameter loc_name"+
            "<<<sp_2, br/>* @param String loc_Name "+
            "<<<sp_2, br/>* @param content (String)"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> writeTxt(<<orange/>String<<white/> loc_Name, <<orange/>String<<white/> content)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<grey/>//content = 'hgello';"+
            "<<white/><<<sp_3, br/>try (FileWriter file = <<orange/>new<<white/> FileWriter(loc_Name+'.txt')){"+
            "<<<sp_4, br/>file.write(content);"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (IOException e) {"+
            "<<<sp_4, br/>e.print<<white/>StackTrace();"+
            "<<<sp_3, br/>}		"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Append a given file 'user.dir + fileName' onto console. "+
            "<<<sp_2, br/>* '.txt' is assumed to be the default format and therefore is omitted in name parameter fileName. "+
            "<<<sp_2, br/>* @param fileName (String)"+
            "<<<sp_2, br/>* @param delimiter (String)"+
            "<<<sp_2, br/>* @return Formatted String representation"+
            "<<<sp_2, br/>* @throws FileNotFoundException"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>String<<white/> readTxt(<<orange/>String<<white/> fileName, <<orange/>String<<white/> delimiter) throws FileNotFoundException"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>String<<white/>Builder b = <<orange/>new<<white/> <<orange/>String<<white/>Builder();"+
            "<<<sp_3, br/>Scanner scan = <<orange/>new<<white/> Scanner(<<orange/>new<<white/> BufferedReader(<<orange/>new<<white/> FileReader(fileName+'.txt')));"+
            "<<<sp_3, br/>scan.useDelimiter(delimiter);"+
            "<<<sp_3, br/><<lightblue/>while<<white/>(scan.hasNext() ) b.append(scan.next() +'\n');"+
            "<<<sp_3, br/><<orange/>return<<white/> b.to<<orange/>String<<white/>();"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Store given bufferedImage onto file-system given by path 'user.dir + File_Name'."+
            "<<<sp_2, br/>* Stored_Image is subject to the drawable implementation"+
            "<<<sp_2, br/>* @param File_Name"+
            "<<<sp_2, br/>* @param d - drawableFunctionalInterface"+
            "<<<sp_2, br/>* @param img - bufferedImage to draw over"+
            "<<<sp_2, br/>* @return true if write success, else false."+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> boolean  writeImage(<<orange/>String<<white/> Filename, drawable d, BufferedImage img)"+
            "<<<sp_1, br/>{"+
            "<<<sp_4, br/>try "+
            "<<<sp_4, br/>{"+
            "<<<sp_6, br/>ImageIO.write( d.draw(img),'PNG', <<orange/>new<<white/> File( directory+ '/' + Filename+'.png') );"+
            "<<<sp_4, br/>} "+
            "<<<sp_4, br/><<lightblue/>catch<<white/> (Exception i) "+
            "<<<sp_4, br/>{"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_4, br/>} "+
            "<<<sp_4, br/><<orange/>return<<white/> true;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Returns a BufferedImage as the result of decoding a supplied File by the imageReader given through the "+
            "<<<sp_2, br/>* 'Filename' parameter. This file must reside within the 'user.dir' directory. (i.e. : FileName + user.dir)"+
            "<<<sp_2, br/>* '.png' is assumed to be the default format, hence is ommited in passing parameter 'Filename'"+
            "<<<sp_2, br/>* @param Filename"+
            "<<<sp_2, br/>* @return BufferedImage representation of image or NULL."+
            "<<<sp_2, br/>* @throws IOException  "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> BufferedImage readImage(<<orange/>String<<white/> Filename) throws IOException"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>return<<white/> ImageIO.read(<<orange/>new<<white/> File(directory + '/'+ Filename+'.png'));"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/* "+
            "<<<sp_2, br/>* Given a path rooted at user.dir, we return the value x ￢ﾈﾈ N s.t. ￂﾬ￢ﾈﾃ x.png ￢ﾈﾈ 'user.dir + path'."+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>String<<white/> generateValidFileName(<<orange/>String<<white/> path) "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; ; i++ )"+
            "<<<sp_4, br/><<lightblue/>if<<white/> (<<orange/>new<<white/> File(directory +  path + '/' + i + '.png').exists() == false) "+
            "<<<sp_5, br/><<orange/>return<<white/> <<orange/>String<<white/>.valueOf(i);"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>}"+
            "<<<sp_0, br/>}"
        },

        {
            key : 4,
            FileTitle : "ImageProcessing.java",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Color;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Graphics2D;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.RenderingHint<<white/>s;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.image.BufferedImage;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.File;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.imageio.ImageIO;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> ImageProcessing extends IO"+
            "<<<sp_0, br/>{"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/** ALGORITHM CAN BE IMPROVED!! #NOT EFFICIENT. ."+
            "<<<sp_2, br/>* Alternative method to locate image. Unlike findMatch() where it first processes BufferedImage into an imageMap object, this method disregards that and processes"+
            "<<<sp_2, br/>* the bufferedImage directly, saving time to O(n) . "+
            "<<<sp_2, br/>* @param base BufferedImage"+
            "<<<sp_2, br/>* @param locate BufferdImage"+
            "<<<sp_2, br/>* @return int array representation of starting pos[x][y] WHERE 0 = x and 1 = y --> I.E : String[0] = 100, String[1] = 100 "+
            "<<<sp_2, br/>* @throws IOException"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>int<<white/>[] findMatchingOnce (BufferedImage baseImg, BufferedImage locateImg) throws IOException"+
            "<<<sp_1, br/>{	"+
            "<<<sp_3, br/><<orange/>int<<white/> popX = -1;"+
            "<<<sp_3, br/><<orange/>int<<white/> popY = -1;"+
            "<<<sp_3, br/><<orange/>int<<white/> lY =0;"+
            "<<<sp_3, br/><<orange/>int<<white/> lX =0;"+
            "<<<sp_3, br/>for(<<orange/>int<<white/> y =0 ; y < baseImg.getHeight(); y++)<<grey/>//(1)"+
            "<<white/><<<sp_5, br/>for( <<orange/>int<<white/> x = (popY == -1) ? 0 : popX ; x < baseImg.getWidth(); x++) <<grey/>//(1)"+
            "<<white/><<<sp_5, br/>{"+
            "<<<sp_7, br/><<lightblue/>if<<white/> ((baseImg.getRGB(x,y) == locateImg.getRGB(lX,lY) && !(x == baseImg.getWidth()-1 && (locateImg.getWidth() - lX) > 0) ))<<grey/>//(2)"+
            "<<white/><<<sp_7, br/>{"+
            "<<<sp_9, br/><<lightblue/>if<<white/>(popX == -1) popX = x;"+
            "<<<sp_9, br/><<lightblue/>if<<white/>(lX == locateImg.getWidth()-1) <<grey/>//(3)"+
            "<<white/><<<sp_9, br/>{"+
            "<<<sp_11, br/><<lightblue/>if<<white/>(lY == locateImg.getHeight()-1)  <<grey/>//FOUND. RETURN MATCH"+
            "<<white/><<<sp_12, br/><<orange/>return<<white/> <<orange/>new<<white/> <<orange/>int<<white/>[] { popX, popY };"+
            "<<<sp_11, br/><<lightblue/>if<<white/>(popY ==-1) popY = y;"+
            "<<<sp_11, br/>lY++;"+
            "<<<sp_11, br/>lX =0;"+
            "<<<sp_11, br/><<orange/>break<<white/>;"+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/>lX++;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<lightblue/>else<<white/> "+
            "<<<sp_7, br/>{ <<grey/>//(4)"+
            "<<white/><<<sp_9, br/><<lightblue/>if<<white/> (popX != -1) x = popX;"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (popY != -1) y = popY;"+
            "<<<sp_9, br/>popX = -1;"+
            "<<<sp_9, br/>popY = -1;"+
            "<<<sp_9, br/>lX =0;"+
            "<<<sp_9, br/>lY =0;"+
            "<<<sp_7, br/>}"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> null;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/** NEEEDS TO BE UPDATED!"+
            "<<<sp_2, br/>* Finding matching RBG, this method saves the buffeeredImages into a Imagemap object for better manipulation. Increase processing time & memory as sacrifice. "+
            "<<<sp_2, br/>* fileName param input (E.g : screen) is assumed to be .png format. Directory of file is located in the capture class"+
            "<<<sp_2, br/>* TimeComplexity = O(n2). for iteration of N into imageMap and processing of ImageMap"+
            "<<<sp_2, br/>* @param Base Image"+
            "<<<sp_2, br/>* @param find Image"+
            "<<<sp_2, br/>* @return int array representation of starting pos[x][y] WHERE 0 = x and 1 = y --> I.E : String[0] = 100, String[1] = 100 "+
            "<<<sp_2, br/>* @throws Processing Exception"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>int<<white/>[] findMatching (BufferedImage b, BufferedImage l) throws IOException"+
            "<<<sp_1, br/>{	"+
            "<<<sp_3, br/>ImageMap locateImg = getImageMap(l);"+
            "<<<sp_3, br/>ImageMap baseImg = getImageMap(b);"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* loop through base_image (1)"+
            "<<<sp_4, br/>* 		if BASE image matches locateImage (2)"+
            "<<<sp_4, br/>*  		we loop through remaining iterations x+y (3)"+
            "<<<sp_4, br/>*  		if (mismatch) we revert back to original position (4)"+
            "<<<sp_4, br/>*  "+
            "<<<sp_4, br/>*  (1) "+
            "<<<sp_4, br/>*   	for Y < baseImg.height		"+
            "<<<sp_4, br/>*   		for X < baseImg.Width --> X = 0 IF popY has not been assigned, else position will start as saving point for next block"+
            "<<<sp_4, br/>*  (2-3) "+
            "<<<sp_4, br/>*   	if matching index of baseImg and locateImg Index ( baseImg[Y][X] == locateImg[lY, lX]) --> save currentPos if not saved already (POPX)"+
            "<<<sp_4, br/>*     		"+
            "<<<sp_4, br/>*    		if locageImageIndex EQUALS locateImg.width -->  we iterate next block as whole width matches"+
            "<<<sp_4, br/>*    			Y++ to iterate baseImg height "+
            "<<<sp_4, br/>*     			lY++ to iterate locateImg height "+
            "<<<sp_4, br/>*    			we break out of inner loop (Break) return back to loop1(height)"+
            "<<<sp_4, br/>*    "+
            "<<<sp_4, br/>*   		iterate locateImageIndex (lX++) "+
            "<<<sp_4, br/>*  "+
            "<<<sp_4, br/>*  (4)"+
            "<<<sp_4, br/>*  	if not matching indexOf baseImg and locateImg index --> pop position back to prev"+
            "<<<sp_4, br/>*   		Y = popY --> if height has been iterated "+
            "<<<sp_4, br/>*   		X = popX --> if width has been iterated"+
            "<<<sp_4, br/>*    		reset popX and popY"+
            "<<<sp_4, br/>*  "+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> popX = -1;"+
            "<<<sp_3, br/><<orange/>int<<white/> popY = -1;"+
            "<<<sp_3, br/><<orange/>int<<white/> lY =0;"+
            "<<<sp_3, br/><<orange/>int<<white/> lX =0;"+
            "<<<sp_3, br/>for(<<orange/>int<<white/> y =0 ; y < baseImg.y; y++)<<grey/>//(1)"+
            "<<white/><<<sp_5, br/>for( <<orange/>int<<white/> x = (popY == -1) ? 0 : popX ; x < baseImg.x; x++) <<grey/>//(1)"+
            "<<white/><<<sp_5, br/>{"+
            "<<<sp_7, br/><<lightblue/>if<<white/> ((baseImg.rgb[y][x] == locateImg.rgb[lY][lX]) && !(x == baseImg.x-1 && (locateImg.x - lX) > 0) )<<grey/>//(2)"+
            "<<white/><<<sp_7, br/>{"+
            "<<<sp_9, br/><<lightblue/>if<<white/>(popX == -1) popX = x;"+
            "<<<sp_9, br/><<lightblue/>if<<white/>(lX == locateImg.x-1) <<grey/>//(3)"+
            "<<white/><<<sp_9, br/>{"+
            "<<<sp_11, br/><<lightblue/>if<<white/>(lY == locateImg.y-1)  <<grey/>//FOUND. RETURN MATCH"+
            "<<white/><<<sp_12, br/><<orange/>return<<white/> <<orange/>new<<white/> <<orange/>int<<white/>[] { popX, popY };"+
            "<<<sp_11, br/><<lightblue/>if<<white/>(popY ==-1) popY = y;"+
            "<<<sp_11, br/>lY++;"+
            "<<<sp_11, br/>lX =0;"+
            "<<<sp_11, br/><<orange/>break<<white/>;"+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/>lX++;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<lightblue/>else<<white/> "+
            "<<<sp_7, br/>{ <<grey/>//(4)"+
            "<<white/><<<sp_9, br/><<lightblue/>if<<white/> (popX != -1) x = popX;"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (popY != -1) y = popY;"+
            "<<<sp_9, br/>popX = -1;"+
            "<<<sp_9, br/>popY = -1;"+
            "<<<sp_9, br/>lX =0;"+
            "<<<sp_9, br/>lY =0;"+
            "<<<sp_7, br/>}"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> null;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* This class formats files to be processed by sub-Func. Used when input is a String(File location."+
            "<<<sp_2, br/>* @param fileName (E.g : screen) //.png format is assumed . Directory of file is located in the capture class"+
            "<<<sp_2, br/>* @return ImageMap class of formatted bufferedImage if exist , else throws IOException"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> ImageMap getImageMap(<<orange/>String<<white/> fileName) throws IOException"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>try "+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>BufferedImage in = IO.readImage(fileName);"+
            "<<<sp_5, br/><<orange/>return<<white/> getImageMap(in);"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (IOException e) {"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('File ' + fileName+' Doese not exit!');"+
            "<<<sp_5, br/>throw <<orange/>new<<white/> IOException(e);"+
            "<<<sp_3, br/>}	    		"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* ReadPixels of bufferedImage and returns a structured imageMap representation of image in RGB. "+
            "<<<sp_2, br/>* Complexity : "+
            "<<<sp_2, br/>* 		TimeComplexity = O(n) where n = x+y;"+
            "<<<sp_2, br/>* 		Memory = O(n) where n = size of array x+y"+
            "<<<sp_2, br/>* @param in BufferedImage"+
            "<<<sp_2, br/>* @return ImageMap class of formatted bufferedImage"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> ImageMap getImageMap(BufferedImage in)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>int<<white/> height = in.getHeight();"+
            "<<<sp_3, br/><<orange/>int<<white/> width = in.getWidth();"+
            "<<<sp_3, br/>ImageMap im = <<orange/>new<<white/> ImageMap(width, height);		"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>for(<<orange/>int<<white/> y = 0; y < height; y++)"+
            "<<<sp_5, br/>for(<<orange/>int<<white/> x =0; x< width; x++)"+
            "<<<sp_7, br/>im.rgb[y][x] = in.getRGB(x,y);"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> im;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>class<<white/> ImageMap"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>int<<white/> x,y;"+
            "<<<sp_3, br/><<orange/>int<<white/> [<<grey/>/* Y */<<white/>][<<grey/>/* X */<<white/>] rgb; "+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>ImageMap( <<orange/>int<<white/> x, <<orange/>int<<white/> y )"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>this.x = x;"+
            "<<<sp_5, br/>this.y = y;"+
            "<<<sp_5, br/>rgb = <<orange/>new<<white/> <<orange/>int<<white/>[y][x];"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<grey/>/* O(n) time */<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>String<<white/> toString<<white/>()"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>String<<white/>Builder b = <<orange/>new<<white/> <<orange/>String<<white/>Builder();"+
            "<<<sp_5, br/>processRgb(b,0,0);"+
            "<<<sp_5, br/><<orange/>return<<white/> b.toString<<white/>();"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* Recursive technique to process rgb into a stringBuilder reference"+
            "<<<sp_4, br/>* Complexity : O(n) run time "+
            "<<<sp_4, br/>* @param b (StringBuilder reference)"+
            "<<<sp_4, br/>* @param x "+
            "<<<sp_4, br/>* @param y"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> processRgb(<<orange/>String<<white/>Builder b, <<orange/>int<<white/> x, <<orange/>int<<white/> y)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (y  == this.y) <<orange/>return<<white/>; <<grey/>//baseCase"+
            "<<white/><<<sp_5, br/>"+
            "<<<sp_5, br/>b.append('[y:'+ y + ',' + 'x:'+x +']'+rgb[y][x] + '\n');"+
            "<<<sp_5, br/>x++;"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (x == this.x)"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/>x = 0;"+
            "<<<sp_7, br/>y++;"+
            "<<<sp_7, br/>b.append('\n\n');"+
            "<<<sp_5, br/>}			"+
            "<<<sp_5, br/>processRgb(b, x, y);"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/><<grey/>/**"+
            "<<<sp_3, br/>* Given a buffered_image 'screen', 'locate', we highlight outer-Region :"+
            "<<<sp_3, br/>* <screen[x]][y], .. , screen[locate.width][locate.height]> where x = f[0], y = f[1]. "+
            "<<<sp_3, br/>* "+
            "<<<sp_3, br/>* @param f[k]. Represent the starting index s.t. |k| = 1 where k = 0 represent 'x', k = 1 represent 'y'.  "+
            "<<<sp_3, br/>* @param locate"+
            "<<<sp_3, br/>* @param screen "+
            "<<<sp_3, br/>*/<<white/>"+
            "<<<sp_2, br/><<orange/>public<<white/> <<orange/>void<<white/> highLightArea(<<orange/>int<<white/>[] f, BufferedImage locate, BufferedImage screen)"+
            "<<<sp_2, br/>{"+
            "<<<sp_6, br/>IO.writeImage('Found', (s) ->"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/>Graphics2D graphics = s.createGraphics();"+
            "<<<sp_7, br/>graphics.setColor ( <<orange/>new<<white/> Color ( 255, 0, 0 ) );"+
            "<<<sp_7, br/>graphics.drawLine(f[0], f[1], <<grey/>/* | */<<white/> f[0]+locate.getWidth(), f[1]); <<grey/>//width top"+
            "<<white/><<<sp_7, br/>graphics.drawLine(f[0], f[1] + locate.getHeight(),  <<grey/>/* | */<<white/>  f[0]+locate.getWidth(), f[1]+ locate.getHeight()); <<grey/>//width bottom"+
            "<<white/><<<sp_7, br/>graphics.drawLine(f[0], f[1] , <<grey/>/* | */<<white/> f[0]  , f[1]+ locate.getHeight()); <<grey/>//height beginning"+
            "<<white/><<<sp_7, br/>graphics.drawLine(f[0]+locate.getWidth(), f[1] , <<grey/>/* | */<<white/> f[0]+locate.getWidth()  , f[1]+ locate.getHeight()); <<grey/>//height end"+
            "<<white/><<<sp_7, br/>graphics.drawChars(<<orange/>new<<white/> <<orange/>char<<white/>[] {'h','e','r','e' } ,0, 4, f[0], f[1]);"+
            "<<<sp_7, br/><<orange/>return<<white/>  s;"+
            "<<<sp_5, br/>}, screen);"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/><<grey/>/**"+
            "<<<sp_3, br/>* Return a new bufferdImage 'j' ￢ﾊﾆ orig s.t. j[0][0], .. , j[w][h] = orig[x][y], ..., orig[x+w][y+h]"+
            "<<<sp_3, br/>* 	where x,y mark the beginning index, and w=width,h=height mark the area matrix. "+
            "<<<sp_3, br/>* @param orig buffered image"+
            "<<<sp_3, br/>* @param x "+
            "<<<sp_3, br/>* @param y"+
            "<<<sp_3, br/>* @param w"+
            "<<<sp_3, br/>* @param h"+
            "<<<sp_3, br/>* @return new bufferedImage 'j'.  "+
            "<<<sp_3, br/>*/<<white/>"+
            "<<<sp_2, br/><<orange/>public<<white/> BufferedImage geImageSubset (BufferedImage orig, <<orange/>int<<white/> x, <<orange/>int<<white/> y, <<orange/>int<<white/> w, <<orange/>int<<white/> h)"+
            "<<<sp_2, br/>{"+
            "<<<sp_4, br/>BufferedImage r = <<orange/>new<<white/> BufferedImage(w, h, BufferedImage.TYPE_INT_RGB);"+
            "<<<sp_4, br/><<lightblue/>if<<white/> (h + y > orig.getHeight() || w +x > orig.getWidth())"+
            "<<<sp_4, br/>{"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println('Invalid offset, too close to edge of screen');"+
            "<<<sp_6, br/><<orange/>return<<white/> null;"+
            "<<<sp_4, br/>}"+
            "<<<sp_4, br/>for(<<orange/>int<<white/> yy=0; yy < h; yy++)"+
            "<<<sp_6, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> xx =0; xx < w; xx++)"+
            "<<<sp_6, br/>{"+
            "<<<sp_8, br/><<orange/>int<<white/> rgb = orig.getRGB(x+xx, y + yy);"+
            "<<<sp_8, br/>r.setRGB(xx, yy, rgb);"+
            "<<<sp_6, br/>}"+
            "<<<sp_4, br/><<orange/>return<<white/> r;"+
            "<<<sp_2, br/>}"+
            "<<<sp_0, br/>}"
        },

        {
            key : 5,
            FileTitle : "ImageManipulation.java",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Graphics;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Graphics2D;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.RenderingHint<<white/>s;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.image.BufferedImage;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> ImageManipulation  {"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Reconfigure and return a new bufferdImage of new dimension/and color_filter.  "+
            "<<<sp_2, br/>* @param bufferedImage"+
            "<<<sp_2, br/>* @param x (width) be the new width"+
            "<<<sp_2, br/>* @param y (height) be the new height"+
            "<<<sp_2, br/>* @param greyScale s.t. true = add greyscale, else rgb; "+
            "<<<sp_2, br/>* @return new reconfigured BufferedImage"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> BufferedImage reConfigureImage(BufferedImage b, <<orange/>int<<white/> x, <<orange/>int<<white/> y, boolean greyScale)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>BufferedImage resized = <<orange/>new<<white/> BufferedImage(x, y, (greyScale ) ? BufferedImage.TYPE_BYTE_BINARY :BufferedImage.TYPE_INT_RGB ); "+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>Graphics2D g = resized.createGraphics();"+
            "<<<sp_3, br/>g.setRenderingHint<<white/>(RenderingHints.KEY_INTERPOLATION,RenderingHints.VALUE_INTERPOLATION_BILINEAR);"+
            "<<<sp_3, br/>g.drawImage(b, 0, 0, x, y, 0, 0, b.getWidth(), b.getHeight(), null);"+
            "<<<sp_3, br/>g.dispose();"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> resized;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* return new grey_scaled bufferdImage object"+
            "<<<sp_2, br/>* @param original bufferdImage"+
            "<<<sp_2, br/>* @return grey_scaled bufferdImage "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> BufferedImage greyScale(BufferedImage b) {"+
            "<<<sp_4, br/>BufferedImage result = <<orange/>new<<white/> BufferedImage("+
            "<<<sp_5, br/>b.getWidth(),"+
            "<<<sp_5, br/>b.getHeight(),"+
            "<<<sp_5, br/>BufferedImage.TYPE_BYTE_BINARY);"+
            "<<<sp_4, br/>Graphics2D gg = result.createGraphics();"+
            "<<<sp_4, br/>gg.drawImage(b, 0, 0, null);"+
            "<<<sp_4, br/>gg.dispose();"+
            "<<<sp_4, br/>"+
            "<<<sp_4, br/><<orange/>return<<white/> result;"+
            "<<<sp_1, br/>}"+
            "<<<sp_0, br/>}"+
            ""
          
        },
        {
            key : 6,
            FileTitle : "GUI.java",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Canvas;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Graphics;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Graphics2D;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Image;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Toolkit;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.event.WindowEvent;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.image.BufferedImage;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.File;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.imageio.ImageIO;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.swing.JFrame;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.swing.WindowConstants;"+
            "<<grey/><<<sp_0, br/>/*"+
            "*  This class is used in conjunction with capture. "+
            "*  It will display capturedScreen within a JFrame container"+
            "*  Directory location is inherited from IO_class.directory"+
            "*/<<white/>"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> GUI extends Canvas  {"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>int<<white/> width,height;"+
            "<<<sp_1, br/><<orange/>private<<white/> BufferedImage in;"+
            "<<<sp_1, br/><<orange/>private<<white/> JFrame f;"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>String<<white/> fileName = ''; <<grey/>/* FileName to display. Type is PNG by default */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>GUI(<<orange/>String<<white/> fileName)"+
            "<<<sp_2, br/>{"+
            "<<<sp_4, br/>f = <<orange/>new<<white/> JFrame();"+
            "<<<sp_4, br/>width = capture.screenSize.width/2;"+
            "<<<sp_4, br/>height = capture.screenSize.height/2;"+
            "<<<sp_5, br/>f.setSize(width+0,height+30);  "+
            "<<<sp_5, br/>f.setVisible(false);  "+
            "<<<sp_5, br/>f.setDefaultCloseOperation(WindowConstants.EXIT_ON_CLOSE);"+
            "<<<sp_5, br/>f.setAlwaysOnTop(true);"+
            "<<<sp_5, br/><<orange/>int<<white/> w=f.getWidth(), h=f.getHeight();"+
            "<<<sp_5, br/>f.setLocation(capture.screenSize.width/2-w/2, capture.screenSize.height/2-h/2);"+
            "<<<sp_2, br/>}"+
            "<<<sp_5, br/>"+
            "<<<sp_2, br/><<grey/>/*"+
            "<<<sp_3, br/>* Display file given by 'fileName' via JFrame."+
            "<<<sp_3, br/>* FileName is a file residing in the user.dir path, '.png' format is assumed hence ommited in parameter 'fileName'. "+
            "<<<sp_3, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> display(<<orange/>String<<white/> FileName) "+
            "<<<sp_1, br/>{"+
            "<<<sp_2, br/>this.fileName = FileName;"+
            "<<<sp_5, br/>f.add(GUI.this);  "+
            "<<<sp_5, br/>f.setVisible(true);  "+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/* hides JFrame window */<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> closeGui()"+
            "<<<sp_1, br/>{"+
            "<<<sp_2, br/>f.setVisible(false);"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/* Close JFrame window */<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> exit() {"+
            "<<<sp_2, br/>f.dispatchEvent(<<orange/>new<<white/> WindowEvent(f, WindowEvent.WINDOW_CLOSING));"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>@Override"+
            "<<<sp_2, br/><<orange/>public<<white/> <<orange/>void<<white/> paint(Graphics g)"+
            "<<<sp_2, br/>{  "+
            "<<<sp_4, br/>try "+
            "<<<sp_4, br/>{	"+
            "<<<sp_5, br/>in = IO.readImage(fileName);"+
            "<<<sp_6, br/>g.drawImage(in, 0,0, this);"+
            "<<<sp_4, br/>} <<lightblue/>catch<<white/> (NullPo<<orange/>int<<white/>erException | IOException e)"+
            "<<<sp_4, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln('File ' + fileName + 'does not exist.' +'\nTry capturing screen first!');"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print<<white/>ln(e.getMessage() + ' SysExit()');"+
            "<<<sp_5, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/>}  "+
            "<<<sp_0, br/>}"
        },
        {
            key : 6,
            FileTitle : "drawable.java",
            content : 
            "<<white/><<<sp_0, br/><<lightblue/>import<<white/> java.awt.image.BufferedImage;"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "* functional interface to draw over an area(X,Y) of a bufferedImage"+
            "* @author assgetit"+
            "*"+
            "*/<<white/>"+
            "<<<sp_0, br/><<orange/>public<<white/> interface drawable "+
            "<<<sp_0, br/>{"+
            "<<<sp_1, br/>BufferedImage draw(BufferedImage b);"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"
        },
        {
            key : 7,
            FileTitle : "capture.java",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.AWTException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Color;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Dimension;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Graphics2D;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Rectangle;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Robot;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.Toolkit;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.image.BufferedImage;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.awt.image.WritableRaster;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.File;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> javax.imageio.ImageIO;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> capture {"+
            "<<<sp_1, br/><<orange/>static<<white/> Dimension screenSize;"+
            "<<<sp_1, br/><<orange/>static<<white/> Robot r;"+
            "<<<sp_1, br/><<orange/>static<<white/> Rectangle screenRect;"+
            "<<<sp_1, br/><<orange/>static<<white/> BufferedImage Screen_Shot;"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>static<<white/>"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('Initialising capture class...');"+
            "<<<sp_3, br/>screenSize = Toolkit.getDefaultToolkit().getScreenSize();		"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('Setting capture information :\n-[ScreenSize : ' + screenSize.getWidth() + ',' + screenSize.getHeight() + ']' );"+
            "<<<sp_3, br/>screenRect = <<orange/>new<<white/> Rectangle(screenSize.getSize());"+
            "<<<sp_3, br/>Screen_Shot = <<orange/>new<<white/> BufferedImage(screenSize.width, screenSize.height, BufferedImage.TYPE_INT_RGB);"+
            ""+
            "<<<sp_3, br/>try "+
            "<<<sp_3, br/>{"+
            "<<<sp_4, br/>r = <<orange/>new<<white/> Robot();"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (AWTException e) {"+
            "<<<sp_4, br/>e.printStackTrace();"+
            "<<<sp_4, br/><<orange/>system<<white/>.out.println('ScreenShot Robot setup unsuccessful.\nSysExit!');"+
            "<<<sp_4, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/* Custom drabale implementation for capture resize */<<white/>"+
            "<<<sp_1, br/><<orange/>static<<white/> <<orange/>final<<white/> drawable b = (i) ->"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>Screen_Shot = r.createScreenCapture(screenRect);"+
            "<<<sp_3, br/>Screen_Shot = ImageManipulation.reConfigureImage(Screen_Shot, screenSize.width/2, screenSize.height/2, false); <<grey/>//resize"+
            "<<white/><<<sp_3, br/><<orange/>return<<white/> Screen_Shot;"+
            "<<<sp_1, br/>};"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Creates an image containing pixels read from the screen. This image does not include the mouse cursor. "+
            "<<<sp_2, br/>* @param captureName; .png is assumed and is ommited "+
            "<<<sp_2, br/>* @param save = true ->  save (on disk) and return buffedImage of capture image, else return BufferedImage only. "+
            "<<<sp_2, br/>* @return bufferdImage of captured screen, else null if write failed. "+
            "<<<sp_2, br/>*/<<white/>	"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> BufferedImage screenCapture(<<orange/>String<<white/> captureName, boolean save)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (save == true)"+
            "<<<sp_3, br/>{"+
            "<<<sp_4, br/>boolean d = IO.writeImage(captureName, b , null);"+
            "<<<sp_4, br/><<orange/>return<<white/> (d == false) ? null : Screen_Shot;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> b.draw(Screen_Shot);"+
            "<<<sp_1, br/>}"+
            "<<<sp_0, br/>}"
        },
    ]
}

export default CaroselItem;
import "./Styling.css"
import ScrollBarMenu, {ScrollBarElement} from "../scrollBarMenu/ScrollBarMenu";
import { useLayoutEffect, useRef } from "react";
import ContentOverlay from "../../BaseProject/Components/ContentOverlay/ContentOverlay";
import Resume from "../../BaseProject/MyResume/Resume/Resume";
import myBrowser from "../../BaseProject/Handlers/myBrowser";

let RenderPage : React.FC<{children : React.ReactNode}> = (props) => { 


    let minWidth = myBrowser.mobile_Width();





    // custom styling
    const PageQuery : String = `
        @media only screen and (max-width: ${minWidth}px) {
            .pageWrapper {
                border-radius: 0px !important;
                min-width : 300px !important;
                width:100%;
                padding :0;
                padding-bottom:90px;
            }

            .wrapper_container{
                padding:0;
            }
        }

        :root {
            --lineSize: ${minWidth}px;
        }
      
    `;

    useLayoutEffect(
        () => { 
            let resizeHandler = () => {
                console.log("RESIZING");
            }

            window.addEventListener("resize", resizeHandler )
            return () => { 
                window.removeEventListener("resize", resizeHandler);
            }
        },[]
    )

    return(
        <>                   
            <style> {PageQuery}</style>
        
            <div className="wrapper_container">
                <div className="pageWrapper" style = {{background:"white"}} >
                    {/* <ScrollBarMenu hasOffset={false} ScrolBarElements={scrollbarElementsRef} mobileWidth={minWidth}   /> */}
                    {props.children}
                    {/* <div ref = {scrollbar_Elements[0].reference}  style = {{height :'960px', width :"100%", background:"white"}}/>
                    <div ref = {scrollbar_Elements[1].reference}  style = {{height :'1100px', width :"100%", background:"orange"}}/>
                    <div ref = {scrollbar_Elements[2].reference}  style = {{height :'960px', width :"100%", background:"pink"}}/> */}
                </div>
            </div>


            <div className="background"/>
        </>
    )
}

export default RenderPage;
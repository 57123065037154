
import React, { CSSProperties, useLayoutEffect, useRef, useState } from 'react';
import myBrowser from '../../BaseProject/Handlers/myBrowser';
import { ObjectRef } from '../../BaseProject/Interfaces/RefObjectInterface';
import DynamicHeading from '../../BaseProject/Components/layouts/Others/dynamicText' 
import Styling from "./Styling.module.css"

let STARTPERCENTAGE = 5;
let CENTERPERCENTAGE = 50;
let ENDINGPERCENTAGE = 95;



let RenderElipseContainer : React.FC <{Title : String, percentage:number}> = (props) => 
{
    let Line : JSX.Element = <div style = {{justifySelf :'center', backgroundColor : 'black',  height :'100%', width : '1px' } } /> ;
    let animationBar = React.useRef<HTMLDivElement>(null);
    let [percentage_FontSize, set_percentage_FontSize] = useState<number>(0); 

    
    
    useLayoutEffect(
        () =>{
            if (animationBar.current != null)
            {
                animationBar.current.style.width = `${props.percentage}%`;
            }
        },
    );


    return (
        <>  
        <div style = {{width:'100%'}}>
            <div className={Styling.TitleContainer}>
                <div>
                    <p style = {{fontSize:'13px', fontWeight:"700"}}> {props.Title} </p>
                </div>
                <div>
                    <p style = {{textAlign:'right', fontSize:'11px', color:"#888", fontWeight:'400'}}> {props.percentage}% </p>
                </div>
            </div> 
            <div style = {{display : 'grid', alignItems :'center' ,position:'relative', width :'100%',  background:'white', padding:'3px',boxSizing:'border-box', borderRadius : '2em', border: "1px solid #e5e5e5"}}>
                <div style = { Object.assign( {overflow :'hidden', position:'relative', width :'100%', height :'6px',zIndex:'5'})}> 
                    <div ref = {animationBar} style = { Object.assign({ position:'relative', width :'0%', backgroundColor :'#007ced', height :'100%',  borderRadius : '2em',  transition : 'width 2s ease-in-out'})}/>  
                </div>
            </div>

        </div>
        </>
    )
    
}

export default RenderElipseContainer;
import TitleWrapper from "../../TitleWrapper/TitleWrapper"
import "../../../Styling.module.css";
import Styling from "./Styling.module.css"
import SubmissionForm from "../../../Components/SubmisisonForm/SubmissionForm";
import myBrowser from "../../../BaseProject/Handlers/myBrowser";

let RenderPage : React.FC<{}> = () => { 

    // custom styling
    const PageQuery : String = `
        @media only screen and (min-width: ${myBrowser.mobile_Width()}px) {
            
            #mobileContactDescription{
                display:none;
            }

            #ContactDescription{
                display:grid;
                grid-template-columns: 20% 80%;
                
            }

            #desktopContactDescription{
                display: grid;
                grid-template-rows: repeat(3, 33.3%);
            }

            #formContainer{
                padding-left:7%;
            }

        }
        @media only screen and (max-width: ${myBrowser.mobile_Width()}px) {
            
            #mobileContactDescription{
                display:block;

            }


            #ContactDescription{
                display:grid;
                grid-template-columns: 100%;
            }


            #desktopContactDescription{
                display:none;
            }


            #formContainer{
                // padding:0% !important;
            }
        }
        
      
    `;


    return(
        <>

            <style> {PageQuery} </style>
           <TitleWrapper>
                <p className="sectionTitle">Contact Me</p>
            </TitleWrapper>
                        
            <div className={Styling.contactContainer}>
            <div style = {{width:'100%', height :'200px'}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d848300.8253314648!2d150.27255546310082!3d-33.84648855820782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b129838f39a743f%3A0x3017d681632a850!2sSydney%20NSW!5e0!3m2!1sen!2sau!4v1708246854869!5m2!1sen!2sau" width="100%" style={{border:'0'}} height="100%"  loading="lazy" ></iframe>
            </div>
 

             {/* 
            <div style = {{width:"100%" , display:"grid", gridTemplateColumns:"repeat(3, 33.3%)", marginBottom:"10px"}}>
                    <div className={Styling.contactElementHolder}>
                            <div className={Styling.iconContainer} style = {{}}>
                                    <img src="./images/myresume_Content/iphone_icon.png" />
                            </div>
                            <div className={Styling.informationContainer}>
                                <h2>  
                                    <a href="tel:0416915923"> 0416 915 923 </a>
                                </h2>
                            </div>
                        </div>
                        <div className={Styling.contactElementHolder} style = {{ justifyContent:'center', justifyItems:'center'}}>
                            <div className={Styling.iconContainer} >
                                    <img  src="./images/myresume_Content/address_icon.png" />
                            </div>
                            <div className={Styling.informationContainer} style = {{width:"auto"}}>
                                <h2> 
                                    <a  href="https://maps.app.goo.gl/RxmfNcKSxowpbWpC6" target="_blank"> Sydney, Nsw </a>
                                </h2>
                                
                            </div>
                        </div>
                        <div className={Styling.contactElementHolder} style={{justifyContent:"flex-end"}}>
                            <div className={Styling.iconContainer}>
                                    <img  src="./images/myresume_Content/email_icon.png" />
                            </div>
                            <div className={Styling.informationContainer} style = {{width:"auto"}} >
                                <h2>
                                    <a href="mailto:gordonlis@outlook.com"> Gordonlis@outlook.com </a>
                                </h2>
                            </div>
                        </div>    

                </div> */}





                
                <div id ="ContactDescription" style = {{display:"grid", gridTemplateColumns :'20% 80%', width:'100%', marginTop:'25px'}}> 

                    <div id="mobileContactDescription" style = {{width:"100%"}}>

                        <div style = {{width:"100%" , display:"flex", flexDirection:"column"}}>
                            <div className={Styling.contentElementHolder_Mobile}>
                                        <div className={Styling.iconContainer}>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="100%" width="100%" version="1.1" id="Capa_1" viewBox="0 0 27.442 27.442">
                                                <g>
                                                    <path d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546   c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481   s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469   c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469z    M19.995,21.1H7.448V3.373h12.547V21.1z"/>
                                                </g>
                                            </svg>
                                        </div>


                                        <div className={Styling.mobileInformation_Container}>
                                            <h2 >  
                                                <a href="tel:0416915923"> 0416 915 923 </a>
                                            </h2>
                                        </div>
                            </div>

                            <div className={Styling.contentElementHolder_Mobile}>
                                        <div className={Styling.iconContainer}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="100%" height="100%" viewBox="0 0 1920 1920">
                                                <path d="M0 1694.235h1920V226H0v1468.235ZM112.941 376.664V338.94H1807.06v37.723L960 1111.233l-847.059-734.57ZM1807.06 526.198v950.513l-351.134-438.89-88.32 70.475 378.353 472.998H174.042l378.353-472.998-88.32-70.475-351.134 438.89V526.198L960 1260.768l847.059-734.57Z" fill-rule="evenodd"/>
                                            </svg>
                                        </div>
                                        <div className={Styling.mobileInformation_Container}>
                                            <h2>
                                                <a href="mailto:gordonlis@outlook.com"> Gordonlis@outlook.com </a>
                                            </h2>
                                        </div>
                            </div>    

                            <div className={Styling.contentElementHolder_Mobile}>
                                        <div className={Styling.iconContainer} >

                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                                            <path d="M5.36328 12.0523C4.01081 11.5711 3.33457 11.3304 3.13309 10.9655C2.95849 10.6492 2.95032 10.2673 3.11124 9.94388C3.29694 9.57063 3.96228 9.30132 5.29295 8.76272L17.8356 3.68594C19.1461 3.15547 19.8014 2.89024 20.2154 3.02623C20.5747 3.14427 20.8565 3.42608 20.9746 3.7854C21.1106 4.19937 20.8453 4.85465 20.3149 6.16521L15.2381 18.7078C14.6995 20.0385 14.4302 20.7039 14.0569 20.8896C13.7335 21.0505 13.3516 21.0423 13.0353 20.8677C12.6704 20.6662 12.4297 19.99 11.9485 18.6375L10.4751 14.4967C10.3815 14.2336 10.3347 14.102 10.2582 13.9922C10.1905 13.8948 10.106 13.8103 10.0086 13.7426C9.89876 13.6661 9.76719 13.6193 9.50407 13.5257L5.36328 12.0523Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>


                                        </div>
                                        <div className={Styling.mobileInformation_Container}>
                                            <h2> 
                                                <a  href="https://maps.app.goo.gl/RxmfNcKSxowpbWpC6" target="_blank"  > Sydney, Nsw </a>
                                            </h2>
                                            
                                        </div>
                            </div>
                        </div>
                    
                    </div>








                    {/** CONTACT HOLDER */}
                    <div id = "desktopContactDescription" className={Styling.contactHolder} style = {{background:"none", maxHeight:"160px", overflow:"hidden"}}>
                        <div className={Styling.contactElementHolder}>
                            <div className={Styling.iconContainer} style = {{}}>
                                    {/* <img src="./images/myresume_Content/iphone_icon.png" /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="100%" width="100%" version="1.1" id="Capa_1" viewBox="0 0 27.442 27.442">
                                         <g>
                                            <path d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546   c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481   s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469   c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469z    M19.995,21.1H7.448V3.373h12.547V21.1z"/>
                                        </g>
                                    </svg>


                            </div>


                            <div className={Styling.informationContainer}>
                                <h2>  
                                    <a href="tel:0416915923"> 0416 915 923 </a>
                                </h2>
                            </div>
                        </div>
                        <div className={Styling.contactElementHolder}>
                            <div className={Styling.iconContainer}>
                                    {/* <img  src="./images/myresume_Content/address_icon.png" /> */}
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                                         <path d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                                        <path d="M5.36328 12.0523C4.01081 11.5711 3.33457 11.3304 3.13309 10.9655C2.95849 10.6492 2.95032 10.2673 3.11124 9.94388C3.29694 9.57063 3.96228 9.30132 5.29295 8.76272L17.8356 3.68594C19.1461 3.15547 19.8014 2.89024 20.2154 3.02623C20.5747 3.14427 20.8565 3.42608 20.9746 3.7854C21.1106 4.19937 20.8453 4.85465 20.3149 6.16521L15.2381 18.7078C14.6995 20.0385 14.4302 20.7039 14.0569 20.8896C13.7335 21.0505 13.3516 21.0423 13.0353 20.8677C12.6704 20.6662 12.4297 19.99 11.9485 18.6375L10.4751 14.4967C10.3815 14.2336 10.3347 14.102 10.2582 13.9922C10.1905 13.8948 10.106 13.8103 10.0086 13.7426C9.89876 13.6661 9.76719 13.6193 9.50407 13.5257L5.36328 12.0523Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>



                            </div>
                            <div className={Styling.informationContainer}>
                                <h2> 
                                    <a  href="https://maps.app.goo.gl/RxmfNcKSxowpbWpC6" target="_blank"> Sydney, Nsw </a>
                                </h2>
                                
                            </div>
                        </div>
                        <div className={Styling.contactElementHolder}>
                            <div className={Styling.iconContainer}>
                                    {/* <img  src="./images/myresume_Content/email_icon.png" /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="100%" height="100%" viewBox="0 0 1920 1920">
                                        <path d="M0 1694.235h1920V226H0v1468.235ZM112.941 376.664V338.94H1807.06v37.723L960 1111.233l-847.059-734.57ZM1807.06 526.198v950.513l-351.134-438.89-88.32 70.475 378.353 472.998H174.042l378.353-472.998-88.32-70.475-351.134 438.89V526.198L960 1260.768l847.059-734.57Z" fill-rule="evenodd"/>
                                    </svg>
                            </div>
                            <div className={Styling.informationContainer}>
                                <h2>
                                    <a href="mailto:gordonlis@outlook.com"> Gordonlis@outlook.com </a>
                                </h2>
                            </div>
                        </div>
                    </div>
                    
                    {/** SEND REQUEST */}
                    <div id ="formContainer"  style = {{height:'100%',  width: "100%", boxSizing:'border-box', paddingLeft:"7%"}}>
                        <SubmissionForm/>
                    </div>
                </div>
            </div>
        </>
    )
};


export default RenderPage;
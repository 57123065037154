import TitleWrapper from "../../../TitleWrapper/TitleWrapper";
import "../../../../index.css";
import Styling from "./Styling.module.css";





let RenderPage : React.FC<{}> = () => { 



    return(
        <>
            <div className={Styling.ContentWrapper}>
                <div className={Styling.ContentContainer}> 
                    <div className={Styling.CategoryContainer}>
                        <div className={Styling.itemContainer}>
                            <div className={Styling.line} />
                            <div className={Styling.mainTitle}>
                                <div className={Styling.dateContainer}> 
                                    <h2>2022 - 2024</h2>
                                </div>
                            </div>
                            <div className={Styling.DescriptionContainer}>
                                <h1> Bachelor of Computer Science</h1>
                                <h3>  Western Sydney University</h3>

                                <ul style = {{listStyleType:'disc'}}>
                                    <li> <span style = {{textDecoration:"underline"}}> Covered essential areas</span>  : OOP, DSA, Discrete Mathematics, Computer Architecture / Organization. </li>
                                    <li> Enhanced abilities in designing efficient, scalable solutions. </li>
                                    <li> Developed strong logical reasoning and problem-solving skills </li>
                                </ul>
                                
                                {/* <p >
                                    Provided a solid foundation in essential areas of computer theory, 
                                    software design, development, and applications encompassing OOP, DSA, Discrete-Mathematics, Computer Architecture, and more. 

                                </p>
                                <p>
                                    This comprehensive education has significantly enhanced my abilities as a developer, 
                                    enabling me to design efficient and scalable solutions by leveraging logical reasoning and problem-solving techniques to produce
                                    optimal solution.
                                </p> */}
                            </div>
                        </div>
                        <div className={Styling.itemContainer} style = {{borderBottom:"none"}}>
                            <div className={Styling.line} />
                            <div className={Styling.mainTitle}>
                                <div className={Styling.dateContainer}> 
                                    <h2>2017 - 2021</h2>
                                </div>
                            </div>
                            <div className={Styling.DescriptionContainer} >
                                <h1>Bachelor of Information System</h1>
                                <h3> Western Sydney University</h3>
                                <ul style = {{listStyleType:'disc'}}>
                                    <li>  Gained expertise in planning, developing, and integrating applications and information systems within business environments </li>
                                    <li>  Developed proficiency in project management, systems analysis, software development methodologies, and more</li>
                                </ul>
{/*  
                                <p>
                                    Provided expertise in planning, 
                                    developing, and seamlessly integrating applications and information systems within 
                                    various business environments. 


                                </p>
                                <p>
                                    Proficiency in a range of skills have been developed including project management, 
                                    systems analysis, software development methodologies, and many more.
                                 

                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className={Styling.CategoryContainer}>
                        {/* <div className={Styling.itemContainer}>

                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )


}


export default RenderPage;
import Education from "./Education_Training/Education"
import Experience from "./Education_Training/Experience"
import Styling from "./Styling.module.css";
import TitleWrapper from "../../TitleWrapper/TitleWrapper";
import MySkills from "./MySkills/MySkills";
import MyProjects from "../MyProjects/MyProject";
import Contact from "../Contact/Contact";
import myBrowser from "../../../BaseProject/Handlers/myBrowser";


let RenderPage : React.FC<{}> = () => { 


    // custom styling
    const PageQuery : String = `
        @media only screen and (min-width: ${myBrowser.mobile_Width()}px) {
            
            #MyHistoryContainer{
                grid-template-columns: 50% 50%;
            }
        }
        @media only screen and (max-width: ${myBrowser.mobile_Width()}px) {
            
            #MyHistoryContainer{
                grid-template-columns: 100%;
            }
        }
        
      
    `;



    return(
        <>
            <style> {PageQuery} </style>
            <div className = {Styling.Aboutme_Container}>
                {/** MY SKILLS */}
                <div className={Styling.MySkill_Container}>
                    <TitleWrapper>
                        <p className="sectionTitle">My Skills</p>
                    </TitleWrapper> 
                    <MySkills/> 
                </div>

                {/** MY HISTORY */}
                <div id ="MyHistoryContainer" className={Styling.MyHistory_Container}>
                    <div className={Styling.container}>
                        <TitleWrapper>
                            <p className="sectionTitle">Education</p>
                        </TitleWrapper>
                        <Education/>
                    </div>
                    <div className={Styling.container} style = {{display:'flex', flexDirection:'column'}}>
                        <div>
                            <TitleWrapper>
                                <p className="sectionTitle">Training </p>
                            </TitleWrapper>
                        </div>
                        <Experience/>
                    </div>
                </div>

                {/** MY PROJECTS */}
                {/* <div className={Styling.MyProject_Container}>
                    <TitleWrapper>
                        <p className="sectionTitle">Projects</p>
                    </TitleWrapper> 
                    <MyProjects/>
                </div> */}

                { /** CONTACT ME */}
                {/* <div className={Styling.ContactMe_Container}> 
                    <TitleWrapper>
                        <p className="sectionTitle">Contact Me</p>
                    </TitleWrapper> 
                    <Contact/>
                </div> */}


            </div>
        </>
    );

}


export default RenderPage;
import  {items} from '../../Components/layouts/contentCarousel/contentCarosel';

 
let CaroselItem : items =    {
    key : 1,
    Subject : "CatalogueSale",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}

            <p>  
                This program is a software system designed for catalog sale in the format of a sealed bid auction. 
                The catalog is organized in a tree structure where each node represents either an item for sale or a category. 
                The system involves three roles: sellers, buyers, and the manager. 
            </p>
            <p>
                Sellers can traverse the catalog, place selling items under existing categories, 
                and create new subcategories or selling items. Buyers can traverse the catalog, view item information, and place bids on items. 
                The manager has the authority to traverse the catalog, create new categories, delete existing categories, remove selling items, 
                and modify category or item details, whereas the buyer, seller can buy and sell respectively.
            </p>
            <p>
                The catalog tree is represented as a general tree with a unique root, different levels of subcategories, and selling items. 
                Selling items have various attributes such as title, key, description, price, quantity, and maxDiscount.
            </p>
          
            <h3 style = {{textDecoration:'underline'}}> Program Analysis : </h3>
            <div style = {{boxSizing:'border-box', marginLeft:'5%'}}>
                <p>
                    CatalogueItems are stored in a general-tree structure, this is the “categoryTree” class whereby starting from the root, 
                    all subsequent nodes can be traversed through its internal linking (parent, sibling - relationship).  
                    This is used to display category items to the user.  A general tree structure allows for the hierarchical structuring 
                    of categories and items which are not limited by size / constraints as with other tree variants.
                    Although the general tree works great for displaying and storing general data, it lacks efficiency for search, 
                    which is why an AVL-tree is used alongside the general tree, with the sacrifice of space.  With the use of an AVL tree, 
                    we can assure that each search operation, provided a key, can be conducted in O(log n) time.
                </p>
                <p style = {{textDecoration:'underline'}}> Insertion : </p>
                <p>
                    Insertion into general tree takes O(1) constant time, whereby given a rootNode, a new item node containing the element 
                    is created and placed as the ‘rootNode.firstChild’, which makes traversal possible. This element becomes the rootnode.first 
                    child if a prior child did not exist. However, If new items are inserted into a node with existing child, fixed (constant) 
                    operations are used to link the child, parent, and sibling nodes. Hence the overall worse-case running time is constant, 
                    resulting in an O(1) insertion time into the general tree where a parent node is given.
                    insertChild(root node, new element)
                </p>
                <p>
                    AVL tree is used in conjunction with the general tree, requiring O(log n) insertion time to locate and place new item. This occur after the general tree node creation and linking process.  
                    Worse case Time complexity of insertion : O(log N)
                </p>
                <p style = {{textDecoration:'underline'}}> Modification : </p>
                <p>
                    Modification of items in the general tree takes constant O(1) time, because no searching is used. A similar approach is used with insertion into the general tree, 
                    whereby given a node, the function replaces the old-entry with the new entry. The specified method/function ‘modify entry()’ simply points n.element = new entry. 
                    The node input is obtained through the user traversal process of the general tree.  
                </p>
                <p>
                    AVL tree is used for search, when item are modified, the old item is deleted and reinserted into the avltree with a new key that is representative of the modified entry.  
                    As stated, insertion/deletion in the AVL tree takes O(log n) time accounting for search, removal, insertion, and rebalancing. So modification can be 
                    summarised to O(log n) time for the deletion and reinsertion process for modified items, Hence the Worse case Time complexity : O(log N)
                </p>            
                <p style = {{textDecoration:'underline'}}> Deletion : </p>
                <p>
                    Deletion of items/Or category in the general tree requires relinking and deletion of the rootNode (given node), and all subsequent child nodes that resides beneath (if any). 
                    This operation takes O(n) time, where n = the sum of all children of a given rootNode. (Item that are leaves takes constant O(1) time, relinking of the removed node 
                    takes constant time).
                </p>
                <p>
                    AVL tree item deletion is used in conjunction with the deletion of general tree items, each requiring search and delete, taking O(log n) time, so child removal operation
                    each takes up O(log n) time at worse. Hence the total running time for deletion of a given rootNode takes O(n * log N), where n is the sum of all child of a given root-node. 
                    Worse case Time complexity :  O(n * log N)
                </p>     


            </div>







        </>,
    img_Url:"https://static.javatpoint.com/tutorial/ai/images/forward-chaining-and-backward-chaining-in-ai3.png",
    file_List : [
        {
            key : 1,
            FileTitle : "main.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Iterator;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<lightblue/>import<<white/> CatalogueSale.catalogueTree.Node;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> main {"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(String[] args) "+
                "<<<sp_1, br/>{<<grey/>//d"+
                "<<white/><<<sp_2, br/>Catalogue c = <<orange/>new<<white/> Catalogue('sales');"+
                "<<<sp_2, br/>user u = <<orange/>new<<white/> manager('john');"+
                "<<<sp_2, br/>c.start(u);"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> String spaces(int b){"+
                "<<<sp_2, br/>StringBuilder bd = <<orange/>new<<white/> StringBuilder();"+
                "<<<sp_2, br/><<lightblue/>for<<white/>(int i =0; i < b; i ++)"+
                "<<<sp_3, br/>bd.append(' ');"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/><<orange/>return<<white/> bd.toString();"+
                "<<<sp_1, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
        },
        {
            key : 2,
            FileTitle : "avlTree.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.ArrayList;"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.List;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<lightblue/>import<<white/> CatalogueSale.catalogueTree.Node;"+
                "<<<sp_0, br/><<lightblue/>import<<white/> CatalogueSale.catalogueTree.Position;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> avlTree <E> {"+
                "<<<sp_1, br/>"+
                "<<<sp_3, br/><<orange/>private<<white/> Node<E> root;"+
                "<<<sp_3, br/>"+
                "<<<sp_3, br/><<orange/>private<<white/> <<orange/>class<<white/> Node <E> {"+
                "<<<sp_6, br/><<orange/>private<<white/> int key;"+
                "<<<sp_6, br/>E element;"+
                "<<<sp_6, br/><<orange/>private<<white/> int balance;"+
                "<<<sp_6, br/><<orange/>private<<white/> Node<E> left, right, parent;"+
                ""+
                "<<<sp_6, br/>Node(int k, Node<E> p, E e) {"+
                "<<<sp_9, br/>key = k;"+
                "<<<sp_9, br/>parent = p;"+
                "<<<sp_9, br/>element = e;"+
                "<<<sp_6, br/>}"+
                "<<<sp_3, br/>}"+
                "<<<sp_3, br/>"+
                "<<<sp_3, br/><<orange/>public<<white/> E get(int k)"+
                "<<<sp_3, br/>{"+
                "<<<sp_7, br/><<lightblue/>if<<white/> (root == null) <<orange/>return<<white/> null;"+
                "<<<sp_7, br/>"+
                "<<<sp_8, br/>Node<E> n = root;"+
                "<<<sp_8, br/>Node<E> delNode = null;"+
                "<<<sp_8, br/>Node<E> child = root;"+
                "<<<br,hidden/>br"+
                "<<<sp_8, br/><<lightblue/>while<<white/> (child != null) {"+
                "<<<sp_10, br/>n = child;"+
                "<<<sp_10, br/><<lightblue/>if<<white/> (k == n.key)"+
                "<<<sp_12, br/><<orange/>return<<white/> n.element;"+
                "<<<sp_10, br/>child = (k > n.key) ? n.right : n.left;"+
                "<<<sp_8, br/>}"+
                "<<<sp_8, br/><<orange/>return<<white/> null;"+
                "<<<sp_3, br/>}"+
                "<<<sp_3, br/>"+
                "<<<sp_3, br/>"+
                "<<<sp_2, br/>"+
                "<<<sp_3, br/><<orange/>public<<white/> boolean insert(int key, E element) {"+
                "<<<sp_6, br/><<lightblue/>if<<white/> (root == null)"+
                "<<<sp_9, br/>root = <<orange/>new<<white/> Node(key, null, element);"+
                "<<<sp_6, br/><<lightblue/>else<<white/> {"+
                "<<<sp_9, br/>Node<E> n = root;"+
                "<<<sp_9, br/>Node<E> parent;"+
                "<<<sp_9, br/><<lightblue/>while<<white/> (true) {"+
                "<<<sp_11, br/><<lightblue/>if<<white/> (n.key == key)"+
                "<<<sp_13, br/><<orange/>return<<white/> false;"+
                ""+
                "<<<sp_11, br/>parent = n;"+
                ""+
                "<<<sp_11, br/>boolean goLeft = n.key > key;"+
                "<<<sp_11, br/>n = goLeft ? n.left : n.right;"+
                ""+
                "<<<sp_11, br/><<lightblue/>if<<white/> (n == null) {"+
                "<<<sp_13, br/><<lightblue/>if<<white/> (goLeft) {"+
                "<<<sp_14, br/>parent.left = <<orange/>new<<white/> Node(key, parent, element);"+
                "<<<sp_13, br/>} <<lightblue/>else<<white/> {"+
                "<<<sp_14, br/>parent.right = <<orange/>new<<white/> Node(key, parent, element);"+
                "<<<sp_13, br/>}"+
                "<<<sp_13, br/>rebalance(parent);"+
                "<<<sp_13, br/><<orange/>break<<white/>;"+
                "<<<sp_11, br/>}"+
                "<<<sp_9, br/>}"+
                "<<<sp_6, br/>}"+
                "<<<sp_6, br/><<orange/>return<<white/> true;"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> delete(int delKey) {"+
                "<<<sp_6, br/><<lightblue/>if<<white/> (root == null)"+
                "<<<sp_9, br/><<orange/>return<<white/>;"+
                "<<<sp_6, br/>Node n = root;"+
                "<<<sp_6, br/>Node parent = root;"+
                "<<<sp_6, br/>Node delNode = null;"+
                "<<<sp_6, br/>Node child = root;"+
                ""+
                "<<<sp_6, br/><<lightblue/>while<<white/> (child != null) {"+
                "<<<sp_9, br/>parent = n;"+
                "<<<sp_9, br/>n = child;"+
                "<<<sp_9, br/>child = delKey >= n.key ? n.right : n.left;"+
                "<<<sp_9, br/><<lightblue/>if<<white/> (delKey == n.key)"+
                "<<<sp_11, br/>delNode = n;"+
                "<<<sp_6, br/>}"+
                ""+
                "<<<sp_6, br/><<lightblue/>if<<white/> (delNode != null) {"+
                "<<<sp_9, br/>delNode.key = n.key;"+
                ""+
                "<<<sp_9, br/>child = n.left != null ? n.left : n.right;"+
                ""+
                "<<<sp_9, br/><<lightblue/>if<<white/> (root.key == delKey) {"+
                "<<<sp_11, br/>root = child;"+
                "<<<sp_9, br/>} <<lightblue/>else<<white/> {"+
                "<<<sp_11, br/><<lightblue/>if<<white/> (parent.left == n) {"+
                "<<<sp_13, br/>parent.left = child;"+
                "<<<sp_11, br/>} <<lightblue/>else<<white/> {"+
                "<<<sp_13, br/>parent.right = child;"+
                "<<<sp_11, br/>}"+
                "<<<sp_11, br/>rebalance(parent);"+
                "<<<sp_9, br/>}"+
                "<<<sp_6, br/>}"+
                "<<<sp_3, br/>}"+
                ""+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>private<<white/> <<orange/>void<<white/> rebalance(Node n) {"+
                "<<<sp_6, br/>setBalance(n);"+
                ""+
                "<<<sp_6, br/><<lightblue/>if<<white/> (n.balance == -2) {"+
                "<<<sp_9, br/><<lightblue/>if<<white/> (height(n.left.left) >= height(n.left.right))"+
                "<<<sp_11, br/>n = rotateRight(n);"+
                "<<<sp_9, br/><<lightblue/>else<<white/>"+
                "<<<sp_11, br/>n = rotateLeftThenRight(n);"+
                ""+
                "<<<sp_6, br/>} <<lightblue/>else<<white/> <<lightblue/>if<<white/> (n.balance == 2) {"+
                "<<<sp_9, br/><<lightblue/>if<<white/> (height(n.right.right) >= height(n.right.left))"+
                "<<<sp_11, br/>n = rotateLeft(n);"+
                "<<<sp_9, br/><<lightblue/>else<<white/>"+
                "<<<sp_11, br/>n = rotateRightThenLeft(n);"+
                "<<<sp_6, br/>}"+
                ""+
                "<<<sp_6, br/><<lightblue/>if<<white/> (n.parent != null) {"+
                "<<<sp_9, br/>rebalance(n.parent);"+
                "<<<sp_6, br/>} <<lightblue/>else<<white/> {"+
                "<<<sp_9, br/>root = n;"+
                "<<<sp_6, br/>}"+
                "<<<sp_3, br/>}"+
                ""+
                "<<<sp_3, br/><<orange/>private<<white/> Node rotateLeft(Node a) {"+
                "<<<sp_5, br/>"+
                "<<<sp_6, br/>Node b = a.right;"+
                "<<<sp_6, br/>b.parent = a.parent;"+
                ""+
                "<<<sp_6, br/>a.right = b.left;"+
                ""+
                "<<<sp_6, br/><<lightblue/>if<<white/> (a.right != null)"+
                "<<<sp_9, br/>a.right.parent = a;"+
                ""+
                "<<<sp_6, br/>b.left = a;"+
                "<<<sp_6, br/>a.parent = b;"+
                ""+
                "<<<sp_6, br/><<lightblue/>if<<white/> (b.parent != null) {"+
                "<<<sp_9, br/><<lightblue/>if<<white/> (b.parent.right == a) {"+
                "<<<sp_11, br/>b.parent.right = b;"+
                "<<<sp_9, br/>} <<lightblue/>else<<white/> {"+
                "<<<sp_11, br/>b.parent.left = b;"+
                "<<<sp_9, br/>}"+
                "<<<sp_6, br/>}"+
                ""+
                "<<<sp_6, br/>setBalance(a, b);"+
                ""+
                "<<<sp_6, br/><<orange/>return<<white/> b;"+
                "<<<sp_3, br/>}"+
                ""+
                "<<<sp_3, br/><<orange/>private<<white/> Node rotateRight(Node a) {"+
                "<<<sp_5, br/>"+
                "<<<sp_6, br/>Node b = a.left;"+
                "<<<sp_6, br/>b.parent = a.parent;"+
                ""+
                "<<<sp_6, br/>a.left = b.right;"+
                ""+
                "<<<sp_6, br/><<lightblue/>if<<white/> (a.left != null)"+
                "<<<sp_9, br/>a.left.parent = a;"+
                ""+
                "<<<sp_6, br/>b.right = a;"+
                "<<<sp_6, br/>a.parent = b;"+
                ""+
                "<<<sp_6, br/><<lightblue/>if<<white/> (b.parent != null) {"+
                "<<<sp_9, br/><<lightblue/>if<<white/> (b.parent.right == a) {"+
                "<<<sp_11, br/>b.parent.right = b;"+
                "<<<sp_9, br/>} <<lightblue/>else<<white/> {"+
                "<<<sp_11, br/>b.parent.left = b;"+
                "<<<sp_9, br/>}"+
                "<<<sp_6, br/>}"+
                ""+
                "<<<sp_6, br/>setBalance(a, b);"+
                ""+
                "<<<sp_6, br/><<orange/>return<<white/> b;"+
                "<<<sp_3, br/>}"+
                ""+
                ""+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>private<<white/> Node rotateLeftThenRight(Node n) {"+
                "<<<sp_6, br/>n.left = rotateLeft(n.left);"+
                "<<<sp_6, br/><<orange/>return<<white/> rotateRight(n);"+
                "<<<sp_3, br/>}"+
                ""+
                "<<<sp_3, br/><<orange/>private<<white/> Node rotateRightThenLeft(Node n) {"+
                "<<<sp_6, br/>n.right = rotateRight(n.right);"+
                "<<<sp_6, br/><<orange/>return<<white/> rotateLeft(n);"+
                "<<<sp_3, br/>}"+
                ""+
                "<<<sp_3, br/><<grey/>/**"+
                "<<<sp_4, br/>* Iterate through all child of a given node. return the height of that position"+
                "<<<sp_4, br/>* @param n"+
                "<<<sp_4, br/>* @return"+
                "<<<sp_4, br/>*/<<white/>"+
                "<<<sp_3, br/><<orange/>private<<white/> int height(Node n) {"+
                "<<<sp_6, br/><<lightblue/>if<<white/> (n == null)"+
                "<<<sp_9, br/><<orange/>return<<white/> -1;"+
                "<<<sp_6, br/><<orange/>return<<white/> 1 + Math.max(height(n.left), height(n.right));"+
                "<<<sp_3, br/>}"+
                ""+
                "<<<sp_3, br/><<grey/>/**"+
                "<<<sp_4, br/>* Set the balance of the given nodes, that is the balance factor of rightSubtree - leftSubtree."+
                "<<<sp_4, br/>* @param nodes"+
                "<<<sp_4, br/>*/<<white/>"+
                "<<<sp_3, br/><<orange/>private<<white/> <<orange/>void<<white/> setBalance(Node... nodes) {"+
                "<<<sp_6, br/><<lightblue/>for<<white/> (Node n : nodes)"+
                "<<<sp_9, br/>n.balance = height(n.right) - height(n.left);"+
                "<<<sp_3, br/>}"+
                "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> printBalance() {"+
                "<<<sp_6, br/>printBalance(root);"+
                "<<<sp_3, br/>}"+
                ""+
                "<<<sp_3, br/><<orange/>private<<white/> <<orange/>void<<white/> printBalance(Node n) {"+
                "<<<sp_6, br/><<lightblue/>if<<white/> (n != null) {"+
                "<<<sp_9, br/>printBalance(n.left);"+
                "<<<sp_9, br/><<orange/>system<<white/>.out.printf('%s ', n.balance);"+
                "<<<sp_9, br/>printBalance(n.right);"+
                "<<<sp_6, br/>}"+
                "<<<sp_3, br/>}"+
                ""+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
        },
        {
            key : 3,
            FileTitle : "buyer.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> buyer extends user{"+
                "<<<sp_1, br/><<orange/>public<<white/> buyer(String n) {"+
                "<<<sp_2, br/>super(n, 'buyer');"+
                "<<<sp_1, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
        },
        {
            key : 4,
            FileTitle : "Catalogue.java",
            content : 
            "<<white/><<<sp_0, br/>package CatalogueSale;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.HashMap;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.List;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Map;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.TreeMap;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> CatalogueSale.catalogueTree.Node;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> CatalogueSale.catalogueTree.Position;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> Catalogue {"+
            "<<<sp_1, br/>String name;"+
            "<<<sp_1, br/><<orange/>private<<white/> catalogueTree<CatalogueItem> ct = <<orange/>new<<white/> catalogueTree<>(<<orange/>new<<white/> catalogueTree.keyComparator<>() {"+
            "<<<sp_3, br/>@Override"+
            "<<<sp_3, br/><<orange/>public<<white/> int getKey(CatalogueItem e) {"+
            "<<<sp_4, br/><<orange/>return<<white/> e.getKey();"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>});"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>private<<white/> catalogueTree.Position<CatalogueItem> curDir ;"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>static<<white/> Map<Integer, String> Functions = <<orange/>new<<white/> TreeMap<>();"+
            "<<<sp_1, br/><<orange/>private<<white/> user currentUser;"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>Catalogue(String n){"+
            "<<<sp_3, br/>name =n;"+
            "<<<sp_3, br/>ct.addRoot(setKey(<<orange/>new<<white/> category('Main Category', 0)));"+
            "<<<sp_3, br/>Functions.put(0,'Add a <<orange/>new<<white/> category' );"+
            "<<<sp_3, br/>Functions.put(1,'Add a selling item' );"+
            "<<<sp_3, br/>Functions.put(2,'mod<<lightblue/>if<<white/>y category' );"+
            "<<<sp_3, br/>Functions.put(3,'Delete Category' );"+
            "<<<sp_3, br/>Functions.put(4,'printTree');"+
            "<<<sp_3, br/>Functions.put(5,'Quit to main menu' );"+
            "<<<sp_3, br/>Functions.put(6,'Change User' );"+
            "<<<sp_3, br/>Functions.put(7,'search');"+
            "<<<sp_3, br/>Functions.put(8,'<-- Back' );"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Return user function name based on given key integer 'i'"+
            "<<<sp_2, br/>* @param i"+
            "<<<sp_2, br/>* @return function name"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> String getUserFunc(int i){ <<orange/>return<<white/> Functions.get(i); }"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Return the keys of user functions designatd for the user "+
            "<<<sp_2, br/>* @param m"+
            "<<<sp_2, br/>* @return int[] key of functions. Return null if invalid user"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> int[] generateUserFunc(user u){"+
            "<<<sp_3, br/>int r[] = null;"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (u.getClass() == manager.<<orange/>class<<white/>) r = <<orange/>new<<white/> int[] {0,2,3,1,4,5,6,7,8};"+
            "<<<sp_3, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (u.getRole() == 'buyer')   r = <<orange/>new<<white/> int[] {5,6,7,8};"+
            "<<<sp_3, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (u.getRole() == 'seller')  r = <<orange/>new<<white/> int[] {0,1,5,6,7,8};"+
            "<<<sp_3, br/><<orange/>return<<white/> r;"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> start(user u){"+
            "<<<sp_3, br/>Scanner s = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
            "<<<sp_3, br/>curDir = ct.getRoot();"+
            "<<<sp_3, br/>setCurrentUser(u);"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<lightblue/>while<<white/>(true){"+
            "<<<sp_4, br/>List<Position<CatalogueItem>> item = generateMenu( currentUser, curDir );"+
            "<<<sp_4, br/>int inp =0;"+
            "<<<br,hidden/>br"+
            "<<<sp_4, br/><<lightblue/>while<<white/>(true)"+
            "<<<sp_5, br/><<lightblue/>try<<white/> { "+
            "<<<sp_6, br/>inp =  s.nextInt();"+
            "<<<sp_6, br/><<orange/>break<<white/>; "+
            "<<<sp_5, br/>}<<lightblue/>catch<<white/>(Exception i) {"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println('Invalid Input, Use the given integer values corresponding to the menu!');"+
            "<<<sp_6, br/>s.next();"+
            "<<<sp_6, br/>continue; }"+
            "<<<sp_4, br/>"+
            "<<<sp_4, br/><<lightblue/>if<<white/> (inp >= item.size()) "+
            "<<<sp_5, br/>processFunction( currentUser, inp-item.size());"+
            "<<<sp_4, br/><<lightblue/>else<<white/> <<grey/>//NON-User based functionality"+
            "<<white/><<<sp_5, br/>changeDir(item.get(inp));<<grey/>//tree navigation OR selection"+
            "<<white/><<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Processes a user input based on the function of the given user"+
            "<<<sp_2, br/>* @param user"+
            "<<<sp_2, br/>* @param input (Int)"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>void<<white/> processFunction(user u , int input){"+
            "<<<sp_3, br/>Scanner s = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
            "<<<sp_3, br/>String name,description;"+
            "<<<sp_3, br/>int price,quantity,n;"+
            "<<<sp_3, br/>double maxdiscount;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>import<<white/>(u.getFunc(input)){"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 0: <<grey/>//add a new category"+
            "<<white/><<<sp_6, br/><<lightblue/>do<<white/>{"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println('Please input <<orange/>new<<white/> category name : ');"+
            "<<<sp_7, br/>name = s.nextLine();"+
            "<<<sp_6, br/>}<<lightblue/>while<<white/>(name.length() > 20);"+
            "<<<sp_6, br/>ct.addChild(curDir,setKey(<<orange/>new<<white/> category(name,0)));"+
            "<<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 1 : <<grey/>//add a selling item"+
            "<<white/><<<sp_6, br/>ct.addChild(curDir, generateItem());"+
            "<<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 2: <<grey/>//modify category"+
            "<<white/><<<sp_6, br/><<orange/>system<<white/>.out.println('Please input <<orange/>new<<white/> name <<lightblue/>for<<white/> the category : ');"+
            "<<<sp_6, br/>name = s.nextLine();"+
            "<<<sp_6, br/>CatalogueItem ci = setKey(<<orange/>new<<white/> category(name,0));"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(getCurrentDir().getElement().getType() == 0 ) ct.mod<<lightblue/>if<<white/>yEn<<lightblue/>try<<white/>(curDir.getElement(), ci, curDir);"+
            "<<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 3 : <<grey/>//delete category"+
            "<<white/><<<sp_6, br/><<orange/>system<<white/>.out.println(getCurrentDir().getElement().getTitle() + ((ct.remove(getCurrentDir()) == true) ?  ' has been removed!\n' : ' was not removed!\n'));"+
            "<<<sp_6, br/>changeDir(ct.root());"+
            "<<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 4 : <<grey/>//print tree"+
            "<<white/><<<sp_6, br/>ct.displayTree(); "+
            "<<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 5 : <<grey/>//quit to main meut"+
            "<<white/><<<sp_6, br/><<orange/>system<<white/>.out.println('Returning to root category');"+
            "<<<sp_6, br/>changeDir(ct.root());"+
            "<<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 6 : <<grey/>//switch user"+
            "<<white/><<<sp_6, br/><<lightblue/>try<<white/> {"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println('Choose <<orange/>new<<white/> user to <<lightblue/>import<<white/> to :\n  (1) manager (2) buyer (3) seller');"+
            "<<<sp_8, br/>n = s.nextInt();"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (n ==1) "+
            "<<<sp_9, br/>setCurrentUser(<<orange/>new<<white/> manager('default manager'));"+
            "<<<sp_7, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (n ==2)"+
            "<<<sp_9, br/>setCurrentUser(<<orange/>new<<white/> buyer('default buyer'));"+
            "<<<sp_7, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (n == 3)"+
            "<<<sp_9, br/>setCurrentUser(<<orange/>new<<white/> seller('default seller'));"+
            "<<<sp_7, br/><<lightblue/>else<<white/>"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println('Invalid input, enter 1,2,or 3');"+
            "<<<sp_6, br/>}<<lightblue/>catch<<white/>(Exception i){ <<orange/>system<<white/>.out.println('Invalid input, use integer only.');}"+
            "<<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 7 : <<grey/>//search tree"+
            "<<white/><<grey/><<<sp_0, br/>//					try {"+
            "<<white/><<<sp_7, br/><<orange/>system<<white/>.out.println('Input an item or category name you would like to search <<lightblue/>for<<white/> : ');"+
            "<<<sp_7, br/>String i = s.nextLine();"+
            "<<<sp_7, br/>Position<CatalogueItem> p = ct.search(setKey(<<orange/>new<<white/> category(i,0))); "+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/><<lightblue/>if<<white/>(p!=null) {"+
            "<<<sp_9, br/>Position<CatalogueItem> h = p; <<grey/>//holder"+
            "<<white/><<<sp_9, br/><<orange/>system<<white/>.out.print('located at : ' + i );"+
            "<<<sp_9, br/><<lightblue/>while<<white/>(ct.isRoot(h) == false ) {"+
            "<<<sp_11, br/>h = ct.getParent(h);"+
            "<<<sp_11, br/><<orange/>system<<white/>.out.print( ' <-- ' + h.getElement().getTitle());"+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/><<lightblue/>do<<white/>{"+
            "<<<sp_12, br/><<orange/>system<<white/>.out.println('\n(1) Navigate to item, (2) Done');"+
            "<<<sp_12, br/>n = s.nextInt();"+
            "<<<sp_9, br/>}<<lightblue/>while<<white/> (!isValidInt(n, 1, 2));"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (n == 1) changeDir(p);"+
            "<<<sp_7, br/>}<<lightblue/>else<<white/> "+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println('Unable to locate exact item : ' + i + '.\nTo navigate to spec<<lightblue/>if<<white/>ied item, use its exact phrase. Use Containing phrase as a guide <<lightblue/>if<<white/> any exist! \n\n');					"+
            "<<grey/><<<sp_0, br/>//					}catch(Exception i){ System.out.println('Invalid input, use integer only.');}"+
            "<<white/><<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 8 : <<grey/>//back directory"+
            "<<white/><<<sp_6, br/><<lightblue/>if<<white/> (!ct.isRoot(curDir)) changeDir(ct.getParent(curDir));"+
            "<<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/>default : <<orange/>system<<white/>.out.println('Invalid Command!\n\n');"+
            "<<<sp_6, br/><<orange/>break<<white/>;"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<orange/>private<<white/> boolean isValidInt(int i , int startRange, int endRange){ "+
            "<<<sp_3, br/><<orange/>return<<white/> (((i >= startRange) && (i <= endRange)));"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Process item selected "+
            "<<<sp_2, br/>* @param positions of item 'i'"+
            "<<<sp_2, br/>* @param user 'u'"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>void<<white/> processItem(Position<CatalogueItem> i, user u)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (i.getElement().getType() !=1)  "+
            "<<<sp_4, br/><<orange/>return<<white/>; <<grey/>//ensure CatalogueItem = item."+
            "<<white/><<<sp_3, br/>"+
            "<<<sp_3, br/>item item = (item)i.getElement();<<grey/>//item holder"+
            "<<white/><<<sp_3, br/>Scanner s = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (item.getType() !=1)<<orange/>return<<white/>; <<grey/>//if not an item, for incorrect use."+
            "<<white/><<<sp_3, br/><<orange/>system<<white/>.out.println('\n------------------ITEM------------------/');"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('Item Name : ' + item.getTitle() + '\n'+"+
            "<<<sp_10, br/>'Item Description : ' + item.getDescription() + '\n'+"+
            "<<<sp_10, br/>'Item Price : ' + item.getPrice() + '\n' +"+
            "<<<sp_10, br/>'Item Quantity : ' + item.getQuantity() + '\n' +"+
            "<<<sp_10, br/>'Max Discount : ' + ((u.getRole() =='manager') ? (item.getMaxDiscount() "+
            "<<<sp_13, br/>+ '% --> $' + item.getminPrice()) : '-Private-'));"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('/----------------------------------------/');"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>import<<white/>(u.getRole()){"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 'manager' :"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('(1) Delete Item (2) Mod<<lightblue/>if<<white/>y Item (3) Done' );"+
            "<<<sp_5, br/>int inp = s.nextInt();"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (inp == 1 ) <<orange/>system<<white/>.out.println((ct.remove(i) == true) ? 'Item has been removed!' : 'Item has Not been removed!\n\n');"+
            "<<<sp_5, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (inp == 2){"+
            "<<<sp_6, br/>item n = generateItem();"+
            "<<<sp_6, br/><<lightblue/>if<<white/>( !ct.mod<<lightblue/>if<<white/>yEn<<lightblue/>try<<white/>(item, n, i)) "+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println('Item name already exist!');"+
            "<<<sp_6, br/>processItem(i,u); <<grey/>//Process item again"+
            "<<white/><<<sp_5, br/>}"+
            "<<<sp_5, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> 'buyer' :"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('(1) Buy item (2) Done' );"+
            "<<<sp_5, br/>inp = s.nextInt();"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (inp == 1){		"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println('Input offer price : ');"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(s.nextDouble() >= item.getminPrice()){"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println('You have bought the item ');"+
            "<<<sp_7, br/><<lightblue/>if<<white/>(item.getQuantity()-1 == 0){"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println('Item has been bought out!\n\n' );"+
            "<<<sp_9, br/>ct.remove(i);"+
            "<<<sp_9, br/><<orange/>return<<white/>;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>item.setQuantity(item.getQuantity()-1);"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println(item.getQuantity() + ' ' + item.getTitle() +  ' Items remaining\n\n');"+
            "<<<sp_6, br/>}<<lightblue/>else<<white/>"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println('Your price is too low! ');"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/>default :"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('(1) Done' );"+
            "<<<sp_5, br/>inp = s.nextInt();"+
            "<<<sp_5, br/><<orange/>break<<white/>;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* generate a new item "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> item generateItem(){	"+
            "<<<sp_3, br/>item i = <<orange/>new<<white/> item();"+
            "<<<sp_3, br/><<lightblue/>while<<white/>(true){"+
            "<<<sp_4, br/>Scanner bs = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
            "<<<sp_4, br/><<lightblue/>try<<white/> {"+
            "<<<sp_5, br/><<lightblue/>do<<white/>{<<orange/>system<<white/>.out.println('Please input <<orange/>new<<white/> Item name : ');} <<lightblue/>while<<white/>(i.setTitle(bs.nextLine()) ==false);"+
            "<<<sp_5, br/><<lightblue/>do<<white/>{<<orange/>system<<white/>.out.println('Please input <<orange/>new<<white/> Item Description : ');} <<lightblue/>while<<white/>(i.setDescription(bs.nextLine()) ==false);"+
            "<<<sp_5, br/><<lightblue/>do<<white/>{<<orange/>system<<white/>.out.println('Please input <<orange/>new<<white/> Item price : ');} <<lightblue/>while<<white/>(i.setPrice(bs.nextDouble()) ==false);"+
            "<<<sp_5, br/><<lightblue/>do<<white/>{<<orange/>system<<white/>.out.println('Please input <<orange/>new<<white/> Item quantity : '); } <<lightblue/>while<<white/>(i.setQuantity(bs.nextInt()) ==false);"+
            "<<<sp_5, br/><<lightblue/>do<<white/>{<<orange/>system<<white/>.out.println('Please input <<orange/>new<<white/> Item maxdiscount : '); } <<lightblue/>while<<white/>(i.setMaxDiscount(bs.nextDouble()) ==false);"+
            "<<<sp_5, br/>setKey(i);"+
            "<<<sp_5, br/><<orange/>return<<white/> i;"+
            "<<<sp_4, br/>}<<lightblue/>catch<<white/>(Exception e ){ <<orange/>system<<white/>.out.println('Invalid input, integer/double to be used <<lightblue/>for<<white/> price,quantity,discount.\nPlease re-enter item\n');}"+
            "<<<sp_3, br/>}		"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Return the configured catalogue item with added key."+
            "<<<sp_2, br/>* @param i"+
            "<<<sp_2, br/>* @return"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> CatalogueItem setKey(CatalogueItem i){"+
            "<<<sp_3, br/>i.setKey(i.getTitle().hashCode());"+
            "<<<sp_3, br/><<orange/>return<<white/> i;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Set the current user of the Catalogue"+
            "<<<sp_2, br/>* @param user"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> setCurrentUser(user u){"+
            "<<<sp_3, br/>u.setFunc(generateUserFunc(u));"+
            "<<<sp_3, br/>currentUser =u;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> changeDir(catalogueTree.Position<CatalogueItem> cd ) {"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (cd.getElement().getType() !=1)"+
            "<<<sp_4, br/>curDir = cd; "+
            "<<<sp_3, br/>processItem(cd, currentUser);"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<orange/>public<<white/> catalogueTree.Position<CatalogueItem> getCurrentDir(){ <<orange/>return<<white/> curDir; }"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>void<<white/> printFunctions(user m, int n){"+
            "<<<sp_3, br/><<lightblue/>try<<white/> {"+
            "<<<sp_4, br/>int[] f =  m.getFunc();"+
            "<<<sp_4, br/><<lightblue/>for<<white/>(int i=0; i < f.length; i++)"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println(n+i + ' ' + getUserFunc( f[i]) + ' (funct)');"+
            "<<<sp_3, br/>}<<lightblue/>catch<<white/>(Exception i){ <<orange/>system<<white/>.out.println(i.getMessage()); }"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Generates and outputs the menu based on a user and given directory. Return list of all child nodes"+
            "<<<sp_2, br/>* @param m"+
            "<<<sp_2, br/>* @param cdir"+
            "<<<sp_2, br/>* @return"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> List<Position<CatalogueItem>> generateMenu(user m, catalogueTree.Position<CatalogueItem> cdir){"+
            "<<<sp_3, br/>List<Position<CatalogueItem>> item =  ct.getChildren(cdir);"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('-----------------------------------------');"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println( item.get(0).getElement().getTitle() + ' |' + m.getRole()+'|' );"+
            "<<<sp_3, br/>int i = 1;"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('-----------------------------------------');"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(; i < item.size() ; i++)"+
            "<<<sp_4, br/><<orange/>system<<white/>.out.println(  i   + ' ' + item.get(i).getElement().getTitle() + ' ' + ((item.get(i).getElement().getType() == 1) ? '(Item)'  : '(Category)'));"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>printFunctions(m, i);"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('-----------------------------------------\n');"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>return<<white/> item;"+
            "<<<sp_1, br/>}"+
            "<<<sp_0, br/>}"
        },
        {
            key : 4,
            FileTitle : "CatalogueItem.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> interface CatalogueItem {"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>String getTitle();"+
                "<<<sp_1, br/><<grey/>/**"+
                "<<<sp_2, br/>* Retrive the catalogue item type, that is :"+
                "<<<sp_2, br/>*  	0 = Category"+
                "<<<sp_2, br/>*  	1 = Item "+
                "<<<sp_2, br/>* @return"+
                "<<<sp_2, br/>*/<<white/>"+
                "<<<sp_1, br/>int getType();"+
                "<<<sp_1, br/>int getKey();"+
                "<<<sp_1, br/><<orange/>void<<white/> setKey(int k);"+
                "<<<sp_1, br/>boolean modifyItem(CatalogueItem e);"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
        },
        {
            key : 5,
            FileTitle : "catalogueTree.java",
            content :
            "<<white/><<<sp_0, br/>package CatalogueSale;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.HashMap;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Iterator;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.LinkedList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.List;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Map;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> CatalogueSale.catalogueTree.Node;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> catalogueTree <E> implements Tree<E>{"+
            "<<<sp_1, br/><<grey/>//INTERFACES"+
            "<<white/><<<sp_1, br/><<orange/>public<<white/> interface keyComparator<E>{ int getKey(E e); }	<<grey/>// to locate key for element searches"+
            "<<white/><<<sp_1, br/><<orange/>public<<white/> interface Position <E> { E getElement() throws IllegalStateException; }"+
            "<<<sp_1, br/><<grey/>//NODE CLASS"+
            "<<white/><<<sp_1, br/>protected <<orange/>static<<white/> <<orange/>class<<white/> Node<E> implements Position<E> {"+
            "<<<sp_3, br/><<orange/>private<<white/> E element; <<grey/>//an element stored at this node"+
            "<<white/><<<sp_3, br/><<orange/>private<<white/> Node<E> p; <<grey/>//reference to the parent node (if any)"+
            "<<white/><<<sp_3, br/><<orange/>private<<white/> Node<E> l; <<grey/>// eference to the left sibbling (if any)"+
            "<<white/><<<sp_3, br/><<orange/>private<<white/> Node<E> r; <<grey/>//reference to the right chsibblingild (if any)"+
            "<<white/><<<sp_3, br/><<orange/>private<<white/> Node<E> firstChild; <<grey/>//reference to the firstChild"+
            "<<white/><<<sp_3, br/><<orange/>public<<white/> Node (E e, Node<E> parent, Node<E> leftSibbling, Node<E> rightSibbling){"+
            "<<<sp_5, br/>element = e;"+
            "<<<sp_5, br/>p = parent;"+
            "<<<sp_5, br/>l = leftSibbling;"+
            "<<<sp_5, br/>r = rightSibbling;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>@Override"+
            "<<<sp_3, br/><<orange/>public<<white/> E getElement() throws IllegalStateException { <<orange/>return<<white/> element; }"+
            "<<<sp_3, br/><<grey/>//update methods "+
            "<<white/><<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> setElement(E e) { element = e; }"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> setParent( Node<E> parentNode) { p = parentNode; } "+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> setLeft (Node<E> leftChild) { l = leftChild;}"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> setRight(Node<E> rightChild) { r = rightChild; } "+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> setFirstChild(Node<E> fc) { firstChild = fc; } "+
            "<<<sp_3, br/><<grey/>//accessors"+
            "<<white/><<<sp_3, br/><<orange/>public<<white/> Node<E> getParent() { <<orange/>return<<white/> p; }"+
            "<<<sp_3, br/><<orange/>public<<white/> Node<E> getLeft() { <<orange/>return<<white/> l; }"+
            "<<<sp_3, br/><<orange/>public<<white/> Node<E> getRight() { <<orange/>return<<white/> r;}"+
            "<<<sp_3, br/><<orange/>public<<white/> Node<E> getFirstChild() { <<orange/>return<<white/> firstChild;}"+
            "<<<sp_1, br/>} <<grey/>/* END OF NODE CLASS */<<white/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>protected Node<E> root = null; <<grey/>//root item"+
            "<<white/><<<sp_1, br/><<orange/>private<<white/> int size=0;"+
            "<<<sp_1, br/><<orange/>private<<white/> avlTree<Node<E>> searchTree = <<orange/>new<<white/> avlTree<Node<E>>();"+
            "<<<sp_1, br/><<grey/>//Map<Integer,Node<E> > ctt = new HashMap<>();"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_1, br/><<orange/>private<<white/> keyComparator<E> keyComp;"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> catalogueTree(keyComparator<E> k){ keyComp =k; } "+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Search and return a position of matching entry if exist."+
            "<<<sp_2, br/>* @param item"+
            "<<<sp_2, br/>* @return position of item"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> Position<E> search(E e){"+
            "<<<sp_3, br/>Node<E> p = searchTree.get(keyComp.getKey(e));"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (p == null){ "+
            "<<<sp_5, br/>printPhrase(e);  "+
            "<<<sp_5, br/><<orange/>return<<white/> null;		"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> p;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* insert and delete old position entry."+
            "<<<sp_2, br/>* @param oldEntry "+
            "<<<sp_2, br/>* @param insertEntry"+
            "<<<sp_2, br/>* @return"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> boolean mod<<lightblue/>if<<white/>yEn<<lightblue/>try<<white/>(E oldEn<<lightblue/>try<<white/>, E <<orange/>new<<white/>En<<lightblue/>try<<white/>, Position<E> position){"+
            "<<<sp_3, br/>Node<E> node = validate(position);"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(searchTree.insert(keyComp.getKey(<<orange/>new<<white/>En<<lightblue/>try<<white/>), node) == false)<<grey/>//if new entry already exist"+
            "<<white/><<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>node.element = <<orange/>new<<white/>En<<lightblue/>try<<white/>;"+
            "<<<sp_3, br/>searchTree.delete(keyComp.getKey(oldEn<<lightblue/>try<<white/>));"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> true;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>protected Node<E> createNode(E e, Node<E> parent, Node<E> left, Node<E> right) {"+
            "<<<sp_3, br/><<orange/>return<<white/> <<orange/>new<<white/> Node<E>(e, parent, left, right);"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>//Validates the position and returns it as a node. Throws exception if 'p' is invalid/defunctional"+
            "<<white/><<<sp_1, br/>protected Node<E> validate (Position<E> p) throws IllegalArgumentException {"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (! (p instanceof Node)) throw <<orange/>new<<white/> IllegalArgumentException('invalid position');"+
            "<<<sp_3, br/>Node<E> node = (Node<E>) p; <<grey/>//safeCasting to node type"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> (node.getParent() == node) <<grey/>// onvention for defunctional node "+
            "<<white/><<<sp_5, br/>throw <<orange/>new<<white/> IllegalArgumentException('p has been removed already');"+
            "<<<sp_3, br/><<orange/>return<<white/> node;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>@Override"+
            "<<<sp_1, br/><<orange/>public<<white/> Position<E> getParent(Position<E> p) throws IllegalArgumentException {"+
            "<<<sp_3, br/>Node<E> node = validate(p);"+
            "<<<sp_3, br/><<orange/>return<<white/> node.getParent();"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> Position<E> addRoot(E e) throws IllegalStateException{"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(!isEmpty()) throw <<orange/>new<<white/> IllegalStateException('root already exist');"+
            "<<<sp_3, br/>root = createNode(e, null,null,null);"+
            "<<<sp_3, br/>searchTree.insert(keyComp.getKey(e), root);"+
            "<<<sp_3, br/>size++;"+
            "<<<sp_3, br/><<orange/>return<<white/> root;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/* add a new child to parent p with element e . Return null if item already exist*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> Position<E> addChild(Position<E> p, E e) throws IllegalArgumentException"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>Node<E> parent = validate(p); <<grey/>//validateposition "+
            "<<white/><<<sp_3, br/>Node<E> <<orange/>new<<white/>child = createNode(e, parent, null, null);"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ( (searchTree.insert(keyComp.getKey(e), <<orange/>new<<white/>child)) ==false)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('Catalogue item already exist! <<lightblue/>try<<white/> using a d<<lightblue/>if<<white/>ferent name.');"+
            "<<<sp_5, br/><<orange/>return<<white/> null;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>addSibbling(parent, <<orange/>new<<white/>child);	"+
            "<<<sp_3, br/>size++;"+
            "<<<sp_3, br/>searchTree.insert(keyComp.getKey(e), <<orange/>new<<white/>child);"+
            "<<<sp_3, br/><<orange/>return<<white/> <<orange/>new<<white/>child;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/* add new-node to the left of first-child node, this becomes the new first-child of the parent*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>void<<white/> addSibbling(Node<E> parent, Node<E> <<orange/>new<<white/>Node) throws IllegalStateException {"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(childExist(parent) == true) {"+
            "<<<sp_5, br/>Node<E> fc = parent.getFirstChild();"+
            "<<<sp_5, br/>fc.setLeft(<<orange/>new<<white/>Node);"+
            "<<<sp_5, br/><<orange/>new<<white/>Node.setRight(fc);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>parent.setFirstChild(<<orange/>new<<white/>Node);"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/* Removes the node at Position p and removes all associated child */<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> boolean remove(Position<E> p) throws IllegalArgumentException "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (isRoot(p)){"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('Cannot remove root category');"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>Node<E> toRemove = validate(p);		"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>//re-link structure"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> (toRemove.getLeft() == null && toRemove.getRight() == null) <<grey/>//this is only child"+
            "<<white/><<<sp_5, br/>toRemove.getParent().firstChild = null; <<grey/>//dereference parent node -to-> removed node"+
            "<<white/><<<sp_3, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (toRemove.getLeft() == null){	<<grey/>//Removed node is root child. Relink parent rootChild to right child"+
            "<<white/><<<sp_5, br/>toRemove.getParent().firstChild = toRemove.getRight();"+
            "<<<sp_5, br/>toRemove.getRight().setLeft(null);"+
            "<<<sp_3, br/>}<<lightblue/>else<<white/> {<<grey/>//Relink sibblings. RemovedNode between rootChild+1 ~ sizeOfChildrens"+
            "<<white/><<<sp_6, br/>toRemove.getLeft().setRight(toRemove.getRight());"+
            "<<<sp_6, br/><<lightblue/>if<<white/> (toRemove.getRight() !=null) toRemove.getRight().setLeft(toRemove.getLeft());"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>//de-reference all child nodes & remove from search tree"+
            "<<white/><<<sp_3, br/><<lightblue/>for<<white/>(Position<E> pos : getChildren(toRemove, -1)) {"+
            "<<<sp_5, br/>searchTree.delete(keyComp.getKey(pos.getElement())); <<grey/>//delete from searchTree"+
            "<<white/><<<sp_5, br/>Node<E> n = (Node<E>)pos;"+
            "<<<sp_5, br/>n.setElement(null);"+
            "<<<sp_5, br/>n.setLeft(null);"+
            "<<<sp_5, br/>n.setRight(null);"+
            "<<<sp_5, br/>n.setFirstChild(null);"+
            "<<<sp_5, br/>n.setParent(n);<<grey/>//convention for defunct node . current.node = current.node. "+
            "<<white/><<<sp_3, br/>}"+
            "<<<sp_3, br/>size--;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> true;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/*preorder traversal￢ﾀﾙ of a tree T, the root of T is visited first and then the subtrees rooted at its children are traversed recursively*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>void<<white/> preOrderTraversal(Node<E> n, int depth, int depthEnd, List<Position<E>> ss, int toprint){"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(toprint == 1)<<orange/>system<<white/>.out.println(main.spaces(depth*2) +((CatalogueItem)n.element).getTitle());"+
            "<<<sp_3, br/>ss.add(n);"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(depth == depthEnd) <<orange/>return<<white/>;"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(childExist(n)){ "+
            "<<<sp_5, br/>n = n.firstChild;"+
            "<<<sp_5, br/><<lightblue/>while<<white/>(true){"+
            "<<<sp_7, br/>preOrderTraversal((Node<E>)n, depth+1,depthEnd, ss, toprint);"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (n.getRight() == null) <<orange/>break<<white/>;"+
            "<<<sp_7, br/>n = n.getRight();"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_2, br/><<grey/>//O(n) running time : Print matching phrase"+
            "<<white/><<<sp_2, br/>int count;"+
            "<<<sp_2, br/><<orange/>public<<white/> <<orange/>void<<white/> printPhrase(E e){"+
            "<<<sp_4, br/>count = 0;"+
            "<<<sp_4, br/>getPhrase(e, root);"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/><<orange/>private<<white/> <<orange/>void<<white/> getPhrase( E e, Node<E> n ) {"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (childExist(n)){"+
            "<<<sp_7, br/>n = n.firstChild;"+
            "<<<sp_7, br/><<lightblue/>while<<white/>(true){"+
            "<<<sp_8, br/><<lightblue/>if<<white/> ( containsPhrase( ((CatalogueItem) e).getTitle() , ((CatalogueItem) n.element).getTitle() ) )"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println( '  Containing Phrase ('+ ++count +') : ' + ((CatalogueItem) n.element).getTitle() );"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/>getPhrase(e, n);"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (n.getRight() == null) <<orange/>break<<white/>;"+
            "<<<sp_8, br/>n = n.getRight();"+
            "<<<sp_7, br/>}"+
            "<<<sp_5, br/>}"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/><<grey/>/**"+
            "<<<sp_4, br/>* Return true if a ￢ﾊﾆ b"+
            "<<<sp_4, br/>* @param a"+
            "<<<sp_4, br/>* @param b"+
            "<<<sp_4, br/>* @return"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> boolean containsPhrase(String a, String b){"+
            "<<<sp_5, br/>int l = (a.length() > b.length()) ? b.length() : a.length();"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(int i =0; i <  l ; i++)"+
            "<<<sp_7, br/><<lightblue/>if<<white/> ( a.charAt(i) != b.charAt(i))"+
            "<<<sp_8, br/><<orange/>return<<white/> false;"+
            "<<<sp_5, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_1, br/>@Override"+
            "<<<sp_1, br/><<orange/>public<<white/> Position<E> root() { <<orange/>return<<white/> root; }"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<orange/>public<<white/> List<Position<E>> getChildren(Position<E> p, int depth){"+
            "<<<sp_3, br/>Node<E> c = validate(p);"+
            "<<<sp_3, br/>List<Position<E>> i = <<orange/>new<<white/> LinkedList<>();"+
            "<<<sp_3, br/>preOrderTraversal(c, 0, depth, i, -1);"+
            "<<<sp_3, br/><<orange/>return<<white/> i;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>@Override"+
            "<<<sp_1, br/><<orange/>public<<white/> List<Position<E>> getChildren(Position<E> p) throws IllegalArgumentException {"+
            "<<<sp_3, br/><<orange/>return<<white/> getChildren(p, 1);"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<orange/>private<<white/> boolean childExist(Node<E> p ){ "+
            "<<<sp_3, br/><<orange/>return<<white/> (p.getFirstChild() != null); "+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>@Override"+
            "<<<sp_1, br/><<orange/>public<<white/> boolean isRoot(Position<E> p) throws IllegalArgumentException {"+
            "<<<sp_3, br/>Node<E> r = validate(p);"+
            "<<<sp_3, br/><<orange/>return<<white/> (root == r);"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>@Override"+
            "<<<sp_1, br/><<orange/>public<<white/> boolean isEmpty() {<<orange/>return<<white/> (size == 0);}"+
            "<<<sp_1, br/>@Override"+
            "<<<sp_1, br/><<orange/>public<<white/> int size() {<<orange/>return<<white/> size;}"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> displayTree(){ preOrderTraversal(root, 0,-1, <<orange/>new<<white/> LinkedList<>(), 1); }"+
            "<<<sp_1, br/><<orange/>public<<white/> Position<E> getRoot(){ <<orange/>return<<white/> root; } "+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"
        },
        {
            key : 6,
            FileTitle : "category.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> category implements CatalogueItem{"+
                "<<<sp_1, br/><<orange/>private<<white/> int key;"+
                "<<<sp_1, br/><<orange/>private<<white/> String title;"+
                "<<<sp_1, br/>category(){"+
                "<<<sp_2, br/>"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>category(String t, int k)"+
                "<<<sp_1, br/>{"+
                "<<<sp_2, br/>title =t;"+
                "<<<sp_2, br/>key = k;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>public<<white/> String getTitle() { <<orange/>return<<white/> title; }"+
                "<<<sp_1, br/><<orange/>public<<white/> int getKey() { <<orange/>return<<white/> key; }"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/>@Override"+
                "<<<sp_1, br/><<orange/>public<<white/> int getType() { <<orange/>return<<white/> 0; }"+
                "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> setTitle(String t) { title = t; }"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> setKey(int k) { key = k; }"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/>@Override"+
                "<<<sp_1, br/><<orange/>public<<white/> boolean modifyItem(CatalogueItem e) {"+
                "<<<sp_2, br/><<lightblue/>if<<white/> (!(e instanceof category))"+
                "<<<sp_3, br/><<orange/>return<<white/> false;"+
                "<<<sp_2, br/>category i = (category)e;"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/>i.key = i.getKey();"+
                "<<<sp_2, br/>i.title = i.getTitle();"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/><<orange/>return<<white/> true;"+
                "<<<sp_1, br/>};"+
                "<<<sp_1, br/>"+
                "<<<sp_0, br/>}"
        },
        {
            key : 7,
            FileTitle : "item.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> item implements CatalogueItem{"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>private<<white/> String title,description;"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/><<orange/>private<<white/> int key,quantity; "+
                "<<<sp_1, br/><<orange/>private<<white/><<lightblue/>do<<white/>uble maxDiscount,price;"+
                "<<<sp_1, br/>item(){};"+
                "<<<sp_1, br/>item(String t, String d, int k, int p, int q,<<lightblue/>do<<white/>uble md) "+
                "<<<sp_1, br/>{"+
                "<<<sp_2, br/>title =t;"+
                "<<<sp_2, br/>description = d;"+
                "<<<sp_2, br/>key = k;"+
                "<<<sp_2, br/>price = p;"+
                "<<<sp_2, br/>quantity = q;"+
                "<<<sp_2, br/>maxDiscount = md;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>public<<white/> String getTitle() { <<orange/>return<<white/> title; }"+
                "<<<sp_1, br/>String getDescription() {<<orange/>return<<white/> description;}"+
                "<<<sp_1, br/><<orange/>public<<white/> int getKey() { <<orange/>return<<white/> key; }"+
                "<<<sp_1, br/>double getPrice() { <<orange/>return<<white/> price; }"+
                "<<<sp_1, br/>int getQuantity() {<<orange/>return<<white/> quantity;}"+
                "<<<sp_1, br/>double getMaxDiscount() {<<orange/>return<<white/> maxDiscount;}"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/>@Override"+
                "<<<sp_1, br/><<orange/>public<<white/> int getType() { <<orange/>return<<white/> 1;}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> setKey(int k) { key = k; }"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>public<<white/> boolean setTitle(String title) {"+
                "<<<sp_2, br/><<lightblue/>if<<white/> (title.length() > 20)"+
                "<<<sp_2, br/>{"+
                "<<<sp_3, br/><<orange/>system<<white/>.out.println('Title should be <= 20 characters');"+
                "<<<sp_3, br/><<orange/>return<<white/> false;"+
                "<<<sp_2, br/>}"+
                "<<<sp_2, br/>this.title = title;"+
                "<<<sp_2, br/><<orange/>return<<white/> true;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/><<orange/>public<<white/> boolean setDescription(String description) {"+
                "<<<sp_2, br/><<lightblue/>if<<white/> (description.length() > 50){"+
                "<<<sp_3, br/><<orange/>system<<white/>.out.println('description should be <= 50 characters');"+
                "<<<sp_3, br/><<orange/>return<<white/> false;"+
                "<<<sp_2, br/>}"+
                "<<<sp_2, br/>this.description = description;"+
                "<<<sp_2, br/><<orange/>return<<white/> true;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/><<orange/>public<<white/> boolean setPrice(double price) {"+
                "<<<sp_2, br/><<lightblue/>if<<white/> (!(price > 0.0 ) ){"+
                "<<<sp_3, br/><<orange/>system<<white/>.out.println('Price must be > 00.');"+
                "<<<sp_3, br/><<orange/>return<<white/> false;"+
                "<<<sp_2, br/>}"+
                "<<<sp_2, br/>this.price = price;"+
                "<<<sp_2, br/><<orange/>return<<white/> true;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/><<orange/>public<<white/> boolean setQuantity(int quantity) {"+
                "<<<sp_2, br/><<lightblue/>if<<white/> (!(quantity > 0 ) ){"+
                "<<<sp_3, br/><<orange/>system<<white/>.out.println('Quantity must be > 0');"+
                "<<<sp_3, br/><<orange/>return<<white/> false;"+
                "<<<sp_2, br/>}"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/>this.quantity = quantity;"+
                "<<<sp_2, br/><<orange/>return<<white/> true;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/><<orange/>public<<white/> boolean setMaxDiscount(double maxDiscount) {"+
                "<<<sp_2, br/><<lightblue/>if<<white/> (maxDiscount < 0.0 || maxDiscount > 100.0){"+
                "<<<sp_3, br/><<orange/>system<<white/>.out.println('maxDiscount must be betwee 0.0 and 100.0');"+
                "<<<sp_3, br/><<orange/>return<<white/> false;"+
                "<<<sp_2, br/>}"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/>this.maxDiscount = maxDiscount;"+
                "<<<sp_2, br/><<orange/>return<<white/> true;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>public<<white/><<lightblue/>do<<white/>uble getminPrice()"+
                "<<<sp_1, br/>{"+
                "<<<sp_2, br/><<orange/>return<<white/> price -  (maxDiscount/100) * price;"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/>"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>@Override"+
                "<<<sp_1, br/><<orange/>public<<white/> boolean mod<<lightblue/>if<<white/>yItem(CatalogueItem e) {"+
                "<<<sp_2, br/><<lightblue/>if<<white/> (!( e instanceof item))"+
                "<<<sp_3, br/><<orange/>return<<white/> false;"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/>item i = (item)e;"+
                "<<<sp_2, br/>setTitle(e.getTitle());"+
                "<<<sp_2, br/>setKey(e.getKey());"+
                "<<<sp_2, br/>this.price = i.getPrice();"+
                "<<<sp_2, br/>this.quantity = i.getQuantity();"+
                "<<<sp_2, br/>this.maxDiscount = i.getMaxDiscount();"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/><<orange/>return<<white/> true;"+
                "<<<sp_2, br/>"+
                "<<<sp_1, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
        },
        {
            key : 8,
            FileTitle : "manager.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> manager extends user{"+
                "<<<sp_1, br/><<orange/>public<<white/> manager(String n){"+
                "<<<sp_2, br/>super(n, 'manager');"+
                "<<<sp_1, br/>}"+
                "<<<sp_0, br/>}"+
                "<<<br,hidden/>br"
        },
        {
            key : 9,
            FileTitle : "seller.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> seller extends user {"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/><<orange/>public<<white/> seller(String n) {"+
                "<<<sp_2, br/>super(n, 'seller');"+
                "<<<sp_2, br/><<grey/>// TODO Auto-generated constructor stub"+
                "<<white/><<<sp_1, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
        },
        {
            key : 10,
            FileTitle : "Tree.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> interface Tree<E> "+
                "<<<sp_0, br/>{"+
                "<<<sp_1, br/><<grey/>//Return the position of the root of the tree . or null if p is the root"+
                "<<white/><<<sp_1, br/>catalogueTree.Position<E> root();"+
                "<<<sp_1, br/><<grey/>//Returns the position of the parent of position p .  null if p is the root"+
                "<<white/><<<sp_1, br/>catalogueTree.Position<E> getParent(catalogueTree.Position<E> p) throws IllegalArgumentException;"+
                "<<<sp_1, br/><<grey/>/**"+
                "<<<sp_2, br/>* Returns iterable containing the children of a given parent p if any "+
                "<<<sp_2, br/>* @param p"+
                "<<<sp_2, br/>* @return"+
                "<<<sp_2, br/>* @throws IllegalArgumentException"+
                "<<<sp_2, br/>*/<<white/>"+
                "<<<sp_1, br/>Iterable<catalogueTree.Position<E>> getChildren(catalogueTree.Position<E>p) throws IllegalArgumentException;"+
                "<<<sp_1, br/><<grey/>//Returns the number of children in position p"+
                "<<white/><<<sp_1, br/>boolean isRoot(catalogueTree.Position<E> p) throws IllegalArgumentException;"+
                "<<<sp_1, br/>boolean isEmpty();"+
                "<<<sp_1, br/>int size();"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"+
                "<<<br,hidden/>br"
        },
        {
            key : 11,
            FileTitle : "user.java",
            content : 
                "<<white/><<<sp_0, br/>package CatalogueSale;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> abstract <<orange/>class<<white/> user {"+
                "<<<sp_1, br/>String name;"+
                "<<<sp_1, br/><<orange/>private<<white/> int[] userFunc;"+
                "<<<sp_1, br/><<orange/>private<<white/> String role; "+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>public<<white/> user(String n, String r)"+
                "<<<sp_1, br/>{"+
                "<<<sp_3, br/>name = n;"+
                "<<<sp_3, br/>role = r;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>public<<white/> String getRole()"+
                "<<<sp_1, br/>{"+
                "<<<sp_3, br/><<orange/>return<<white/> role;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> setFunc(int[] f)"+
                "<<<sp_1, br/>{"+
                "<<<sp_3, br/>userFunc = f;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/><<grey/>/**"+
                "<<<sp_2, br/>* retrieve all userfunctions 1~n-1."+
                "<<<sp_2, br/>* @return array of available func"+
                "<<<sp_2, br/>* @throws Exception"+
                "<<<sp_2, br/>*/<<white/>"+
                "<<<sp_1, br/><<orange/>public<<white/> int[] getFunc() throws Exception{ "+
                "<<<sp_3, br/><<lightblue/>if<<white/> (userFunc == null) "+
                "<<<sp_4, br/>throw <<orange/>new<<white/> Exception('User Has no set Function!');"+
                "<<<sp_3, br/>"+
                "<<<sp_3, br/><<orange/>return<<white/> userFunc;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<grey/>/**"+
                "<<<sp_2, br/>* Return a specific arrayNo in the func array"+
                "<<<sp_2, br/>* @param i"+
                "<<<sp_2, br/>* @return functionNo OR -1 if invalid 'i' is input,"+
                "<<<sp_2, br/>* @throws Exception,  "+
                "<<<sp_2, br/>*/<<white/>"+
                "<<<sp_1, br/><<orange/>public<<white/> int getFunc(int i) { "+
                "<<<sp_3, br/><<lightblue/>if<<white/> (userFunc == null || i >= userFunc.length ) "+
                "<<<sp_4, br/><<orange/>return<<white/> -1;"+
                "<<<sp_1, br/>"+
                "<<<sp_3, br/><<orange/>return<<white/> userFunc[i];"+
                "<<<sp_1, br/>}"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
        }
    ]
}

export default CaroselItem;

import styling from './styling.module.css';
import React, { CSSProperties, ReactElement, ReactText, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { reduceEachTrailingCommentRange } from 'typescript';
import DynamicText from "../Others/dynamicText";
import {StateWrapper} from '../../../Interfaces/stateObjectInterface';
import AppleCloseButtom from '../../buttons/closeButtonApple/CloseButtonApple'
import myBrowser from '../../../Handlers/myBrowser';
import ScrollableFrame from '../Others/scrollableFrame';
import {ObjectRef} from '../../../Interfaces/RefObjectInterface';
import ImageFrame from '../Others/imageFrame';
import CodeWindow, {WindowState, FileList} from '../codeDemoWindow/CodeWindow';
import { act } from 'react-dom/test-utils';
import DynamicStringHandler from '../Dynamic_StringContent/DynamicString'; 

/* ------------------------------------------ Component Description ------------------------------------------
    Renders a content carosel component inheriting the full width,height of the parenting component. Content 
    is rendered dynamically based on these values.

    The contents of the elements given will utilise the 'Dynamic_StringContent' class to format / structure a
    string of text into its HTMLElement (with styling applied), this is applied to the content description 
    (i.e. props.Description).

    The content_Carosel has two important properties that must be satisfied, this is :
        1)  Parent component must have min-height of 1000px, else overflow will occur. 
        2)  Parent component must have well-defined width, height. 

    The Component take as arguments through props  : 
        Text : String 
        Styling : React.CSSProperties 
        onChangeAction : onChange callback action handler
        isSimpleContainer : boolean, s.t. isSimpleContainer = true IFF its direct parent container and styling given by props
                            is not a flex-box nor grid-box, else isSimpleContainer = false.  This is set to false by default. 
       popup_ZIndex : z_index specified for popup.  
    Note : The following conditions must be met for components proper functionality 
        1) Padding in direct parent container is not yet supported by this compoment, we use additional div-wrapping as solution.           
        2) Direct parentNode container must inherit the overflow = hidden property to ensure proper container scalling values 
           conducted in --> (Ref : 2107)
        3) isSimpleContainer must be specified IFF parent container OR styling given is : 
                A) A flex box
                B) Grid Box
------------------------------------------------------------------------------------------------------------*/

/** 
 * Iterate to a new row by settingState to new row value
 * @direction : 1) "forward", 2) "backward"; to specify which direcition to iterate. 
 */
let iterateRow = (event :React.MouseEvent, direction : String, currentRow : StateWrapper<number>, total_ElementSize : number, ElementSize : number,  Horizontal_ElementSize : number, contentWrapper_Ref : React.RefObject<HTMLDivElement> ) => {
    if (contentWrapper_Ref.current != undefined) 
    {
            let totalnumber_Rows = Math.ceil(total_ElementSize / Horizontal_ElementSize); 
            let prev_RowSize = currentRow.item;
            let activeRowsize = Math.floor(contentWrapper_Ref.current.clientHeight / ElementSize); // currently active rows 
            let rowsOverflown = ((totalnumber_Rows - prev_RowSize) - activeRowsize) ;
            
            if (direction === "forward")
            {
                if (rowsOverflown > 0 )
                {
                    console.log( rowsOverflown + " row is overflown");
                    currentRow.setItem(currentRow.item + activeRowsize);
                }
            }else if (direction === "backward"){
                let decrement_Val = currentRow.item - activeRowsize;
                if (decrement_Val < 0) decrement_Val = 0;
                currentRow.setItem(decrement_Val);
            }

    }
}
/** position elements after currentRow change (or on rescale) */
let positionElements = (contentContainer_Ref :React.RefObject<HTMLDivElement>, contentSize: number, currentRowWrapper: StateWrapper<number>, Horizontal_ElementSize: number ) => {
    if (contentContainer_Ref.current!= undefined)
    {
        let x = contentSize * currentRowWrapper.item;
        contentContainer_Ref.current.style.transform = `translate(0,-${x+0.4}px)`;
    }
}

/** Handle Carosel element Click : Open code window */
let Toggle_CodeWindow = (event :React.MouseEvent | undefined, action : String,  Horizontal_ElementSize :number,  Items : items[], window_States : WindowState[], overlay_Wrapper : StateWrapper<{ item : items, visible : boolean, windowState?: WindowState | undefined } | undefined>) => {
    console.log("Toggled");
    
    switch(action){
        case "close" : 
            document.body.style.overflowY = "auto"; //  default page scroll
            overlay_Wrapper.setItem(undefined); 
        break;
        case "open" :
            document.body.style.overflowY = "hidden"; // stop default page scroll
            if (event != undefined){
                let item : HTMLDivElement = event.currentTarget as HTMLDivElement;
                let itemNumber : number  = parseInt( ( (item.dataset.key != null) ? item.dataset.key : "-1"));
                let state : WindowState | undefined;

                if (itemNumber != -1) {
                    state = window_States[itemNumber];
                }
                console.log("window " + itemNumber + " has been opened");
                overlay_Wrapper.setItem({item : Items[itemNumber],  visible : true, windowState :state}); 
            }
        break;
   }
}

export interface items {
    key : number;
    Subject : String;
    Description : React.ReactNode;
    img_Url : String;
    file_List : FileList[];
}
let RenderComponent : React.FC<{ isdarkNode ?: boolean,  Items : items[], contentHeight : number, Title : String, Description :String, popup_ZIndex : number, TitleStyling? : React.CSSProperties}> = (props) => { 
    let Horizontal_ElementSize = useRef<number>(0);
    let spacing : number = 1; // gap percentage between elements
    let total_elementSize = props.Items.length;  
    let content_BoxSizing :number = (100/Horizontal_ElementSize.current) ;              
    let contentContainer_Ref  = React.useRef<HTMLDivElement>(null);
    let container_Ref = React.useRef<HTMLDivElement> (null);
    let contentWrapper_Ref = React.useRef<HTMLDivElement>(null);
    let [currentRow, set_currentRow] = React.useState<number>(0); 
    let [contentSize, set_contentSize] = useState<number>(0);
    let [contentContainer_Sizing, set_contentContainerSizing] = useState<{w : number, h : number}>( {w:0, h :0});
    let currentRowWrapper :StateWrapper<number> = {
        item : currentRow,
        setItem : set_currentRow       
    }
    let [overlayState, set_OverlayState] = useState<{item : items, visible : boolean, windowState?: WindowState}> ();
    let overlayState_Wrapper : StateWrapper<{ item : items, visible : boolean, windowState?: WindowState } | undefined> = {
        item : overlayState,
        setItem : set_OverlayState,
    } 
    let max_VerticalSizing = props.contentHeight;
    let [globalFontSize, set_GlobalFontSize] = useState<number> (10); // global FontSize. 
    let Dynamic_globalFontRef:ObjectRef<HTMLParagraphElement> ={
        setFunc(ref, toRef) {
            if (toRef.current != undefined) {
                ref.ref = toRef;
            }
        },
        ref : undefined
    }
    /** CodeWindow initialisation & States, that is : the CodeDemo component */
    let CodeWindow_Elements : { windowStates:WindowState[], windowElement : React.ReactElement[] } = React.useMemo( () => { 
        let states :{ windowStates:WindowState[], windowElement : React.ReactElement[] }  = {
            windowStates : [],
            windowElement : []
        };

        for (let i=0; i < props.Items.length; i++)
        {
            states.windowStates.push( {windowState :0, setWindowState : undefined});     // Initialise all codeWindow to closed. 
            states.windowElement.push( <CodeWindow popup_ZIndex={(props.popup_ZIndex+1)} Window_State = {states.windowStates[i]} Title = {props.Items[i].Subject} fileList = {props.Items[i].file_List} />);
        }
        
        return states;
    }, props.Items);



    /** Listeners setup */
    useLayoutEffect( 
        ()=>{
            let timeout_ID: NodeJS.Timeout | null = null;

            // rescale carosel content sizing
            const re_scale = () => {
                // Rescale values
                let rescalePercentage;
                // if (window.innerWidth < myBrowser.mobile_Width()) {
                //     Horizontal_ElementSize.current = 2;
                //     rescalePercentage = 1;
                // }else{ 
                //     rescalePercentage = 0.5;
                //     Horizontal_ElementSize.current = 3;
                // }

                if (myBrowser.isMobileWidth()){
                    Horizontal_ElementSize.current = 2;
                    rescalePercentage = 1;
                }else{ 
                    Horizontal_ElementSize.current = 3;
                    rescalePercentage = 0.5;
                }

                /** Dynamically rescale container & element */
                if (container_Ref.current != undefined){
                    let container_Width = container_Ref.current.clientWidth;                                // current container width
                    let new_ContainerState = {w: rescalePercentage * container_Width, h: 0};                // set new container size
                    let new_ElementSize = (new_ContainerState.w / Horizontal_ElementSize.current)-0.5;      // set new element size to fit container

                    // calculate container heigh size [MAKE MORE EFFICIENT]
                    for (let i =1 ; i <= max_VerticalSizing ; i++) {
                        if (Horizontal_ElementSize.current * i >= props.Items.length || i == max_VerticalSizing ){
                            new_ContainerState.h = i * new_ElementSize;
                            break;
                        }
                    }

                    // initialise container size
                    if (new_ContainerState.w != contentContainer_Sizing.w || new_ContainerState.h != contentContainer_Sizing.h) {
                        set_contentContainerSizing(new_ContainerState);
                    }
                    // initialise element size
                    if (new_ElementSize != contentSize) set_contentSize(new_ElementSize);
                 }
            };
            
            const rescale_Handler = () => {
                if (timeout_ID) {
                  clearTimeout(timeout_ID); // cancel previous setTimeout Call if it has not been executed.
                }
                
                timeout_ID = setTimeout(re_scale, 0.1 ); // initialise setTImeout call
            };
            
            rescale_Handler();
            window.addEventListener("resize", rescale_Handler);
            return () => {
                window.removeEventListener("resize", rescale_Handler);
                if (timeout_ID) clearTimeout(timeout_ID);                           // previous setTimeout Call
            }
        },[contentSize, contentContainer_Sizing]
    )


    /** Reposition elements on re-render */
    useLayoutEffect(
        () =>{ positionElements(contentWrapper_Ref, contentSize, currentRowWrapper, Horizontal_ElementSize.current); }
    );


    let viewMore_Handler = (event :React.MouseEvent, direction : String ) => {
        iterateRow( event, direction, currentRowWrapper, total_elementSize, contentSize, Horizontal_ElementSize.current, contentContainer_Ref);
    }

    let dynamicFontChange_Handler = () => {
        if (Dynamic_globalFontRef.ref != undefined){
                set_GlobalFontSize(myBrowser.RefHandling.getRefFontSize(Dynamic_globalFontRef.ref));
        }
    }


    /** Open code window handler */
    let OpenCodeWindow = () => {

        if (overlayState != undefined) {
            if (overlayState.windowState?.setWindowState != undefined) {
                overlayState.windowState.setWindowState(1);
            }
        }
    }

    return(
        <>
            { CodeWindow_Elements.windowElement /** Render WindowElements */}
            { // RENDER SELECTED CODE WINDOW 
                (overlayState!= undefined && overlayState.visible) &&
                <div className={styling.codeWindow_Container} style = {{zIndex:`${props.popup_ZIndex}`}}>
                    <div className= {styling.close_OverlayButtonContainer} onMouseDown={() => {Toggle_CodeWindow(undefined, "close",  Horizontal_ElementSize.current, props.Items, CodeWindow_Elements.windowStates, overlayState_Wrapper)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 50 50" fill='white'>
                            <path d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z"></path>
                        </svg>
                        {/* <AppleCloseButtom handleAction={ () =>{ console.log("Close btn clicked!"); Toggle_CodeWindow(undefined, "close",  Horizontal_ElementSize.current, props.Items, CodeWindow_Elements.windowStates, overlayState_Wrapper)}}/> */}
                    </div>
                    <div className={styling.codeWindow_SubContainer}>
                        <div style = {{width:'100%', height :'100%', overflow:'hidden'}}>
                            <ScrollableFrame showOverflowNotification={false}>
                                <div className={styling.overlayDescriptionContainer}>
                                    <div>
                                        <div className= { styling.SubjectContainer } > 
                                                {overlayState.item.Subject} 
                                        </div>
                                        <div className= { styling.DescriptionContainer }> 
                                            { overlayState.item.Description}
                                        </div>
                                    </div>
                                </div>
                           </ScrollableFrame>

                        </div>
                        <div className= { styling.overlayImageContainer } > 
                            <div style = {{textAlign:'center', width:'100%',  display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                <img className = {styling.viewProject_Image} src = {`${overlayState.item.img_Url}`}/>
                                {/* <div className = {styling.viewProjectWrapper} onMouseDown={ () => { OpenCodeWindow() }} >  
                                    <p className= { styling.DescriptionContainer } style ={{ padding:'0', margin:'0', display:'inline' }}> VIEW PROJECT CODE</p>
                                </div> */}

                            </div>

                        </div> 
                    </div>
                </div>
            }
            


            { // RENDER CAROSEL
                <div className= {styling.container} >
                    <div style = {{  width :'100%'}}> 
                        <div className={styling.caroselDescription_Container}> 
                            <div className = {styling.mainTitle + ' ' + styling.textOverflow} style = { Object.assign( {}, props.TitleStyling) }>
                                    {props.Title}
                            </div>
                            <div  className= {styling.mainDescription + ' ' + styling.textOverflow}  style = { Object.assign ({color:'grey !important' }, props.TitleStyling)}>
                                    {props.Description}
                            </div>
                            <div className={styling.divider} style = {{position:'absolute', bottom:'0px'}}/>
                        </div>

                        <div className={styling.contentContainer} >
                                <div ref = {container_Ref} className = {styling.contentWrapper} > 
                                        <div ref = {contentContainer_Ref} style ={{   position:'relative', overflow :'hidden', height : `${contentContainer_Sizing.h}px`, width : `${contentContainer_Sizing.w}px`, transition :'transform 1s'}} >
                                            <div ref ={contentWrapper_Ref} className = {styling.contentContainer} style = {{padding:'0'}}>

                                                    {
                                                        (() => {
                                                            const listItems = [];
                                                            for (let i = 0; i < props.Items.length; i++) {
                                                                listItems.push(
                                                                    <div className={styling.contentItems} key ={i} style = {{  width:`${contentSize}px`, height :`${contentSize}px`, paddingLeft:`${spacing/2}%`, paddingRight:`${spacing/2}%`, paddingTop:`${spacing}%`}}> 
                                                                        <div className= {styling.elementContainer} style ={{ backgroundImage : `url(${props.Items[i].img_Url}`}} 
                                                                            onMouseDown={ (event : React.MouseEvent) => { Toggle_CodeWindow (event,"open",  Horizontal_ElementSize.current, props.Items, CodeWindow_Elements.windowStates, overlayState_Wrapper)} } data-key ={i}  > 
                                                                        
                                                                            <div className={styling.titleContainer} > {props.Items[i].Subject} </div>
                                                                            <div className = { styling.ElementInformationContainer }>
                                                                                <div style = {{position:'relative', display:'grid',  width:'100%', height :'100%', gridTemplateRows :'15% 60% 15%', gap :'5%', fontSize :`${globalFontSize}px`} }> 
                                                                                    <div className= {styling.Element_Title} >
                                                                                        { // Dynamic FontSize Initialisor
                                                                                            i==0 &&
                                                                                            <div style = {{width:'100%', height:'100%', position:'absolute' ,  visibility:'hidden'}}>
                                                                                                <DynamicText Text={"A"} textRef = {Dynamic_globalFontRef}  onChangeAction = {dynamicFontChange_Handler}/>
                                                                                            </div>
                                                                                        }
                                                                                        {props.Items[i].Subject} 
                                                                                    </div>
                                                
                                                                                    <div className= {styling.Element_Description} style = {{position:'relative', width :'100%', height :'100%', whiteSpace:'normal'}}>
                                                                                        <ScrollableFrame showOverflowNotification = {true}> 
                                                                                            <div  className= {styling.Element_Description}>
                                                                                                {props.Items[i].Description}
                                                                                            </div>
                                                                                        </ScrollableFrame>                                                                    
                                                                                    </div>
                                                                                    <div className={styling.Element_LearnMore}>
                                                                                        Learn More
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                            return listItems;
                                                        })()
                                                    }
                                            </div>
                                        </div>
                                </div >
                        </div>
                        <div id = "viewMoreContent_Container" style ={{zIndex:'1', width:'100%', overflow :'hidden',  minHeight:'30px', marginTop:'1%'  }}>
                            <div className = {styling.viewMore_Container}>
                                {
                                    ((currentRowWrapper.item + Math.floor(contentContainer_Sizing.h / contentSize)) * Horizontal_ElementSize.current < total_elementSize) &&
                                    <div className={styling.button_boxcontainer} onMouseDown = { (event:React.MouseEvent) => {viewMore_Handler(event, "forward")} }   > 
                                        view more 
                                    </div>  
                                }
                                {
                                    currentRowWrapper.item >0 &&
                                    <div className={styling.button_boxcontainer} onMouseDown ={ (event:React.MouseEvent) => {viewMore_Handler(event,"backward")} } > 
                                        view Less 
                                    </div>  

                                }
                            </div>
                        </div>
                         


                    </div>
                </div>
            }
        </>
    );
}



export default RenderComponent;
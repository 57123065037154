import { useRef } from "react";
import Styling from "./Styling.module.css"
import myBrowser from "../../Handlers/myBrowser";




let RenderPage : React.FC <{}> = (props) => { 

    let main_Ref = useRef<HTMLDivElement>(null);
    const formattedDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
    });
  


    <div id='resumeDownload_Container' onMouseDown={()=>{myBrowser.DirHandling.download("./G_Resume.pdf", "G_Resume.pdf" )}} style ={{color:'red'}}> DOWNLOAD </div>

    return(

        <>
            <div ref ={main_Ref} className= {Styling.mainContainer} >
                <div id = {Styling.test} className= {Styling.ContentContainer}>
                    <h1> GORDON L </h1>
                    <div className={Styling.jobTitle} style ={{padding:"0", margin:'0'}}>
                        <h3 style = {{opacity:"0"}}> . </h3>
                    </div>
                </div>

                <div className= {Styling.bottomContainer}> 
                    {/** SECTION 1*/}
                    <div className={Styling.section_1} >
                        { /** CONTACT SECTION */}
                        <div className = {Styling.contactContainer} >
                            <div className= {Styling.dividerVertical} style ={{right:'0', bottom:'0%', }}/>
                            <h3 className={Styling.sectionTitle} >  CONTACT  </h3>
                            { /** PHONE NUMBER */}
                            <div className={Styling.contactElement}>
                                <div className={Styling.imageContainer} >
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z"/></svg>
                                </div>
                                <div style = {{ paddingLeft:'5%'}}>
                                    <p> <a href="tel:0416915923"> + 0416 915 923 </a> </p>
                                </div>
                            </div>

                            { /** EMAIL */}
                            <div className={Styling.contactElement}>

                                <div className={Styling.imageContainer} >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g data-name="18-Mail"><path d="M28 32H4a4 4 0 0 1-4-4V12a4 4 0 0 1 4-4h2a1 1 0 0 1 0 2H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2h-2a1 1 0 0 1 0-2h2a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4z"/><path d="M16 22a1 1 0 0 1-.618-.214l-14-11a1 1 0 1 1 1.236-1.572L16 19.728 29.382 9.214a1 1 0 1 1 1.236 1.572l-14 11A1 1 0 0 1 16 22z"/><path d="M2 31a1 1 0 0 1-.707-1.707l11-11a1 1 0 0 1 1.414 1.414l-11 11A1 1 0 0 1 2 31zM30 31a1 1 0 0 1-.707-.293l-11-11a1 1 0 0 1 1.414-1.414l11 11A1 1 0 0 1 30 31zM26 14a1 1 0 0 1-1-1V2H7v11a1 1 0 0 1-2 0V2a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v11a1 1 0 0 1-1 1z"/><path d="M21 7H11a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2zM21.064 12H11a1 1 0 0 1 0-2h10.064a1 1 0 0 1 0 2z"/></g></svg>

                                </div>
                                <div style = {{ paddingLeft:'5%'}}>
                                    <p> <a href="mailto:gordonlis@outlook.com"> Gordonlis@outlook.com </a> </p>
                                </div>
                            </div>


                            { /** LOCATION */}
                            <div className={Styling.contactElement}>
                                <div className={Styling.imageContainer}>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g data-name="18-Mail"><path d="M28 32H4a4 4 0 0 1-4-4V12a4 4 0 0 1 4-4h2a1 1 0 0 1 0 2H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2h-2a1 1 0 0 1 0-2h2a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4z"/><path d="M16 22a1 1 0 0 1-.618-.214l-14-11a1 1 0 1 1 1.236-1.572L16 19.728 29.382 9.214a1 1 0 1 1 1.236 1.572l-14 11A1 1 0 0 1 16 22z"/><path d="M2 31a1 1 0 0 1-.707-1.707l11-11a1 1 0 0 1 1.414 1.414l-11 11A1 1 0 0 1 2 31zM30 31a1 1 0 0 1-.707-.293l-11-11a1 1 0 0 1 1.414-1.414l11 11A1 1 0 0 1 30 31zM26 14a1 1 0 0 1-1-1V2H7v11a1 1 0 0 1-2 0V2a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v11a1 1 0 0 1-1 1z"/><path d="M21 7H11a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2zM21.064 12H11a1 1 0 0 1 0-2h10.064a1 1 0 0 1 0 2z"/></g></svg> */}
                                <svg xmlns="http://www.w3.org/2000/svg"  fill="#000000" version="1.1" viewBox="0 0 489.536 489.536" enable-background="new 0 0 489.536 489.536">
                                    <g>
                                        <g>
                                        <path d="m488.554,476l-99-280.2c-1-4.2-5.2-7.3-9.4-7.3h-45.6c12.9-31.1 19.6-54.9 19.6-70.8 0-64.6-50-117.7-112.5-117.7-61.5,0-112.5,52.1-112.5,117.7 0,17.6 8.2,43.1 19.9,70.8h-39.7c-4.2,0-8.3,3.1-9.4,7.3l-99,280.2c-3.2,10.3 6.3,13.5 9.4,13.5h468.8c4.2,0.5 12.5-4.2 9.4-13.5zm-246.9-455.3c51,1.06581e-14 91.7,43.7 91.7,96.9 0,56.5-79.2,182.3-91.7,203.1-31.3-53.1-91.7-161.5-91.7-203.1 0-53.1 40.6-96.9 91.7-96.9zm-216.7,448l91.7-259.4h41.7c29.9,64.1 83.3,151 83.3,151s81.4-145.7 83.8-151h47.4l91.7,259.4h-439.6z"/>
                                        <rect width="136.5" x="177.054" y="379.1" height="20.8"/>
                                        <path d="m289.554,108.2c0-26-21.9-47.9-47.9-47.9s-47.9,21.9-47.9,47.9 20.8,47.9 47.9,47.9c27.1,0 47.9-21.8 47.9-47.9zm-75-1c0-14.6 11.5-27.1 27.1-27.1s27.1,12.5 27.1,27.1-11.5,27.1-27.1,27.1c-14.6,2.84217e-14-27.1-12.5-27.1-27.1z"/>
                                        </g>
                                    </g>
                                    </svg>
                                     {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><path d="m14.92 22.84-5.639-8.121L1.16 9.08l21.5-7.741zM5.485 9.648l5.234 3.633 3.633 5.234 4.987-13.854z"/></svg> */}
                                </div>
                                <div style = {{ paddingLeft:'5%'}}>
                                   <p> <a href="https://maps.app.goo.gl/bP5Z5j4RRkBijgQL8" target="_blank"> Sydney, NSW </a> </p>
                                </div>
                            </div>
                        </div>



                        <div className = {Styling.objectiveContainer}>
                            <h3 className={Styling.sectionTitle}> 
                                OBJECTIVES 
                            </h3>
                            <p >
                                Enthusiastic and dedicated individual with a strong work ethic, 
                                experienced in graphics design and web development. <br></br> <br></br>
                                Possesses a solid technical foundation, particularly in the theoretical aspects of computer science. 
                                Pursuing a role where my ambitions, skills, and experiences can contribute to achieving organizational 
                                goals while fostering continuous learning, knowledge growth, and enhancing interpersonal skills.
                            </p>
                        </div>

                    </div>


                    {/** SECTION 2 */}
                    <div className={Styling.section_2} >
                        <div className = {Styling.EducationContainer} >
                            <div className= {Styling.dividerVertical} style ={{right:'0', bottom:'0%', height:"92%"}}/>


                            <div style = {{}}>
                                <h3 className={Styling.sectionTitle} >  EDUCTATION & <br></br>TRAINING </h3>
                                
                                {/** Computer science */}
                                <div className={Styling.educationItem}>
                                    <p className={Styling.educationTitle}> BACHELOR OF COMPUTER SCIENCE</p>
                                    <p className={Styling.educationDescription}> Western Sydney University  </p>
                                    <li className={Styling.educationTime}> 2022 — 2024 </li>
                                </div>
                                {/** IS */}
                                <div className={Styling.educationItem}>
                                    <p className={Styling.educationTitle}> BACHELOR OF INFORMATIONS <br></br> SYSTEM</p>
                                    <p className={Styling.educationDescription}> Western Sydney University  </p>
                                    <li className={Styling.educationTime}> 2017 — 05/2021 </li>
                                </div>
                                 {/** ART */}
                                 <div className={Styling.educationItem}>
                                    <p className={Styling.educationTitle}> DIPLOMA IN DIGITAL DESIGN </p>
                                    <p className={Styling.educationDescription}> AIT - Ultimo, NSW  </p>
                                    <li className={Styling.educationTime}> 10/2015 (INCOMPLETED) </li>
                                </div>
                            </div>
                            <div className={Styling.SkillsContainer} style = {{paddingTop:"3%"}}>
                                <div className= {Styling.dividerHorizontal} style ={{top:'0', left:'0%'}}/>
                                <h3 className={Styling.sectionTitle} >  SKILLS </h3>


                                {/** INTERPERSONAL SKILLS */}
                                <div className={Styling.skillSection}>
                                    <h3> INTERPERSONAL - SKILLS </h3>
                                    <ul>
                                        <li className={Styling.SkillsList}> Effective Verbal & Written English and spoken Chinese (Cantonese) </li>
                                        <li className={Styling.SkillsList}> Effective Collaborator; Ability to work <br></br> synergistically in a team as-well as <br></br> independently. </li>
                                        <li className={Styling.SkillsList}> Creative problem solver with a talent <br></br> for thinking outside the box. </li>
                                        <li className={Styling.SkillsList}> Effective Time Management and <br></br>Punctuality </li>
                                        <li className={Styling.SkillsList}> Capacity to rapidly grasp, learn, and <br></br> adjust to emerging technologies and <br></br> methodologies  </li>
                                        <li className={Styling.SkillsList}> Writing Clean, Efficient, and Reusable Code with Insightful Comments  </li>
                                    </ul>
                                </div>   

                                {/** TECHNICAL SKILLS */}
                                <div className={Styling.skillSection} style = {{marginTop:'9%'}}>
                                    <h3> TECHNICAL - SKILLS </h3>
                                    <ul>
                                        <li className={Styling.SkillsList}>  
                                                <span style = {{fontWeight:'700', textDecoration:'underline'}}> Understandings : </span> 
                                                <br></br>
                                                <p>
                                                    DSA & Algorithms, OOP,
                                                    Computer Architecture, Finite State Model Checking, 
                                                    Networks & IOT, Security, AI, <br></br>Distribured System Architecture, Web technologies, RestAPI
                                                </p>
                                        </li>
                                        <li className={Styling.SkillsList}>  
                                                <span style = {{fontWeight:'700', textDecoration:'underline'}}>Programming Languages : </span> 
                                                <br></br>
                                                <p>
                                                    Java, Java-Script (TypeScript), C/C++
                                                </p>
                                        </li>

                                        <li className={Styling.SkillsList}>  
                                                <span style = {{fontWeight:'700', textDecoration:'underline'}}>Web Technologies  : </span> 
                                                <br></br>
                                                <p>
                                                    HTML & CSS, React Framework, <br></br> Spring-Boot
                                                </p>
                                        </li>


                                        <li className={Styling.SkillsList}>  
                                                <span style = {{fontWeight:'700', textDecoration:'underline'}}>Tools  : </span> 
                                                <br></br>
                                                <p>
                                                    VSC, Eclipse, Git, Developer Tool, <br></br> Adobe Suit (PS, AI, AE)
                                                </p>
                                        </li>


                                        <li className={Styling.SkillsList}>  
                                                <span style = {{fontWeight:'700', textDecoration:'underline'}}>OS  : </span> 
                                                <br></br>
                                                <p> Microsoft-Windows (10,8,7,XP), <br></br> macOS, Unix, Android, iOS </p>
                                        </li>

                                    </ul>
                                    
                                </div>   
                            </div>



                            
                        </div>


                        <div className = {Styling.objectiveContainer}>
                            <h3 className={Styling.sectionTitle}>  EMPLOYMENT HISTORY  <br></br>...</h3>
                            {/** PCMARKET JOB */}
                            <div className={Styling.jobContainer}>
                                <div className={Styling.circle}>
                                    <svg fill="#ececec" viewBox="0 0 32 32" stroke="#000000" xmlns="http://www.w3.org/2000/svg"><g ></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="16" cy="16" r="16"></circle> </g></svg>
                                </div>
                                {/* <h3>PCMarket</h3> */}
                                <h3>Graphic Designer / Web Developer</h3>
                                
                                <h2 style = {{marginBottom:'0.5%'}}>  
                                    Wu Jonathan |
                                    <a href="tel:0404088927"> 0404 088 927 </a> 

                                </h2>
                                <p className={Styling.educationDescription} style = {{marginBottom:'0.5%'}}>Sydney, Australia, NSW   </p>
                                <p className={Styling.educationDescription}>11/2021 — {formattedDate}  (Current) </p>
                                <ul>
                                    <li className={Styling.SkillsList}> Create captivating visual assets for print and digital media, spanning brochures, flyers, newsletters, advertisements, web-pages, and social media content.</li>
                                    <li className={Styling.SkillsList}> 
                                        Develop functional and responsive web pages that align with specified objectives, 
                                        utilizing foundational technologies such as vanilla HTML, CSS, and JavaScript, as well as incorporating React.js frameworks in recent projects.
                                    </li>
                                    <li className={Styling.SkillsList}> Deploy solutions across diverse hosting environments, encompassing the WordPress content management system, Neto, and other hosting platforms.</li>
                                    <li className={Styling.SkillsList}> keeping up-to-date with emerging trends, technologies, and best practices, while rapidly acquiring new skills on the job through self-directed learning and research.</li>
                                </ul>
                            </div>


                            <div className={Styling.jobContainer}>
                                <div  className={Styling.circle}>
                                    <svg fill="#ececec" viewBox="0 0 32 32" stroke="#000000" xmlns="http://www.w3.org/2000/svg"><g ></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="16" cy="16" r="16"></circle> </g></svg>
                                </div>
                                {/* <h3>PCMarket</h3> */}
                                <h3>Computer Store Assistant</h3>
                            
                                <p className={Styling.educationDescription}>Sydney, Australia, NSW  [JW COMPUTERS]  </p>
                                <p className={Styling.educationDescription}> 01/2018 — 05/2019</p>
                                <ul>
                                    <li className={Styling.SkillsList}> Ascertaining customer needs; Ensuring high level of customer satisfaction </li>
                                    <li className={Styling.SkillsList}> Assist in computer building process</li>
                                    <li className={Styling.SkillsList}>  Maintaining outstanding store condition & visual merchandising standards </li>
                                    <li className={Styling.SkillsList}> Accurately recommend, match and describe products features & benefits to customers (Computers, Monitors, hardwares)  </li>
                                    <li className={Styling.SkillsList}> Keep up-to-date on new product information</li>
                                    <li className={Styling.SkillsList}> Actively involve in the receiving of new shipments</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div onClick={() => { myBrowser.DirHandling.download("./G_Resume.pdf", "G_Resume.pdf" )}}  className={Styling.downloadContainer}> 
                    <div className= {Styling.dividerHorizontal} style ={{width:"95%", top:"0"}}/>
                    <p> DOWNLOAD </p>
                    {/* <button onClick={() => { myBrowser.DirHandling.download("./G_Resume.pdf", "G_Resume.pdf" )}}> Download File </button> */}
                </div>    
            </div>
        </>
    )
}


export default RenderPage;
import TitleWrapper from "../../../TitleWrapper/TitleWrapper";
import "../../../../index.css";
import Styling from "./Styling.module.css";





let RenderPage : React.FC<{}> = () => { 

    return(
        <>
            <div className={Styling.ContentWrapper} style = {{height:"100%"}}>
                <div className={Styling.ContentContainer} style = {{ height:"100%" }}> 
                    <div className={Styling.CategoryContainer} style = {{height:'100%' , display:'flex', flexDirection:'column'}}>
                        <div className={Styling.itemContainer} style = {{borderBottom:"none" ,  flex:"1" }}>
                            <div className={Styling.line} />
                            <div className={Styling.mainTitle}>
                                <div className={Styling.dateContainer}> 
                                    <h2>2021 - Current</h2>
                                </div>
                            </div>
                            <div className={Styling.DescriptionContainer}>
                                <h1> Graphic Designer / Developer  </h1>
                                <h3> PC Market </h3>
                                {/* <p>
                                    Designer/Developer Role within a start-up environment, encompassing the creation of captivating visual assets for 
                                    both print and digital media, alongside architecting and developing functional and responsive web pages utilizing vanilla HTML, CSS, JavaScript, 
                                    and React.js frameworks across various projects. 
                                </p>
                                <p>
                                    Deployed & Managed solutions across diverse hosting environments like WordPress, Neto, AWS and GIT.
                                    Stayed updated with emerging trends & technologies through self-directed learning and 
                                    acquiring new skills to deliver solutions that meet business objectives (i.e., Android development).
                                </p> */}

                                <ul style = {{listStyleType:'disc'}}>
                                    <li>  Designed captivating visual assets for print and digital media in a start-up environment </li>
                                    <li>  Developed functional, responsive web pages using HTML, CSS, JavaScript, and React.js </li>
                                    <li>  Deployed and managed existing solutions across diverse hosting environments: WordPress, Neto, AWS, Git</li>
                                    <li>  Acquired new skills, including Android development, to deliver solutions meeting business objectives </li>
                                    <li>  <span style = {{textDecoration:"underline"}}>(Key Project) Vend management system </span>   : Developed a comprehensive vending machine management system with a web-based platform for ordering and an Android application for vending machine control and operations.</li>
                                </ul>
                                {/* <p style = {{textDecoration:"underline", fontWeight:"900"}}> Key Project </p> */}
                                <ul style = {{listStyleType:'disc', marginTop:'25px'}}>
                                </ul>
                            </div>
                        </div>
                        {/* <div className={Styling.itemContainer} style = {{borderBottom:"none" ,  flex:"1" }}>
                            <div className={Styling.line} />
                            <div className={Styling.mainTitle}>
                                <div className={Styling.dateContainer}> 
                                    <h2>2010</h2>
                                </div>
                                <h3> HelloWorld! </h3>
                            </div>
                            <div className={Styling.DescriptionContainer}>
                                <h1> UI Design  </h1>
                                <p>
                                    ui design descriptionui design descriptionui design descriptionui design descriptionui design descriptionui design description
                                </p>
                            </div>
                        </div> */}
                    </div>
                    
                    <div className={Styling.CategoryContainer}>
                     
                    </div>
                </div>
            </div>
        </>
    )


}


export default RenderPage;

import React, { CSSProperties, useLayoutEffect, useRef, useState } from 'react';
import myBrowser from '../../../Handlers/myBrowser';
import { ObjectRef } from '../../../Interfaces/RefObjectInterface';
import DynamicHeading from '../../layouts/Others/dynamicText'
import Styling from "./Styling.module.css"


interface ColorProperty {
    baseColor : CSSProperties,
    overlayColor : CSSProperties
}


let Color_Themes : {blue : ColorProperty, red : ColorProperty, yellow : ColorProperty, purple : ColorProperty} = {
    blue :  {baseColor : {backgroundColor :'#e9ecef'}, overlayColor : { backgroundColor : '#ccf1f6'}}, 
    yellow :  {baseColor : {backgroundColor :'#e9ecef'}, overlayColor : { backgroundColor : '#fde7b9'}}, 
    red :  {baseColor : {backgroundColor :'#e9ecef'}, overlayColor : { backgroundColor : '#FFCCCB'}}, 
    purple : {baseColor : {backgroundColor :'#e9ecef'}, overlayColor : { backgroundColor : '#d0b8ea'}}, 
}

let STARTPERCENTAGE = 5;
let CENTERPERCENTAGE = 50;
let ENDINGPERCENTAGE = 95;

/**
 * Render selection component, this is the animation bar with its associated percentage values. We let set_percentage_FontSize be a reference
 * that will be attached to the text (percentage number) returned by 'DynamicHeading' component.
 * @param sectionPercentage : 
 * @param upperText 
 * @param percentageFont_Ref : ObjectRef object that attaches to the percentage number by DynamicHeading component .
 * @returns 
 */
let RenderSection = ( sectionPercentage : number, upperText : String, onChangeAction : () => void, percentageSize : number, percentageFont_Ref? :ObjectRef<HTMLParagraphElement> ) : JSX.Element =>
{
    let Line : JSX.Element = <div style = {{ justifySelf :'center', backgroundColor : '#7f8893',  height :'100%', width : '1px', zIndex :'1' } } /> ;
    let sectionWidth = 7; // in percentage

    return (
        <>
            <div style = {{ position:'absolute', left : `${sectionPercentage - (sectionWidth/2)}%`, height :'100%', width :`${sectionWidth}%`, display : 'grid', gridTemplateRows : '25% 50% 25%'}}>
                <div style = { { height : '100%', width :'100%', overflow :'hidden'}}> 
                    {/* <DynamicHeading Text = {upperText} Styling = {{textAlign:'center',color:'#7f8893'}} /> */}
                </div>
                {Line}
                <div style = { { height : '100%', width :'100%', overflow :'hidden', textAlign :'center',color:'#7f8893', position:'relative',  fontSize:`${percentageSize}px`}}> 
                    {
                        (sectionPercentage == STARTPERCENTAGE)  &&
                            <div style = { { opacity:'0', height : '100%', width :'100%', position:'absolute'}}>
                               <DynamicHeading Text = { `${100}%`} textRef = {percentageFont_Ref} isSimpleContainer = {true} onChangeAction={onChangeAction}/>
                            </div>
                    }
                    {sectionPercentage}%
                </div>
            </div>
        </>
    );
}



let RenderElipseContainer : React.FC <{percentage:number, color_Theme? : String, customColor ?: React.CSSProperties}> = (props) => 
{
    let Line : JSX.Element = <div style = {{justifySelf :'center', backgroundColor : 'black',  height :'100%', width : '1px' } } /> ;
    let animationBar = React.useRef<HTMLDivElement>(null);
    let Current_Theme :ColorProperty = Color_Themes.red; 
    let [percentage_FontSize, set_percentage_FontSize] = useState<number>(0); 
    let percentFont_Ref : ObjectRef<HTMLParagraphElement> = {
        setFunc : ( ref: ObjectRef<HTMLParagraphElement>, toRef: React.RefObject<HTMLParagraphElement>  ) => {
            ref.ref = toRef;
        },
        ref : undefined
    }

    let fontchange_Handler = () => {
        if (percentFont_Ref.ref != undefined){
            set_percentage_FontSize( myBrowser.RefHandling.getRefFontSize(percentFont_Ref.ref));
        }
    }   


    
    
    useLayoutEffect(
        () =>{
            if (animationBar.current != null)
            {
                animationBar.current.style.width = `${props.percentage}%`;
            }
        },
    );

    // useLayoutEffect(
    //     () => {
    //         if (percentFont_Ref.ref != undefined)
    //         {
    //             let globalFontSize = myBrowser.RefHandling.getRefFontSize(percentFont_Ref.ref);

    //             if (globalFontSize != percentage_FontSize){
    //                 set_percentage_FontSize(globalFontSize);
    //                 console.log("Global font size set to :" + globalFontSize);
    //             }
    //         }
    //     }
    // )
    if (props.customColor === undefined){
        switch (props.color_Theme)
        {
            case "red" :Current_Theme = Color_Themes.red; 
            break;
            case "blue" :Current_Theme = Color_Themes.blue; 
            break;
            case "yellow" :Current_Theme = Color_Themes.yellow; 
            break;
            case "purple" :Current_Theme = Color_Themes.purple; 
            break;
            default : return (<>  <div style = {{display : 'grid', alignItems :'center' ,position:'relative', textAlign :'center', width :'100%', color :'red', height :'100%' }}> Invalid Color_Theme, only : blue,yellow,red,purple supported. </div> </>);
        };

    }else{ 
        Current_Theme = {baseColor : {backgroundColor :'#e9ecef'}, overlayColor : props.customColor};
    }
    
    return (
        <>
            <div style = {{display : 'grid', alignItems :'center' ,position:'relative', width :'100%', height :'100%'}}>
                <div style = { Object.assign( {overflow :'hidden', position:'relative', width :'100%', borderRadius : '2em', border :'0.1px solid grey', height :'30%',zIndex:'5'}, Current_Theme.baseColor)}>  
                    <div ref = {animationBar} style = { Object.assign({ position:'relative', width :'0%', backgroundColor :'black', height :'100%', transition : 'width 2s ease-in-out'}, Current_Theme.overlayColor)}/>  
                </div>
                {RenderSection(STARTPERCENTAGE, "1 year",fontchange_Handler, percentage_FontSize, percentFont_Ref)}
                {RenderSection(CENTERPERCENTAGE,"2 year",fontchange_Handler, percentage_FontSize)}
                {RenderSection(ENDINGPERCENTAGE, "3 year", fontchange_Handler, percentage_FontSize )}
            </div>
        </>
    )
    
}

export default RenderElipseContainer;
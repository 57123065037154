import { useLayoutEffect, useRef, useState } from "react";
import '../index.css';
import myBrowser from "../BaseProject/Handlers/myBrowser";
import { off } from "process";
import Introduction from "./ResumeComponents/Introduction";
import MyDescription from "../BaseProject/MyResume/MyDescription/ MyDescrption";
import MyProjects from "../BaseProject/MyResume/MyProjects/Projects"
import ScrollBarMenu, {ScrollBarElement} from "../BaseProject/Components/layouts/scrollBarMenu/ScrollBarMenu"
import Resume from "../BaseProject/MyResume/Resume/Resume";
import ContentOverlay from "../BaseProject/Components/ContentOverlay/ContentOverlay";
import AnimationElipseContainer from "../BaseProject/Components/animations/AnimationPageScrollContainer/AnimationContainer"
import ReverseElipseContainer  from "../BaseProject/Components/animations/AnimationPageScrollContainer/Template/ReverseEclipseContainer"
import FrameLayout from "../BaseProject/Components/layouts/FrameLayout/FrameLayout"


function RenderApp() {


    // useLayoutEffect(
    //     () => { 


    //         let scrollHandler = (event : WheelEvent) => { 

    //             console.log(event.deltaY);
    //             let offset = topOffset.current +  (0.3 * event.deltaY);
    //             topOffset.current = offset > 0 ? 0 : offset;
    //             // console.log("OFFSET = " + topOffset.current);
    //             // console.log("WT = " + myBrowser.getWindowTop());

    //             // if (container_Ref.current != null){
    //             //     container_Ref.current.style.top = `${topOffset.current}px`;
    //             //     // container_Ref.current.style.transform = `translate(0, ${topOffset.current}px)`
    //             // }
    //         }

    //         window.addEventListener("wheel", scrollHandler);
            
    //         return () => {
    //             window.removeEventListener("wheel", scrollHandler);
    //           };
    //     },[]
    // )

    let container_Ref = useRef<HTMLDivElement>(null);
    let topOffset = useRef<number>(0);

    /** Main menu scrollBar Elements */
    const scrollbar_Elements : ScrollBarElement[]=
    [
        {
            reference : useRef<HTMLDivElement>(null),
            name : "Intro"
        },
        {
            reference : useRef<HTMLDivElement>(null),
            name : "MyProfile"
        },
        {
            reference : useRef<HTMLDivElement>(null),
            name : "MyProjects"
        },
        {
            reference : useRef<HTMLDivElement>(null),
            name : "Resume"
        }, 
    ];
    const scrollbarElementsRef = useRef<ScrollBarElement[]>(scrollbar_Elements);
    return(
        <>
            <ScrollBarMenu hasOffset={false} ScrolBarElements={scrollbarElementsRef} mobileWidth={700} starting_properties={{opacity:'0'}} non_Starting_properties={{opacity:'1'}} />
            {/* <ContentOverlay name = {"Full Resume"} isPrompt={true} activationRef={scrollbar_Elements[2].reference}>
                <Resume/>
            </ContentOverlay> */}
            <div ref = {container_Ref} style = {{width:'100vw', position:'absolute', transition:'all 1s', background:'white'}}>
                <div ref = {scrollbar_Elements[0].reference} style = {{width:'100%'}}> 
                    <Introduction rootContainer = {container_Ref}/>
                </div>

                <div style = {{marginTop:"3%"}}/>
                <div ref = {scrollbar_Elements[1].reference}>
                    <MyDescription/>
                </div>

                {/* <div ref = {scrollbar_Elements[2].reference} style = {{width:"100vw", display:'flex', justifyContent:'center'}}>
                    <div style = {{background:'#222', width:"90%", borderRadius:'1rem'}}>
                            <MyProjects/>
                    </div>                
                </div> */}

                    {/* <div style = {{marginTop:"10%"}}/> */}
                    {/* <ImageGallery/> */}
{/* 
                        <div style = {{width:"100%", height :"540px", marginTop:"250px", textAlign:'center' }}>
                        <h1>
                        CONTACT ME
                        </h1>
                    </div> */}

                {/* <div ref = {scrollbar_Elements[3].reference} style = {{marginTop:"10%"}}/>  */}
                    {/* <ContentOverlay name = {"Full Resume"} activationRef={scrollbar_Elements[3].reference} isPrompt = {true}>
                            <Resume/>
                    </ContentOverlay> */}
            </div>
        </>
    );
}


export default RenderApp;


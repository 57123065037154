import React, {useRef, useLayoutEffect} from 'react';
import { ObjectRef } from '../../Interfaces/RefObjectInterface';

/* Render a transition frame taking the size of the parent container. */
let RenderTransitionFrame: React.FC <{text:String, customStying ?: React.CSSProperties, textRef : ObjectRef<HTMLParagraphElement>,}> = (props) =>
{

    let textRef = useRef<HTMLParagraphElement>(null);

    useLayoutEffect( 
        () => {
             props.textRef.setFunc(props.textRef, textRef);
        },
     )

    return (
        <p ref = {textRef} style={Object.assign({}, props.customStying)}>{props.text}</p>
        
    );
};

export default RenderTransitionFrame;

import  {items} from '../../../../contentCarousel/contentCarosel';
import DemoWrapper from "../../../../DemoWrapper/DemoWrapper";
import React, { MouseEvent, useRef } from 'react';
import IframeWrapper from "../../../../IFrame_Container/IFrameContainer";


let CaroselItem : items =    {
    key : 1,
    navigationUrl : "http://example1.igli.site/",
    Subject : "Website Demo 2",
    Description : 
        <>
            <p style = {{marginBottom:'50px'}}>
                A demonstration of my first web project, utilising vinalla javascript, html and css to build a sleek and
                functional web application functional web application with animations.
            </p>
            {/* <DemoWrapper Title={"Demo Project"}>
                <div style = {{width:'100%', height :'550px', overflow:"hidden"}}>
                    <IframeWrapper PageURL={"http://example1.igli.site.s3-website-us-east-1.amazonaws.com/"}/>
                </div>
            </DemoWrapper>  */}
        </>,
    img_Url:"./images/Project_Images/webDemo_1.jpg", 
    show_Image: false,           
    file_List : [
        {
            key : 1,
            FileTitle : "",
            content : ""
        }
    ]
}

export default CaroselItem;
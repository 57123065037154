import  {items} from '../../../../contentCarousel/contentCarosel';
import ComponentDemo from "../../../../DemoWrapper/DemoWrapper"; 


let CaroselItem : items =    {
    key : 1,
    Subject : "File Processing System",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}
            <p>
                This multifunctional file processing system in C simulates Unix system multitasking by utilizing Unix system calls, file operations, process management (including fork(), execvp(), and wait()), dynamic memory allocation, and efficient algorithm design. It creates three child processes to concurrently perform tasks such as obtaining age statistics, sorting file contents, and modifying files, enhancing understanding of process management and concurrent execution in Unix/Linux environments.






            </p>

            <p>  

                This program serves as a multifunctional file processing system in C, it simulates the unix system, incorporating Unix system calls, file operations / descriptors (i.e. INODE), 
                process management (including fork(), execvp(), and wait()), dynamic memory allocation, and efficient algorithm design. 
                
                

            </p>

            <p>

                The program consisting of three distinct functionalities,  such as obtaining statistics about individuals' ages, 
                sorting file contents, and modifying file contents. 
                Each functionality is implemented as a separate child process, ensuring parallel execution and efficient resource utilization.
            </p>


            <p style = {{fontWeight:"800", textDecoration:"underline"}}> Process Description : </p>

            <ol>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  P1 - Age Processing : </span>
                        Implemented as Child Process 1, it utilizes fork() to create a child process, 
                        which prompts the user for age input using standard input/output. T
                        he process calculates the average age and identifies the oldest age, displaying the results before exiting.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> P2 - File Sorting : </span>
                        Implemented as Child Process 2, it prompts the user for the file name and executes the "./sort" program with 
                        the specified file as input. The sorting program organizes the file contents alphabetically based on the first Character 
                        of each line.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> P3 - File Modification : </span>
                        Implemented as Child Process 3, it prompts the user for the file name and executes the "./modifyFile" 
                        program with the specified file as input. 
                        The modification program inserts the designated string at the beginning of the file and replaces occurrences of
                        a specified string.
                    </li>
            </ol>

        </>,
    img_Url:"./images/Project_Images/filesystem.jpg",
    file_List : [
        {
            key : 1,
            FileTitle : "main.c",
            content :  
            
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <stdio.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <stdlib.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <unistd.h>"+
            "<<<sp_0, br/><<orange/>int<<white/> createProcess();"+
            "<<<sp_0, br/><<orange/>void<<white/> child_Process_1();"+
            "<<<sp_0, br/><<orange/>void<<white/> child_Process_2();"+
            "<<<sp_0, br/><<orange/>void<<white/> child_Process_3();"+
            "<<<sp_0, br/><<orange/>void<<white/> parent_code();"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**  Obtaining user input for the ages of 6 person. We sum the input values, and store the greatest input utilised "+
            "*   in obtaining the average and oldest age, this is displayed via standardoutput to console."+
            "*  "+
            "*   The exit status code of 17 is given by the child, marking the successful execution of this process"+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>void<<white/> child_Process_1() { "+
            "<<<sp_3, br/><<orange/>printf<<white/>('Child [%d] :  Starting.\n', getpid());"+
            "<<<sp_3, br/><<orange/>int<<white/> person_Amount = 6;              <<grey/>/** integer of person amount */<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> person_age[person_Amount];      <<grey/>/** integer array of all 6 person */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>int<<white/> oldest_Age = 0;                 <<grey/>/** oldest age holder*/<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> average_Age = 0;                <<grey/>/** average or total age holder */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>printf<<white/>('Child [%d] : Enter the ages for %d people:\n', getpid(), person_Amount);"+
            "<<<sp_3, br/><<grey/>/** Iterate user input for 6 person */<<white/>"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = 0; i < person_Amount; i++) {"+
            "<<<sp_7, br/><<orange/>printf<<white/>('Child [%d] : Enter %d person's age \n',getpid(), i+1);"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<grey/>/** Check for valid input, that is, userInput is integer */<<white/>"+
            "<<<sp_7, br/><<lightblue/>while<<white/> (scanf('%d', &person_age[i]) != 1)"+
            "<<<sp_7, br/>{"+
            "<<<sp_13, br/><<orange/>printf<<white/>('Child [%d] : Invalid input, Enter %d person's age \n',getpid(), i+1);   "+
            "<<<sp_13, br/><<lightblue/>while<<white/> (get<<orange/>char<<white/>() != '\n');"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/>average_Age += person_age[i];                                       <<grey/>/** sum of input ags */<<white/>"+
            "<<<sp_7, br/><<lightblue/>if<<white/> ( oldest_Age < person_age[i] ) oldest_Age = person_age[i];       <<grey/>/** update oldest age */<<white/>"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** print result, that is : 1) average age, 2) oldest age */<<white/>"+
            "<<<sp_3, br/><<orange/>printf<<white/>('Child [%d] : Average age: %d\n', getpid(), (<<orange/>int<<white/>) (average_Age / person_Amount));"+
            "<<<sp_3, br/><<orange/>printf<<white/>('Child [%d] : Oldest age: %d\n', getpid(), oldest_Age);"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>exit(17); "+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**  Obtaining user input for the name of a file to be sorted. If fileName is valid the './sort' program is executed with the specified arguments, "+
            "*   this includes the input file name."+
            "* "+
            "*   The exit status code of 17 is given by the child, marking the successful execution of this process"+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>void<<white/> child_Process_2() { "+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>char<<white/> *input = <<orange/>NULL<<white/>;                                  <<grey/>/** file input string (char array) */<<white/>"+
            "<<<sp_3, br/>size_t size = 0;                                     <<grey/>/** input size */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>printf<<white/>('Child [%d] : Enter name of file to be sorted (i.e. sort.txt):\n', getpid());"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>// while input fileName does not exist"+
            "<<white/><<<sp_3, br/><<lightblue/>while<<white/>(1) {"+
            "<<<sp_7, br/><<orange/>int<<white/> bytesRead = getline(&input, &size, stdin);  <<grey/>/* get user input */<<white/>"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (bytesRead == -1) {                          <<grey/>/* handle read err */<<white/>"+
            "<<<sp_11, br/><<orange/>printf<<white/>('Input read failed!\n');"+
            "<<<sp_11, br/>continue;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>input[bytesRead - 1] = '\0';                    <<grey/>/* Remove newline char for next input */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (access(input, 0) != -1) {                   <<grey/>/* if input fileName exist, we break; */<<white/>"+
            "<<<sp_11, br/><<orange/>printf<<white/>('Child [%d] : Executing ./sort on %s\n', getpid(), input);"+
            "<<<sp_11, br/><<orange/>break<<white/>;"+
            "<<<sp_7, br/>} <<lightblue/>else<<white/> {"+
            "<<<sp_11, br/><<orange/>printf<<white/>('Child [%d] :Filedoes not exist, Enter valid name of file to be sorted (i.e. sort.txt):\n', getpid());"+
            "<<<sp_7, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>char<<white/>* sortArgs[] = {'./sort', input, <<orange/>NULL<<white/>};         <<grey/>// initialise input argument "+
            "<<white/><<<sp_3, br/>execvp('./sort', sortArgs);                         <<grey/>// execute program"+
            "<<white/><<<sp_3, br/>free(input);                                        <<grey/>// deallocaite memory utilised for userInput"+
            "<<white/><<<sp_3, br/>exit(17);                                           <<grey/>// exit, with code 17"+
            "<<white/><<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**  Obtaining user input for the name of a file to be modified. If fileName is valid the './modifyFile' program is executed with the specified arguments, "+
            "*   this includes the input file name."+
            "* "+
            "*   The exit status code of 17 is given by the child, marking the successful execution of this process"+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>void<<white/> child_Process_3() { "+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>char<<white/> *input = <<orange/>NULL<<white/>;                                         <<grey/>/** file input string (char array) */<<white/>"+
            "<<<sp_3, br/>size_t size = 0;                                            <<grey/>/** input size */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>printf<<white/>('Child [%d] : Enter name of file to be modified (i.e. modified.txt):\n', getpid());"+
            "<<<sp_3, br/><<grey/>// while input fileName does not exist"+
            "<<white/><<<sp_3, br/><<lightblue/>while<<white/>(1) {"+
            "<<<sp_7, br/><<orange/>int<<white/> bytesRead = getline(&input, &size, stdin);          <<grey/>// get user input"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> (bytesRead == -1) {                                  <<grey/>// handle read err"+
            "<<white/><<<sp_11, br/><<orange/>printf<<white/>('Input read failed!\n');"+
            "<<<sp_11, br/>continue;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>input[bytesRead - 1] = '\0';                            <<grey/>// Remove newline char for next input"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (access(input, 0) != -1) {                           <<grey/>// if input fileName exist, we break;"+
            "<<white/><<<sp_11, br/><<orange/>printf<<white/>('Child [%d] : Executing ./modifyFile on %s\n', getpid(), input); "+
            "<<<sp_11, br/><<orange/>break<<white/>;"+
            "<<<sp_7, br/>} <<lightblue/>else<<white/> {"+
            "<<<sp_11, br/><<orange/>printf<<white/>('Child [%d] :File does not exist, Enter valid name of file to be modified (i.e. modified.txt):\n', getpid());"+
            "<<<sp_7, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>char<<white/>* sortArgs[] = {'./modifyFile', '-1', input, <<orange/>NULL<<white/>};     <<grey/>// initialise input argument "+
            "<<white/><<<sp_3, br/>execvp('./modifyFile', sortArgs);                           <<grey/>// execute program"+
            "<<white/><<<sp_3, br/>free(input);                                                <<grey/>// deallocaite memory utilised for userInput"+
            "<<white/><<<sp_3, br/>exit(17);                                                   <<grey/>// exit, with code 17"+
            "<<white/><<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Main function, we generate 3 child process, execute their functionality (in parallel), and process their completition via function parent_Code() */<<white/>"+
            "<<<sp_0, br/><<orange/>int<<white/> main (<<orange/>int<<white/> argc, <<orange/>char<<white/> * agrv[])"+
            "<<<sp_0, br/>{"+
            "<<<sp_4, br/><<orange/>int<<white/> childProcess_1,childProcess_2, childProcess_3;    <<grey/>/** Child PID*/<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>// Creating child process one"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> ((childProcess_1 = createProcess()) == 0) child_Process_1();     <<grey/>// if child process (1), we execute child process function"+
            "<<white/><<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>// Creating child process two"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> ((childProcess_2 = createProcess()) == 0) child_Process_2();     <<grey/>// if child process (2), we execute child process function"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>// Creating child three"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> ((childProcess_3 = createProcess()) == 0) child_Process_3();     <<grey/>// if child process (3), we execute child process function"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** parent handler function */<<white/>"+
            "<<<sp_3, br/>parent_code(); "+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>return<<white/> 0;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Parent code. The Parent process waits for the completition of (3) child processes and handles their completition appropriately */<<white/>"+
            "<<<sp_0, br/><<orange/>void<<white/> parent_code()"+
            "<<<sp_0, br/>{"+
            "<<<sp_3, br/><<orange/>printf<<white/>('Parent [%d] : waiting for child processes to finish.\n', getpid());"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Waits for all 3 child process to complete */<<white/>"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = 3; i > 0;) {"+
            "<<<sp_5, br/><<orange/>int<<white/> child_status;                   <<grey/>/** represent the child status*/<<white/>"+
            "<<<sp_5, br/><<orange/>int<<white/> high_8, low_7, bit_7;           <<grey/>/** Used to extract the child status */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<orange/>int<<white/> proc_ID = wait(&child_status);  <<grey/>/** Obtain process ID*/<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<grey/>/** Handle Error */<<white/>"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (proc_ID == -1) {"+
            "<<<sp_11, br/>perror('waitpid');"+
            "<<<sp_11, br/>continue;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<grey/>/** Display Dialog (i.e. status of exiting child process)*/<<white/>"+
            "<<<sp_5, br/><<orange/>printf<<white/>('parent [%d] :  child [%d] exited - ', getpid(), proc_ID);"+
            "<<<sp_7, br/>high_8 = child_status >> 8;     <<grey/>/* 1111 1111 0000 0000 */<<white/>"+
            "<<<sp_7, br/>low_7  = child_status & 0x7F;   <<grey/>/* 0000 0000 0111 1111 */<<white/>"+
            "<<<sp_7, br/>bit_7  = child_status & 0x80;   <<grey/>/* 0000 0000 1000 0000 */<<white/>"+
            "<<<sp_7, br/><<orange/>printf<<white/>('status [exit=%d, sig=%d, core=%d]\n', high_8, low_7, bit_7);"+
            "<<<sp_7, br/>i--;    <<grey/>/** Decrement process (child) */<<white/>"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>printf<<white/>('Parent [%d] : We Exit!\n', getpid());       <<grey/>/** Concluding dialog */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Create child proccess and return the state value given by fork(). This function is utilised for dialog purposes. */<<white/>"+
            "<<<sp_0, br/><<orange/>int<<white/> createProcess(){"+
            "<<<sp_3, br/><<orange/>int<<white/>forkStatus = fork();            <<grey/>/** Create child process */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Generate dialog / handle fork state */<<white/>"+
            "<<<sp_3, br/><<orange/>switch<<white/> (forkStatus) "+
            "<<<sp_3, br/>{"+
            "<<<sp_7, br/><<lightblue/>case<<white/> -1 : f<<orange/>printf<<white/>(stderr, 'Error occured creating child\n');"+
            "<<<sp_7, br/><<orange/>break<<white/>;"+
            "<<<sp_7, br/><<lightblue/>case<<white/> 0 : <<orange/>break<<white/>;"+
            "<<<sp_7, br/>default : <<orange/>printf<<white/>('Parent [%d]: Child created with processID = %d\n', getpid(),forkStatus); "+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/>forkStatus;"+
            ""



        },
        {
            key : 2,
            FileTitle : "modifyFile.c",
            content :  
            
            "<<white/><<<sp_0, br/><<lightblue/>#include<<white/> <fcntl.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <stdio.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <stdlib.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <string.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <unistd.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <sys/stat.h>"+
            "<<<sp_0, br/><<orange/>void<<white/> writeChar(<<orange/>int<<white/> index, <<orange/>char<<white/> c, <<orange/>int<<white/> file_fd);"+
            "<<<sp_0, br/><<orange/>char<<white/> getFileChar(<<orange/>int<<white/> index, <<orange/>int<<white/> file_fd);"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**  Return the character at the given index in the file. This function takes the index and file descriptor. "+
            "*  The lseek position prior to call is retained upon return"+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>char<<white/> getFileChar(<<orange/>int<<white/> index, <<orange/>int<<white/> file_fd) "+
            "<<<sp_0, br/>{"+
            "<<<sp_3, br/><<orange/>char<<white/> toRet; "+
            "<<<sp_3, br/><<orange/>int<<white/> currentPosition =  lseek(file_fd, 0, SEEK_CUR); <<grey/>// optain current location"+
            "<<white/><<<sp_3, br/>lseek(file_fd, index, SEEK_SET);                    <<grey/>// navigate to given position in file"+
            "<<white/><<<sp_3, br/>read(file_fd, &toRet, 1);                           <<grey/>// read character at given location in file"+
            "<<white/><<<sp_3, br/>lseek (file_fd, currentPosition, SEEK_SET);         <<grey/>// re-instate previous lseek position"+
            "<<white/><<<sp_3, br/><<orange/>return<<white/> toRet;"+
            "<<<sp_0, br/>} "+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Insert the character 'c' into the fild given by the file_descriptor at the specified index. "+
            "*  The lseek position prior to call is retained upon return"+
            "*/<<white/>"+
            "<<<sp_0, br/><<orange/>void<<white/> writeChar(<<orange/>int<<white/> index, <<orange/>char<<white/> c, <<orange/>int<<white/> file_fd)"+
            "<<<sp_0, br/>{"+
            "<<<sp_3, br/><<orange/>int<<white/> currentPosition =  lseek(file_fd, 0, SEEK_CUR); <<grey/>// optain current location"+
            "<<white/><<<sp_3, br/>lseek(file_fd, index, SEEK_SET);                    <<grey/>// navigate to given position in file"+
            "<<white/><<<sp_3, br/>write(file_fd, &c, sizeof(<<orange/>char<<white/>));                   <<grey/>// write character at given location in file"+
            "<<white/><<<sp_3, br/>lseek (file_fd, currentPosition, SEEK_SET);         <<grey/>// re-instate previous lseek position"+
            "<<white/><<<sp_0, br/>}"+
            "<<grey/><<<sp_0, br/>/**"+
            "*  Main function Descritpion :"+
            "*      modify a file by first inserting ￢ﾀﾜThis is the updated version.￢ﾀﾝ at the beginning of this file and then replacing all "+
            "*      the occurrences of the string ￢ﾀﾜtable￢ﾀﾝ with ￢ﾀﾜdesk￢ﾀﾝ.  The file to be modified is taken from the second command line argument(i.e. argv[2]). "+
            "* "+
            "*  Algorithm Description :"+
            "*      1. starts by obtaining the size of the file and opening it for both reading and writing. "+
            "*      2. We define the string that needs to be replaced and specifies its replacement. "+
            "*      3. A buffer is created and initialized with an initial insertion string, and index variables are set up to keep track of the buffer and file positions. "+
            "*      4. A offset is initialized to track the replacements made. "+
            "*      5. We then loop until all characters are replaced and inserted into the file. "+
            "*              Within this loop, the current character is retrieved from the buffer and the character at "+
            "*              the corresponding index is read from the file, this is later inserted into the buffer to be written back to file."+
            "*          "+
            "*              At each iteration, we checks if the current character matches the last character of the string to be replaced, if so, "+
            "*              we proceeds to check for a complete match of the string, which operates at O(n), where n is the string to be replaced. "+
            "*              If a complete match is found, the algorithm replaces the string in the file with the replacement characters and adjusts the offset accordingly. "+
            "*      6.The algorithm then writes the current character from the buffer to the file, updates the buffer by replacing the character with the one read from the file,"+
            "*      and updates the indexes for both the buffer and the file. "+
            "*      7.If the end of the buffer is reached, indicated by the '/0' character, then the replacement is complete."+
            "* "+
            "*      Complexity : O(N^2) for string comparision, we note the KMP algorithm can be utilised for O(N) complexity."+
            "* "+
            "*      Note : The algorithm utilised assumes the size of the replacement string is less then  (or equal) to the string To be replace, "+
            "*      otherwise a queue base solution should be utilised instead."+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>int<<white/> main(<<orange/>int<<white/> argc, <<orange/>char<<white/>* argv[]) {"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Validate input */<<white/>"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (argc < 3) {"+
            "<<<sp_6, br/><<orange/>printf<<white/>('Syntax: %s [flag] [filePath] - (i.e. %s -1 file.txt)\n', argv[0], argv[0]);"+
            "<<<sp_6, br/><<orange/>return<<white/> 1;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Open file */<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> file_fd = open(argv[2], O_RDWR);    <<grey/>/* file descriptor, Open file in read-write mode */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (file_fd == -1) {                    <<grey/>/* handle open operation fail */<<white/>"+
            "<<<sp_6, br/>perror('Failed in openining file');"+
            "<<<sp_6, br/><<orange/>return<<white/> 1;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>struct stat fileInfo;                               <<grey/>/** stat fileStructure for file */<<white/>"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ( stat(argv[2], &fileInfo) == -1)                <<grey/>/** handle statcall err */<<white/>"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>f<<orange/>printf<<white/>(stderr,'Error occured reading stat for file : %s\n', argv[2]);"+
            "<<<sp_3, br/><<orange/>return<<white/> 1;"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>int<<white/> fileSize =(long long)fileInfo.st_size;          <<grey/>/** File size in bytes */<<white/>"+
            "<<<sp_3, br/><<orange/>char<<white/> charToReplace[] = 'table';                     <<grey/>/** String to be replaced */<<white/>"+
            "<<<sp_3, br/><<orange/>char<<white/> replacement_Chars[] = 'desk';                  <<grey/>/** Replacement String*/<<white/>"+
            "<<<sp_3, br/><<orange/>char<<white/> Buffer[] = 'This is the updated version.';     <<grey/>/** Buffer & Initial insertion string*/<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> buffer_Index = 0;                               <<grey/>/** Index for buffer array */<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> file_Index = 0;                                 <<grey/>/** Index for pointer location */<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> offset = 0;                                     <<grey/>/** offset */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** While characters are not all replace & inserted */<<white/>"+
            "<<<sp_3, br/><<lightblue/>while<<white/> (1)"+
            "<<<sp_3, br/>{"+
            "<<<sp_9, br/><<orange/>char<<white/> currentChar = Buffer[buffer_Index];                                            <<grey/>/** Current inserting character */<<white/>"+
            "<<<sp_9, br/><<orange/>char<<white/> fileChar = (file_Index < fileSize) ? getFileChar(file_Index, file_fd) : '\0';  <<grey/>/** Current character in file, or empty charater is assigned represnting EOF */<<white/>"+
            "<<<sp_9, br/><<orange/>int<<white/> matching = -1;                                                                  <<grey/>/** matching flag*/<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<grey/>/** Check if replacement is complete */<<white/>"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (currentChar == '\0') {                                                          <<grey/>/** If EOF is reached, then insertion and replacement is complete  */<<white/>"+
            "<<<sp_14, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = (file_Index - offset); i < fileSize; i++)  writeChar(i, '\0', file_fd);        <<grey/>/** dissociate ending indices with empty char*/<<white/>"+
            "<<<sp_14, br/><<orange/>break<<white/>;"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<grey/>/* Check for a complete match of the string to be replaced */<<white/>"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (currentChar == charToReplace[(sizeof(charToReplace)-1 / sizeof(<<orange/>char<<white/>))-1]){          <<grey/>/** If the inserting character = ending character in charToReplace, then we check if subsequent characters are identical */<<white/>"+
            "<<<sp_14, br/>matching = 1;   <<grey/>/* Set flag to true */<<white/>                       "+
            "<<<sp_14, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = (sizeof(charToReplace)-1 / sizeof(<<orange/>char<<white/>))-2; i >= 0; i-- )          <<grey/>/** Iterate charToReplace (backwards )*/<<white/>"+
            "<<<sp_14, br/>{"+
            "<<<sp_17, br/><<orange/>int<<white/> k = ( (file_Index - offset)-1 - ( ((sizeof(charToReplace)-1 / sizeof(<<orange/>char<<white/>))-2)))+i; <<grey/>/** k = associated indicie in file */<<white/>"+
            "<<<sp_17, br/><<lightblue/>if<<white/> ( (k < 0) || getFileChar(k, file_fd) != charToReplace[i])                        <<grey/>/** if k is outofBounds or character are not identical */<<white/>"+
            "<<<sp_17, br/>{"+
            "<<<sp_19, br/>matching = -1;  <<grey/>/** set flags to false */<<white/>"+
            "<<<sp_19, br/><<orange/>break<<white/>;          "+
            "<<<sp_17, br/>}"+
            "<<<sp_14, br/>}"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<grey/>/** If there exist a match, then we replace character and generate the offset */<<white/>"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (matching == 1) "+
            "<<<sp_9, br/>{"+
            "<<<sp_11, br/><<grey/>/** Iterate and replace characters */<<white/>"+
            "<<<sp_11, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i <= (sizeof(replacement_Chars)-1 / sizeof(<<orange/>char<<white/>))-1; i++ ){"+
            "<<<sp_14, br/>writeChar((file_Index - offset) - ((sizeof(replacement_Chars)-1 / sizeof(<<orange/>char<<white/>)) -i), replacement_Chars[i], file_fd);"+
            "<<<sp_11, br/>}"+
            "<<<sp_11, br/><<grey/>/** Generate file_Index offset */<<white/>"+
            "<<<sp_11, br/>offset = offset + ( (sizeof(charToReplace)-1 / sizeof(<<orange/>char<<white/>)) - (sizeof(replacement_Chars)-1 / sizeof(<<orange/>char<<white/>))  );"+
            "<<<sp_9, br/>}<<lightblue/>else<<white/>  writeChar( (file_Index - offset), currentChar, file_fd); <<grey/>/** We write character if no mmatch is found */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/>Buffer[buffer_Index] = fileChar;   <<grey/>/** Assign current file Character to buffer */<<white/>"+
            "<<<sp_9, br/>buffer_Index = (buffer_Index+1) %  (sizeof(Buffer)-1 / sizeof(<<orange/>char<<white/>));     <<grey/>/** Increment the bufferIndex*/<<white/>"+
            "<<<sp_9, br/>file_Index++;                       <<grey/>/** Increment file_Index */<<white/>"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>close(file_fd);     <<grey/>/** Close Descriptor */<<white/>"+
            "<<<sp_3, br/><<orange/>printf<<white/>('\nFile modification complete.\n');"+
            "<<<sp_3, br/><<orange/>return<<white/> 0;"+
            "<<<sp_0, br/>}"
        },
        {
            key : 3,
            FileTitle : "sort.c",
            content :  
            "<<white/><<<sp_0, br/><<lightblue/>#include<<white/> <stdio.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <stdlib.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <fcntl.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <unistd.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <string.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <sys/stat.h>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <fcntl.h>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>#define<<white/> BUFFER_SIZE 1000                            <<grey/>/** Default buffer size*/<<white/>"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "* "+
            "* The main function reads the contents of a file specified as a command-line argument and sorts the lines in descending order based on their ASCII values. "+
            "* The file's size is determined using the stat function, and a byte container is created to store the file's content. "+
            "* A dynamic array called lineIndexer_Array is also created to store the indices of each line in the byte container.The file is read in chunks using the read "+
            "* function, and each character is processed to populate the byte container. The array lineIndexer_Array is resized dynamically as needed to accommodate the "+
            "* growing number of lines in the file. Once the file is read and the line indices are established, a bubble sort algorithm is used to sort the lines in descending order."+
            "* "+
            "* The sorted lines are then printed to the console by iterating over the line indices and retrieving the corresponding characters from the byte container. "+
            "* Each line is printed until a newline character is encountered, indicating the end of the line. The file descriptor is closed, and the program terminates with a "+
            "* return value of 0."+
            "* "+
            "* Complexity : O(n ^2) for sorting (bubble sort)"+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>int<<white/> main(<<orange/>int<<white/> argc, <<orange/>char<<white/>* argv[]) {"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Validate argument */<<white/>"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (argc != 2) {"+
            "<<<sp_6, br/><<orange/>printf<<white/>('Syntax: %s [filename]\n', argv[0]);"+
            "<<<sp_6, br/><<orange/>return<<white/> 1;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>char<<white/>* filename = argv[1];"+
            "<<<sp_3, br/><<orange/>int<<white/> fd = open(filename, O_RDONLY);                                                                          <<grey/>/** open file descriptor */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (fd == -1) {                                                                                             <<grey/>/** handle file-descriptor connection err */<<white/>"+
            "<<<sp_6, br/><<orange/>printf<<white/>('Failed to open the file: %s\n', filename);"+
            "<<<sp_6, br/><<orange/>return<<white/> 1;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>struct stat fileInfo;                                                                                       <<grey/>/** stat fileStructure for file */<<white/>"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ( stat(filename, &fileInfo) == -1)                                                                       <<grey/>/** handle statcall err */<<white/>"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>f<<orange/>printf<<white/>(stderr,'Error occured reading stat for file : %s\n', filename);"+
            "<<<sp_3, br/><<orange/>return<<white/> 1;"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>int<<white/> fileSize =(long long)fileInfo.st_size;                                                                  <<grey/>/** File size in bytes*/<<white/>"+
            "<<<sp_3, br/><<orange/>char<<white/> file_Container[fileSize];                                                                              <<grey/>/** Byte Container to store content*/<<white/>"+
            "<<<sp_3, br/><<orange/>char<<white/> input_Buffer[BUFFER_SIZE];                                                                             <<grey/>/** Input buffer for read() call */<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> bytes_Read;                                                                                             <<grey/>/** Bytes read for read() call */<<white/> "+
            "<<<sp_3, br/><<orange/>int<<white/> lineCount = 0;          "+
            "<<<sp_3, br/><<orange/>int<<white/> fileIterator = 0;                                                                                       <<grey/>/** File Iterator */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Create Dynamic array */<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> lineIndexer_Size = 5;                                                                                   <<grey/>/** Defaut array size */<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> * lineIndexer_Array = malloc((lineIndexer_Size) * sizeof(<<orange/>int<<white/>)); "+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Read file content & Establish line indices */<<white/>"+
            "<<<sp_3, br/><<lightblue/>while<<white/> ((bytes_Read = read(fd, input_Buffer, BUFFER_SIZE)) > 0) "+
            "<<<sp_3, br/>{   "+
            "<<<sp_6, br/><<grey/>// iterate read bytes"+
            "<<white/><<<sp_6, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < bytes_Read; i++)   "+
            "<<<sp_6, br/>{"+
            "<<<sp_8, br/><<orange/>char<<white/> currentChar = input_Buffer[i]; "+
            "<<<sp_8, br/>file_Container[fileIterator++] = currentChar;                                                       <<grey/>/* store current char in container */<<white/>"+
            "<<<sp_8, br/><<grey/>// Handle new line"+
            "<<white/><<<sp_8, br/><<lightblue/>if<<white/> (currentChar == '\n'){"+
            "<<<sp_10, br/>lineCount++;"+
            "<<<sp_10, br/><<grey/>//  Handle array resize (ifany) - Dynamically rescale array size"+
            "<<white/><<<sp_10, br/><<lightblue/>if<<white/> (lineCount > lineIndexer_Size)       "+
            "<<<sp_10, br/>{"+
            "<<<sp_12, br/><<orange/>int<<white/> <new_LineSize = (lineCount*2);                                                           <<grey/>/* new line size, this is double the previous size */<<white/>"+
            "<<<sp_12, br/><<orange/>int<<white/> * <<orange/>new<<white/>_LineIndexerArray = malloc(<new_LineSize * sizeof(<<orange/>int<<white/>));                            <<grey/>/* create the new array */<<white/> "+
            "<<<sp_12, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i =0; i <= lineIndexer_Size; i++) <<orange/>new<<white/>_LineIndexerArray[i] = lineIndexer_Array[i];  <<grey/>/* reinstate previous value */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_12, br/>free(lineIndexer_Array);                                                                    <<grey/>/* Free memory */<<white/>"+
            "<<<sp_12, br/>lineIndexer_Array = <<orange/>new<<white/>_LineIndexerArray;                                                   <<grey/>/* reassign pointer*/<<white/>"+
            "<<<sp_12, br/>lineIndexer_Size = <new_LineSize;                                                            <<grey/>/* associate new arraysize */<<white/>"+
            "<<<sp_10, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_10, br/>lineIndexer_Array[lineCount] = (i+1);                                                           <<grey/>/* associate line index  */<<white/>"+
            "<<<sp_8, br/>} "+
            "<<<sp_6, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Sort Lines via bubblesort : O(N) */<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> decrementor = 0;"+
            "<<<sp_3, br/><<lightblue/>while<<white/>((lineCount - decrementor) > 0) <<grey/>// while unsorted "+
            "<<white/><<<sp_3, br/>{"+
            "<<<sp_6, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i =1; i <= (lineCount - decrementor); i++)                                                     <<grey/>/*   iterate lines */<<white/>"+
            "<<<sp_6, br/>{"+
            "<<<sp_8, br/><<orange/>int<<white/> currentline_Index = lineIndexer_Array[i];                                                       <<grey/>/* index of line 'i' in file_Container */<<white/>"+
            "<<<sp_8, br/><<orange/>int<<white/> previousLine_Index = lineIndexer_Array[i-1];                                                    <<grey/>/* index of line (i-1) in file_Container*/<<white/>"+
            "<<<sp_8, br/><<lightblue/>if<<white/> ((<<orange/>int<<white/>)file_Container[currentline_Index] > (<<orange/>int<<white/>)file_Container[previousLine_Index])               <<grey/>/*  perform swap if necessary */<<white/>"+
            "<<<sp_8, br/>{"+
            "<<<sp_10, br/><<grey/>// swap"+
            "<<white/><<<sp_10, br/>lineIndexer_Array[i] = previousLine_Index;"+
            "<<<sp_10, br/>lineIndexer_Array[i-1] = currentline_Index;"+
            "<<<sp_8, br/>}"+
            "<<<sp_6, br/>}"+
            "<<<sp_6, br/>decrementor++;                                                                                          <<grey/>// decrement sorted index, that is, index lineIndexer_Array[linecount-decrementor] is sorted."+
            "<<white/><<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Print sorted lines */<<white/>  "+
            "<<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i =0; i <= lineCount;i++)                                              <<grey/>/* iterate line index */<<white/>"+
            "<<<sp_3, br/>{"+
            "<<<sp_6, br/><<orange/>int<<white/> currentline_Index = lineIndexer_Array[i];                               <<grey/>// current index for line 'i'"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>/** Iterate until next line is reached */<<white/>"+
            "<<<sp_6, br/><<lightblue/>while<<white/> (1)"+
            "<<<sp_6, br/>{"+
            "<<<sp_8, br/><<orange/>char<<white/> currentChar = file_Container[currentline_Index];                   <<grey/>// current character"+
            "<<white/><<<sp_8, br/><<orange/>printf<<white/>('%c', currentChar);          "+
            "<<<sp_8, br/>currentline_Index++;                                                    <<grey/>// iterate to next character"+
            "<<white/><<<sp_8, br/><<lightblue/>if<<white/> (currentChar == '\n' || !(currentline_Index < fileSize) ) <<orange/>break<<white/>;     <<grey/>// break on new line"+
            "<<white/><<<sp_6, br/>}"+
            "<<<sp_6, br/><<orange/>printf<<white/>('\n');"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>close(fd);"+
            "<<<sp_3, br/><<orange/>return<<white/> 0;"+
            "<<<sp_0, br/>}"+
            ""            



        },  

    ]
}

export default CaroselItem;
import { useState } from "react";
import Styling from "./Styling.module.css"



let RenderForm = () => { 


    let [isSubmitted, set_IsSubmit] = useState<boolean>(false)

    return (
        <>
            <div style = {{width:"100%", }}>
                {
                    // !isSubmitted &&
                    <form  onSubmit={(e) => {
                            e.preventDefault();
                            set_IsSubmit(true);
                            // Add your form submission logic here
                            }} className={Styling.form}>
                        <label style = {{width:"100%", display:'inline-block'}}>
                            <input readOnly = {isSubmitted}  style = {{display:"inline", color:`${isSubmitted ? "grey" : "black"}`, width:'49.5%', marginRight:"1%",}}  placeholder="Email" type="email" className={Styling.input} />
                            <input readOnly = {isSubmitted} style = {{display:"inline", color:`${isSubmitted ? "grey" : "black"}`, width:'49.5%',}} type="tel" placeholder="Telephone" className={Styling.input}/>
                        </label> 
                        <label>
                            <textarea style = {{color:`${isSubmitted ? "grey" : "black"}`,}} readOnly = {isSubmitted}  placeholder="Message" className={Styling.input01}></textarea>
                        </label>
                        <button className={Styling.fancy} >
                        <span className={Styling.top_key}></span>
                        <span className={Styling.text}>
                            {
                                !isSubmitted && <> submit</>
                                || <> Request Submitted </>
                            }
                        </span>
                        <span className={Styling.bottom_key_1}></span>
                        <span className={Styling.bottom_key_2}></span>
                        </button>
                    </form>

                }
            </div>
        </>
    )
}


export default RenderForm;
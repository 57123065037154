//  


import Styling from "./Styling.module.css"
import ProfileBanner, {AnimationBannerInformation} from "../../BaseProject/Components/animations/AnimationBannerProfile/AnimationBanner";

import { useEffect, useLayoutEffect, useRef, useState } from "react"
import myBrowser from "../../BaseProject/Handlers/myBrowser"
import DynamicText from "../../BaseProject/Components/layouts/Others/dynamicText"

let isLocked = false;
let RenderPage : React.FC <{rootContainer : React.RefObject<HTMLDivElement>}> = (props) => { 
    /** Description for profile banner */
    let profileBanner_Info : AnimationBannerInformation = 
    {
      title_Right: "</Coder>",
      title_Left : "Designer",
      desc_Right : "Front end developer who writes clean, elegant and efficient code.",
      desc_Left : "Designer specialising in Graphic design and illustrations.",
      image_RightURL : "./images/myresume_Content/lap_2.png",
      image_LeftURL : "./images/myresume_Content/lap_1.png"
    }
    let timerRef = useRef<HTMLParagraphElement> (null); 
    let container_Ref = useRef<HTMLDivElement> (null); 
    let widthDimension = useRef<HTMLParagraphElement>(null);
    let [isScaling, set_isScaling] = useState<boolean>(false);
    let [ruler_Size, set_RulerSize] = useState<{sideSize: number, topSide : number}>({sideSize:0, topSide:0});
    let [isPortrait, set_IsPortrait] = useState<boolean>(myBrowser.isPortrait());
    let TopContainer_Ref = useRef<HTMLDivElement>(null); 
    let [is_inView, set_inView] = useState<boolean | undefined>(undefined);
    let contentCover_Ref = useRef<HTMLDivElement>(null);


    // HANDLE content TIMER 
    useLayoutEffect(
        () => {
            let count =0;
            function setTime() {
                if (timerRef.current != undefined){
                    const currentDate = new Date();
                    const hours = currentDate.getHours();
                    const minutes = currentDate.getMinutes();
                    const seconds = currentDate.getSeconds();
                    timerRef.current.innerText = `${hours}:${minutes}:${seconds} AEDT`;

                }

                setTimeout(setTime, 1000); // execute every second
              }
              
              // Start the initial execution
              setTime();
        },[]
    )

    // HANDLE Content re-scaling animation
    useLayoutEffect(
        () => { 
            let isTransition = false;
            let scrollTimeout: NodeJS.Timeout | undefined;

            if (container_Ref.current != undefined){
                let TransitionStart_Handler = (event : TransitionEvent) => { 
                    if (event.propertyName === "width"){
                        set_isScaling(true);
                    }
               }


                let transitionEnd = (event : TransitionEvent) => { 
                    console.log('Transitioned property:', event.propertyName);

                    if (event.propertyName === "width"){
                        set_isScaling(false);
                        container_Ref.current?.removeEventListener('transitionstart', TransitionStart_Handler);
                        container_Ref.current?.removeEventListener('transitionend', transitionEnd);
                        isTransition = false;

                    }
                }
                let timeoutId:any;
                let handleDimension_Display = () => { 
                    // HANDLE RESIZE PROMPT
                    if (widthDimension.current != undefined){
                        widthDimension.current.innerText = myBrowser.getBrowserWidth().toString();
                    }

                    // HANDLE RULER
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(() => {
                       set_RulerSizing();
                       set_IsPortrait(myBrowser.isPortrait());
                    }, 500);
                }

                let resizeHandler = () => { 
                    handleDimension_Display();

                    // initialise transiiton handler                    
                    if (container_Ref.current != undefined && !isTransition){
                        isTransition = true;
                        container_Ref.current.addEventListener('transitionstart', TransitionStart_Handler);
                        container_Ref.current.addEventListener('transitionend', transitionEnd);
                    }

                }

                // set sideruler length
                let set_RulerSizing = () => {
                    if (container_Ref.current != undefined){
                        set_RulerSize( { sideSize:container_Ref.current.clientHeight/20, topSide : container_Ref.current.clientWidth/20 } );
                    }
                }
                window.addEventListener("resize", resizeHandler);
                set_RulerSizing();

                return () => {
                    window.removeEventListener("resize", resizeHandler);
                    container_Ref.current?.removeEventListener('transitionstart', TransitionStart_Handler);
                    container_Ref.current?.removeEventListener('transitionend', transitionEnd);
                }
            }
        },[]
    )



    // Handle scroll listener & Handle
    useLayoutEffect(
        () => { 
            // Inframe handling
            let set_isInView = (event ?: Event | undefined) => { 
                if (TopContainer_Ref.current != undefined && props.rootContainer.current != undefined){
                    let k = TopContainer_Ref.current.getBoundingClientRect();
                    // IF CONTENT IS INVIEW.
                    if (k.bottom > 0) {                                             // content in view
                        // console.log("TOP = " + k.top + " height = " + k.height + " % = " + 100* (-k.top / k.height));
                        // console.log("TO TRANSLATE = " +  (-k.top / k.height) * TopContainer_Ref.current.clientHeight);
                        // console.log("Is in view scrolling!");
                        // TopContainer_Ref.current.style.opacity = `${100 -  (100* (-k.top / k.height))}%`;
                        // props.rootContainer.current.style.top = `-${  (-k.top / k.height) * TopContainer_Ref.current.clientHeight - 100}px` ;

                        if (is_inView != true)  set_inView(true);    
                    } else if (is_inView != false) set_inView(false)                // content NOT in view;
                }
            }
            window.addEventListener("scroll", set_isInView);
            set_isInView(undefined);
            return() => { 
                window.removeEventListener("scroll", set_isInView);
            }
        }
    )

    
    // Handle content focus.
    useLayoutEffect(
        () => { 
            let isTransition = false;           // is content transitioning
            let isCover = false;                // is covering div active

            // Handle Transition
            let TransitionEndHandler = (Event : TransitionEvent) => { 
                console.log("Transition Ended");
                contentCover_Ref.current?.removeEventListener("transitionend", TransitionEndHandler);       // remove event listener
                if (contentCover_Ref.current != undefined){                                     
                    if (isCover) contentCover_Ref.current.style.zIndex= "-50";                             // remove content coverer
                }

                isTransition = false;
            }


            // Handle transition behaviour
            let wheelHandler = (event ?: WheelEvent) => { 
                if ( (props.rootContainer.current != undefined && TopContainer_Ref.current != undefined && contentCover_Ref.current != undefined)){
                    if (is_inView){                                             
                        // CONTENT IS IN VIEW
                        // console.log("CONTENT IS IN VIEW!");
                        // IF NOT ALREADY TRASITIONING
                        if (isTransition === false){
                            isTransition = true;
                            isCover= true;                                                                          // cover page content since component is inView.
                            contentCover_Ref.current.addEventListener("transitionend", TransitionEndHandler);       // listen for transiton
                        }
                        
                        // Handle component Transition 
                        if ( event != undefined && event.deltaY > 0)  {          
                            console.log("DOWN SCROLLING! : " + is_inView);              
                            // if down ScrollING
                            TopContainer_Ref.current.style.opacity = "0";                       // hide component content via opacity
                            props.rootContainer.current.style.top = `-${TopContainer_Ref.current.clientHeight-100}px` ;     // transiiton up
                            contentCover_Ref.current.style.opacity = "0";
                            // props.rootContainer.current.style.transform = `translate(0,-${TopContainer_Ref.current.clientHeight-100}px)`; 
                        }
                        else                  
                        {
                            // up scrolling
                            TopContainer_Ref.current.style.opacity = "1";
                            props.rootContainer.current.style.top = `${0}px` ;
                            contentCover_Ref.current.style.opacity = "1";
                            contentCover_Ref.current.style.height = "100%";

                        }                        
                    }else {                     
                        // CONTENT NOT IN VIEW 
                        // console.log("CONTENT IS NOT IN VIEW!");
                        props.rootContainer.current.style.top = `-${TopContainer_Ref.current.clientHeight-100}px` ;
                        contentCover_Ref.current.style.zIndex= "-50"; 
                        contentCover_Ref.current.style.opacity = "0";
                        contentCover_Ref.current.style.height = "0";

                        // props.rootContainer.current.style.transform = `translate(0,-${TopContainer_Ref.current.clientHeight-100}px)`;

                    }
                }
            }

            if (is_inView){
                window.addEventListener("wheel", wheelHandler);
            }
            wheelHandler();     // default handle

            return () => { 
                window.removeEventListener("wheel", wheelHandler);
                contentCover_Ref.current?.removeEventListener("transitionend", TransitionEndHandler);
 
            }
        },
    )   


    return(
        <>
          
            <div  ref = {TopContainer_Ref} className={Styling.IntroductionContainer}>
                <div className={Styling.sideContainers + ' ' +Styling.lineBottom}>
                    <div className={Styling.centerContainer}>
                        {/* <svg className={Styling.Dot + ' ' + Styling.Dot_BottomLeft} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg> */}
                        {/* <svg className={Styling.Dot + ' ' + Styling.Dot_BottomRigt} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg> */}
                    </div>
                </div>
                <div className={Styling.sideContainers} >
                    <div ref = {container_Ref}  className={Styling.centerContainer} style = {{padding:"2%", boxSizing:'border-box'}}>
                        {/** Initialise rulers */}
                        {/* <p style = {{position:'absolute', top:'0', left:'0',  margin:"0", transform:"translate(-150%,-150%"}}> {0}</p>  */}
                        <div className={Styling.sideRuler_Container}>
                            {/* {
                                (() => {
                                    let k : React.ReactNode[] = [];
                                    for (let i =1; i < ruler_Size.sideSize; i++) k.push(
                                            <div style = {{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                                <p> {i}</p>
                                                <div style = {{display:"inlineblock", width:'5px', height:"1px", background:"rgba(221, 234, 248, .08)"}}/>
                                            </div>
                                        
                                        );
                                    return k;
                                })()
                            } */}
                        </div>
                        <div className={Styling.topRuler_Container}>
                            {/* {
                                (() => {
                                    let k : React.ReactNode[] = [];
                                    for (let i =1; i < ruler_Size.topSide; i++) 
                                        k.push( 
                                            <div style = {{display:'grid', justifyItems:'center'}}>
                                                <p style = {{display:"inline-block"}}> {i}</p> 
                                                <div style = {{display:"inlineblock", height:'5px', width:"1px", background:"rgba(221, 234, 248, .08)"}}/>
                                            </div>
                                        );
                                    return k;
                                })()
                            } */}
                        </div>

                        {/** Initialise content */}
                        <div id = "this" className={Styling.ScalingContainer} style = {{opacity: `${!isScaling ? "0" : "1"}`, zIndex : `${isScaling ? "100" : "0" }`}}>
                            <div style = {{width:'100%', height :'100%'}}>
                                <div className={Styling.ScalingLabel_Container}>
                                    <div className={Styling.lineCenter}>
                                        <p ref = {widthDimension}/> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        { !isScaling && 
                            <>
                                {/** Initialise center lines */}
                                <div className={Styling.centerLines} style={{left:'30%'}}/>
                                <div className={Styling.centerLines} style={{left:'70%'}}/>
                                <ProfileBanner isLight={true} AnimationBarInfo={profileBanner_Info}/> 
                            </>
                        }
                    </div>
                    {/* <h1 className = {Styling.viewMoreButton}> READ MORE </h1>  */}
                </div>

                <div className={Styling.sideContainers + ' ' + Styling.lineTop + ' ' + Styling.lineBottom}>
                    <div className={Styling.centerContainer}>
                        <div style = {{width:"95%", height:'100%', display:'grid', alignItems:'center'}}>
                            <p className = {Styling.timer}  ref = {timerRef}> </p>
                            {/* <button>
                                <a href="#" className={Styling.btn2}>
                                    <span className={Styling.spn2}>HELLO !</span>
                                </a>
                            </button> */}

                        </div>
                        {/* <svg className={Styling.Dot + ' ' + Styling.Dot_TopLeft} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                        <svg className={Styling.Dot + ' ' + Styling.Dot_TopRight} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                        <svg className={Styling.Dot + ' ' + Styling.Dot_BottomLeft} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                        <svg className={Styling.Dot + ' ' + Styling.Dot_BottomRigt} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg> */}
                    </div>
                </div>

                {/* <div className={Styling.centerContainer}>
                </div>
                <div className={Styling.sideContainers}>
                </div> */}

            </div>
            <div ref = {contentCover_Ref} style = {{transition:'all 1s', background:"rgb(17, 17, 19)", opacity :'0%', position:"absolute", height :"100%", width:"100%", zIndex: -50 }}/>
        </>
    )

}

export default RenderPage;
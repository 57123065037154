import MyProjects from "../BaseProject/MyResume/MyProjects/Projects"




let RenderPage : React.FC <{ }> = (props) => { 

    return(
        <>
           <div style = {{width:"100%"  ,boxSizing:'border-box', display:'flex', justifyContent:'center'}}>
                <div style = {{background:'#222', width:"100%", borderRadius:'1rem'}}>
                    <MyProjects />
                </div>                
            </div> 
        </>
    )
}


export default RenderPage;
import Styling from "./Styling.module.css";


let RenderPage : React.FC<{children : React.ReactNode}> = (props) => { 





    return(
        <>
            <div className={Styling.TitleWrapper}>
                {props.children}
                <div className={Styling.Highlight} style = {{borderBottom: '2px solid #007ced', width :'30%'}}/>
                <div className={Styling.Highlight}/>


            </div>
        </>
    )


}

export default RenderPage;
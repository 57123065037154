import { useLayoutEffect, useRef, useState } from "react";
import Styling from "./Styling.module.css"
import MyBrowser from "../../BaseProject/Handlers/myBrowser";
import React from 'react';
import ScrollableFrame from "../../BaseProject/Components/layouts/Others/scrollableFrame";

/**
 * Componenet is activate when pageY is reached at activationRef location
 * @param props 
 * @returns 
 */
let RenderPage : React.FC <{ name : String, Styling ?: React.CSSProperties, activationRef : React.RefObject<HTMLDivElement>, children:React.ReactNode, isPrompt : boolean }> = (props) => { 

    const lanscapeScale = 0.6;
    const portraitScale = 1;
    const maxScaling :number= 1.5, minScaling :number = 0.4;
    let [hasReached, set_hasReached] = useState<boolean>(false);
    let [hasOverlay, set_hasOverlay] = useState<boolean>(false);
    let startingScroll : number = 20;
    let currentScroll = useRef<number>(startingScroll);
    // let moreNotify = useRef<HTMLDivElement>(null);
    let componentRef = useRef<HTMLDivElement>(null);
    let content_Ref = useRef<HTMLDivElement>(null);
    let scalingRef = useRef<HTMLDivElement>(null);
    let [scaling, set_scaling] = useState<number | undefined>( undefined );


    // initialise action listeners
    useLayoutEffect(
        () => { 
            let scrollHandler = () => { 
                if (props.activationRef.current != undefined){
                    const rect = props.activationRef.current.getBoundingClientRect();
                    let itemLocation = (rect.top - MyBrowser.getBrowserHeight());
                    let itemLocationEnd = (rect.bottom + MyBrowser.getBrowserHeight());
    
                    if (itemLocation <= 0 && itemLocationEnd >= 0){
                        set_hasReached(true);
                    } 
                    else{
                        // if (props.isPrompt === false) set_hasOverlay(false);
                        set_hasReached(false);
                    } 
                }
            }

            let last_ID = 0;
            function executeAfterDelay(id : number) {
                setTimeout(() => {
                    if(id === last_ID){
                        last_ID=0;
                        if (scalingRef.current != undefined){
                            scalingRef.current.style.opacity = "0";
                        }

                    }
                }, 2000);
              }
              
            let mouseMoveHandler = () => { 
                if (scalingRef.current != undefined){
                    scalingRef.current.style.opacity = "1";
                    last_ID++;
                    executeAfterDelay(++last_ID);
                }
            }

            document.addEventListener("mousemove", mouseMoveHandler);
            window.addEventListener("touchmove", contentScroll_Handler);
            window.addEventListener("touchstart", contentScroll_Handler);
            document.addEventListener("scroll", scrollHandler );

            return () => { 
                document.removeEventListener("scroll", scrollHandler );
                document.removeEventListener("mousemove", mouseMoveHandler);
                window.removeEventListener("touchstart", contentScroll_Handler);
                window.removeEventListener("touchmove", contentScroll_Handler);
            }
        },[]
    )




    // validate position reached --> Activate button 
    useLayoutEffect(
        ()=>{
            // chekc if pos reached
            if (componentRef.current != null){
                let item = componentRef.current;
                if (hasReached)     item.style.display = "block";
                else                item.style.display= "none";
            }

            // check if component opened
            if (hasOverlay) MyBrowser.lockPageScroll();
            else MyBrowser.unlockPageScroll();
        }
    )

    // validate all content is scrolled 
    let hasEnd = () : boolean => {
        if (content_Ref.current != undefined){
            const rect = content_Ref.current.getBoundingClientRect();
            const bottomPos = rect.bottom - MyBrowser.getBrowserHeight();
            
            return (bottomPos > 0);
        }
        
        return false;
    }

    // Scroll handler, where scrollAmount is the addition of some value (in percentage) to current position
    let scrollHandler = (scrollAmount : number ) => {

        if (content_Ref.current != undefined){
        
            // console.log("To scroll = " + scrollAmount);

            const rect = content_Ref.current.getBoundingClientRect();


            if (scrollAmount > 0){
                let newScroll = currentScroll.current + scrollAmount;
                if (newScroll >= startingScroll) currentScroll.current = startingScroll;
                else currentScroll.current += scrollAmount;
            }else if (hasEnd()) {
                currentScroll.current += scrollAmount;
            } 

            content_Ref.current.style.top = `${currentScroll.current}%`;
        }

    }

    let initialTouch =0;
    let contentScroll_Handler = (event : React.WheelEvent<HTMLElement> | TouchEvent  ) => {


        switch(event.type) {
            case "touchstart" :  initialTouch =(event as TouchEvent).touches[0].clientY;
            break;
            case "touchmove" :     scrollHandler  (0.009 *  ((event as TouchEvent).touches[0].clientY - initialTouch));
            break;
            case "wheel" :  scrollHandler(0.009 * (event as React.WheelEvent<HTMLElement>).deltaY);
        }
         

    }

    let scale = (is_UP : boolean) => {
        if (content_Ref.current != undefined ){
            if (scaling === undefined) scaling = (MyBrowser.isPortrait()) ? portraitScale : lanscapeScale;
            if (is_UP && (scaling <= maxScaling))  set_scaling (scaling+0.1);
            else if (scaling > minScaling) set_scaling (scaling-0.1);
        }   
    }

    let closeOverlay = (isclose : boolean) => { 
        if (!isclose && props.activationRef.current != undefined){
            const rect = props.activationRef.current.getBoundingClientRect();
        }

        set_hasOverlay(isclose);
    }

    return(
        <>  
            {
                hasOverlay &&
                <div ref = {scalingRef} className={Styling.scalingContainer} >
                    <div onMouseDown={() => { scale(false)}}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M20.9992 21L14.9492 14.95" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 10H14" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </div>
                    <div onMouseDown={() => { scale(true)}} style = {{marginLeft:'15%'}}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M20.9992 21L14.9492 14.95" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 10H14" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M10 6V14" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </div>
                </div>
            }
            {/* display :`${hasOverlay ? "flex" : "none"} !important` */}
            <div onMouseDown={() =>{ closeOverlay(true) }} ref = {componentRef} className={Styling.buttonContainer}>
                {props.name}
            </div>
           
            <div  onWheel={contentScroll_Handler} onMouseDown={() => {closeOverlay(false)} } className={Styling.contentContainer} style = {{top:`${hasOverlay ? "0%" : "100%"}`, opacity :`${hasOverlay ? "100" : "0"}` }}>
                <div ref = {content_Ref} onMouseDown={(event) => {event.stopPropagation()}} className={Styling.content} style = { {transform: `scale(${scaling})`, top: hasOverlay ? `${startingScroll}%` : "100%"}}>
                       {props.children} 
                </div>
            </div>
        </>
    )
}


export default RenderPage;
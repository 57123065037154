import  {items} from '../../../../contentCarousel/contentCarosel';
import ComponentDemo from "../../../../DemoWrapper/DemoWrapper"; 
import Test from "../../../../../ComponentDemo/ContentCaroselDemo";


let CaroselItem : items =    {
    key : 1,
    Subject : "Sudoku Analyzer",
    Description : 
        <>
   
            <p>  
                Implemented a Sudoku game analyzer in Java that analyzes the game board for victory conditions. 
                The project utilizes multithreading and concurrency concepts for real-time analysis of the Sudoku game.

                The project demonstrates concurrent programming, object-oriented design in the java programming language.
            </p>
            <p style = {{fontWeight:"800", textDecoration:"underline"}}> The main components of the project include: </p>
            <ul>
                <li>
                    <span style = {{textDecoration:'underline'}}>  GameAnalyser Class : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                                Analyzes the Sudoku game board for victory conditions.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                                Implements the Runnable interface for concurrent execution (threads).
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                                Utilizes a ReentrantLock to ensure thread safety.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Trread interruption upon detecting the victory condition.
                        </li>
                    </ol>
                </li>

                <li>

                    <span style = {{textDecoration:'underline'}}>  Sudoku Class : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                                Represents the Sudoku game.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Manages the game board and player interactions.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                                Provides methods for placing numbers on the board and validating positions
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Implements a nested gameMap class for representing the Sudoku board.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Includes a nested position class for defining positions on the board
                        </li>
                    </ol>
                </li>


                <li>
                    <span style = {{textDecoration:'underline'}}>  Main Method : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Parses command-line arguments to determine the size of the Sudoku game.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Initializes the Sudoku game with the specified size
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Creates a game thread for player input
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Creates a separate thread for the game analyzer to monitor the game board
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Coordinates player input and game analysis using multithreading and locks
                        </li>
                    </ol>
                </li>
            </ul>
        </>,
    img_Url:"./images/Project_Images/suduku_A.jpg",
    file_List : [
        {
            key : 1,
            FileTitle : "suduku.java",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.BufferedReader;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.InputStreamReader;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.PrintStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.concurrent.ExecutorService;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.concurrent.Executors;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.concurrent.locks.ReentrantLock;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> suduku "+
            "<<<sp_0, br/>{"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>static<<white/> PrintStream p =  <<orange/>new<<white/> PrintStream( <<orange/>system<<white/>.out );"+
            "<<<sp_1, br/><<grey/>/* return null if object is not integer-type, else the integer is returned  */<<white/>"+
            "<<<sp_1, br/><<orange/>static<<white/>intValidator intvalidator = (object) ->"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (object.getClass() != <<orange/>String<<white/>.<<orange/>class<<white/> ) <<orange/>return<<white/> null;"+
            "<<<sp_3, br/>try"+
            "<<<sp_3, br/>{ "+
            "<<<sp_5, br/><<orange/>return<<white/> <<orange/>char<<white/>.valueOf( (<<orange/>String<<white/>)object );"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<lightblue/>catch<<white/>(Exception i) "+
            "<<<sp_3, br/>{ "+
            "<<<sp_5, br/><<orange/>return<<white/> null; "+
            "<<<sp_3, br/>}			"+
            "<<<sp_1, br/>};"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>private<<white/> gameMap m;"+
            "<<<sp_1, br/>suduku(<<orange/>String<<white/> name, <<orange/>int<<white/> x, <<orange/>int<<white/> y)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>p.println(name + ' has been started!');"+
            "<<<sp_3, br/>m = <<orange/>new<<white/> gameMap(x,y);"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* "+
            "<<<sp_2, br/>* @param pos (position)"+
            "<<<sp_2, br/>* @param value (int)"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>void<<white/> place (position pos, <<orange/>char<<white/> value) throws Exception {"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (pos == null || value == null ) "+
            "<<<sp_5, br/>throw <<orange/>new<<white/> Exception('Invalid Input : Must be nonchars!');"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ( ((position)pos).y < m.map.length && ((position)pos).x < m.map[0].length) "+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>p.println( (m.changeMap((position)pos, (<<orange/>char<<white/>)value) == true) ? 'update has been made' : 'position alread established' );"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<lightblue/>else<<white/> "+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>throw <<orange/>new<<white/> Exception('Invalid position' + '! \nMust be within X:' +  m.map.length+ ' Y:' + m.map[0].length );"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>String<<white/> to<<orange/>String<<white/>() "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>return<<white/> m.to<<orange/>String<<white/>();"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_2, br/><<orange/>class<<white/> gameMap <T extends position>  "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>private<<white/> <<orange/>int<<white/>[][] map;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>gameMap(<<orange/>int<<white/> x, <<orange/>int<<white/> y)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>map = <<orange/>new<<white/> <<orange/>int<<white/>[x][y];"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* @apiNote change map value of array"+
            "<<<sp_4, br/>* @param x"+
            "<<<sp_4, br/>* @param y"+
            "<<<sp_4, br/>* @param value"+
            "<<<sp_4, br/>* @return true if success, false if not"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> boolean changeMap(T pos, <<orange/>int<<white/> value) "+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>if<<white/> ( map [pos.x][pos.y] == 0) "+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>map[pos.x][pos.y] = value;"+
            "<<<sp_6, br/><<orange/>return<<white/> true;"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>int<<white/>[][] getMap()"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>return<<white/> map;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>int<<white/> getY() "+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>return<<white/> map.length;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>int<<white/> getX() "+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>return<<white/> map[0].length;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* @return gameMap"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>String<<white/> to<<orange/>String<<white/>() {"+
            "<<<sp_5, br/><<orange/>String<<white/>Builder b = <<orange/>new<<white/> <<orange/>String<<white/>Builder();"+
            "<<<sp_5, br/>for(<<orange/>int<<white/> y=0; y < map.length; y++ ) {"+
            "<<<sp_6, br/>for(<<orange/>int<<white/> x =0; x < map[y].length; b.append( ( !(map[x][y] == 0) ) ?  '[' + map[x][y] + ']'  : '[ ]'  )  , x++  );"+
            "<<<sp_6, br/>b.append('\n');"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<orange/>return<<white/> b.to<<orange/>String<<white/>();	"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_2, br/><<orange/>class<<white/> position"+
            "<<<sp_2, br/>{"+
            "<<<sp_5, br/><<orange/>char<<white/> x, y;"+
            "<<<sp_5, br/>position set(<<orange/>char<<white/> x, <<orange/>char<<white/> y)  throws Exception "+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/><<lightblue/>if<<white/> (x == null | y ==null) <<orange/>return<<white/> null;"+
            "<<<sp_6, br/>this.x = (<<orange/>char<<white/>) x;"+
            "<<<sp_6, br/>this.y = (<<orange/>char<<white/>) y;"+
            "<<<sp_6, br/><<orange/>return<<white/> this;"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/>"+
            "<<<sp_4, br/><<orange/>static<<white/> <<orange/>int<<white/> [] pos = <<orange/>new<<white/> <<orange/>int<<white/>[2]; <<grey/>/* 0 = x, 1 = y */<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(<<orange/>String<<white/>[]args) "+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (args.length > 1  ) "+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>try {"+
            "<<<sp_7, br/>pos [0] = <<orange/>char<<white/>.valueOf(args[0]);"+
            "<<<sp_7, br/>pos [1] = <<orange/>char<<white/>.valueOf(args[1]);"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (pos [0] < 3 || pos[1] < 3) throw <<orange/>new<<white/> Exception();"+
            "<<<sp_6, br/>}<<lightblue/>catch<<white/>(Exception i) "+
            "<<<sp_6, br/>{"+
            "<<<sp_7, br/>p.pr<<orange/>int<<white/>ln('Incorrect Syntax : Input = (<<orange/>int<<white/>) x,y of greater then 3');"+
            "<<<sp_7, br/><<orange/>return<<white/>;"+
            "<<<sp_6, br/>}"+
            "<<<sp_5, br/>}<<lightblue/>else<<white/> "+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>p.pr<<orange/>int<<white/>ln('Enter valid sizefor game : e.g --> 3 3');"+
            "<<<sp_6, br/><<orange/>return<<white/>;"+
            "<<<sp_6, br/>"+
            "<<<sp_5, br/>}"+
            "<<<sp_6, br/>"+
            "<<<sp_5, br/>suduku game = <<orange/>new<<white/> suduku('game1', pos[0], pos[1]);"+
            "<<<sp_5, br/>suduku.position pos = game.<<orange/>new<<white/> position();"+
            "<<<sp_5, br/>ReentrantLock threadlock = <<orange/>new<<white/> ReentrantLock();"+
            "<<<sp_5, br/>Thread gameService = <<orange/>new<<white/> Thread() "+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>@Override"+
            "<<<sp_6, br/><<orange/>public<<white/> <<orange/>void<<white/> run() "+
            "<<<sp_6, br/>{"+
            "<<<sp_7, br/>Scanner scan = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
            "<<<sp_7, br/><<lightblue/>while<<white/>(true)"+
            "<<<sp_8, br/>try "+
            "<<<sp_8, br/>{"+
            "<<<sp_9, br/>threadlock.lock();"+
            "<<<sp_9, br/>game.place(pos.set( intvalidator.getInt(scan.next()) , intvalidator.getInt(scan.next())  ), intvalidator.getInt(scan.next() ));"+
            "<<<sp_9, br/>threadlock.unlock();"+
            "<<<sp_9, br/>Thread.sleep(500);"+
            "<<<sp_9, br/>"+
            "<<<sp_8, br/>}<<lightblue/>catch<<white/> (InterruptedException exit) {"+
            "<<<sp_9, br/><<grey/>/* interruption caused by game ended */<<white/>"+
            "<<<sp_9, br/><<orange/>return<<white/>;"+
            "<<<sp_8, br/>}"+
            "<<<sp_8, br/><<lightblue/>catch<<white/> (Exception i) {"+
            "<<<sp_9, br/>p.print<<white/>ln(i.getMessage());"+
            "<<<sp_8, br/>}"+
            "<<<sp_6, br/>}	"+
            "<<<sp_5, br/>};"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/>gameService.start();"+
            "<<<sp_5, br/><<orange/>new<<white/> Thread(<<orange/>new<<white/> gameAnalyser(game.m , gameService , threadlock)).start();"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"

        },
        {
            key : 2,
            FileTitle : "intValidator.java",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> interface intValidator {"+
            "<<<sp_1, br/><<grey/>/* *"+
            "<<<sp_2, br/>* return an integer of some calculation."+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>char<<white/> getInt (Object e);"+
            "<<<sp_0, br/>}"
        },
        {
            key : 3,
            FileTitle : "gameAnalyser.java",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.concurrent.ExecutorService;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.concurrent.locks.ReentrantLock;"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> gameAnalyser <M extends suduku.gameMap<suduku.position>> implements Runnable{"+
            "<<<sp_1, br/>M map;"+
            "<<<sp_1, br/>Thread interruptService;"+
            "<<<sp_1, br/>ReentrantLock threadlock;"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* "+
            "<<<sp_2, br/>* @param map"+
            "<<<sp_2, br/>* @param t : This is the game thread to interrupt once condition of this runnable is met. "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/>gameAnalyser(M map, Thread t, ReentrantLock r ){"+
            "<<<sp_3, br/>gameAnalyser.this.map = map;"+
            "<<<sp_3, br/>interruptService = t;"+
            "<<<sp_3, br/>threadlock = r;"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>@Override"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> run() {"+
            "<<<sp_3, br/>suduku.p.println('analyser has initialised');"+
            "<<<sp_3, br/><<grey/>/* Takes in a map, returns the score result of a row. This is the condition for victory! */<<white/>"+
            "<<<sp_3, br/><<orange/>int<<white/> conditionValidator = <<orange/>new<<white/> <<orange/>int<<white/>Validator() {"+
            "<<<sp_4, br/>"+
            "<<<sp_4, br/>@Override"+
            "<<<sp_4, br/><<orange/>public<<white/> <<orange/>char<<white/> getInt(Object e) {"+
            "<<<sp_5, br/><<orange/>int<<white/> toRet=0;"+
            "<<<sp_5, br/><<lightblue/>if<<white/> ( e.getClass() != suduku.gameMap.<<orange/>class<<white/> ) <<orange/>return<<white/> null;"+
            "<<<sp_5, br/>suduku.gameMap<?> m = ((suduku.gameMap<?>) e);"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = 1; (i-1) < ((suduku.gameMap<?>)e).getX() ; toRet+=i, i++ );"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<orange/>return<<white/> toRet;"+
            "<<<sp_4, br/>}"+
            "<<<sp_4, br/>"+
            "<<<sp_3, br/>}.getInt(map);"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>try {"+
            "<<<sp_4, br/>"+
            "<<<sp_4, br/><<lightblue/>while<<white/> (true) "+
            "<<<sp_4, br/>{"+
            "<<<sp_5, br/>threadlock.lock();"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> y=0; y < map.getY(); y++)"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/><<orange/>int<<white/> total = 0;"+
            "<<<sp_6, br/>for(<<orange/>int<<white/> x =0; x < map.getX(); x++) "+
            "<<<sp_6, br/>{"+
            "<<<sp_7, br/><<orange/>int<<white/>[][] mapping = map.getMap(); "+
            "<<<sp_7, br/>total += mapping[x][y];"+
            "<<<sp_6, br/>}"+
            "<<<sp_6, br/>"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(total == conditionValidator) {"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println(conditionValidator);"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println( '\n\n\n\n\n\n\n\nGame has concluded! You have won!');"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println(map);"+
            "<<<sp_7, br/>interruptService.interrupt();"+
            "<<<sp_7, br/><<orange/>return<<white/>;"+
            "<<<sp_6, br/>}"+
            "<<<sp_6, br/>"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>threadlock.unlock();"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println(map);"+
            "<<<sp_5, br/>Thread.sleep(1000);"+
            "<<<br,hidden/>br"+
            "<<<sp_4, br/>}"+
            "<<<sp_3, br/>}<<lightblue/>catch<<white/>(Exception i) { suduku.p.println('GameAnalyser Interrupted'); }"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"
        },
    ]
}

export default CaroselItem;
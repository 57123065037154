import  {items} from '../../../../../contentCarousel/contentCarosel';
import DemoWrapper from "../../../../../DemoWrapper/DemoWrapper";
import React, { MouseEvent, useRef } from 'react';
import IframeWrapper from "../../../../../IFrame_Container/IFrameContainer";
import MySkills from "../../../../../../BaseProject/MyResume/MySkills/MySkills";

let CaroselItem : items =    {
    key : 1,
    Subject : "BarChart",
    Description : 
        <>
            <p>
                The BarChart component dynamically renders groups of CatgoryItems given by props, 
                each encompassing children items associated by the : name, percentage, and color attribute. 
                Users can navigate through different categories using the navigation bar, and layout adapts responsively to different screen sizes.
                This conponent supports both static and dynamic content rendering, allowing developers to populate the carousel with content from various data sources or APIs
            </p>


            <h3 style = {{textDecoration:'underline'}}> Usage : </h3>
            <p style = {{margin:'0', marginBottom:'50px'}}>   
                {'<BarChart   SubjectItems = {items}/>'  }<br/>
            </p>
        
            <DemoWrapper Title = {"BarChart"}>
                <div style = {{width:'100%',background:'white', overflow:"hidden", padding:'3%', boxSizing:'border-box'}}>
                    <MySkills/>
                </div>
            </DemoWrapper> 
        </>,
    img_Url:"./images/Project_Images/barChart.jpg",
    show_Image : false,
    file_List : [
        {
            key : 1,
            FileTitle : "Barchar.tsx",
            content :
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> React, { <<orange/>useEffect<<white/>, <<orange/>useLayoutEffect<<white/>, <<orange/>useRef<<white/>, <<orange/>useState<<white/> } from 'react';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> DynamicHeading from '../Others/dynamicText';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> {ObjectRef} from '../../../Interfaces/RefObjectInterface';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> StateObject,{StateWrapper} from '../../../Interfaces/stateObjectInterface';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> myBrowser from '../../../Handlers/myBrowser';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> { JsxElement } from 'typescript';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> ScrollableFrame from '../Others/scrollableFrame';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> AnimationBar from '../../animations/AnimationBar/animationBar';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> Styling from './styling.module.css';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> GridFrame from '../Others/gridFrame';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> { channel } from 'diagnostics_channel';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> ImageFrame from '../Others/imageFrame';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> SimpleButton from '../../buttons/simpleButton2/simpleButton2';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> ToggleButton from '../../buttons/ToggleButton/ToggleButton';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> { title } from 'process';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> Stack from '../../../DataStructures/Stack';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> FrameLayout from '../FrameLayout/FrameLayout'"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/* ------------------------------------------ Component Description ------------------------------------------"+
            "<<<sp_3, br/>Renders a bar-chart displaying given items and animated-percentage Bar in association with each item. "+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>This component works in conjunction with the barChartHander class to store state values, and to "+
            "<<<sp_3, br/>coordinate the items to be displayed.  "+
            "<<<sp_3, br/>Bar-Chart has 3 main sections, this is :"+
            "<<<sp_6, br/>1) SubjectItems"+
            "<<<sp_6, br/>2) CategoryItems"+
            "<<<sp_6, br/>3) Elements "+
            "<<<sp_3, br/>where Elements ￢ﾈﾈ CategoryItems ￢ﾈﾈ CategoryItems, As such : the selected SubjectItem will dictate the type"+
            "<<<sp_3, br/>of CategoryItems, and hence elements to be displayed on the bar-Chart."+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>Hence, this component takes as props :"+
            "<<<sp_6, br/>SubjectItems : SubjectItem[]; The array of SubjectItems each containing its own distinct set of"+
            "<<<sp_10, br/>CategoryItem and respective elements to be rendered. "+
            "<<<sp_10, br/>"+
            "<<<sp_3, br/>Note : Current Component only support |SubjectItems| = 2.  "+
            "<<<sp_0, br/>------------------------------------------------------------------------------------------------------------*/<<white/>"+
            "<<<sp_0, br/>export <<orange/>interface<<white/> SubjectItem"+
            "<<<sp_0, br/>{"+
            "<<<sp_3, br/>Name : <<orange/>String<<white/>,"+
            "<<<sp_3, br/>CategoryItems : CategoryItem[];"+
            "<<<sp_0, br/>}"+
            "<<grey/><<<sp_0, br/>/* CategoryItem ￢ﾊﾆ SubjectItems */<<white/>"+
            "<<<sp_0, br/>export <<orange/>interface<<white/> CategoryItem {"+
            "<<<sp_3, br/>Title : string,"+
            "<<<sp_3, br/>elements :  { "+
            "<<<sp_6, br/>name : <<orange/>String<<white/>,"+
            "<<<sp_6, br/>percentage : <<orange/>number<<white/>"+
            "<<<sp_6, br/>color_Theme : string"+
            "<<<sp_3, br/>}[],"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>let<<white/> k:Stack< <<orange/>number<<white/>> = <<orange/>new<<white/> Stack();"+
            "<<grey/><<<sp_0, br/>/* Global Bar-Chart Handler */<<white/>"+
            "<<<sp_0, br/><<orange/>let<<white/> RenderBarChart : React.FC<{SubjectItems : SubjectItem[]}> = (props) : React.ReactElement => "+
            "<<<sp_0, br/>{    "+
            "<<<sp_3, br/><<orange/>let<<white/> [selected_Categoryitem, set_CategoryItem] = <<orange/>useState<<white/><CategoryItem | <<purple/>undefined<<white/>>(props.SubjectItems[0].CategoryItems[0]);"+
            "<<<sp_3, br/><<orange/>let<<white/> [itemHovered, set_ItemHovered] = <<orange/>useState<<white/>< <<orange/>String<<white/> | <<purple/>undefined<<white/>> (<<purple/>undefined<<white/>);"+
            "<<<sp_3, br/><<orange/>let<<white/> moreInfoRef = <<orange/>useRef< <white/><HTMLDivElement>(<<orange/>null<<white/>);"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>useLayoutEffect<<white/>("+
            "<<<sp_6, br/>() => {"+
            "<<<sp_7, br/><<orange/>let<<white/> mouseMoveHandler = (event :MouseEvent) => { "+
            "<<<sp_8, br/><<orange/>let<<white/> mouseX = event.clientX, mouseY = event.clientY;"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/><<lightblue/>if<<white/> (moreInfoRef.current != <<orange/>null<<white/>){"+
            "<<<sp_9, br/>moreInfoRef.current.style.top = `${mouseY-11}px`;"+
            "<<<sp_9, br/>moreInfoRef.current.style.left = `${mouseX-5}px`;"+
            "<<<sp_8, br/>}"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (itemHovered != <<purple/>undefined<<white/>){"+
            "<<<sp_8, br/>window.addEventListener('mousemove', mouseMoveHandler);"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<orange/>return<<white/> () => { "+
            "<<<sp_8, br/>window.removeEventListener('mousemove', mouseMoveHandler);"+
            "<<<sp_7, br/>}"+
            "<<<sp_6, br/>}"+
            "<<<sp_3, br/>);"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>useLayoutEffect<<white/>("+
            "<<<sp_6, br/>() => {modifyLayout();}"+
            "<<<sp_3, br/>)"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* Check if content exist on either side"+
            "<<<sp_4, br/>* @param direction "+
            "<<<sp_4, br/>* @returns "+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>let<<white/> hasContent = (direction:<<orange/>String<<white/>) => { "+
            "<<<sp_6, br/><<lightblue/>if<<white/> (titleContainer_Ref.current != <<purple/>undefined<<white/> && ToptitleContainer_Ref.current != <<purple/>undefined<<white/>){ "+
            "<<<sp_7, br/><<orange/>let<<white/> node = titleContainer_Ref.current as HTMLDivElement;"+
            "<<<sp_7, br/><<orange/>let<<white/> childs = node.children;"+
            "<<<sp_7, br/><<orange/>let<<white/> firstChild = childs[0] as HTMLElement;                "+
            "<<<sp_7, br/><<orange/>let<<white/> lastChild = childs[childs.length-2] as HTMLElement;                "+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (direction === 'left')  <<orange/>return<<white/>  (( firstChild.offsetLeft) < titleOffset.current) ;"+
            "<<<sp_7, br/><<lightblue/>else<<white/> <<orange/>return<<white/> (lastChild.offsetLeft + lastChild.offsetWidth) > (titleOffset.current +  node.clientWidth );"+
            "<<<sp_6, br/>}"+
            "<<<sp_6, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>let<<white/> modifyLayout = () => { "+
            "<<<sp_6, br/><<lightblue/>if<<white/> (titleContainer_Ref.current != <<purple/>undefined<<white/> && ToptitleContainer_Ref.current != <<purple/>undefined<<white/> && rightArrow_Ref.current != <<purple/>undefined<<white/> && leftArrow_Ref.current != <<purple/>undefined<<white/>){"+
            "<<<sp_7, br/><<orange/>let<<white/> hasLeft = hasContent('left'), hasRight = hasContent('right');"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (hasLeft && hasRight)   {"+
            "<<<sp_8, br/><<grey/>// ToptitleContainer_Ref.current.style.gridTemplateColumns = '2% 96% 2%';"+
            "<<white/><<<sp_8, br/>titleContainer_Ref.current.style.borderRight = '0.5px solid grey';"+
            "<<<sp_8, br/>titleContainer_Ref.current.style.borderLeft = '0.5px solid grey';"+
            "<<<sp_8, br/>rightArrow_Ref.current.style.width = '30px';"+
            "<<<sp_8, br/>leftArrow_Ref.current.style.width = '30px';"+
            "<<<sp_7, br/>} "+
            "<<<sp_7, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (hasLeft){"+
            "<<<sp_8, br/><<grey/>// ToptitleContainer_Ref.current.style.gridTemplateColumns = '2% 98% 0%';"+
            "<<white/><<<sp_8, br/>titleContainer_Ref.current.style.borderLeft = '0.5px solid grey';"+
            "<<<sp_8, br/>titleContainer_Ref.current.style.borderRight = 'none'; "+
            "<<<sp_8, br/>rightArrow_Ref.current.style.width = '0px';"+
            "<<<sp_8, br/>leftArrow_Ref.current.style.width = '30px'; "+
            "<<<sp_7, br/>}   "+
            "<<<sp_7, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (hasRight) {"+
            "<<<sp_8, br/><<grey/>// ToptitleContainer_Ref.current.style.gridTemplateColumns = '0% 98% 2%';"+
            "<<white/><<<sp_8, br/>titleContainer_Ref.current.style.borderRight = '0.5px solid grey';"+
            "<<<sp_8, br/>titleContainer_Ref.current.style.borderLeft = 'none'; "+
            "<<<sp_8, br/>rightArrow_Ref.current.style.width = '30px';"+
            "<<<sp_8, br/>leftArrow_Ref.current.style.width = '0px';"+
            "<<<sp_7, br/>}                "+
            "<<<sp_7, br/><<lightblue/>else<<white/>{"+
            "<<<sp_8, br/><<grey/>// ToptitleContainer_Ref.current.style.gridTemplateColumns = '0% 100% 0%';"+
            "<<white/><<<sp_8, br/>titleContainer_Ref.current.style.borderLeft = 'none';"+
            "<<<sp_8, br/>titleContainer_Ref.current.style.borderRight = 'none'; "+
            "<<<sp_8, br/>rightArrow_Ref.current.style.width = '0px';"+
            "<<<sp_8, br/>leftArrow_Ref.current.style.width = '0px';"+
            "<<<sp_7, br/>}                       "+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>useLayoutEffect<<white/>("+
            "<<<sp_6, br/>() => {"+
            "<<<sp_7, br/><<orange/>let<<white/> resizeHandler = () => { "+
            "<<<sp_8, br/>set_CategoryItem(props.SubjectItems[0].CategoryItems[0]);"+
            "<<<sp_8, br/>modifyLayout();"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/>window.addEventListener('resize', resizeHandler);"+
            "<<<sp_7, br/><<orange/>return<<white/> () => {"+
            "<<<sp_8, br/>window.removeEventListener('resize', resizeHandler);"+
            "<<<sp_7, br/>}"+
            "<<<sp_6, br/>},"+
            "<<<sp_3, br/>)"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>useLayoutEffect<<white/>("+
            "<<<sp_6, br/>()=>{"+
            "<<<sp_7, br/>transition('right', selected_Categoryitem?.Title);"+
            "<<<sp_6, br/>},[selected_Categoryitem]"+
            "<<<sp_3, br/>)"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>let<<white/> titleContainer_Ref = <<orange/>useRef<<white/><HTMLDivElement>(<<orange/>null<<white/>);"+
            "<<<sp_3, br/><<orange/>let<<white/> titleOffset = <<orange/>useRef<<white/>< < <orange/>number<<white/>> (0);"+
            "<<<sp_3, br/><<orange/>let<<white/> rightArrow_Ref = <<orange/>useRef<<white/><HTMLDivElement>(<<orange/>null<<white/>);"+
            "<<<sp_3, br/><<orange/>let<<white/> leftArrow_Ref = <<orange/>useRef<<white/><HTMLDivElement>(<<orange/>null<<white/>);"+
            "<<<sp_3, br/><<orange/>let<<white/> firstElement = <<orange/>useRef<<white/>< < <orange/>String<<white/> | <<purple/>undefined<<white/>>(selected_Categoryitem?.Title);"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>let<<white/> transition = ( direction : <<orange/>String<<white/>, itemName ?:<<orange/>String<<white/> ) => { "+
            "<<<sp_6, br/><<grey/>// titlecontainer width"+
            "<<white/><<<sp_6, br/><<orange/>let<<white/> width = titleContainer_Ref.current?.clientWidth;"+
            "<<<sp_6, br/><<lightblue/>if<<white/> (width != <<purple/>undefined<<white/>){"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (titleContainer_Ref.current != <<purple/>undefined<<white/>){"+
            "<<<sp_8, br/><<orange/>let<<white/> node = titleContainer_Ref.current as HTMLDivElement; <<grey/>// title container node"+
            "<<white/><<<sp_8, br/><<orange/>let<<white/> childs = node.children;                              <<grey/>// titles "+
            "<<white/><<<sp_8, br/><<grey/>// iterate titles"+
            "<<white/><<<sp_8, br/><<lightblue/>for<<white/>(<<orange/>let<<white/> i =0; i < childs.length; i++) {"+
            "<<<sp_9, br/><<orange/>let<<white/> child = childs[i] as HTMLElement;"+
            "<<<sp_9, br/>"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (itemName != <<purple/>undefined<<white/>)"+
            "<<<sp_9, br/>{"+
            "<<<sp_11, br/><<grey/>// if title is selected"+
            "<<white/><<<sp_11, br/><<lightblue/>if<<white/> (child.innerText === itemName){"+
            "<<<sp_12, br/>titleOffset.current = child.offsetLeft-20;"+
            "<<<sp_12, br/><<orange/>break<<white/>;"+
            "<<<sp_11, br/>}"+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> ( ( (child.offsetLeft + child.offsetWidth) - titleOffset.current) > node.clientWidth && child.innerText != ''){"+
            "<<<sp_12, br/><<grey/>// console.log('overflow item is : ' + child.innerText);"+
            "<<white/><<<sp_12, br/><<grey/>// if (child.offsetWidth >= width) titleOffset.current = child.offsetWidth;"+
            "<<white/><<<sp_12, br/><<grey/>// else "+
            "<<white/><<<sp_12, br/><<lightblue/>if<<white/> (firstElement.current == child.innerText){"+
            "<<<sp_14, br/>titleOffset.current += child.offsetWidth;"+
            "<<<sp_12, br/>}<<lightblue/>else<<white/>{"+
            "<<<sp_14, br/>titleOffset.current = child.offsetLeft;"+
            "<<<sp_14, br/>firstElement.current = child.innerText;"+
            "<<<sp_12, br/>}"+
            "<<<sp_12, br/><<orange/>break<<white/>;"+
            "<<<sp_9, br/>}"+
            "<<<sp_8, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (direction === 'right') {"+
            "<<<sp_8, br/>titleContainer_Ref.current.scrollTo({"+
            "<<<sp_9, br/>left: titleOffset.current,"+
            "<<<sp_9, br/>behavior: 'smooth'"+
            "<<<sp_8, br/>});"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<lightblue/>else<<white/> {"+
            "<<<sp_8, br/>titleOffset.current = 0;"+
            "<<<sp_8, br/>titleContainer_Ref.current.scrollTo({"+
            "<<<sp_9, br/>left: 0,"+
            "<<<sp_9, br/>behavior: 'smooth'"+
            "<<<sp_8, br/>});"+
            "<<<sp_7, br/>} "+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/>modifyLayout();"+
            "<<<sp_7, br/>}"+
            "<<<sp_6, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>let<<white/> ToptitleContainer_Ref = <<orange/>useRef<<white/><HTMLDivElement>(<<orange/>null<<white/>);"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>return<<white/> ("+
            "<<<sp_6, br/><>"+
            "<<<sp_7, br/><div style = {{width:'100%', position:'relative',}}>"+
            "<<<sp_8, br/><div ref ={moreInfoRef} className={Styling.overlayTitle} style = {{ color:'white', fontSize:'12px', transform: 'translate(-50%,-100%)',  display:`${itemHovered != <<purple/>undefined<<white/> ? 'block' : 'none'} `}}> "+
            "<<<sp_9, br/>{ itemHovered != <<purple/>undefined<<white/> && <> { itemHovered } </> }"+
            "<<<sp_9, br/>"+
            "<<<sp_8, br/></div>"+
            "<<<sp_8, br/><div ref = {leftArrow_Ref}  onMouseDown={() => { transition('left')}} className={Styling.arrowContainer} style = {{ transform :'translate(-100%,0%)', left:'0'}} >"+
            "<<<sp_9, br/><svg fill='black' height='100%' width='100%' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 330.002 330.002' stroke='#ffffff'><g id='SVGRepo_bgCarrier' stroke-width='0'></g><g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g><g id='SVGRepo_iconCarrier'> <path id='XMLID_227_' d='M233.25,306.001L127.5,165.005L233.25,24.001c4.971-6.628,3.627-16.03-3-21c-6.627-4.971-16.03-3.626-21,3 L96.75,156.005c-4,5.333-4,12.667,0,18l112.5,149.996c2.947,3.93,7.451,6.001,12.012,6.001c3.131,0,6.29-0.978,8.988-3.001 C236.878,322.03,238.221,312.628,233.25,306.001z'></path> </g></svg>"+
            "<<white/><<<sp_8, br/></div>"+
            "<<<sp_8, br/><div ref = {rightArrow_Ref}  onMouseDown={()=>{transition('right')}} className={Styling.arrowContainer} style = {{right:'0', transform :'translate(100%,0%)',}} > "+
            "<<<sp_9, br/><svg fill='black' height='100%' width='100%' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 330 330'  stroke='#ffffff'><g id='SVGRepo_bgCarrier' stroke-width='0'></g><g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g><g id='SVGRepo_iconCarrier'> <path id='XMLID_222_' d='M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z'></path> </g></svg>"+
            "<<white/><<<sp_8, br/></div>"+
            "<<<sp_9, br/>"+
            "<<<sp_8, br/><div ref ={ToptitleContainer_Ref} className={Styling.title_TopContainer}>"+
            "<<<sp_9, br/><div ref = {titleContainer_Ref} className={Styling.titleContainer} > "+
            "<<<sp_11, br/>{"+
            "<<<sp_12, br/>props.SubjectItems[0].CategoryItems.map("+
            "<<<sp_14, br/>(item : CategoryItem) => {"+
            "<<<sp_15, br/><<orange/>let<<white/> isSelected : <<orange/>boolean <<white/>=  (item.Title === selected_Categoryitem?.Title);"+
            "<<<br,hidden/>br"+
            "<<<sp_15, br/><<orange/>return<<white/> <>"+
            "<<<sp_16, br/><span "+
            "<<<sp_17, br/>className= {Styling.itemTitle}"+
            "<<<sp_17, br/>style = {{paddingBottom:'9px', borderBottom: `${isSelected ? 'solid 2px black' :'solid 0.5px black'}`,   cursor:'pointer',  color : `${ (isSelected) ? 'black' : 'grey' }`, fontWeight: `${(isSelected) ? '500' : '300'}`}}"+
            "<<<sp_17, br/>onMouseDown={() => {set_CategoryItem(item); transition('right', item.Title) }}>{item.Title}"+
            "<<<sp_16, br/></span>"+
            "<<<sp_16, br/><span style ={{paddingBottom:'9px', borderBottom:'solid 0.1px black', paddingLeft:'20px'}}/>"+
            "<<<sp_15, br/></>;"+
            "<<<sp_14, br/>}"+
            "<<<sp_12, br/>)"+
            "<<<sp_11, br/>}"+
            "<<<sp_9, br/></div>      "+
            "<<<sp_9, br/>{<<grey/>/* <div  ref = {rightArrow_Ref} onMouseDown={()=>{transition('right')}} className={Styling.arrowContainer}> "+
            "<<<sp_11, br/><svg fill='#ffffff' height='100%' width='100%' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 330 330'  stroke='#ffffff'><g id='SVGRepo_bgCarrier' stroke-width='0'></g><g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g><g id='SVGRepo_iconCarrier'> <path id='XMLID_222_' d='M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z'></path> </g></svg>"+
            "<<<sp_9, br/></div> */<<white/>}"+
            "<<<sp_8, br/></div>"+
            "<<<sp_8, br/>"+
            "<<<sp_8, br/><div className={Styling.mainContainer}>"+
            "<<<sp_9, br/><div className={Styling.innerContainer}>"+
            "<<<sp_11, br/>{<<grey/>/* <div style = {{width:'100%', background:'blue'}}> kkk </div>"+
            "<<<sp_11, br/><div style = {{width:'100%', background:'blue'}}> kkk </div> */<<white/>}"+
            "<<<sp_11, br/>{"+
            "<<<sp_12, br/>(selected_Categoryitem != <<purple/>undefined<<white/>) && <> "+
            "<<<sp_14, br/>{"+
            "<<<sp_15, br/>selected_Categoryitem.elements.map( (item : {name : <<orange/>String<<white/>, percentage : <<orange/>number<<white/>, color_Theme:string}) => {"+
            "<<<sp_17, br/><<orange/>return<<white/> <>  "+
            "<<<sp_18, br/><div className={Styling.ElementContainer} onMouseOver={(event : React.MouseEvent) => {set_ItemHovered(item.name)}} "+
            "<<<sp_19, br/>onMouseLeave={ (event:React.MouseEvent) =>{ set_ItemHovered(<<purple/>undefined<<white/>)}} >"+
            "<<<sp_19, br/><div className={Styling.itemNameContainer}>"+
            "<<<sp_20, br/><div className={Styling.itemName} >"+
            "<<<sp_21, br/><p> {item.name} </p>"+
            "<<<sp_20, br/></div>"+
            "<<<sp_19, br/></div>"+
            "<<<sp_19, br/><div style = {{width:'100%', height :'60px'}}>"+
            "<<<sp_20, br/><AnimationBar percentage={item.percentage} color_Theme={item.color_Theme} />"+
            "<<<sp_19, br/></div>"+
            "<<<sp_18, br/></div> "+
            "<<<sp_17, br/></>   "+
            "<<<sp_16, br/>}"+
            "<<<sp_15, br/>)"+
            "<<<sp_14, br/>}"+
            "<<<sp_12, br/></>"+
            "<<<sp_11, br/>}"+
            "<<<sp_9, br/></div>"+
            "<<<sp_8, br/></div>"+
            "<<<sp_7, br/></div>"+
            "<<<sp_6, br/></> "+
            "<<<sp_3, br/>)"+
            "<<<sp_0, br/>}"+
            ""
            
        },
        {
            key : 1,
            FileTitle : "styling.css",
            content :
            "<<grey/><<<sp_0, br/>/* @media (orientation: landscape) { */<<white/>"+
            "<<<sp_0, br/>@media only screen and (min-width: 1000px ){"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>.innerContainer{"+
            "<<<sp_4, br/>grid-template-columns: 50% 50%;"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/>.titleContainer{"+
            "<<<sp_4, br/>font-size: clamp(20px, 0.8vw, 100px);"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/>.itemTitle{"+
            "<<<sp_4, br/>font-size: clamp(2px, 1vw, 100px);"+
            "<<<sp_2, br/>}"+
            "<<<sp_0, br/>}"+
            "<<<sp_1, br/>"+
            "<<grey/><<<sp_0, br/>/* @media (orientation: portrait) { */<<white/>"+
            "<<<sp_0, br/>@media only screen and (max-width: 1000px ){"+
            "<<<sp_2, br/>.innerContainer{"+
            "<<<sp_4, br/>grid-template-columns: 100%;"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/>.titleContainer{"+
            "<<<sp_4, br/>font-size: clamp(15px, 2vw, 100px) !important;"+
            "<<<sp_2, br/>}"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/* "+
            "<<<sp_0, br/>@media only screen and (min-width: 1000px) {"+
            "<<<sp_2, br/>.innerContainer{"+
            "<<<sp_4, br/>grid-template-columns: 50% 50%;"+
            "<<<sp_2, br/>}"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>@media only screen and (max-width: 1000px) {"+
            "<<<sp_2, br/>.innerContainer{"+
            "<<<sp_4, br/>grid-template-columns: 100%;"+
            "<<<sp_2, br/>}"+
            "<<<sp_0, br/>} */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.title_TopContainer{"+
            "<<<sp_2, br/>width: 100%;"+
            "<<<sp_2, br/>display: flex;"+
            "<<<sp_2, br/>transition: 0.5s all;"+
            "}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.mainContainer{"+
            "<<<sp_2, br/>width: 100%;"+
            "<<<sp_2, br/>padding: 2%;"+
            "<<<sp_2, br/><<grey/>/* padding-bottom: 5%; */<<white/>"+
            "<<<sp_2, br/>box-sizing: border-box;"+
            "<<<sp_2, br/>background-color: #f4f6f8!important;"+
            "<<<sp_2, br/>border-radius: 0.2em;"+
            "<<<sp_2, br/>font-size: 'Nunito Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.innerContainer{"+
            "<<<sp_2, br/>display: grid;"+
            "<<<sp_2, br/><<grey/>/* gap :10%; */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.arrowContainer{"+
            "<<<sp_2, br/>position: absolute;"+
            "<<<sp_2, br/>top: 0;"+
            "<<<sp_3, br/>opacity: 1;"+
            "<<<sp_2, br/>height: 30px;"+
            "<<<sp_2, br/>height: 30px;"+
            "<<<sp_2, br/>overflow: hidden;"+
            "<<<sp_2, br/>display: inline-block;"+
            "<<<sp_2, br/>padding-bottom: 13px;"+
            "<<<sp_2, br/>padding-top: 5px;"+
            "<<<sp_2, br/>box-sizing: border-box;"+
            "<<<sp_2, br/>transition: all 0.5s;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.titleContainer{"+
            "<<<sp_2, br/>padding-bottom: 13px;"+
            "<<<sp_2, br/>display: block;"+
            "<<<sp_2, br/>padding-top: 5px;"+
            "<<<sp_2, br/>box-sizing: border-box;"+
            "<<<sp_2, br/>text-wrap: nowrap;"+
            "<<<sp_2, br/>white-space: nowrap;"+
            "<<<sp_2, br/>margin-bottom: 10px;"+
            "<<<sp_2, br/>width: 100%;"+
            "<<<sp_2, br/>overflow: hidden;"+
            "<<<sp_2, br/>transition: 1s all;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.itemNameContainer{"+
            "<<<sp_2, br/>width: 100%;"+
            "<<<sp_2, br/>height: auto;"+
            "<<<sp_2, br/>overflow: hidden;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.itemName p{"+
            "<<<sp_2, br/>font-size: 15px ;"+
            "<<<sp_2, br/>font-weight: 500;"+
            "<<<sp_2, br/>display: -webkit-box;"+
            "<<<sp_2, br/>-webkit-line-clamp: 1;"+
            "<<<sp_2, br/>-webkit-box-orient: vertical;"+
            "<<<sp_2, br/>overflow: hidden;"+
            "<<<sp_2, br/>padding: 0;"+
            "<<<sp_2, br/>margin: 0;"+
            "<<<sp_2, br/>font-size: .875rem;"+
            "<<<sp_2, br/><<grey/>/* background-color: red; */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.overlayTitle{"+
            "<<<sp_2, br/>background-color: black;"+
            "<<<sp_2, br/>opacity: 0.9;"+
            "<<<sp_2, br/>padding: 10px;"+
            "<<<sp_2, br/>border-radius: 1em;"+
            "<<<sp_2, br/>box-sizing: border-box;"+
            "<<<sp_2, br/>border : 0.1px solid white;"+
            "<<<sp_2, br/>z-index: 100;"+
            "<<<sp_2, br/>position: fixed;"+
            "<<<sp_0, br/>}"+
            "<<<sp_1, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.ElementContainer{"+
            "<<<sp_2, br/>font-size: 17px;"+
            "<<<sp_2, br/>color: #212529;"+
            "<<<sp_2, br/>font :'Nunito Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';"+
            "<<<sp_2, br/>line-height: 1.6;"+
            "<<<sp_2, br/>font-weight: bolder;"+
            "<<<sp_2, br/>width: 100%;"+
            "<<<sp_2, br/>box-sizing: border-box;"+
            "<<<sp_2, br/>padding: 5%;"+
            "<<<sp_2, br/>transition: all 1s;"+
            "<<<sp_2, br/>cursor:none;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.ElementContainer:hover{"+
            "<<<sp_2, br/><<grey/>/* background-color: red; */<<white/>"+
            "<<<sp_2, br/>border :0.1px solid black;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.itemTitle{"+
            "<<<sp_2, br/>transition: all 0.2s;"+
            "<<<sp_2, br/>padding-right: 0.5vw; "+
            "<<<sp_2, br/><<grey/>/* font-size: clamp(2px, 1vw, 100px); */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.itemTitle:hover{"+
            "<<<sp_2, br/>font-weight: 400 !important;}"
        }




    ]
}

export default CaroselItem;
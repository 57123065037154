import { useRef, useLayoutEffect, useEffect, useState } from "react";
import "./Styling.css"


let RenderPage : React.FC<{PageURL : String}> = (props) => {


    let iframeRef = useRef<HTMLIFrameElement>(null);
    let [scrolbar_Width, setScrollbarWidth] = useState<number>(0);

    // useLayoutEffect(() => {
    //     const getScrollbarWidth = () => {
    //       const iframe = iframeRef.current;
    //       if (iframe) {
    //         const iframeDocument = iframe.contentDocument;
    //         if (iframeDocument) {
    //           const clientWidth = iframeDocument.documentElement.clientWidth;
    //           const scrollWidth = iframeDocument.documentElement.scrollWidth;
    //           const widthDifference = scrollWidth - clientWidth
    //           setScrollbarWidth(widthDifference);
    //         }

    //       }
    //     };
    
    //     // Call the function once to get scrollbar width on initial load
    
    //     // Listen for resize events to recalculate scrollbar width if the content changes

    //     if(iframeRef.current != undefined) {
    //         iframeRef.current.addEventListener("load", () => {
    //             window.addEventListener('resize', getScrollbarWidth);
    //             getScrollbarWidth();
    //         });
    //     }

    
    //     // Clean up event listener on component unmount
    //     return () => {
    //       window.removeEventListener('resize', getScrollbarWidth);
    //     };
    //   }, []);
    
     
    console.error("SCROLLBAR WIDTH = " + scrolbar_Width);
    let [containerSize, set_ContainerSize] = useState<{Width : number, Height : number}> ({Width:0, Height:0});

    
    // useLayoutEffect(
    //     () => {
    //         if (container_Ref.current != undefined){
    //             let w = container_Ref.current.clientWidth;
    //             let h = container_Ref.current.clientHeight;
    //             if (containerSize.Width != w || containerSize.Height != h){
    //                 set_ContainerSize({Width:w, Height:h});
    //             }

    //         }
    //     }
    // )

    let container_Ref = useRef<HTMLDivElement>(null);
    // console.log("CONTAINER W = " + containerSize.Width + " HEIGHT = " + containerSize.Height);
    // console.log("Barwidth = " + scrolbar_Width);

    return(
        <>
            <div ref ={container_Ref} style = {{width:'100%', height :'100%', background:'none', overflow:'hidden'}}>
                {/* <div style = {{width:`${containerSize.Width + scrolbar_Width}px`, height:`${containerSize.Height + scrolbar_Width}px`}}> */}
                    <iframe
                        ref = {iframeRef}
                        src={props.PageURL.toString()}
                        width= "100%"
                        height="100%"
                        frameBorder="0 !important"
                        scrolling="auto" 
                    />

                </div>
            {/* </div> */}

        </>
    );
}


export default RenderPage;
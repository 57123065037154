import myBrowser from "../../../Handlers/myBrowser";
import Styling from "./Styling.module.css";
import { useLayoutEffect, useRef, useState} from "react";



let RenderScrollBar : React.FC<{ children : React.ReactNode , isDarkMode : boolean}> = (props) => {
let widthDimension = useRef<HTMLParagraphElement>(null);
let [isScaling, set_isScaling] = useState<boolean>(false);

  // HANDLE scaling
  useLayoutEffect(
    () => { 
        let isTransition = false;
        let scrollTimeout: NodeJS.Timeout | undefined;

        if (container_Ref.current != undefined){
            let TransitionStart_Handler = () => { 
                set_isScaling(true);
           }


            let transitionEnd = (event : TransitionEvent) => { 

                    set_isScaling(false);
                    container_Ref.current?.removeEventListener('transitionstart', TransitionStart_Handler);
                    container_Ref.current?.removeEventListener('transitionend', transitionEnd);
                    isTransition = false;
            }

            let handleDimension_Display = () => { 
                if (widthDimension.current != undefined && container_Ref.current != undefined){
                    widthDimension.current.innerText = container_Ref.current.clientWidth.toString();
                }
            }

            let resizeHandler = () => { 

                // 
                handleDimension_Display();


                // initialise transiiton handler                    
                if (container_Ref.current != undefined && !isTransition){
                    isTransition = true;
                    container_Ref.current.addEventListener('transitionstart', TransitionStart_Handler);
                    container_Ref.current.addEventListener('transitionend', transitionEnd);
                }

            }

            window.addEventListener("resize", resizeHandler);
            return () => {
                window.removeEventListener("resize", resizeHandler);
                container_Ref.current?.removeEventListener('transitionstart', TransitionStart_Handler);
                container_Ref.current?.removeEventListener('transitionend', transitionEnd);

            }
        }
    },[]);


    let container_Ref = useRef<HTMLDivElement> (null); 
    let lineColor_Property : React.CSSProperties = {  
            border :  `${!props.isDarkMode ? "1px solid rgba(221, 234, 248, .09)" : "1px solid black"}`,
            color :  `${!props.isDarkMode ? "1px solid rgba(221, 234, 248, .09)" : "1px solid black"}`
    };


    return (
        <>
            <div ref = {container_Ref} className={Styling.Framecontainer} style = {{...lineColor_Property}}>
                <div style = {{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%', left:'0', top:'0', position:'absolute'}}>
                    <div className={Styling.centerLine}  style = {{ opacity:`${!isScaling ? "0" : "1"}`,  borderBottom:`${!props.isDarkMode ? "0.5px solid white" : "1px solid black"}`}} >
                        <p style = {{color :`${props.isDarkMode ? "black" : "white"}`}} ref = {widthDimension}/>
                    </div>
                </div>
                <div style = {{opacity:`${isScaling ? "0" : "1"}`}}>
                    {props.children}
                </div>
                    
                <svg className={Styling.dot + ' ' + Styling.topLeft} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                <svg className={Styling.dot + ' ' + Styling.topRight} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                <svg className={Styling.dot + ' ' + Styling.bottomLeft} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
                <svg className={Styling.dot + ' ' + Styling.bottomRight} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>
            </div>
        </>
    )
}


export default RenderScrollBar;
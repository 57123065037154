import  {items} from '../../../../contentCarousel/contentCarosel';

 
let CaroselItem : items =    {
    key : 1,
    Subject : "Nineboard TickTacToe",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}

            <p> 


                This project presents an intelligent solution for playing Nine-Board Tic-Tac-Toe, a variation of the classic game. 
                
                Leveraging object-oriented programming (OOP), alongside minimax and A* search algorithms, it streamlines decision-making and optimizes branch prioritization for 
                intelligent decision implemented for the AI-Player.

                The game incorporates human, AI, and random players, each with their own distinct strategies and decision-making processes.


            </p>
            <h3 style = {{textDecoration:'underline'}}> Game Description : </h3>
            <p>
                The Nine-Board Tic-Tac-Toe game comprises nine identical tic-tac-toe boards arranged in a 3x3 grid. 
                Initially, the first player (X) selects a board to play, such as the top-left corner or board (1,1). 
                Subsequent moves dictate the board for the next player (O), ensuring strategic placements across the interconnected boards. 
            </p>
            <p>
                If, for instance, X makes a move at row 2, column 3 on a chosen board, O must respond on the corresponding board at row 2, column 3.
                This pattern continues, with players taking turns until one achieves three in a row on any board, signaling victory and ending the game. 
                If a player is compelled to move to a full board, they can select any other available board. A draw occurs if all boards are full without a winner.
            </p>
        </>,
    img_Url:"./images/Project_Images/9board_Tiktactoe.jpg",    
    file_List : [
        {
            key : 1,
            FileTitle : "main.cpp",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <iostream>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <cstdlib>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'NineBoardGame.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'NBGame.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'AiPlayer.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'TicTacToe.h'"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>int<<white/> main() { "+
            "<<<sp_1, br/><<orange/>int<<white/> randNo = <<orange/>static<<white/>_cast<unsigned>(time(<<orange/>nullptr<<white/>));"+
            "<<<sp_1, br/><<orange/>cout<<white/> <- 'RandSeed : ' <- randNo <- endl;"+
            "<<<sp_1, br/>srand(randNo);"+
            "<<<sp_1, br/>NBGame* nineboard_game = <<orange/>new<<white/> NBGame();"+
            "<<<sp_1, br/>nineboard_game->Play();"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>return<<white/> 0;"
        },
        {
            key : 2,
            FileTitle : "AIPlayer.h",
            content : 



            "<<white/><<<sp_0, br/><<lightblue/>#ifndef<<white/> AIPLAYER_H"+
            "<<<sp_0, br/><<lightblue/>#define<<white/> AIPLAYER_H"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'Player.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <queue>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>using namespace<<white/> std;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>class<<white/> AIPlayer : <<orange/>public<<white/> Player { "+
            "<<<sp_3, br/><<orange/>private<<white/> :"+
            "<<<sp_5, br/><<orange/>class<<white/> priorityBoard{"+
            "<<<sp_9, br/><<orange/>public<<white/> : "+
            "<<<sp_13, br/><<orange/>int<<white/> priority;"+
            "<<<sp_13, br/><<orange/>int<<white/> boardNo; "+
            "<<<sp_13, br/>priorityBoard(<<orange/>int<<white/> priority, <<orange/>int<<white/> boardNo) : priority(priority), boardNo(boardNo) {}"+
            "<<<br,hidden/>br"+
            "<<<sp_13, br/><<grey/>// Overload the '<' operator to compare items based on priority"+
            "<<white/><<<sp_13, br/><<orange/>bool<<white/> operator<(<<lightblue/>const<<white/> priorityBoard& other) <<lightblue/>const<<white/> {"+
            "<<<sp_15, br/><<grey/>// Higher priority items should appearbefore lower"+
            "<<white/><<<sp_15, br/><<orange/>return<<white/> priority < other.priority;"+
            "<<<sp_13, br/>}"+
            "<<<sp_6, br/>};"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<orange/>int<<white/> depth;"+
            "<<<sp_6, br/><<orange/>int<<white/> MAXDEPTH;"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>/** Depth size is a fucntion of the boardSize. Scale with size of running board */<<white/>"+
            "<<<sp_6, br/>double getDepth(NineBoardGame * gameBoard) {"+
            "<<<sp_9, br/><<orange/>int<<white/> freeBoards =0;"+
            "<<<sp_9, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < 9; i++)"+
            "<<<sp_13, br/><<lightblue/>if<<white/> (gameBoard->GameBoard[i].gameStatus() <= 2) <<grey/>// gameboard is finished"+
            "<<white/><<<sp_15, br/>freeBoards++;"+
            "<<<sp_13, br/>"+
            "<<<sp_9, br/><<orange/>return<<white/> freeBoards;"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>/**  "+
            "<<<sp_6, br/>* Return 1 IFF a winning position exist for the player 'playerNo' in the given board,"+
            "<<<sp_6, br/>* else 0 is returned. "+
            "<<<sp_6, br/>* "+
            "<<<sp_6, br/>* x,y is also given if win exist, else -1 will be assigned to ,x,y."+
            "<<<sp_6, br/>*/<<white/>"+
            "<<<sp_6, br/><<orange/>int<<white/> boardHasWin(<<orange/>int<<white/> **board, <<orange/>int<<white/> playerNo, <<orange/>int<<white/> &x, <<orange/>int<<white/> &y)"+
            "<<<sp_6, br/>{"+
            "<<<sp_9, br/>x = -1; "+
            "<<<sp_9, br/>y = -1; "+
            "<<<sp_9, br/><<orange/>int<<white/> L_DCounter = 0;"+
            "<<<sp_9, br/><<orange/>int<<white/> R_DCounter = 0;"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < 3; i++)"+
            "<<<sp_9, br/>{"+
            "<<<sp_13, br/><<grey/>// CHECK ROW"+
            "<<white/><<<sp_13, br/><<orange/>int<<white/> rowCount = 0;"+
            "<<<sp_13, br/><<orange/>int<<white/> colCount = 0;"+
            "<<<sp_13, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> j=0; j < 3; j++)                           "+
            "<<<sp_13, br/>{"+
            "<<<sp_15, br/><<lightblue/>if<<white/> (board[i][j] == playerNo) colCount++;          "+
            "<<<sp_15, br/><<lightblue/>if<<white/> (board[j][i] == playerNo) rowCount++;"+
            "<<<br,hidden/>br"+
            "<<<sp_15, br/><<grey/>// IF HAS >= 2 SCORE IN COLUMN"+
            "<<white/><<<sp_15, br/><<lightblue/>if<<white/> (colCount >= 2) {"+
            "<<<sp_19, br/><<grey/>// find winning column number (if any)"+
            "<<white/><<<sp_19, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> k =0; k < 3; k++) "+
            "<<<sp_23, br/><<lightblue/>if<<white/> (board[i][k] != playerNo) {"+
            "<<<sp_27, br/><<lightblue/>if<<white/> (board[i][k] != 0) <<orange/>break<<white/>;                <<grey/>// if not empty position (cannot win in column)"+
            "<<white/><<<sp_27, br/><<lightblue/>else<<white/> {"+
            "<<<sp_31, br/>y = k;"+
            "<<<sp_31, br/>x = i;"+
            "<<<sp_31, br/><<orange/>return<<white/> 1;"+
            "<<<sp_27, br/>}"+
            "<<<sp_23, br/>}"+
            "<<<sp_15, br/>}"+
            "<<<sp_15, br/>"+
            "<<<sp_15, br/><<lightblue/>if<<white/>(rowCount >= 2) {"+
            "<<<sp_19, br/><<grey/>// find winning row number (if any)"+
            "<<white/><<<sp_19, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> k =0; k < 3; k++) {"+
            "<<<sp_23, br/><<lightblue/>if<<white/> (board[k][i] != playerNo) {   "+
            "<<<sp_27, br/><<lightblue/>if<<white/> (board[k][i] != 0)  <<orange/>break<<white/>;                                      <<grey/>// empty position taken by enemy"+
            "<<white/><<<sp_27, br/><<lightblue/>else<<white/>"+
            "<<<sp_27, br/>{"+
            "<<<sp_31, br/>x = k;"+
            "<<<sp_31, br/>y = i;"+
            "<<<sp_31, br/><<orange/>return<<white/> 1;"+
            "<<<sp_27, br/>}"+
            "<<<sp_23, br/>}"+
            "<<<sp_19, br/>}"+
            "<<<sp_15, br/>}"+
            "<<<sp_13, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_13, br/><<grey/>// CHECK DIAGONALLY"+
            "<<white/><<<sp_13, br/><<lightblue/>if<<white/> (board[i][i] == playerNo) L_DCounter++;"+
            "<<<sp_13, br/><<lightblue/>if<<white/> (board[2-i][i] == playerNo) R_DCounter++;"+
            "<<<sp_13, br/><<lightblue/>if<<white/> (L_DCounter >= 2) {"+
            "<<<sp_15, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < 3; i++) "+
            "<<<sp_19, br/><<lightblue/>if<<white/> (board[i][i] != playerNo) {"+
            "<<<sp_23, br/><<lightblue/>if<<white/> (board[i][i] != 0) <<orange/>break<<white/>;                         <<grey/>// empty position taken by enemy"+
            "<<white/><<<sp_23, br/>x = i;"+
            "<<<sp_23, br/>y = i;"+
            "<<<sp_23, br/><<orange/>return<<white/> 1;"+
            "<<<sp_19, br/>} "+
            "<<<sp_13, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_13, br/><<lightblue/>if<<white/> (R_DCounter >= 2) {"+
            "<<<sp_16, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < 3; i++) "+
            "<<<sp_19, br/><<lightblue/>if<<white/> (board[2-i][i] != playerNo) {"+
            "<<<sp_23, br/><<lightblue/>if<<white/> (board[2-i][i] != 0) <<orange/>break<<white/>;                       <<grey/>// empty position taken by enemy"+
            "<<white/><<<sp_23, br/>x = 2-i;"+
            "<<<sp_23, br/>y = i;"+
            "<<<sp_23, br/><<orange/>return<<white/> 1;"+
            "<<<sp_19, br/>} "+
            "<<<sp_13, br/>}"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<orange/>return<<white/> 0;                                                               <<grey/>// no winning move"+
            "<<white/><<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>/**  Heuristic : Get the number of total boardWins by the given playNo  */<<white/>"+
            "<<<sp_6, br/>double boardWins( NineBoardGame * gameboard, <<orange/>int<<white/> playerNo)"+
            "<<<sp_6, br/>{"+
            "<<<sp_9, br/>double score = 0;"+
            "<<<sp_9, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < 9; i++ )  <<lightblue/>if<<white/> (gameboard->GameBoard[i].gameStatus() == playerNo) score+=0.11;"+
            "<<<sp_9, br/><<orange/>return<<white/> score;"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>/** Heuristic : score determined by next move = win on all boards */<<white/>"+
            "<<<sp_6, br/>double TotalNextWins(NineBoardGame *gameboard, <<orange/>int<<white/> playerNo){"+
            "<<<sp_9, br/>double score =0;"+
            "<<<sp_9, br/><<orange/>int<<white/> nul;"+
            "<<<sp_9, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < 9; i++)  <<lightblue/>if<<white/> (boardHasWin(gameboard->GameBoard[i].getBoard(), playerNo, nul,nul)) score +=0.20;"+
            "<<<sp_9, br/><<orange/>return<<white/> score;              "+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>/** Evaluation funct : calculate game score for potential winning board (nextmove = win); MaxScore = 8 x 0.2 = 1.6 for the 8 angles of win */<<white/>"+
            "<<<sp_6, br/>double EvaluateGame(NineBoardGame * gameboard, <<orange/>int<<white/> playerNo){"+
            "<<<sp_9, br/>double score =0;"+
            "<<<sp_9, br/><<orange/>int<<white/> L_DCounter = 0;                                                                         <<grey/>// Right Diagonal win counter"+
            "<<white/><<<sp_9, br/><<orange/>int<<white/> R_DCounter = 0;"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < 3; i++) {"+
            "<<<sp_13, br/><<orange/>int<<white/> RowCounter = 0;"+
            "<<<sp_13, br/><<orange/>int<<white/> ColCounter = 0;"+
            "<<<sp_13, br/><<orange/>int<<white/> D_LVal = gameboard->GameBoard[(i*4)].gameStatus();                                  <<grey/>// LeftDiagonal value"+
            "<<white/><<<sp_13, br/><<orange/>int<<white/> D_RVal = gameboard->GameBoard[ 2 + (i*2) ].gameStatus();                            <<grey/>// Right Diagonal value"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_13, br/><<grey/>// COUNT WINS DIAGONALLY"+
            "<<white/><<<sp_13, br/><<lightblue/>if<<white/> (D_LVal == playerNo) L_DCounter++;"+
            "<<<sp_13, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (D_LVal == -playerNo) L_DCounter = -5;                      "+
            "<<<br,hidden/>br"+
            "<<<sp_13, br/><<lightblue/>if<<white/> (D_RVal == playerNo) R_DCounter++;"+
            "<<<sp_13, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (D_RVal == -playerNo) R_DCounter = -5;"+
            "<<<br,hidden/>br"+
            "<<<sp_13, br/><<grey/>// CHECK ROW AND COLUMN"+
            "<<white/><<<sp_13, br/>for(<<orange/>int<<white/> j =0; j < 3; j++) {"+
            "<<<sp_15, br/><<orange/>int<<white/> rowVal = gameboard->GameBoard[(i*3)+j].gameStatus();                            <<grey/>// current winning status of row i,j"+
            "<<white/><<<sp_15, br/><<orange/>int<<white/> colVal = gameboard->GameBoard[ i + (j*3) ].gameStatus();"+
            "<<<br,hidden/>br"+
            "<<<sp_15, br/><<grey/>// COUNT WINS IN COLUMN AND ROW"+
            "<<white/><<<sp_15, br/><<lightblue/>if<<white/> (rowVal == playerNo) RowCounter++;                                               <<grey/>// If Status is winning player, increment win counter"+
            "<<white/><<<sp_15, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (rowVal == -playerNo) RowCounter = -5;                                      <<grey/>// If nonEmpty, we cannot win in this row             "+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_15, br/><<lightblue/>if<<white/> (colVal == playerNo) ColCounter++;"+
            "<<<sp_15, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (colVal == -playerNo) ColCounter = -5;                                      <<grey/>// deassociate columm, cannot win in row."+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_15, br/><<grey/>// SUM SCORE (IF ANY)"+
            "<<white/><<<sp_15, br/><<lightblue/>if<<white/> (j == 2){"+
            "<<<sp_19, br/><<lightblue/>if<<white/> (RowCounter >= 2 ) score =  score + 0.2;                                     <<grey/>// there exist win in row"+
            "<<white/><<<sp_19, br/><<lightblue/>if<<white/> (ColCounter >= 2)  score  =  score + 0.2;                                    <<grey/>// there exist win in column"+
            "<<white/><<<sp_15, br/>}"+
            "<<<sp_13, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_13, br/><<grey/>// SUM SCORE (IFANY)"+
            "<<white/><<<sp_13, br/><<lightblue/>if<<white/> (i == 2){"+
            "<<<sp_15, br/><<lightblue/>if<<white/> (L_DCounter >= 2) score =  score + 0.2;                                          <<grey/>// there exist win left diagonally"+
            "<<white/><<<sp_15, br/><<lightblue/>if<<white/> (R_DCounter >= 2)  score  =  score + 0.2;                                        <<grey/>// there exist win right diagonally  "+
            "<<white/><<<sp_13, br/>} "+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/><<orange/>return<<white/> score + boardWins(gameboard, playerNo) + TotalNextWins(gameboard, playerNo);         <<grey/>// return heuristic score"+
            "<<white/><<<sp_6, br/>}"+
            "<<<sp_6, br/>"+
            "<<<sp_6, br/>"+
            "<<<sp_6, br/>"+
            "<<<sp_6, br/><<grey/>/** Return the best move given the current gameboard */<<white/>"+
            "<<<sp_6, br/><<orange/>int<<white/> * get_BestMove(NineBoardGame * gameBoard)"+
            "<<<sp_6, br/>{"+
            "<<<sp_9, br/>MAXDEPTH = 10000 + (10000 * getDepth(gameBoard));                                                                             <<grey/>// search tree branchￂﾠ(factor/depth)"+
            "<<white/><<<sp_9, br/><<orange/>int<<white/> bestMove[3];                                                                                                    <<grey/>// where 0 = x, 1 = y, 2 = boardNo"+
            "<<white/><<<sp_9, br/>double bestScore = -10;                                                                                             <<grey/>// we denote -10 as -infinity         "+
            "<<white/><<<sp_9, br/><<orange/>int<<white/> bestDepth = -10;                                                            "+
            "<<<sp_9, br/><<orange/>int<<white/> gameState = gameBoard->get_gameState();                                                                         <<grey/>// 1 = waiting to choose a new gameBoard,  2 = waiting to choose new x,y"+
            "<<white/><<<sp_6, br/>"+
            "<<<sp_9, br/>priority_queue<priorityBoard> pq;"+
            "<<<sp_9, br/>priorityBoard *greedy_HighestPriorityBoard = <<orange/>nullptr<<white/>;                                                               <<grey/>// highest priority board in queue (greedy approach)"+
            "<<white/><<<sp_9, br/>NineBoardGame currentGame = gameBoard->getCopy();                                                                   <<grey/>// get copy of current game"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_9, br/><<grey/>// assign board into prioriry queue based on board heuristic"+
            "<<white/><<<sp_9, br/><<lightblue/>if<<white/> (gameState == 1){"+
            "<<<sp_13, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> boardNo=0; boardNo < 9; boardNo++){                                                                    <<grey/>// Iterate all boards"+
            "<<white/><<<sp_15, br/><<lightblue/>if<<white/> (currentGame.isValidBoard(boardNo)){                                                                     <<grey/>// Verify if board is valid                              "+
            "<<white/><<<sp_19, br/>priorityBoard currentBoard(boardHasWin( currentGame.GameBoard[boardNo].getBoard(),                      <<grey/>// Create priority object"+
            "<<white/><<<sp_23, br/>currentGame.getTurn(), bestMove[0], bestMove[1]), boardNo);"+
            "<<<sp_19, br/>pq.push(currentBoard);                                                                                  <<grey/>// insert into PQ"+
            "<<white/><<<sp_15, br/>}"+
            "<<<sp_13, br/>}"+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/><<grey/>// iterate board pn PQ"+
            "<<white/><<<sp_9, br/><<lightblue/>do<<white/>                                                                                                                 <<grey/>// while has Board in PQ or gameBoard not required"+
            "<<white/><<<sp_9, br/>{"+
            "<<<sp_13, br/><<orange/>int<<white/> boardNo = currentGame.currentBoard;                                                                         <<grey/>// current boardNo in game"+
            "<<white/><<<sp_13, br/><<lightblue/>if<<white/> (gameState == 1){                                                                                             <<grey/>// if require board selection"+
            "<<white/><<<sp_15, br/>priorityBoard currentPriorityBoard = pq.top();      "+
            "<<<sp_15, br/><<lightblue/>if<<white/> (greedy_HighestPriorityBoard == <<orange/>nullptr<<white/>) greedy_HighestPriorityBoard = &currentPriorityBoard;            <<grey/>// initialise highest priority board"+
            "<<white/><<<sp_15, br/>pq.pop();                                                                                                   <<grey/>// remove tob board"+
            "<<white/><<<sp_15, br/>boardNo = currentPriorityBoard.boardNo;                                                                     <<grey/>// override boardNo with highest priority board"+
            "<<white/><<<sp_13, br/>}"+
            "<<<sp_13, br/>currentGame.currentBoard = boardNo;                                                                             <<grey/>// select boardNo in game instance"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_13, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> y =0; y < 3; y++)                                                                                      <<grey/>// ITERATE ALL GAME ROW "+
            "<<white/><<<sp_13, br/>{                                                                                                               <<grey/>// ITERATE ALL GAME ROW"+
            "<<white/><<<sp_15, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> x =0; x < 3; x++)                                                                                  <<grey/>// ITERATE ALL COLUMN  "+
            "<<white/><<<sp_15, br/>{                                                                                    "+
            "<<<sp_19, br/>depth = 0;                                                                                              <<grey/>// Depth counter"+
            "<<white/><<<sp_19, br/><<lightblue/>if<<white/> (currentGame.isValidMove(x,y))                                                                       <<grey/>// if valid move"+
            "<<white/><<<sp_19, br/>{"+
            "<<<sp_23, br/>currentGame.addMove(x,y);                                                                           <<grey/>// insert move"+
            "<<white/><<<sp_21, br/>double score = minimax(&currentGame, false, -10 , 10, gameBoard->getTurn());                        <<grey/>// MIN-PLAYER TURN, where 10 = positive infinity"+
            "<<white/><<<sp_21, br/><<lightblue/>if<<white/> (score > bestScore || (score == bestScore && depth < bestDepth))                                 <<grey/>// if betters core OR depth"+
            "<<white/><<<sp_23, br/>{"+
            "<<<sp_27, br/>bestScore = score;"+
            "<<<sp_27, br/>bestDepth = depth;"+
            "<<<sp_27, br/>bestMove[0] = x;"+
            "<<<sp_27, br/>bestMove[1] = y;"+
            "<<<sp_27, br/>bestMove[2] = boardNo;"+
            "<<<sp_23, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_23, br/>currentGame.setTurn(gameBoard->getTurn());                                                          <<grey/>// Restore original player"+
            "<<white/><<<sp_23, br/>currentGame.currentBoard = boardNo;                                                                 <<grey/>// restore previous boardNo"+
            "<<white/><<<sp_23, br/>currentGame.undoMove(x,y);                                                                          <<grey/>// undo previous move"+
            "<<white/><<<sp_19, br/>}"+
            "<<<sp_15, br/>}"+
            "<<<sp_13, br/>}"+
            "<<<sp_9, br/>} <<lightblue/>while<<white/>(!pq.empty());"+
            "<<<sp_9, br/>"+
            "<<<sp_9, br/><<orange/>cout<<white/><<'AI SCORE = ' << bestScore <<endl;"+
            "<<<sp_9, br/><<orange/>return<<white/> bestMove;"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_4, br/>"+
            "<<<sp_6, br/><<grey/>/** Minimax algorithm with alpha beta pruning; depth is utilised to limit the searching factor */<<white/>"+
            "<<<sp_6, br/>double minimax(NineBoardGame * gameboard, <<orange/>bool<<white/> player, <<orange/>int<<white/> alpha, <<orange/>int<<white/> beta, <<orange/>int<<white/> playerNo){"+
            "<<<sp_9, br/><<orange/>int<<white/> gameState = gameboard->get_gameState();"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<grey/>// BASE CASES : Utilitiy funcs"+
            "<<white/><<<sp_9, br/><<lightblue/>if<<white/> (gameState == 3) {                                                       <<grey/>// game has concluded"+
            "<<white/><<<sp_13, br/><<orange/>int<<white/> gameStatus = gameboard->check_gameStatus();                         <<grey/>// get ending status               "+
            "<<white/><<<sp_6, br/>"+
            "<<<sp_13, br/><<lightblue/>if<<white/> (gameStatus == 2) <<orange/>return<<white/> 0;                                          <<grey/>// draw"+
            "<<white/><<<sp_13, br/><<orange/>return<<white/> (playerNo == gameStatus) ? 5 : -5;                               <<grey/>// 1 IFF Maximising player wins, else -1 for loss."+
            "<<white/><<<sp_9, br/>}<<lightblue/>else<<white/> <<lightblue/>if<<white/> (depth > MAXDEPTH) {"+
            "<<<sp_13, br/><<orange/>return<<white/> EvaluateGame(gameboard, playerNo);  "+
            "<<<sp_9, br/>}"+
            "<<<sp_6, br/>"+
            "<<<sp_9, br/><<orange/>int<<white/> currentPlayer = gameboard->getTurn();                                   <<grey/>// current Player"+
            "<<white/><<<sp_9, br/><<orange/>int<<white/> parentBoardNo = gameboard->currentBoard;                                <<grey/>// parent boardNo "+
            "<<white/><<<sp_9, br/>priority_queue<priorityBoard> pq;"+
            "<<<sp_9, br/>depth++;                                                                   <<grey/>// increment depth"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_9, br/><<orange/>int<<white/> x;"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (gameState == 1){                                                        <<grey/>// assign board into prioriry queue based on board heuristic"+
            "<<white/><<<sp_13, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> boardNo=0; boardNo < 9; boardNo++){                             <<grey/>// populrate board in PQ with heuristc                            "+
            "<<white/><<<sp_15, br/><<lightblue/>if<<white/> (gameboard->isValidBoard(boardNo)) {                             <<grey/>// Verify if board is valid"+
            "<<white/><<<sp_19, br/>priorityBoard currentBoard(boardHasWin(gameboard->GameBoard[boardNo].getBoard(), gameboard->getTurn() ,x,x), boardNo);"+
            "<<<sp_19, br/>pq.push(currentBoard); "+
            "<<<sp_15, br/>}"+
            "<<<sp_13, br/>}"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (player)                                                                 <<grey/>// MAXIMISING PLAYER"+
            "<<white/><<<sp_9, br/>{                                                               "+
            "<<<sp_13, br/>double max_Score = -10;                                                    <<grey/>// -infinity"+
            "<<white/><<<sp_13, br/>"+
            "<<<sp_13, br/><<lightblue/>do<<white/>                                                                     <<grey/>// Iterate PQ or Noboard selection necessary"+
            "<<white/><<<sp_13, br/>{                                   "+
            "<<<sp_15, br/><<orange/>int<<white/> boardNo = gameboard->currentBoard;"+
            "<<<sp_15, br/><<lightblue/>if<<white/> (gameState == 1){                                                 <<grey/>// override current BoardNo "+
            "<<white/><<<sp_19, br/>priorityBoard currentPriorityBoard = pq.top();"+
            "<<<sp_19, br/>pq.pop(); <<grey/>// remove tob board"+
            "<<white/><<<sp_19, br/>boardNo = currentPriorityBoard.boardNo;"+
            "<<<sp_15, br/>}"+
            "<<<sp_15, br/>gameboard->currentBoard = boardNo;"+
            "<<<br,hidden/>br"+
            "<<<sp_15, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> y =0; y < 3; y++)                                          <<grey/>// ITERATE ALL COLUMN{"+
            "<<white/><<<sp_15, br/>{"+
            "<<<sp_19, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> x =0; x < 3; x++)                                      <<grey/>// ITERATE ALL COLUMN{"+
            "<<white/><<<sp_19, br/>{                                           "+
            "<<<sp_23, br/><<lightblue/>if<<white/> (gameboard->isValidMove(x,y))                            <<grey/>// if valid move"+
            "<<white/><<<sp_23, br/>{                       "+
            "<<<sp_31, br/>gameboard->addMove(x,y);                            <<grey/>// make new move"+
            "<<white/><<<sp_31, br/>double score = minimax(gameboard, !player , alpha , beta, playerNo); <<grey/>//  MIN PLAYER TURN"+
            "<<white/><<<sp_31, br/><<lightblue/>if<<white/> (score > max_Score) {                            <<grey/>// if better score exist"+
            "<<white/><<<sp_34, br/>alpha = score;                                  <<grey/>// new alpha value"+
            "<<white/><<<sp_34, br/>max_Score = score;                              <<grey/>// new max score"+
            "<<white/><<<sp_31, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_31, br/><<lightblue/>if<<white/> (alpha >= beta){                                 <<grey/>// MINPlayer will never consider this path"+
            "<<white/><<<sp_34, br/>gameboard->currentBoard = boardNo;              <<grey/>// restore previous boardNo"+
            "<<white/><<<sp_34, br/>gameboard->undoMove(x,y);                       <<grey/>// undo previous move"+
            "<<white/><<<sp_34, br/><<orange/>return<<white/> max_Score;                               <<grey/>// Return to parent Tree"+
            "<<white/><<<sp_31, br/>}"+
            "<<<sp_31, br/>gameboard->setTurn(currentPlayer);                  <<grey/>// Restore original player"+
            "<<white/><<<sp_31, br/>gameboard->currentBoard = boardNo;                  <<grey/>// restore previous boardNo"+
            "<<white/><<<sp_31, br/>gameboard->undoMove(x,y);                           <<grey/>// undo previous move"+
            "<<white/><<<sp_23, br/>} "+
            "<<<sp_19, br/>}"+
            "<<<sp_15, br/>}"+
            "<<<sp_13, br/>} <<lightblue/>while<<white/> (!pq.empty());"+
            "<<<br,hidden/>br"+
            "<<<sp_13, br/><<orange/>return<<white/> max_Score;                                                       <<grey/>// Return to parent"+
            "<<white/><<<sp_9, br/>}"+
            "<<<sp_9, br/><<lightblue/>else<<white/>"+
            "<<<sp_9, br/>{                                                                           <<grey/>// Minimising Player"+
            "<<white/><<<sp_13, br/>double min_Score = 10;"+
            "<<<br,hidden/>br"+
            "<<<sp_13, br/>do"+
            "<<<sp_13, br/>{"+
            "<<<sp_15, br/><<orange/>int<<white/> boardNo = gameboard->currentBoard;"+
            "<<<sp_15, br/><<lightblue/>if<<white/> (gameState == 1){                                                 <<grey/>// override current BoardNo "+
            "<<white/><<<sp_19, br/>priorityBoard currentPriorityBoard = pq.top();"+
            "<<<sp_19, br/>pq.pop(); <<grey/>// remove tob board"+
            "<<white/><<<sp_19, br/>boardNo = currentPriorityBoard.boardNo;"+
            "<<<sp_15, br/>}"+
            "<<<sp_15, br/>gameboard->currentBoard = boardNo; "+
            "<<<br,hidden/>br"+
            "<<<sp_15, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> y =0; y < 3; y++)                                          <<grey/>// ITERATE ALL COLUMN{"+
            "<<white/><<<sp_15, br/>{"+
            "<<<sp_19, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> x =0; x < 3; x++)                                      <<grey/>// ITERATE ALL COLUMN{"+
            "<<white/><<<sp_19, br/>{                                           "+
            "<<<sp_23, br/><<lightblue/>if<<white/> (gameboard->isValidMove(x,y))                            <<grey/>// if valid move"+
            "<<white/><<<sp_23, br/>{                       "+
            "<<<sp_31, br/>gameboard->addMove(x,y);                            <<grey/>// make new move"+
            "<<white/><<<sp_31, br/>double score = minimax(gameboard, !player , alpha , beta, playerNo); <<grey/>//  MAX PLAYER TURN"+
            "<<white/><<<sp_31, br/><<lightblue/>if<<white/> (score < min_Score) {                            <<grey/>// if better score exist"+
            "<<white/><<<sp_34, br/>beta = score;                                   <<grey/>// new beta value"+
            "<<white/><<<sp_34, br/>min_Score = score;                              <<grey/>// new max score"+
            "<<white/><<<sp_31, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_31, br/><<lightblue/>if<<white/> (beta <= alpha){                                 <<grey/>// MAX player will never consider this path"+
            "<<white/><<<sp_34, br/>gameboard->currentBoard = boardNo;              <<grey/>// restore previous boardNo"+
            "<<white/><<<sp_34, br/>gameboard->undoMove(x,y);                       <<grey/>// undo previous move"+
            "<<white/><<<sp_34, br/><<orange/>return<<white/> min_Score;                               <<grey/>// Return min Score"+
            "<<white/><<<sp_31, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_31, br/>gameboard->setTurn(currentPlayer);                  <<grey/>// Restore original player"+
            "<<white/><<<sp_31, br/>gameboard->currentBoard = boardNo;                  <<grey/>// restore previous boardNo"+
            "<<white/><<<sp_31, br/>gameboard->undoMove(x,y);                           <<grey/>// undo previous move"+
            "<<white/><<<sp_23, br/>} "+
            "<<<sp_19, br/>}"+
            "<<<sp_15, br/>}"+
            "<<<sp_13, br/>} <<lightblue/>while<<white/>(!pq.empty());"+
            "<<<sp_13, br/><<orange/>return<<white/> min_Score;"+
            "<<<sp_9, br/>}"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<orange/>int<<white/> savex=-1, savey = -1;                                                       <<grey/>// value memoriser"+
            "<<white/><<<sp_3, br/><<orange/>public<<white/>  :"+
            "<<<sp_6, br/>AIPlayer(string name ): Player(name){"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<orange/>void<<white/> getMove(NineBoardGame * gameboard, <<orange/>int<<white/> &x, <<orange/>int<<white/>&y) override {"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (savex == -1 || savey == -1)                                             <<grey/>// if move is not already made @ funct getBoard"+
            "<<white/><<<sp_9, br/>{"+
            "<<<sp_13, br/><<orange/>int<<white/> *moves = get_BestMove(gameboard);"+
            "<<<sp_13, br/>x = moves[0];"+
            "<<<sp_13, br/>y = moves[1];"+
            "<<<sp_9, br/>}<<lightblue/>else<<white/>{"+
            "<<<sp_13, br/>x = savex;"+
            "<<<sp_13, br/>y = savey;"+
            "<<<sp_13, br/>savex = -1;"+
            "<<<sp_13, br/>savey = -1;"+
            "<<<sp_9, br/>}"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<orange/>int<<white/> getBoard(NineBoardGame *gameboard, <<orange/>int<<white/> &x, <<orange/>int<<white/> &y) override {"+
            "<<<sp_9, br/><<orange/>int<<white/> *moves = get_BestMove(gameboard);"+
            "<<<sp_9, br/>x = moves[2]%3;"+
            "<<<sp_9, br/>y = floor(moves[2]/3);"+
            "<<<sp_9, br/>savex = moves[0];"+
            "<<<sp_9, br/>savey = moves[1];"+
            "<<<sp_6, br/>}"+
            "<<<sp_0, br/>};"+
            "<<<br,hidden/>br"
        },
        {
            key : 3,
            FileTitle : "NBGame.h",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>#ifndef<<white/> NBGAME_H"+
            "<<<sp_0, br/><<lightblue/>#define<<white/> NBGAME_H"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'Player.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'HumanPlayer.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'AIPlayer.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'NineBoardGame.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'RandomPlayer.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <string>"+
            "<<<sp_0, br/><<lightblue/>using namespace<<white/> std;"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>class<<white/> NBGame{"+
            "<<<sp_3, br/><<orange/>private<<white/> :"+
            "<<<sp_5, br/>Player * player[2];                                                         <<grey/>/** The two player */<<white/>"+
            "<<<sp_5, br/><<orange/>int<<white/> winner = -2;                                                            <<grey/>/** Final winner */<<white/> "+
            "<<<sp_5, br/>string getUser(Player * player);                                            <<grey/>/** Return a string representation of the player type.  */<<white/>"+
            "<<<sp_5, br/>NineBoardGame * NINEBOARDGAME;                                              <<grey/>/** Nineboard game instance */<<white/>  "+
            "<<<sp_3, br/><<orange/>public<<white/> :"+
            "<<<sp_5, br/>NBGame();"+
            "<<<sp_5, br/><<orange/>void<<white/> Play();                                                                <<grey/>/** Game drive */<<white/>"+
            "<<<sp_5, br/><<orange/>void<<white/> DisplayPlayers();                                                      <<grey/>/** Display the current players */<<white/>"+
            "<<<sp_5, br/>~NBGame() {                                                                 <<grey/>/** Destructor - Deallocate memory */<<white/>"+
            "<<<sp_7, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < 2; i++) <<lightblue/>delete<<white/> player[i];"+
            "<<<sp_7, br/><<lightblue/>delete<<white/> NINEBOARDGAME;"+
            "<<<sp_5, br/>}"+
            "<<<sp_0, br/>};"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>string NBGame::getUser(Player * player){"+
            "<<<sp_2, br/><<grey/>// attempt to cast player to type AIPlayer, null is returned on fail."+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> (AIPlayer* aiPlayer = dynamic_cast<AIPlayer*>(player)) <<orange/>return<<white/> 'AIPlayer [Minimax]';"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (HumanPlayer* humanPlayer = dynamic_cast<HumanPlayer*>(player))  <<orange/>return<<white/> 'HumanPlayer';"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (RandomPlayer* humanPlayer = dynamic_cast<RandomPlayer*>(player))  <<orange/>return<<white/> 'RandomPlayer';"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>return<<white/> 'Unknown Player';"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>NBGame::NBGame()"+
            "<<<sp_0, br/>{"+
            "<<<sp_3, br/><<orange/>cout<<white/> <- 'Creating NB Game' <- endl;"+
            "<<<sp_3, br/>NINEBOARDGAME = <<orange/>new<<white/> NineBoardGame(false);                                       <<grey/>// New nineboard instance"+
            "<<white/><<<sp_7, br/>"+
            "<<<sp_3, br/><<grey/>// INITIALISE PLAYERS"+
            "<<white/><<<sp_3, br/><<orange/>int<<white/> userInput; "+
            "<<<sp_3, br/><<orange/>int<<white/> currentUser = 1;"+
            "<<<sp_3, br/><<orange/>cout<<white/> <- '\n\nCreating player ' <- currentUser <- endl;"+
            "<<<sp_3, br/><<lightblue/>while<<white/>(true)"+
            "<<<sp_3, br/>{   "+
            "<<<sp_5, br/><<orange/>cout<<white/> <- '   Select player ' <- currentUser <- ' type = 1) Human Player   2) Minimax Player  3) Random Player : ' ;"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (cin >> userInput && (userInput >=1 && userInput <=3))                   <<grey/>// if user input is integer && in range"+
            "<<white/><<<sp_5, br/>{ "+
            "<<<sp_7, br/><<orange/>cout<<white/> <- '   Input name<<lightblue/>for<<white/> player : ';"+
            "<<<sp_11, br/>"+
            "<<<sp_7, br/>string name;"+
            "<<<sp_7, br/>cin >> name;"+
            "<<<sp_7, br/><<grey/>// User Creation  "+
            "<<white/><<<sp_7, br/><<lightblue/>import<<white/>(userInput){"+
            "<<<sp_9, br/><<lightblue/>case<<white/> 1 : player[currentUser-1] = <<orange/>new<<white/> HumanPlayer(name);"+
            "<<<sp_9, br/><<orange/>break<<white/>;"+
            "<<<sp_9, br/><<lightblue/>case<<white/> 2 : player[currentUser-1] = <<orange/>new<<white/> AIPlayer(name);"+
            "<<<sp_9, br/><<orange/>break<<white/>;"+
            "<<<sp_9, br/><<lightblue/>case<<white/> 3 : player[currentUser-1] = <<orange/>new<<white/> RandomPlayer(name);"+
            "<<<sp_9, br/><<orange/>break<<white/>;"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (currentUser == 2) <<orange/>break<<white/>;                                            <<grey/>// break after player 2 creation "+
            "<<white/><<<sp_7, br/>currentUser++;                                                          <<grey/>// else increment to player 2."+
            "<<white/><<<sp_7, br/><<orange/>cout<<white/> <- '\nCreating player ' <- currentUser <- endl;    "+
            "<<<sp_5, br/>} "+
            "<<<sp_5, br/><<lightblue/>else<<white/>                                                                        <<grey/>// IF Invalid selection    "+
            "<<white/><<<sp_5, br/>{                                                                          "+
            "<<<sp_7, br/><<orange/>cout<<white/> <- '   Invalid player selection!' <- endl;"+
            "<<<sp_7, br/><<grey/>// Re-Set Input buffer"+
            "<<white/><<<sp_7, br/>std::cin.clear();                                                       <<grey/>// clear error-state in buffer"+
            "<<white/><<<sp_7, br/><<lightblue/>while<<white/> (std::cin.get() != '\n');                                         <<grey/>// clear remaining chars in buffer "+
            "<<white/><<<sp_5, br/>}"+
            "<<<sp_3, br/>} <<grey/>// END PLAYER INITIALISATION"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/>DisplayPlayers();"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** GAME DRIVEER */<<white/>"+
            "<<<sp_0, br/><<orange/>void<<white/> NBGame::Play()"+
            "<<<sp_0, br/>{ "+
            "<<<sp_3, br/>Player *currentplayer;                                                          <<grey/>// Current playing player               "+
            "<<white/><<<sp_3, br/><<orange/>int<<white/> gameStatus =  NINEBOARDGAME->get_gameState();                               <<grey/>// Game status where : 1 = choose a new gameBoard, 2 = choose new x,y move, 3 = Game concluded"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/><<lightblue/>while<<white/>(gameStatus != 3)                                                          <<grey/>// while game has not concluded"+
            "<<white/><<<sp_3, br/>{       "+
            "<<<sp_5, br/>currentplayer = (NINEBOARDGAME->getTurn() == 1) ? player[0] : player[1];    <<grey/>// get player turn"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_5, br/><<orange/>cout<<white/> <- '\n' <- currentplayer->getName()  <- ' turn to move ' <-   ( (NINEBOARDGAME->getTurn() == 1) ? '[X]' : '[O]')  <- endl;"+
            "<<<sp_5, br/><<orange/>int<<white/> x, y;                                                                   <<grey/>// board selection "+
            "<<white/><<<sp_5, br/><<lightblue/>import<<white/> (gameStatus) "+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/><<lightblue/>case<<white/> 1 :                                                                <<grey/>// waiting for new gameboard selection"+
            "<<white/><<<sp_9, br/>currentplayer->getBoard(NINEBOARDGAME, x, y);                       "+
            "<<<sp_9, br/><<orange/>cout<<white/> <- currentplayer->getName() <- ' has selected board : ' <- x+1 <- ',' <- y+1 <- endl;"+
            "<<<sp_9, br/>NINEBOARDGAME->setBoard(x,y);"+
            "<<<sp_7, br/><<orange/>break<<white/>;"+
            "<<<sp_7, br/><<lightblue/>case<<white/> 2 :                                                                <<grey/>// waiting for new x,y move selection"+
            "<<white/><<<sp_9, br/>currentplayer->getMove(NINEBOARDGAME , x, y);"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (NINEBOARDGAME->addMove(x,y))                                    <<grey/>// validate move success"+
            "<<white/><<<sp_11, br/><<orange/>cout<<white/> <- currentplayer->getName() <- ' has selected move : ' <- x+1 <- ',' <- y+1 <- endl;"+
            "<<<sp_9, br/><<lightblue/>else<<white/> "+
            "<<<sp_11, br/><<orange/>cout<<white/> <- currentplayer->getName() <- ' move invalid of : ' <- x+1 <- ',' <- y+1 <- endl;"+
            "<<<sp_7, br/><<orange/>break<<white/>;"+
            "<<<sp_5, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/>NINEBOARDGAME->DisplayBoards();                                             <<grey/>// display board"+
            "<<white/><<<sp_5, br/>gameStatus = NINEBOARDGAME->get_gameState();                                <<grey/>// Get game status"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>// PRINT FINAL GAME DETAIL"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> (NINEBOARDGAME->check_gameStatus() < 2)"+
            "<<<sp_3, br/>{                                     <<grey/>// if some player : 1/ or -1 has won "+
            "<<white/><<<sp_5, br/><<orange/>cout<<white/> <- '\n\n\nGame has concluded with winner' <- endl;         "+
            "<<<sp_5, br/>winner = NINEBOARDGAME->check_gameStatus();                                 <<grey/>// set game winner."+
            "<<white/><<<sp_5, br/>DisplayPlayers();                                                       "+
            "<<<sp_3, br/>}  <<lightblue/>else<<white/> "+
            "<<<sp_5, br/><<orange/>cout<<white/> <- '\n\n\nGame ended in a draw' <- endl;"+
            "<<<sp_3, br/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>void<<white/> NBGame::DisplayPlayers(){ "+
            "<<<sp_3, br/><<orange/>cout<<white/> <- '\n----- Current Players Info ----- ' <- endl;"+
            "<<<sp_3, br/><<orange/>cout<<white/> <- 'Player 1 (X): ' <- player[0]->getName() <- ' as ' <- getUser(player[0]) <- ((winner != -2) ? ((winner == 1) ? ' [Winner]' : ' [Loser]')  : '') <- endl;"+
            "<<<sp_3, br/><<orange/>cout<<white/> <- 'Player 2 (O): ' <- player[1]->getName() <- ' as ' <- getUser(player[1]) <- ((winner != -2) ? ((winner == -1) ? ' [Winner]' : ' [Loser]')  : '') <- endl;"+
            "<<<sp_3, br/><<orange/>cout<<white/> <- '---------------------------------- ' <- endl;"+
            "<<<sp_0, br/>};"+
            "<<<br,hidden/>br"+
            ""
            
        },
        {
            key : 4,
            FileTitle : "TicTacToe.h",
            content : 

            "<<white/><<<sp_0, br/> <<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>#ifndef<<white/> TICTACTOE_H"+
            "<<<sp_0, br/><<lightblue/>#define<<white/> TICTACTOE_H"+
            "<<<sp_0, br/><<lightblue/>using namespace<<white/> std;"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <iomanip> <<grey/>// Include the header for setw"+
            "<<white/>"+
            "<<grey/>// Sub tick-tac-toe game TASK 1"+
            "<<white/><<<sp_0, br/><<orange/>class<<white/> TicTacToe{"+
            "<<<sp_3, br/><<orange/>private<<white/> : "+
            "<<<sp_5, br/><<orange/>int<<white/> **board; "+
            "<<<sp_3, br/><<orange/>public<<white/> : "+
            "<<<sp_5, br/><<lightblue/>const<<white/> <<orange/>int<<white/> row = 3;"+
            "<<<sp_5, br/><<lightblue/>const<<white/> <<orange/>int<<white/> column = 3;  "+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<grey/>/** Assign the newBoard values the current board*/<<white/>"+
            "<<<sp_5, br/><<orange/>void<<white/> assignBoard(<<orange/>int<<white/> **<<orange/>new<<white/>Board)"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> x=0; x < column ;x++)"+
            "<<<sp_8, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> y = 0; y < column; y++ ) board[x][y] = <<orange/>new<<white/>Board[x][y];"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<grey/>/** Get the current board*/<<white/>"+
            "<<<sp_5, br/><<orange/>int<<white/> ** getBoard() {"+
            "<<<sp_7, br/><<orange/>return<<white/> board;"+
            "<<<sp_5, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/>TicTacToe(){"+
            "<<<sp_7, br/><<grey/>// initialise default values "+
            "<<white/><<<sp_7, br/>board = <<orange/>new<<white/> <<orange/>int<<white/>*[row];"+
            "<<<sp_7, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < column; i++)"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/>board[i] = <<orange/>new<<white/> <<orange/>int<<white/>[column];"+
            "<<<sp_8, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> j =0; j < column; j++) board[i][j] = 0;"+
            "<<<sp_7, br/>}"+
            "<<<sp_5, br/>};"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/>~TicTacToe() {                                                                 <<grey/>/** Destructor - Deallocate memory */<<white/>"+
            "<<<sp_7, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = 0; i < row; ++i) <<lightblue/>delete<<white/>[] board[i];                            <<grey/>// Delete each row            "+
            "<<white/><<<sp_7, br/><<lightblue/>delete<<white/>[] board;                                                             <<grey/>// Delete the array of row pointers            "+
            "<<white/><<<sp_5, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<grey/>/**"+
            "<<<sp_6, br/>* Check the game status. "+
            "<<<sp_6, br/>* @return : 1 / -1 = winning player, 2 = draw, 3 = game still running   "+
            "<<<sp_5, br/>*/<<white/>"+
            "<<<sp_5, br/><<orange/>int<<white/> gameStatus() {"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<grey/>// check column and rows fow win"+
            "<<white/><<<sp_7, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = 0; i < 3; i++)"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/><<grey/>// Check rows for a win"+
            "<<white/><<<sp_8, br/><<lightblue/>if<<white/> (board[i][0] != 0 && (board[i][0] == board[i][1]) && (board[i][1] == board[i][2])) <<orange/>return<<white/> board[i][0];"+
            "<<<sp_8, br/><<grey/>// Check columns for a win"+
            "<<white/><<<sp_8, br/><<lightblue/>if<<white/> (board[0][i] != 0 && (board[0][i] == board[1][i]) && (board[1][i] == board[2][i])) <<orange/>return<<white/> board[0][i];"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<grey/>//Check diagonals for a win"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> (board[0][0] != 0 && (board[0][0] == board[1][1]) && (board[1][1] == board[2][2])) <<orange/>return<<white/> board[0][0];"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (board[2][0] != 0 && (board[2][0] == board[1][1]) && (board[1][1] == board[0][2])) <<orange/>return<<white/> board[2][0];"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<grey/>// check for full game"+
            "<<white/><<<sp_7, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> x=0; x < row; x++) {"+
            "<<<sp_8, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> y=0; y < column; y++)  <<lightblue/>if<<white/> (board[x][y] == 0) <<orange/>return<<white/> 3; <<grey/>// game still playing"+
            "<<white/><<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<orange/>return<<white/> 2; <<grey/>// draw"+
            "<<white/><<<sp_5, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<grey/>/** Return true (1) IFF given column, row is valid on the board, that is, range only*/<<white/>"+
            "<<<sp_5, br/><<orange/>bool<<white/> isValidRange(<<orange/>int<<white/> x, <<orange/>int<<white/> y) {"+
            "<<<sp_7, br/><<orange/>return<<white/> ( ((x >= 0 && x < 3) && (y >= 0 && y < 3)) ); <<grey/>// assuming board is 3x3"+
            "<<white/><<<sp_5, br/>}"+
            "<<<sp_5, br/><<grey/>/** Return true (1) IFF given column, row is valid on the board, that is, within range and not taken */<<white/>"+
            "<<<sp_5, br/><<orange/>bool<<white/> isValidMove(<<orange/>int<<white/> x, <<orange/>int<<white/> y) {"+
            "<<<sp_7, br/><<orange/>return<<white/> ( ((x >= 0 && x < 3) && (y >= 0 && y < 3)) && (board[x][y] == 0) ); <<grey/>// assuming board is 3x3"+
            "<<white/><<<sp_5, br/>}"+
            "<<<sp_8, br/>"+
            "<<<sp_5, br/><<grey/>/** Print the current state of the board */<<white/>"+
            "<<<sp_5, br/><<orange/>void<<white/> pr<<orange/>int<<white/>Board() { "+
            "<<<sp_7, br/><<orange/>cout<<white/> << '   1    2    3' << endl << endl;"+
            "<<<sp_7, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = 0; i < 3; i++) "+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/><<orange/>cout<<white/> << i + 1;"+
            "<<<sp_8, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> j = 0; j < 3; j++) "+
            "<<<sp_8, br/>{"+
            "<<<sp_9, br/><<orange/>char<<white/> playerSymbol = ' ';"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (board[j][i] == 1) playerSymbol = 'X';"+
            "<<<sp_9, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (board[j][i] == -1) playerSymbol = 'O';"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<orange/>cout<<white/> << setw(3) << playerSymbol;"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (j != 2) <<orange/>cout<<white/> << ' |';"+
            "<<<sp_8, br/>}"+
            "<<<sp_8, br/><<orange/>cout<<white/> << endl;"+
            "<<<sp_8, br/><<lightblue/>if<<white/> (i != 2)  <<orange/>cout<<white/> << ' ____|____|____' << endl << '     |    |    ' << endl;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<orange/>cout<<white/> << endl;"+
            "<<<sp_5, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<grey/>/** Set a move on the board by player. "+
            "<<<sp_5, br/>* "+
            "<<<sp_5, br/>* @return : True on success, else false. "+
            "<<<sp_5, br/>*/<<white/>"+
            "<<<sp_5, br/><<orange/>bool<<white/> setMove(<<orange/>int<<white/> x, <<orange/>int<<white/> y, <<orange/>int<<white/> player){"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (isValidMove(x, y) && gameStatus() ==3 )"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/><<orange/>int<<white/> *position = &board[x][y];"+
            "<<<sp_8, br/><<grey/>// set player if empty"+
            "<<white/><<<sp_8, br/><<lightblue/>if<<white/> (position != 0)  "+
            "<<<sp_8, br/>{"+
            "<<<sp_9, br/>*position = player;"+
            "<<<sp_9, br/><<orange/>return<<white/> true;"+
            "<<<sp_8, br/>} "+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<orange/>return<<white/> false;"+
            "<<<sp_5, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<grey/>/** Reset board value at given x,y */<<white/>"+
            "<<<sp_5, br/><<orange/>void<<white/> reset(<<orange/>int<<white/> x, <<orange/>int<<white/> y){"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (isValidRange(x,y)) board[x][y] = 0;"+
            "<<<sp_5, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<grey/>/** "+
            "<<<sp_5, br/>* Get value at the given position"+
            "<<<sp_5, br/>* @return positive int value at specified location, else 0;"+
            "<<<sp_5, br/>**/<<white/>"+
            "<<<sp_5, br/><<orange/>int<<white/> At(<<orange/>int<<white/> x, <<orange/>int<<white/> y){ "+
            "<<<sp_7, br/><<lightblue/>if<<white/> (((x >= 0 && x < 3) && (y >= 0 && y < 3))) <<orange/>return<<white/> board[x][y];"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<orange/>return<<white/> 0;"+
            "<<<sp_5, br/>}"+
            "<<<sp_0, br/>};"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            ""


        },
        {
            key : 5,
            FileTitle : "RandomPlayer.h",
            content : 

            "<<white/><<<sp_0, br/><<lightblue/>#ifndef<<white/> RANDOMPLAYER_H_"+
            "<<<sp_0, br/><<lightblue/>#define<<white/> RANDOMPLAYER_H_"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'Player.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <string>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'NineBoardGame.h'"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>using namespace<<white/> std;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>class<<white/> RandomPlayer:<<orange/>public<<white/> Player {"+
            "<<<sp_1, br/><<orange/>private<<white/> :"+
            "<<<sp_3, br/><<grey/>// GET RANDOM MOVE SELECTION"+
            "<<white/><<<sp_3, br/><<orange/>void<<white/> getRandom(<<orange/>int<<white/> &x, <<orange/>int<<white/> &y) {"+
            "<<<sp_4, br/>x = rand() % 3;"+
            "<<<sp_4, br/>y = rand() % 3;"+
            "<<<sp_3, br/>}                "+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<orange/>public<<white/>:"+
            "<<<sp_2, br/>RandomPlayer(string name):Player(name){}"+
            "<<<sp_3, br/><<orange/>void<<white/> getMove(NineBoardGame * gameboard, <<orange/>int<<white/> &x, <<orange/>int<<white/>&y) override {"+
            "<<<sp_4, br/><<lightblue/>while<<white/>(true) {                                       "+
            "<<<sp_5, br/>getRandom(x,y);"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (gameboard->isValidMove(x,y)) <<orange/>break<<white/>;"+
            "<<<sp_4, br/>}      "+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>int<<white/> getBoard(NineBoardGame *gameboard, <<orange/>int<<white/> &x, <<orange/>int<<white/> &y) override {"+
            "<<<sp_4, br/><<lightblue/>while<<white/>(true) {"+
            "<<<sp_5, br/>getRandom(x,y);"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (gameboard->isValidBoard(x,y)) <<orange/>break<<white/>;"+
            "<<<sp_4, br/>}      "+
            "<<<sp_3, br/>}"+
            "<<<sp_0, br/>};"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>#endif<<white/>"+
            ""
        },
        {
            key : 6,
            FileTitle : "Player.h",
            content : 
            
            "<<white/><<<sp_0, br/><<lightblue/>#ifndef<<white/> PLAYER_H"+
            "<<<sp_0, br/><<lightblue/>#define<<white/> PLAYER_H"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>// Abstract base Player class"+
            "<<white/><<<sp_0, br/><<orange/>class<<white/> Player {"+
            "<<<sp_1, br/><<orange/>private<<white/> : "+
            "<<<sp_2, br/>string Name;"+
            "<<<sp_1, br/><<orange/>public<<white/>:"+
            "<<<sp_2, br/><<lightblue/>virtual<<white/> <<orange/>void<<white/> getMove(NineBoardGame * gameboard, <<orange/>int<<white/> &x, <<orange/>int<<white/>&y) = 0;         <<grey/>// Pure virtual function getNive. "+
            "<<white/><<<sp_2, br/><<lightblue/>virtual<<white/> <<orange/>int<<white/> getBoard(NineBoardGame * gameboard, <<orange/>int<<white/> &x, <<orange/>int<<white/>&y) = 0;         "+
            "<<<sp_2, br/>string getName(){ <<orange/>return<<white/> Name;}"+
            "<<<sp_2, br/>Player(string name){"+
            "<<<sp_4, br/>Name = name;"+
            "<<<sp_2, br/>}"+
            "<<<sp_0, br/>};"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            ""

        },
        {
            key : 7,
            FileTitle : "HumanPlayer.h",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>#ifndef<<white/> HUMANPLAYER_H "+
            "<<<sp_0, br/><<lightblue/>#define<<white/> HUMANPLAYER_H"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'Player.h'"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>using namespace<<white/> std;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>class<<white/> HumanPlayer : <<orange/>public<<white/> Player{"+
            "<<<sp_2, br/><<orange/>private<<white/> : "+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<orange/>public<<white/> : "+
            "<<<sp_4, br/>HumanPlayer(string name) : Player(name){};"+
            "<<<br,hidden/>br"+
            "<<<sp_4, br/><<orange/>void<<white/> getMove(NineBoardGame * gameboard, <<orange/>int<<white/> &x, <<orange/>int<<white/>&y) override {"+
            "<<<sp_5, br/><<orange/>cout<<white/> <- 'Please select <<orange/>new<<white/> move to play on x,y : ';"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<lightblue/>while<<white/>(true){"+
            "<<<sp_7, br/><<lightblue/>if<<white/> ((cin>>x>>y) && gameboard->isValidMove(--x,--y)) <<orange/>break<<white/>; <<grey/>// valid move"+
            "<<white/><<<sp_4, br/>"+
            "<<<sp_7, br/><<orange/>cout<<white/> <- '[' <- getName() <-  '] Position invalid, please enter move x,y  : ';"+
            "<<<sp_7, br/><<grey/>// clear Input buffer"+
            "<<white/><<<sp_7, br/>std::cin.clear();                                                               <<grey/>// clear error-state in buffer"+
            "<<white/><<<sp_7, br/><<lightblue/>while<<white/> (std::cin.get() != '\n');                                                 <<grey/>// clear remaining chars in buffer "+
            "<<white/><<<sp_5, br/>}      "+
            "<<<sp_4, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_4, br/><<orange/>int<<white/> getBoard(NineBoardGame *gameboard, <<orange/>int<<white/> &x, <<orange/>int<<white/> &y) override {"+
            "<<<sp_5, br/><<orange/>cout<<white/> <- 'Please select <<orange/>new<<white/> game board '  <- endl;"+
            "<<<sp_5, br/><<orange/>cout<<white/> <- '[' <- getName() <- '] Input your <<orange/>new<<white/> gameboard x,y : ';"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<lightblue/>while<<white/>(true){"+
            "<<<sp_7, br/><<lightblue/>if<<white/> ( (cin>>x>>y) && gameboard->isValidBoard(--x,--y)) <<orange/>break<<white/>;"+
            "<<<sp_7, br/><<orange/>cout<<white/> <- '[' <- getName() <-  ']Gameboard Invalid, please enter <<orange/>new<<white/> gameboard x,y  : ';"+
            "<<<sp_7, br/>std::cin.clear();               "+
            "<<<sp_7, br/><<lightblue/>while<<white/> (std::cin.get() != '\n'); "+
            "<<<sp_5, br/>}      "+
            "<<<sp_5, br/>"+
            "<<<sp_4, br/>}"+
            "<<<sp_0, br/>};"+
            "<<<sp_0, br/><<lightblue/>#endif<<white/>"+
            ""



        },
        {
            key : 8,
            FileTitle : "NineBoardGame.h",
            content : 
            "<<white/><<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>#ifndef<<white/> NineBoardGame_H"+
            "<<<sp_0, br/><<lightblue/>#define<<white/> NineBoardGame_H"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> 'TicTacToe.h'"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <cmath>"+
            "<<<sp_0, br/><<lightblue/>#include<<white/> <string>"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>using namespace<<white/> std;"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Nineboard tictactoe game : Task 2 */<<white/>"+
            "<<<sp_0, br/><<orange/>class<<white/> NineBoardGame{"+
            "<<<sp_3, br/><<orange/>private<<white/> :"+
            "<<<sp_7, br/><<orange/>int<<white/> getBoardNumber(<<orange/>int<<white/>, <<orange/>int<<white/> columnSize = 3);"+
            "<<<sp_7, br/><<orange/>int<<white/> currentPlayer;                                      <<grey/>/* current player turn */<<white/>"+
            "<<<sp_7, br/><<orange/>bool<<white/> isValidIndices(<<orange/>int<<white/>);                               <<grey/>/** Check if given indices exist in gameBoard */<<white/>"+
            "<<<sp_7, br/><<orange/>void<<white/> get_SubIndices(<<orange/>int<<white/> boardIndices, <<orange/>int<<white/>* x, <<orange/>int<<white/> * y, <<orange/>int<<white/> *BoardNumber);"+
            "<<<sp_7, br/>string drawLine(<<orange/>bool<<white/> isSelected );                      <<grey/>/* Generate board line utilised in printBoard funct */<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> : "+
            "<<<sp_7, br/>TicTacToe GameBoard[9];                                 <<grey/>/* 3x3 board as one dimensional array */<<white/>"+
            "<<<sp_7, br/><<orange/>int<<white/> currentBoard;                                       <<grey/>/* current playing board */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<grey/>/** Default constructor */<<white/>"+
            "<<<sp_7, br/>NineBoardGame(<<orange/>bool<<white/> isRandom) "+
            "<<<sp_7, br/>{ "+
            "<<<sp_9, br/><<orange/>cout<<white/> <-- '[NineBoard] Creating nineBoard game board' <-- endl;"+
            "<<<sp_9, br/>currentPlayer = 1; "+
            "<<<sp_9, br/>currentBoard = (isRandom) ? (rand() % 9) : -1;"+
            "<<<sp_9, br/>this->DisplayBoards();"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<grey/>/** Create a new nineboard instance with existing game value */<<white/>"+
            "<<<sp_7, br/>NineBoardGame(NineBoardGame * existingGame)"+
            "<<<sp_7, br/>{"+
            "<<<sp_9, br/>for(<<orange/>int<<white/> i=0; i < 9; i++) GameBoard[i].assignBoard(existingGame->GameBoard[i].getBoard());       "+
            "<<<sp_9, br/>currentBoard = existingGame->currentBoard;"+
            "<<<sp_9, br/>currentPlayer = existingGame->currentPlayer;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/><<orange/>int<<white/> getBoardNo(<<orange/>int<<white/> x, <<orange/>int<<white/> y);                           <<grey/>/** Return the board number associated with x,y on 3x3 board */<<white/>"+
            "<<<sp_7, br/><<orange/>bool<<white/> setBoard(<<orange/>int<<white/> x, <<orange/>int<<white/> y);                            <<grey/>/** Set the currentBoard number; return  T on success, else F*/<<white/>"+
            "<<<sp_7, br/><<orange/>void<<white/> DisplayBoards();"+
            "<<<sp_7, br/><<orange/>int<<white/> check_gameStatus();                                 <<grey/>/** return game status where : 1 / -1 = winning player, 2 = draw, 3 = game still running  */<<white/>"+
            "<<<sp_7, br/><<orange/>int<<white/> get_gameState();                                    <<grey/>/** Return the current game state where : 1 = awaiting for gameboard, 2) awaiting new position 3) game concluded */<<white/>"+
            "<<<sp_7, br/><<orange/>bool<<white/> addMove (<<orange/>int<<white/> x, <<orange/>int<<white/> y);                            <<grey/>/** Insert a new move */<<white/>"+
            "<<<sp_7, br/><<orange/>void<<white/> undoMove (<<orange/>int<<white/> x, <<orange/>int<<white/> y);                           <<grey/>/** Undo move at position x,y at currentboard ( i.e, set to 0 ) */<<white/>"+
            "<<<sp_7, br/><<orange/>int<<white/> getTurn();                                          <<grey/>/** Get player turn where : 1 = player 1, -1 = player 2*/<<white/>"+
            "<<<sp_7, br/><<orange/>bool<<white/> isValidBoard(<<orange/>int<<white/> x, <<orange/>int<<white/> y);                        <<grey/>/** Validate x,y of 3x3 board  */<<white/>"+
            "<<<sp_7, br/><<orange/>bool<<white/> isValidBoard(<<orange/>int<<white/> boardNo);                         <<grey/>/** Valid boardNo of combined 3x3 board */<<white/>"+
            "<<<sp_7, br/><<orange/>bool<<white/> isValidMove(<<orange/>int<<white/> x, <<orange/>int<<white/> y);                         <<grey/>/** T IFF x,y is valid move in currentBoard */<<white/>"+
            "<<<sp_7, br/><<orange/>void<<white/> setTurn (<<orange/>int<<white/> player) { currentPlayer = player;};   <<grey/>/** Set the players turn */<<white/>"+
            "<<<sp_7, br/>NineBoardGame getCopy();                                <<grey/>/** Return a copy of the current instance of nineboardGame */<<white/>"+
            "<<<sp_7, br/>"+
            "<<<sp_0, br/>};"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Get a new instance of the current nineboard game*/<<white/>"+
            "<<<sp_0, br/>NineBoardGame NineBoardGame::getCopy(){"+
            "<<<sp_3, br/>NineBoardGame <<orange/>new<<white/>game(this);"+
            "<<<sp_3, br/><<orange/>return<<white/> <<orange/>new<<white/>game;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Undo the move at x,y in gameboard[currentboard]*/<<white/>"+
            "<<<sp_0, br/><<orange/>void<<white/> NineBoardGame::undoMove(<<orange/>int<<white/> x, <<orange/>int<<white/> y){"+
            "<<<sp_3, br/>GameBoard[currentBoard].reset(x,y);"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "* Return the boardNo for GameBoard[boardNo] representing location x,y "+
            "* in a 3x3 grid. "+
            "* @return : 0 <= x <= 8 integer of the boardNo on success;  -1 is returned if x,y does not map to any boardNo "+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>int<<white/> NineBoardGame::getBoardNo(<<orange/>int<<white/> x, <<orange/>int<<white/> y){"+
            "<<<sp_3, br/><<orange/>int<<white/> boardNo = (y * 3) + x;"+
            "<<<sp_3, br/><<orange/>return<<white/> (boardNo > 8) ? -1 : boardNo;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Return T IFF x,y is a valid gameBoard Number, that is : its in range and has not been concluded */<<white/>"+
            "<<<sp_0, br/><<orange/>bool<<white/> NineBoardGame::isValidBoard(<<orange/>int<<white/> x, <<orange/>int<<white/> y) {"+
            "<<<sp_3, br/><<orange/>int<<white/> boardNo = getBoardNo(x,y);                                                          <<grey/>// transform x,y into boardNo"+
            "<<white/><<<sp_3, br/><<orange/>return<<white/> ( (boardNo >=0 && boardNo <9) && !(GameBoard[boardNo].gameStatus() <=2) );       <<grey/>// check if 0 <= boardNo <= 8 AND gameBoard[boardNo] is NOT concluded"+
            "<<white/><<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Return T IFF boardNo is a valid gameBoard Number, that is : its in range and has not been concluded */<<white/>"+
            "<<<sp_0, br/><<orange/>bool<<white/> NineBoardGame::isValidBoard(<<orange/>int<<white/> boardNo) {"+
            "<<<sp_3, br/><<orange/>return<<white/> ( (boardNo >=0 && boardNo <9) && !(GameBoard[boardNo].gameStatus() <=2) );       <<grey/>// check if 0 <= boardNo <= 8 AND gameBoard[boardNo] is NOT concluded"+
            "<<white/><<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "* Return the current player turn "+
            "* @return x : player 1, -x : player 2. "+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>int<<white/> NineBoardGame::getTurn(){"+
            "<<<sp_3, br/><<orange/>return<<white/> currentPlayer;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "* Return the state of the game, where :"+
            "*      1 = waiting to choose a new gameBoard               (Has order of priority 2)"+
            "*      2 = waiting to choose new x,y move on gameboard     (Has order of priority 3)"+
            "*      3 = game has concluded                              (Has order of priority 1)"+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>int<<white/> NineBoardGame::get_gameState(){"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (check_gameStatus() <= 2) <<orange/>return<<white/> 3;                  <<grey/>// if game is finished"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> (currentBoard == -1) <<orange/>return<<white/> 1;                       <<grey/>// if waiting for new board position"+
            "<<white/><<<sp_3, br/><<orange/>return<<white/> 2;                                               <<grey/>// waiting for new x,y move"+
            "<<white/><<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Return T IFF x,y move is valid on game in current board */<<white/>"+
            "<<<sp_0, br/><<orange/>bool<<white/> NineBoardGame::isValidMove(<<orange/>int<<white/> x, <<orange/>int<<white/> y){"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (currentBoard == -1) <<orange/>return<<white/> false;"+
            "<<<sp_3, br/><<orange/>return<<white/> (GameBoard[currentBoard].isValidMove(x,y));"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "* Insert a new move on the currentBoard."+
            "* @return T on success, else F;"+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>bool<<white/> NineBoardGame::addMove(<<orange/>int<<white/> x,  <<orange/>int<<white/> y) {"+
            "<<<sp_3, br/><<grey/>// if playing board is not selected"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> (currentBoard == -1)  "+
            "<<<sp_7, br/><<orange/>cout<<white/> <-- '[NineBoard] No Board Selected, please select a board to play on!' <-- endl;"+
            "<<<sp_3, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (GameBoard[currentBoard].setMove(x,y, currentPlayer)) "+
            "<<<sp_3, br/>{"+
            "<<<sp_7, br/>currentPlayer = -currentPlayer;                     <<grey/>// rotate player for player 1 = x, player 2 = -x; where x = original player passed. "+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> (!setBoard(x,y)) currentBoard = -1;              <<grey/>// update currentBoard to new move if valid, else -1 for player selection"+
            "<<white/><<<sp_7, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> false;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "<<<sp_1, br/>* Return the game status. "+
            "<<<sp_1, br/>* @return : 1 / -1 = winning player, 2 = draw, 3 = game still running   "+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>int<<white/> NineBoardGame::check_gameStatus(){"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = 0; i < 3; i++ ) {"+
            "<<<sp_8, br/><<grey/>// check row for win"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> ( GameBoard[(i*3)].gameStatus() <=1 && ( (GameBoard[i*3].gameStatus() == GameBoard[(i*3)+1].gameStatus()) && (GameBoard[i*3].gameStatus() == GameBoard[(i*3)+2].gameStatus())) ) <<orange/>return<<white/> GameBoard[(i*3)].gameStatus();"+
            "<<<sp_7, br/><<grey/>// Check column for win"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> ( GameBoard[i].gameStatus() <= 1 && ( (GameBoard[i].gameStatus() == GameBoard[i+3].gameStatus()) && ( GameBoard[i].gameStatus() == GameBoard[i+6].gameStatus()) )) <<orange/>return<<white/> GameBoard[i].gameStatus();"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>// check diagonal for win "+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> ( GameBoard[0].gameStatus() <= 1 && ( (GameBoard[0].gameStatus() == GameBoard[4].gameStatus()) && (GameBoard[0].gameStatus() == GameBoard[8].gameStatus()) ) ) <<orange/>return<<white/> GameBoard[0].gameStatus() ;"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ( GameBoard[2].gameStatus() <= 1 && ((GameBoard[2].gameStatus() == GameBoard[4].gameStatus()) && ( GameBoard[2].gameStatus() == GameBoard[6].gameStatus() )) ) <<orange/>return<<white/> GameBoard[2].gameStatus();"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>// check if game is still playing O(N)"+
            "<<white/><<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i=0; i < 9; i++) <<lightblue/>if<<white/> (GameBoard[i].gameStatus() == 3) <<orange/>return<<white/> 3;             <<grey/>// game is still running "+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>return<<white/> 2;                                                                           <<grey/>// game is draw (finished)"+
            "<<white/><<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Set a new currentBoard Number "+
            "* @return T on success, else F;"+
            "*/<<white/>"+
            "<<<sp_0, br/><<orange/>bool<<white/> NineBoardGame::setBoard(<<orange/>int<<white/> x, <<orange/>int<<white/> y){"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (!isValidBoard(x, y)) <<orange/>return<<white/> false;                                              <<grey/>// if boardNo mapped by x,y is not valid"+
            "<<white/><<<sp_3, br/>"+
            "<<<sp_3, br/>currentBoard = getBoardNo(x,y);                                                     <<grey/>// change currentBoard"+
            "<<white/><<<sp_3, br/><<orange/>return<<white/> true;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** "+
            "* Draw the associated line of the board based on the currentBoard number and isSelected value. "+
            "* "+
            "* The isSelected value represent the generated line and its relation with  the currentBoard number, as such : "+
            "*      isSelected = true IFF the current line being generated is that of the currentBoard (assuming 3x3 board), else false to draw default line."+
            "* @return String value of the line"+
            "* */<<white/>"+
            "<<<sp_0, br/>string NineBoardGame::drawLine(<<orange/>bool<<white/> isSelected)"+
            "<<<sp_0, br/>{   "+
            "<<<sp_3, br/>string Selected_Line = ' *** *** ***';                              "+
            "<<<sp_3, br/>string Non_SelectedLine = ' ___ ___ ___'; "+
            "<<<sp_3, br/>string gapSpacing = '    ';"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>int<<white/> lineSize = ((isSelected) ? Selected_Line.length() : Non_SelectedLine.length()) + (Non_SelectedLine.length() *2) + (gapSpacing.length()*2) + 1 ; <<grey/>// size of the line"+
            "<<white/><<<sp_3, br/><<orange/>char<<white/> lineHolder[ lineSize ];                                                                    <<grey/>// line holder"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>// Generate the 3 block lines"+
            "<<white/><<<sp_3, br/><<orange/>char<<white/>* linePtr = lineHolder;                                                                     <<grey/>// Pointer to current position in 'line'"+
            "<<white/><<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = 0; i < 3; i++) {               "+
            "<<<sp_7, br/>string* currentLine = ((i == currentBoard % 3 && isSelected)) ? &Selected_Line : &Non_SelectedLine;"+
            "<<<sp_7, br/><<lightblue/>for<<white/>(<<orange/>char<<white/> c : *currentLine)  *linePtr++ = c;                                                <<grey/>// iterate and assign all char in currentLine"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> (i != 2)<<lightblue/>for<<white/> (<<orange/>char<<white/> c : gapSpacing) *linePtr++ = c;                                       <<grey/>// IF NOT last line, Assign gap spacing"+
            "<<white/><<<sp_3, br/>}"+
            "<<<sp_3, br/>*linePtr = '\0';                                                                                <<grey/>// EOL Null-termination char"+
            "<<white/><<<sp_3, br/><<orange/>return<<white/> string(lineHolder);"+
            "<<<sp_0, br/>} "+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Display the 3x3 Nineboard tictactoe game */<<white/>"+
            "<<<sp_0, br/><<orange/>void<<white/> NineBoardGame::DisplayBoards(){                                                               "+
            "<<<sp_3, br/><<grey/>// ITERATE ALL BOARD & PRINT RESULT"+
            "<<white/><<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>int<<white/> i = 0; ; i++) "+
            "<<<sp_3, br/>{                                                                        "+
            "<<<sp_7, br/><<orange/>int<<white/> x,y, boardNo;"+
            "<<<sp_7, br/>this->get_SubIndices( i ,&x,&y, &boardNo);                                                  <<grey/>// get board Number and positition; "+
            "<<white/><<<sp_7, br/><<grey/>// LAST GAME BLOCK (9x9)"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/>(i == 81) {                                                                             "+
            "<<<sp_9, br/><<orange/>cout<<white/> <-- '\n' <-- drawLine((currentBoard >= 6 && currentBoard <= 8)) <-- endl;             <<grey/>// getLine : True IFF 6 <= currentBoard <= 8."+
            "<<white/><<<sp_9, br/><<orange/>break<<white/>;                                                          "+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<grey/>// NEXT GAME LINE; "+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> (i % 9 == 0 && i != 0) <<orange/>cout<<white/> <-- '\n' <-- drawLine( ( (x ==0 && y ==0) && (currentBoard >= boardNo-3 && currentBoard <= (boardNo-3)+2) ) ) <-- endl; "+
            "<<<sp_7, br/><<grey/>// NEXT GAME BLOCK "+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> (i % 27 == 0) <<orange/>cout<<white/> <-- '\n' <-- drawLine( (currentBoard >= boardNo && currentBoard <= (boardNo+2)) ) <-- endl;                              <<grey/>// getLine : True IFF currentBoard is contained in boardNo's row."+
            "<<white/><<<sp_4, br/>"+
            "<<<sp_7, br/><<grey/>// PRINT ELEMENT VALUE"+
            "<<white/><<<sp_7, br/><<orange/>int<<white/> value = GameBoard[boardNo].At(x,y);                                                                                                     <<grey/>// get value of associated indices"+
            "<<white/><<<sp_7, br/><<orange/>cout<<white/> <-- ((currentBoard == boardNo && x == 0) ? '* ' : '| ' )<-- ( (value != 0) ? ((value == 1) ? 'X' : 'O')  : ' ') <-- ' ' ;                 <<grey/>// insert element and Starting borderr"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> ( (i+1) % 3 == 0 ) <<orange/>cout<<white/> <-- ((currentBoard == boardNo) ?  '*   ' : '|   ');                                                               <<grey/>// closing borderr "+
            "<<white/><<<sp_3, br/>}"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Check if given board-indices exist on gameBoard WHERE boardIndices is the expanded 9x9 board. */<<white/>"+
            "<<<sp_0, br/><<orange/>bool<<white/> NineBoardGame::isValidIndices(<<orange/>int<<white/> boardIndice){"+
            "<<<sp_3, br/><<orange/>return<<white/> (boardIndice >=0 && boardIndice < (sizeof(GameBoard) / sizeof(TicTacToe)) * (GameBoard[0].column * GameBoard[0].row)); "+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "<<<sp_0, br/>*   Return the GameBoard number based on the given Board_indices. "+
            "<<<sp_0, br/>*"+
            "<<<sp_0, br/>*   This function maps the given board_indice to its associated BoardNumber."+
            "<<<sp_0, br/>*   This board_indice represent elements in the GameBoard when combined in a 3x3 structure"+
            "<<<sp_0, br/>*   s.t. if indices = 7, then GameBoard = 3. I.e. where indices = 0 = first cell in gameBoard "+
            "<<<sp_0, br/>*   and indices = (|N_GameBoard| * gameSize(TicTacToe Board) )-1 = (9 x 9)-1 = 80 the last. "+
            "<<<sp_0, br/>*"+
            "<<<sp_0, br/>*   @return GameBoard of given indices x in {Real number 1,...9}, or -1 if invalid indices is given."+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>int<<white/> NineBoardGame::getBoardNumber(<<orange/>int<<white/> boardIndice, <<orange/>int<<white/> columnSize){"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (!isValidIndices(boardIndice))  <<orange/>return<<white/> -1;"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>int<<white/> row_Pos = floor(boardIndice / (columnSize * ( GameBoard[0].column * GameBoard[0].row ) )); "+
            "<<<sp_3, br/><<orange/>int<<white/> col_Pos = (boardIndice / columnSize) % columnSize;"+
            "<<<sp_3, br/><<orange/>return<<white/> (col_Pos+1 + (row_Pos * columnSize));"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "*  Given some board_indice, we return its associated x,y and boardNumber position, that is :"+
            "*      board_indices --maps--> GameBoard[getBoardNumber(board_Indices)].at(x,y). "+
            "*  The values are assigned to the x,y,boardNumber references passed."+
            "* "+
            "*  Note :  Game 3x3 board size is assumed, else incorrect mapping will be produced ;"+
            "<<<sp_0, br/>*/<<white/>"+
            "<<<sp_0, br/><<orange/>void<<white/> NineBoardGame::get_SubIndices(<<orange/>int<<white/> boardIndice, <<orange/>int<<white/>* x, <<orange/>int<<white/>*y, <<orange/>int<<white/> *BoardNumber)"+
            "<<<sp_0, br/>{"+
            "<<<sp_3, br/><<orange/>int<<white/> boardNumber = getBoardNumber(boardIndice)-1;"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (boardNumber == -1) <<orange/>return<<white/>;"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>int<<white/> colSize = 3;                                                                        <<grey/>// generic board column size"+
            "<<white/><<<sp_3, br/><<orange/>int<<white/> rowSize = 3;                                                                        <<grey/>// generic board row size"+
            "<<white/><<<sp_3, br/><<orange/>int<<white/> positionWithinBoardX = boardIndice % (colSize * rowSize);                           <<grey/>// repersent X position in 3x3 main-board"+
            "<<white/><<<sp_3, br/><<orange/>int<<white/> positionWithinBoardY = floor(boardNumber / 3);                                      <<grey/>// represent Y position in 3x3 main-board"+
            "<<white/><<<sp_3, br/><<orange/>int<<white/> subboardX = positionWithinBoardX % colSize;                                         <<grey/>// represent X position in 3x3 sub-board"+
            "<<white/><<<sp_3, br/><<orange/>int<<white/> subboardY = 2;                                                                      <<grey/>// represent Y position in 3x3 sub-Board , default at 3nd row.              "+
            "<<white/><<<sp_3, br/><<orange/>int<<white/> StartBoardNumber = (positionWithinBoardY * ((colSize * rowSize) *3) ) + ((boardNumber % 3) * 3) ;   <<grey/>// indices at board[boardNumber] block;"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ( boardIndice >= StartBoardNumber && boardIndice <= StartBoardNumber+ (colSize-1) )  <<grey/>// if within first row range"+
            "<<white/><<<sp_7, br/>subboardY = 0;  <<grey/>// set subBoard Y --> first row"+
            "<<white/><<<sp_3, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (boardIndice >= (StartBoardNumber+(colSize*rowSize)) &&                        "+
            "<<<sp_10, br/>boardIndice <= (StartBoardNumber+ ((colSize*rowSize) + (colSize-1)) ) )        <<grey/>// else, if within second row range"+
            "<<white/><<<sp_7, br/>subboardY = 1;   <<grey/>// set subBoard Y --> second row;"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>// assign values to references"+
            "<<white/><<<sp_3, br/>*x = subboardX;"+
            "<<<sp_3, br/>*y = subboardY;"+
            "<<<sp_3, br/>*BoardNumber = boardNumber;"+
            "<<<sp_0, br/>};"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"   
        }
    ]
}

export default CaroselItem;
import  {items} from '../../../../contentCarousel/contentCarosel';
 
let CaroselItem : items =    {
    key : 1,
    Subject : "CaphalopodGame (AI)",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}

            <p>  
                This program implements the driver and logic for the Cephalopod Game. 
                The implementation covers various aspects, including board size selection, automatic turn management, player types (human and computer), 
                efficient move generation, heuristic-based decision-making, and Classical AI Search algorithms.
            </p>



            <h3 style = {{textDecoration:'underline'}}> Game Description : </h3>
            <p>
                Cephalopod Game is a strategic board game where two players take turns to place dices onto a grid board. 
                The standard size of the board is 5x5, but in theory it can be played on any rectangle board, such as 2x3, 3x3, 5x3 and so on. 
                Each player has as many dices as needed in respective colours, say white and black. When a player has a turn, he can place a 
                dice of his colour on to the board at any unoccupied cell. The newly placed dice may capture up to four dices vertically and horizontally 
                adjacent to it, depending on the numbers and the pip counts of the adjacent dices. 
                
                The target of a player is to occupy the majority of the cells (no matter how many the total pip counts are) on the board to win a game. 
                If the total occupied cells are the same, the game terminates with a draw.
            </p>
            
            <h3 style = {{textDecoration:'underline'}}> Program Analysis : </h3>
            <div style = {{boxSizing:'border-box', marginLeft:'5%'}}>
                <p>
                    The computation complexity for generating and adding valid random move operate in O(n) worse-case time.( Note : This analysis is mainly
                    based on the java implementation )
                </p>
                <p>
                    We first consider the type of datastructure to use in supports of best insertion and deletion time complexity. 
                    Here a simple vector (in c++) and LinkedList (java) is being utilised for storing empty-cells of the board. 
                    The overall complexity for generating random moves will be as- good as the selected data-structure, more specifically insertion and deletion time.
                </p>
                <p style = {{textDecoration:'underline'}}> 
                    Other Datastructure Considerations :
                </p>
                <p style ={{ marginLeft:'3%', boxSizing:'border-box' }}>
                    1. Hash map : O(1) Best-Case insertion and deletion time.
                    <br></br>
                    2. AVL/BinaryTree : Log n insertion and deletion time.
                </p>

                <p>
                    The initial setup for empty-cell (vector) requires nested for-loops iterating all the rows and columns of the board, where the size N = m*n. 
                    For each iteration, elements(integer) are first generated and then inserted (unordered) into the vector using generateIndex()
                    and .pushBack(), both of which operates in O(1) time.
                </p>
                <p>
                    Each invocation of the function getMove(), a random move will be generated through the combination of the function rand(), the modulo operator + the size of emptyCell, 
                    and the assignment of the result to the randomMove variable. Generating random move operate in O(1) time.
                </p>
                <p>
                    Update operations In the case when new cell entires are added to board are done through entry deletion in the vector. A linear search is utilised to locate this 
                    entry (unordered). This operate in O(n) worse- case time. Capturing placements through the function capturingPlacement() operates in constant time, preforming a constant 
                    number of operation each time in checking, summing and removing the 4 neighbouring cells at most.
                </p>
                <p>
                    Conclusively, to support the operations for generating a valid random entry, a initial setup and further update of the vector is required, the update (or deletion) operate in O(n) time as with the initial setup, so the overall computational complexity for generating and adding valid random move is O(n).
                </p>



            </div>

        </>,
    img_Url:"./images/Project_Images/catapod.jpg",        
    file_List : [
        {
            key : 1,
            FileTitle : "main.java",
            content : 
            "<<white/><<<sp_0, br/>package Cephalopod;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.PrintStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.ArrayList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Comparator;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Iterator;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.LinkedList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.List;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.PriorityQueue;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Queue;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> main {"+
            "<<<sp_1, br/><<orange/>static<<white/> Scanner s = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/*********** Declaration*******"+
            "<<<sp_0, br/>I hereby certify that no part of this assignment has been copied from any other student￢ﾀﾙs "+
            "<<<sp_0, br/>work or from any other source. No part of the code has been written/produced for me by "+
            "<<<sp_0, br/>another person."+
            "<<<sp_0, br/>I hold a copy of this assignment that I can produce if the original is lost or damaged."+
            "<<<sp_0, br/>**************************/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> PrintStream p = <<orange/>new<<white/> PrintStream(<<orange/>system<<white/>.out);"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(String[] args)"+
            "<<<sp_1, br/>{"+
            "<<<sp_2, br/>int x,y;"+
            "<<<sp_2, br/><<lightblue/>while<<white/>(true)"+
            "<<<sp_2, br/>{"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('Input size of the board (either 3*3, 3*5 or 5*5): ');"+
            "<<<sp_3, br/>x = s.nextInt();"+
            "<<<sp_3, br/>y = s.nextInt();"+
            "<<<sp_3, br/><<lightblue/>if<<white/>((x >=3 && y >=3) && (x <6 && y < 6))"+
            "<<<sp_4, br/><<orange/>break<<white/>;"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/>Board b = <<orange/>new<<white/> Board(x, y);"+
            "<<<sp_2, br/>Player[] players = <<orange/>new<<white/> Player[2];"+
            "<<<sp_2, br/>setPlayers(players);"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.println(players[0].getPlayerName() + ' vs ' + players[1].getPlayerName());"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>CephalopodGame c = <<orange/>new<<white/> CephalopodGame(b, players);"+
            "<<<sp_2, br/>c.play();"+
            "<<<sp_1, br/>"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/><<grey/>/*"+
            "<<<sp_2, br/>*"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> setPlayers(Player[] p)"+
            "<<<sp_1, br/>{"+
            "<<<sp_2, br/>displayMenu();"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.println('Enter your choice: ');"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<lightblue/>import<<white/> (s.next().toLowerCase())"+
            "<<<sp_2, br/>{"+
            "<<<sp_3, br/><<lightblue/>case<<white/> 'a' :"+
            "<<<sp_4, br/>p[0] = <<orange/>new<<white/> HumanPlayer(1);"+
            "<<<sp_4, br/>p[1] = <<orange/>new<<white/> ComputerPlayer(-1);"+
            "<<<sp_4, br/><<orange/>break<<white/>;"+
            "<<<sp_3, br/><<lightblue/>case<<white/> 'b' :"+
            "<<<sp_4, br/>p[0] = <<orange/>new<<white/> ComputerPlayer(1);"+
            "<<<sp_4, br/>p[1] = <<orange/>new<<white/> RandomPlayer(-1);"+
            "<<<sp_4, br/><<orange/>break<<white/>;"+
            "<<<sp_3, br/><<lightblue/>case<<white/> 'c' :"+
            "<<<sp_4, br/>p[0] = <<orange/>new<<white/> RandomPlayer(1);"+
            "<<<sp_4, br/>p[1] = <<orange/>new<<white/> MinimaxPlayer(-1);"+
            "<<<sp_4, br/><<orange/>break<<white/>;"+
            "<<<sp_3, br/><<lightblue/>case<<white/> 'd' :"+
            "<<<sp_4, br/>p[0] = <<orange/>new<<white/> ComputerPlayer(1);"+
            "<<<sp_4, br/>p[1] = <<orange/>new<<white/> MinimaxPlayer(-1);"+
            "<<<sp_4, br/><<orange/>break<<white/>;"+
            "<<<sp_3, br/><<lightblue/>case<<white/> 'e' :"+
            "<<<sp_4, br/>p[0] = <<orange/>new<<white/> HumanPlayer(1);"+
            "<<<sp_4, br/>p[1] = <<orange/>new<<white/> MinimaxPlayer(-1);"+
            "<<<sp_4, br/><<orange/>break<<white/>;"+
            "<<<sp_2, br/>"+
            "<<<sp_3, br/>default :"+
            "<<<sp_4, br/><<orange/>system<<white/>.out.println('Enter a valid choice');"+
            "<<<sp_4, br/>setPlayers(p);"+
            "<<<sp_4, br/><<orange/>break<<white/>;"+
            "<<<sp_2, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> displayMenu()"+
            "<<<sp_1, br/>{"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.println('=====MENU=====');"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.println('A = Human Player vs Heuristic Player');"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.println('B = Heuristic Player vs Random Player');"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.println('C = Random vs Minimax Player');"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.println('D = Heuristic vs Minimax Player');"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.println('E = Human Player vs Minimax Player');"+
            "<<<sp_2, br/><<orange/>system<<white/>.out.println('==============');"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"
        },
        {
            key : 2,
            FileTitle : "Board.java",
            content : 
            "<<white/><<<sp_0, br/>package Cephalopod;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.ArrayList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Comparator;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.LinkedList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.List;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.PriorityQueue;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Queue;"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> Board  {"+
            "<<<sp_1, br/><<grey/>//CellHeuristicComparator arrangement elements from Largest to smallest"+
            "<<white/><<<sp_1, br/><<orange/>static<<white/> Comparator<Cell> heuristicComparator = <<orange/>new<<white/> Comparator<>() "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>@Override"+
            "<<<sp_3, br/><<orange/>public<<white/> int compare(Cell o1, Cell o2) {"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (o1.heuristic > o2.heuristic) <<orange/>return<<white/> -1;"+
            "<<<sp_5, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (o1.heuristic == o2.heuristic)<<orange/>return<<white/> 0;"+
            "<<<sp_5, br/><<lightblue/>else<<white/> <<orange/>return<<white/> 1;"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>};"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>Board(Board b)"+
            "<<<sp_1, br/>{	"+
            "<<<sp_3, br/>row = b.row;"+
            "<<<sp_3, br/>col = b.col;"+
            "<<<sp_3, br/>player = b.player;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>grid = <<orange/>new<<white/> int[row][col];"+
            "<<<sp_3, br/>EmptyIndexes = <<orange/>new<<white/> LinkedList<>();"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(int i=0; i < row; i++)"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(int j =0; j < col; j++)"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>grid[i][j] = b.grid[i][j];"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(grid[i][j] == 0 )"+
            "<<<sp_8, br/>EmptyIndexes.add(generateIndex(i,j));"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>int row,col;"+
            "<<<sp_1, br/><<orange/>private<<white/> int player; <<grey/>//1 = white; -1 = black"+
            "<<white/><<<sp_1, br/>int[<<grey/>/*row*/<<white/>][<<grey/>/*col*/<<white/>] grid;"+
            "<<<sp_1, br/>List<Integer> EmptyIndexes;"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>Board(int r, int c)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>row = r;"+
            "<<<sp_3, br/>col = c;"+
            "<<<sp_3, br/>grid = <<orange/>new<<white/> int[row][col];"+
            "<<<sp_3, br/>EmptyIndexes = <<orange/>new<<white/> LinkedList<>();"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(int i=0; i < row; i++)"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(int j =0; j < col; j++)"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>grid[i][j] = 0;"+
            "<<<sp_6, br/>EmptyIndexes.add(generateIndex(i,j));"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>printBoard();"+
            "<<<br,hidden/>br"+
            "<<white/><<<br,hidden/>br"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>player = 1;"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> List<Integer> getEmptyCells()"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>List <Integer> t = <<orange/>new<<white/> LinkedList<>();"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(int i : EmptyIndexes)"+
            "<<<sp_5, br/>t.add(i);"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> EmptyIndexes;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Given a cell, return a value 'k' that is representative of the x and y position."+
            "<<<sp_2, br/>*  	k = x*n+y where  n = height of the board"+
            "<<<sp_2, br/>* @param ypos"+
            "<<<sp_2, br/>* @param xpos"+
            "<<<sp_2, br/>* @return k"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> int generateIndex(int x, int y )"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>return<<white/> (y * row )+ x;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Given a integer 'k', return a Cell object representative of its location, that is the x and y pos"+
            "<<<sp_2, br/>* 		x= k/n  where 'n' = height of the map"+
            "<<<sp_2, br/>* 		y= k% n"+
            "<<<sp_2, br/>* @param c"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> Cell generatePosition(int k)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>int y = k/row;"+
            "<<<sp_3, br/>int x = k%row;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> <<orange/>new<<white/> Cell(x, y, 0);"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* 	Given the x,y position "+
            "<<<sp_2, br/>*		store the number of neighbours it has (up,down,left,right) in a sorted structure (smallest to largest pip)"+
            "<<<sp_2, br/>* 		IF number of neighbors > 1 "+
            "<<<sp_3, br/>*			let sum = neighbor[1] + neighbor[2] "+
            "<<<sp_2, br/>*			if (sum > 6)"+
            "<<<sp_2, br/>*				we return player;"+
            "<<<sp_3, br/>*			else"+
            "<<<sp_3, br/>* 				remove neighbors[1] and neighbors[2] from the board"+
            "<<<sp_3, br/>*				we iterate the remainder of neighbors if any"+
            "<<<sp_2, br/>*					where sum + neighbor[current] < 6, WE DO"+
            "<<<sp_2, br/>*						sum = sum + neighbor[current] "+
            "<<<sp_3, br/>*						remove neighbor[current] from board"+
            "<<<sp_3, br/>*	O(1) INSERTION TIME"+
            "<<<sp_3, br/>*	n logn sort time"+
            "<<<sp_3, br/>* 	O(1) removal time"+
            "<<<sp_2, br/>*	return sum. "+
            "<<<sp_2, br/>* @param x"+
            "<<<sp_2, br/>* @param y"+
            "<<<sp_2, br/>* @return"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/>int capturingPlacement(int x, int y)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>Cell ch;<<grey/>//cell holder"+
            "<<white/><<<sp_3, br/>List<Cell> n = <<orange/>new<<white/> ArrayList<>(); "+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>//if up neighbor exists && is non-empty"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/>(! ((x-1) < 0) && grid[x-1][y] != 0 ) {"+
            "<<<sp_5, br/><<grey/>// create a new cell, storing absolute value of UP-CELL (pip-count) into heuristic variable.   "+
            "<<white/><<<sp_5, br/>ch = <<orange/>new<<white/> Cell(x-1, y, (grid[x-1][y] < 0 ) ?  - grid[x-1][y] : grid[x-1][y]   );"+
            "<<<sp_5, br/>n.add(ch);	"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(!(x+1 > row-1) && grid[x+1][y] != 0 ){"+
            "<<<sp_5, br/>ch = <<orange/>new<<white/> Cell(x+1, y, (grid[x+1][y] < 0 ) ?  - grid[x+1][y] : grid[x+1][y]   );"+
            "<<<sp_5, br/>n.add(ch); "+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(!((y-1) < 0) && grid[x][y-1] != 0 ) {"+
            "<<<sp_5, br/>ch = <<orange/>new<<white/> Cell(x, y-1, (grid[x][y-1] < 0 ) ?  - grid[x][y-1] : grid[x][y-1]   );"+
            "<<<sp_5, br/>n.add(ch); "+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(!((y+1) > col-1) && grid[x][y+1] != 0 ){"+
            "<<<sp_5, br/>ch = <<orange/>new<<white/> Cell(x, y+1, (grid[x][y+1] < 0 ) ?  - grid[x][y+1] : grid[x][y+1]   );"+
            "<<<sp_5, br/>n.add(ch); }"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ( n.size() > 1 )  {"+
            "<<<sp_5, br/>n.sort(heuristicComparator); <<grey/>//nlog(n) worse case"+
            "<<white/><<<sp_5, br/>ch = n.remove(n.size()-1);"+
            "<<<sp_5, br/>int sum = ch.heuristic;"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (sum + n.get(n.size()-1).heuristic > 6) <<orange/>return<<white/> player;"+
            "<<<sp_5, br/>remove(ch.x,ch.y); <<grey/>//remove first entry "+
            "<<white/><<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>while<<white/>(!n.isEmpty()){ <<grey/>//o(n) worse case"+
            "<<white/><<<sp_6, br/><<lightblue/>if<<white/> (sum + n.get(n.size()-1).heuristic > 6)"+
            "<<<sp_8, br/><<orange/>break<<white/>;"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>ch = n.remove(n.size()-1);"+
            "<<<sp_6, br/>sum = sum + ch.heuristic;"+
            "<<<sp_6, br/>remove(ch.x,ch.y);"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<orange/>return<<white/> (player == -1) ? -sum : sum;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> player;			"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Remove x,y from board. update EmptyIndexes "+
            "<<<sp_2, br/>* @param x"+
            "<<<sp_2, br/>* @param y"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/>boolean remove(int x, int y) {"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (x < 0 || y < 0 || x > row - 1 || y > col - 1 )"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>grid[x][y] = 0;"+
            "<<<sp_3, br/>EmptyIndexes.add(generateIndex(x,y));"+
            "<<<sp_3, br/><<orange/>return<<white/> true;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Add x,y to board, update emptyIndexes"+
            "<<<sp_2, br/>* @param p"+
            "<<<sp_2, br/>* @param x"+
            "<<<sp_2, br/>* @param y"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/>boolean addMove(int p, int x, int y){"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (p != player) {"+
            "<<<sp_5, br/>main.p.println( 'It is not the player's turn!');"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (!validMove(x, y))"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>grid[x][y] = capturingPlacement(x, y);"+
            "<<<sp_3, br/>player = -1 * player;"+
            "<<<sp_3, br/>EmptyIndexes.remove( (Integer)generateIndex(x,y) );"+
            "<<<sp_3, br/><<orange/>return<<white/> true;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>boolean validMove(int x, int y){"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (x < 0 || y < 0 || x > row - 1 || y > col - 1)"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (grid[x][y] != 0) "+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/><<orange/>return<<white/> true;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>boolean isBoardFull(){"+
            "<<<sp_3, br/><<lightblue/>for<<white/> (int i = 0; i < row; i++) {"+
            "<<<sp_5, br/><<lightblue/>for<<white/> (int j = 0; j < col; j++) {"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(grid[i][j] == 0)"+
            "<<<sp_8, br/><<orange/>return<<white/> false;"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> true;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Get current player "+
            "<<<sp_2, br/>* @return -1 (black) or 1(white)"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/>int getTurn(){"+
            "<<<sp_3, br/><<orange/>return<<white/> player;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* "+
            "<<<sp_2, br/>* @return 1 (white win), -1 (black wins) , 0 (draw), -2 (board ! full)"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/>int checkWinningStatus()"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (!isBoardFull())"+
            "<<<sp_5, br/><<orange/>return<<white/> -2; <<grey/>//error message"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_3, br/>int counter = 0;"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<lightblue/>for<<white/> (int i = 0; i < row; i++) {"+
            "<<<sp_5, br/><<lightblue/>for<<white/> (int j = 0; j < col; j++) {"+
            "<<<sp_6, br/><<lightblue/>if<<white/> (grid[i][j] > 0)"+
            "<<<sp_8, br/>counter++;"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (counter * 2 > row * col)"+
            "<<<sp_5, br/><<orange/>return<<white/> 1; <<grey/>//white player won"+
            "<<white/><<<sp_3, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (counter * 2 < row * col)"+
            "<<<sp_5, br/><<orange/>return<<white/> -1; <<grey/>//black player won"+
            "<<white/><<<sp_3, br/><<lightblue/>else<<white/>"+
            "<<<sp_5, br/><<orange/>return<<white/> 0;<<grey/>//draw"+
            "<<white/><<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>void<<white/> printBoard()"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>main.p.print('    ');"+
            "<<<sp_3, br/><<lightblue/>for<<white/> (int j =0; j < col; j++)"+
            "<<<sp_5, br/>main.p.print( (j + 1) + '   ');"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>main.p.println();"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(int i =0; i < row; i++)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>main.p.print('  ');"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(int j=0; j < col; j++)"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>main.p.print(' ---');"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/>main.p.println();"+
            "<<<sp_5, br/>main.p.print( (i+1) + ' |');"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(int j =0; j < col; j++)"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(grid[i][j]  ==0)"+
            "<<<sp_6, br/>{"+
            "<<<sp_8, br/>main.p.print('   |');"+
            "<<<sp_6, br/>}<<lightblue/>else<<white/> <<lightblue/>if<<white/> (grid[i][j] > 0 )"+
            "<<<sp_6, br/>{"+
            "<<<sp_8, br/>main.p.print( ' '  + grid[i][j] + ' |');"+
            "<<<sp_6, br/>}<<lightblue/>else<<white/> <<lightblue/>if<<white/> (grid[i][j] < 0)"+
            "<<<sp_6, br/>{"+
            "<<<sp_8, br/>main.p.print('' + grid[i][j] + ' |');"+
            "<<<sp_6, br/>}"+
            "<<<sp_8, br/>"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>main.p.println();"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_3, br/>main.p.print('  ');"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(int j =0; j < col; j++)"+
            "<<<sp_5, br/>main.p.print(' ---');"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>main.p.println();"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Given an x,y pos return neighboring pip as a whole number if any. Does not remove entry from grid.  "+
            "<<<sp_2, br/>* @param x"+
            "<<<sp_2, br/>* @param y"+
            "<<<sp_2, br/>* @return whole number neighbouring pip"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/>int getNeighbourPip(int x, int y)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>Cell ch;<<grey/>//cell holder"+
            "<<white/><<<sp_3, br/>List<Cell> n = <<orange/>new<<white/> ArrayList<>(); "+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>//if up neighbor exists && is non-empty"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/>(! ((x-1) < 0) && grid[x-1][y] != 0 ) {"+
            "<<<sp_5, br/><<grey/>// create a new cell, storing absolute value of UP-CELL (pip-count) into heuristic variable.   "+
            "<<white/><<<sp_5, br/>ch = <<orange/>new<<white/> Cell(x-1, y, (grid[x-1][y] < 0 ) ?  - grid[x-1][y] : grid[x-1][y]   );"+
            "<<<sp_5, br/>n.add(ch);	"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(!(x+1 > row-1) && grid[x+1][y] != 0 ){"+
            "<<<sp_5, br/>ch = <<orange/>new<<white/> Cell(x+1, y, (grid[x+1][y] < 0 ) ?  - grid[x+1][y] : grid[x+1][y]   );"+
            "<<<sp_5, br/>n.add(ch); "+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(!((y-1) < 0) && grid[x][y-1] != 0 ) {"+
            "<<<sp_5, br/>ch = <<orange/>new<<white/> Cell(x, y-1, (grid[x][y-1] < 0 ) ?  - grid[x][y-1] : grid[x][y-1]   );"+
            "<<<sp_5, br/>n.add(ch); "+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(!((y+1) > col-1) && grid[x][y+1] != 0 ){"+
            "<<<sp_5, br/>ch = <<orange/>new<<white/> Cell(x, y+1, (grid[x][y+1] < 0 ) ?  - grid[x][y+1] : grid[x][y+1]   );"+
            "<<<sp_5, br/>n.add(ch); }"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ( n.size() > 1 )  {"+
            "<<<sp_5, br/>n.sort(heuristicComparator); <<grey/>//nlog(n) worse case"+
            "<<white/><<<sp_5, br/>ch = n.remove(n.size()-1);"+
            "<<<sp_5, br/>int sum = ch.heuristic;"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (sum + n.get(n.size()-1).heuristic > 6) <<orange/>return<<white/> player;"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<lightblue/>while<<white/>(!n.isEmpty()){ <<grey/>//o(n) worse case"+
            "<<white/><<<sp_6, br/><<lightblue/>if<<white/> (sum + n.get(n.size()-1).heuristic > 6)"+
            "<<<sp_8, br/><<orange/>break<<white/>;"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>ch = n.remove(n.size()-1);"+
            "<<<sp_6, br/>sum = sum + ch.heuristic;"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<orange/>return<<white/> sum;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> 0;			"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Given an x,y pos return the whole number. "+
            "<<<sp_2, br/>* @param x"+
            "<<<sp_2, br/>* @param y"+
            "<<<sp_2, br/>* @return whole number of x,y cell"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/>int getPositionPip(int x, int y)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>int r = grid[x][y];"+
            "<<<sp_3, br/><<orange/>return<<white/> (r < 0 ) ? -r : r;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Determine if a given position x,y can be overtaken in either of its 4 axis by neighboring cells. "+
            "<<<sp_2, br/>* @param b"+
            "<<<sp_2, br/>* @param pos"+
            "<<<sp_2, br/>* @return"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> boolean canBeOvertaken(Board b, int x, int y)"+
            "<<<sp_1, br/>{ 	"+
            "<<<sp_3, br/>b.addMove(b.getTurn(), x, y);"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>//check LEFT-upper "+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/>( (((x - 1) >= 0 && (y - 1) >=0)  && ( b.grid[x-1][y-1] != 0)) && ( b.getPositionPip(x-1, y-1) + b.getPositionPip(x, y) <= 6  ) ) 		"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (b.grid[x-1][y] == 0 || b.grid[x][y-1] == 0 ) "+
            "<<<sp_6, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/><<lightblue/>if<<white/>( ((x - 1) >= 0 && (y + 1) < b.col)  && ( b.grid[x-1][y+1] != 0) && ( b.getPositionPip(x-1, y+1) + b.getPositionPip(x, y) <= 6  )) "+
            "<<<sp_5, br/><<lightblue/>if<<white/> (b.grid[x-1][y] == 0 || b.grid[x][y+1] == 0 )"+
            "<<<sp_6, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/><<grey/>//check LEFT-LOWER"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/>( ((x + 1) < b.row && (y - 1) >= 0)  && ( b.grid[x+1][y-1] != 0) &&  ( b.getPositionPip(x+1, y-1) + b.getPositionPip(x, y) <= 6  )) "+
            "<<<sp_5, br/><<lightblue/>if<<white/> (b.grid[x][y-1] == 0 || b.grid[x+1][y] == 0 )"+
            "<<<sp_6, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/><<grey/>//check RIGHT-LOWER"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/>( ((x + 1) < b.row && (y + 1) < b.col)  && ( b.grid[x+1][y+1] != 0) && ( b.getPositionPip(x+1, y+1) + b.getPositionPip(x, y) <= 6  )) "+
            "<<<sp_5, br/><<lightblue/>if<<white/> (b.grid[x+1][y] == 0 || b.grid[x][y+1] == 0 )"+
            "<<<sp_6, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/><<grey/>//check left intersection"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> ( ((y - 2) >=0 && b.grid[x][y-2] != 0) && b.grid[x][y-1] == 0 && ( b.getPositionPip(x, y-2) + b.getPositionPip(x, y) <= 6  ))"+
            "<<<sp_6, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/><<grey/>//check right intersection"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> ( ((y + 2) < b.col && b.grid[x][y+2] != 0) && b.grid[x][y+1] == 0 &&  ( b.getPositionPip(x, y+2) + b.getPositionPip(x, y) <= 6  ))"+
            "<<<sp_6, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/><<grey/>//check upper intersection"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> ( ((x - 2 ) >= 0 && b.grid[x-2][y] != 0) && b.grid[x-1][y] == 0 && ( b.getPositionPip(x-2, y) + b.getPositionPip(x, y) <= 6  ))"+
            "<<<sp_6, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/><<grey/>//check lower intersection"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> ( ((x + 2 ) < b.row && b.grid[x+2][y] != 0) && b.grid[x+1][y] == 0 && ( b.getPositionPip(x+2, y) + b.getPositionPip(x, y) <= 6  ) )"+
            "<<<sp_6, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> false;"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"
        },
        {
            key : 3,
            FileTitle : "CephalopodGame.java",
            content : 
            "<<white/><<<sp_0, br/>package Cephalopod;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> CephalopodGame {"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>private<<white/> Board board;"+
            "<<<sp_1, br/><<orange/>private<<white/> Player[] players = <<orange/>new<<white/> Player[2];"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>CephalopodGame(Board b, Player p[])"+
            "<<<sp_1, br/>{"+
            "<<<sp_2, br/>board = b;"+
            "<<<sp_2, br/>players[0] = p[0];"+
            "<<<sp_2, br/>players[1] =  p[1];"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>int play()"+
            "<<<sp_1, br/>{"+
            "<<<sp_2, br/><<grey/>//While board is not full"+
            "<<white/><<<sp_2, br/>int pos[<<grey/>/*0 = x; 1 = y*/<<white/>] = <<orange/>new<<white/> int[2]; <<grey/>//temp position "+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_2, br/>do"+
            "<<<sp_2, br/>{"+
            "<<<sp_3, br/>int playerIndex = (board.getTurn() == 1)? 0:1;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>//Do while move is invalid "+
            "<<white/><<<sp_3, br/><<lightblue/>do<<white/>"+
            "<<<sp_5, br/>players[playerIndex].getMove(board, pos);"+
            "<<<sp_3, br/><<lightblue/>while<<white/>(board.addMove(board.getTurn(), pos[0], pos[1]) == false);"+
            "<<<sp_4, br/>"+
            "<<<sp_3, br/>main.p.println(players[playerIndex].getPlayerName() + ''s move: (' + (pos[0]+1) + ', ' + (pos[1]+1) + ')');"+
            "<<<sp_3, br/>board.printBoard();"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_2, br/>}<<lightblue/>while<<white/>(!board.isBoardFull());"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/>int winner = board.checkWinningStatus();"+
            "<<<sp_2, br/>int playerIndex = (winner == 1) ? 0 : 1;"+
            "<<<sp_2, br/><<lightblue/>if<<white/> (winner != 0) {"+
            "<<<sp_3, br/>main.p.println( 'The winner is ' + players[playerIndex].getPlayerName() + '. Congratulations!');"+
            "<<<sp_3, br/><<orange/>return<<white/> winner;"+
            "<<<sp_2, br/>} <<lightblue/>else<<white/> {"+
            "<<<sp_3, br/>main.p.println('Draw game!');"+
            "<<<sp_3, br/><<orange/>return<<white/> 0;"+
            "<<<sp_2, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"
        },
        {
            key : 4,
            FileTitle : "ComputerPlayer.java",
            content : 
                "<<white/><<<sp_0, br/>package Cephalopod;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.PriorityQueue;"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Queue;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> ComputerPlayer extends Player{"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/>ComputerPlayer( int t) {"+
                "<<<sp_3, br/>super('computer player', t);"+
                "<<<sp_1, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<grey/>/**"+
                "<<<sp_2, br/>*  INPUT : current board instance, x and y position"+
                "<<<sp_2, br/>*  OUTPUT : best x,y position. This is determined by the following priority level:"+
                "<<<sp_2, br/>*   	1. move that cannot be taken over whilst generating the highest pip . "+
                "<<<sp_2, br/>*    	moves that cannot be overtaken is generated as follows : "+
                "<<<sp_2, br/>*     		 	- The current generate pip-count == 6"+
                "<<<sp_2, br/>*    	 		- No diagonal neighbors WHERE neighbors x+1,y-1 cells are occupied (that means it cannot be overtaken)"+
                "<<<sp_2, br/>*     			  NOR neighbors in either 2 index of the cross-section WHERE there is a gap in their intersection"+
                "<<<sp_2, br/>*     				i.e : "+
                "<<<sp_2, br/>*      				If ( current.Cell.x - 1 != 0) then there is no gap in 'x-1' intersection"+
                "<<<sp_2, br/>*      		- our current pipcount + lowest neighbor pip >= 6. (that is diagonal neighbors + cross-section neighbors) "+
                "<<<sp_2, br/>*     		Total heuristic has a total of 13. "+
                "<<<sp_2, br/>*     			Move that cannot be overtaken returns heuristic of 7"+
                "<<<sp_2, br/>*      		highest pipcount Calculated as : 6"+
                "<<<sp_2, br/>*      	"+
                "<<<sp_2, br/>*/<<white/>"+
                "<<<sp_1, br/>@Override"+
                "<<<sp_1, br/><<orange/>void<<white/> getMove(Board board, int[] pos) {"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/>Queue<Cell> pq = <<orange/>new<<white/> PriorityQueue<>(board.heuristicComparator);"+
                "<<<sp_3, br/>int player = 1;"+
                "<<<sp_3, br/>Board b;"+
                "<<<sp_3, br/><<lightblue/>for<<white/>(int i : board.EmptyIndexes)"+
                "<<<sp_3, br/>{"+
                "<<<sp_5, br/>Cell c = board.generatePosition(i);"+
                "<<<sp_5, br/>int pipHeuristic = board.getNeighbourPip(c.x, c.y);"+
                "<<<sp_5, br/>b = <<orange/>new<<white/> Board(board);"+
                "<<<sp_5, br/>pipHeuristic += (b.canBeOvertaken(b,c.x, c.y) == false) ? 7 : 0; "+
                "<<<sp_5, br/>c.heuristic = pipHeuristic;"+
                "<<<sp_5, br/>pq.add(c);"+
                "<<<sp_3, br/>}"+
                "<<<sp_3, br/>pos[0] = pq.peek().x;"+
                "<<<sp_3, br/>pos[1] = pq.peek().y;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
        },
        {
            key : 4,
            FileTitle : "HumanPlayer.java",
            content : 
                "<<white/><<<sp_0, br/>package Cephalopod;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> HumanPlayer extends Player {"+
                "<<<sp_1, br/>Scanner keyboard = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/>HumanPlayer(int t)"+
                "<<<sp_1, br/>{"+
                "<<<sp_3, br/>super('Human', t);"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<grey/>/**"+
                "<<<sp_2, br/>* getPosition from player, if x,y is invalid :"+
                "<<<sp_2, br/>* 		we keep looping. "+
                "<<<sp_2, br/>* updated pos[0]-[1] on success "+
                "<<<sp_2, br/>*/<<white/>"+
                "<<<sp_1, br/>@Override"+
                "<<<sp_1, br/><<orange/>void<<white/> getMove(Board board, int[] pos) "+
                "<<<sp_1, br/>{"+
                "<<<sp_3, br/><<lightblue/>if<<white/>(board.isBoardFull())"+
                "<<<sp_5, br/><<orange/>return<<white/>;"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<lightblue/>do<<white/>{"+
                "<<<sp_5, br/>main.p.println('Input your move with row and column numbers:');"+
                "<<<sp_5, br/>int row, col;"+
                "<<<sp_5, br/><<grey/>//cin >> row >> col;"+
                "<<white/><<<sp_5, br/>pos[0] = keyboard.nextInt()-1;"+
                "<<<sp_5, br/>pos[1] = keyboard.nextInt()-1;"+
                "<<<sp_5, br/>main.p.println('You have entered move : ' + pos[0] + ' , ' + pos[1]);"+
                "<<<sp_3, br/>} <<lightblue/>while<<white/> (!board.validMove(pos[0],pos[1]));"+
                "<<<sp_3, br/>"+
                "<<<sp_3, br/>"+
                "<<<sp_1, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
        },
        {
            key : 5,
            FileTitle : "MinimaxPlayer.java",
            content :
                "<<white/><<<sp_0, br/>package Cephalopod;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Collections;"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Comparator;"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.List;"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.PriorityQueue;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> MinimaxPlayer extends Player{"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/>int steps; "+
                "<<<sp_1, br/>MinimaxPlayer(int t) {"+
                "<<<sp_2, br/>super('Minimax Player', t);"+
                "<<<sp_2, br/>steps = 100000;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<br,hidden/>br"+
                "<<<sp_1, br/>@Override"+
                "<<<sp_1, br/><<orange/>void<<white/> getMove(Board board, int[] pos) {"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_2, br/>int bestHeuristic = -1000;"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/>List<Integer> emptyCells = board.getEmptyCells();"+
                "<<<sp_2, br/><<lightblue/>for<<white/>(int i  : emptyCells)"+
                "<<<sp_2, br/>{"+
                "<<<sp_3, br/>steps = 100000;"+
                "<<<sp_3, br/>Board bh = <<orange/>new<<white/> Board(board);"+
                "<<<sp_3, br/>Cell c = board.generatePosition(i);"+
                "<<<sp_3, br/>"+
                "<<<sp_3, br/>bh.addMove(getType(), c.x, c.y);"+
                "<<<sp_3, br/>int heuristic = minimax(bh, false);"+
                "<<<sp_3, br/><<lightblue/>if<<white/> (heuristic > bestHeuristic)"+
                "<<<sp_3, br/>{"+
                "<<<sp_4, br/>pos[0] = c.x;"+
                "<<<sp_4, br/>pos[1] = c.y;"+
                "<<<sp_4, br/>bestHeuristic = heuristic;"+
                "<<<sp_3, br/>}"+
                "<<<sp_2, br/>}"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/><<orange/>system<<white/>.out.println('The value of the best Move ' +'is '+ bestHeuristic +  ' OF ' + pos[0] +',' + pos[1]);"+
                "<<<sp_2, br/>"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>private<<white/> int minimax(Board b,  Boolean ismax)"+
                "<<<sp_1, br/>{"+
                "<<<br,hidden/>br"+
                "<<<sp_2, br/>int gameCondition = b.checkWinningStatus();"+
                "<<<sp_2, br/><<grey/>/** BaseCases **/<<white/>"+
                "<<<sp_2, br/><<lightblue/>if<<white/>(gameCondition != -2)"+
                "<<<sp_3, br/><<lightblue/>if<<white/> (gameCondition == getType())"+
                "<<<sp_4, br/><<orange/>return<<white/> 10;"+
                "<<<sp_3, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (gameCondition == 0)"+
                "<<<sp_4, br/><<orange/>return<<white/> 0;"+
                "<<<sp_3, br/><<lightblue/>else<<white/> "+
                "<<<sp_4, br/><<orange/>return<<white/> -10;"+
                "<<<sp_2, br/>"+
                "<<<br,hidden/>br"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/>List<Integer> emptyCells;"+
                "<<<sp_2, br/>"+
                "<<<sp_2, br/><<grey/>//Maximser move"+
                "<<white/><<<sp_2, br/><<lightblue/>if<<white/> (ismax)"+
                "<<<sp_2, br/>{"+
                "<<<sp_3, br/><<lightblue/>if<<white/> (steps <= 0 ) <<orange/>return<<white/> -3;"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/>int best = -1000;"+
                "<<<sp_3, br/>"+
                "<<<sp_3, br/><<grey/>//traverse all empty positions"+
                "<<white/><<<sp_3, br/>emptyCells =  b.getEmptyCells();"+
                "<<<sp_3, br/>"+
                "<<<sp_3, br/><<lightblue/>for<<white/>(int i : emptyCells)"+
                "<<<sp_3, br/>{"+
                "<<<sp_4, br/>Board tb = <<orange/>new<<white/> Board(b);"+
                "<<<sp_4, br/>Cell ch = tb.generatePosition(i);"+
                "<<<sp_4, br/>tb.addMove(getType(), ch.x, ch.y);"+
                "<<<sp_4, br/>best = Math.max(best, minimax(tb, !ismax));"+
                "<<<br,hidden/>br"+
                "<<<sp_4, br/>"+
                "<<<sp_3, br/>}"+
                "<<<sp_3, br/>steps--;"+
                "<<<sp_3, br/><<orange/>return<<white/> best;"+
                "<<<sp_2, br/>}"+
                "<<<sp_2, br/><<lightblue/>else<<white/> <<grey/>//minimiser move"+
                "<<white/><<<sp_2, br/>{"+
                "<<<sp_3, br/><<lightblue/>if<<white/> (steps <= 0 ) <<orange/>return<<white/> -3;"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/>int best = 1000;"+
                "<<<sp_3, br/>emptyCells =  b.getEmptyCells();"+
                "<<<sp_3, br/><<grey/>//traverse all empty positions"+
                "<<white/><<<sp_1, br/>"+
                "<<<sp_3, br/><<lightblue/>for<<white/>(int i : emptyCells)"+
                "<<<sp_3, br/>{"+
                "<<<sp_4, br/>Board tb = <<orange/>new<<white/> Board(b);"+
                "<<<sp_4, br/>Cell ch = tb.generatePosition(i);"+
                "<<<sp_4, br/>"+
                "<<<sp_4, br/>tb.addMove(tb.getTurn(), ch.x, ch.y);"+
                "<<<sp_4, br/>best = Math.min(best, minimax(tb, !ismax));"+
                "<<<sp_4, br/>"+
                "<<<sp_4, br/>"+
                "<<<sp_3, br/>}"+
                "<<<sp_3, br/>steps--;"+
                "<<<sp_3, br/><<orange/>return<<white/> best;"+
                "<<<sp_3, br/>"+
                "<<<sp_2, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_0, br/>}"
        },
        {
            key : 6,
            FileTitle : "Player.java",
            content : 
                "<<white/><<<sp_0, br/>package Cephalopod;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> abstract <<orange/>class<<white/> Player {"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/><<orange/>private<<white/> String name;"+
                "<<<sp_1, br/><<orange/>private<<white/> int type; <<grey/>//1 = white; -1 = black"+
                "<<white/><<<sp_1, br/>"+
                "<<<sp_1, br/><<grey/>/**"+
                "<<<sp_2, br/>* "+
                "<<<sp_2, br/>* @param PlayerName"+
                "<<<sp_2, br/>* @param //1 = white; -1 = black"+
                "<<<sp_2, br/>*/<<white/>"+
                "<<<sp_1, br/>Player(String i, int t)"+
                "<<<sp_1, br/>{"+
                "<<<sp_3, br/>name =i;"+
                "<<<sp_3, br/>type =t;"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>int getType() { <<orange/>return<<white/> type; }"+
                "<<<sp_1, br/>String getPlayerName() { <<orange/>return<<white/> name; }"+
                "<<<sp_1, br/>abstract <<orange/>void<<white/> getMove(Board board, int[] pos);"+
                "<<<sp_0, br/>}"
        },
        {
            key : 7,
            FileTitle : "RandomPlayer.java",
            content : 
                "<<white/><<<sp_0, br/>package Cephalopod;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Random;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> RandomPlayer extends Player{"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>RandomPlayer(int t)"+
                "<<<sp_1, br/>{"+
                "<<<sp_2, br/>super('Random Player' , t);"+
                "<<<sp_2, br/>"+
                "<<<sp_1, br/>}"+
                "<<<sp_1, br/>Random randomNumber = <<orange/>new<<white/> Random();"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>@Override"+
                "<<<sp_1, br/><<grey/>//TASK 2"+
                "<<white/><<<sp_1, br/><<orange/>void<<white/> getMove(Board board, int[] pos)"+
                "<<<sp_1, br/>{"+
                "<<<sp_3, br/>int r = randomNumber.nextInt(board.EmptyIndexes.size());"+
                "<<<sp_3, br/>Cell c = board.generatePosition( board.EmptyIndexes.get(r));"+
                "<<<sp_3, br/>main.p.println( getPlayerName() + ' has been picked : ' + c.x + ', ' +c.y );"+
                "<<<sp_3, br/>pos[0] = c.x;"+
                "<<<sp_3, br/>pos[1] = c.y;	"+
                "<<<sp_1, br/>"+
                "<<<sp_1, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/>}"
                
        }

    ]
}

export default CaroselItem;
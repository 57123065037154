import  {items} from '../../../../contentCarousel/contentCarosel';

 
let CaroselItem : items =    {
    key : 1,
    Subject : "Puzzle8Solver (AI)",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}

            <p>
                The 8 Sliding Tile Puzzle Solver is a program designed to tackle the classic sliding tile puzzle, 
                a brain-teasing game where the challenge is to rearrange eight numbered tiles within a 3x3 grid, 
                leaving one square empty. 
                
                The objective is to transform a given initial configuration (S0) into a specified final configuration 
                (SF) using the least number of moves possible.
            </p>
            
            <h3 style = {{textDecoration:'underline'}}> Program structure and justification : </h3>

            <p>
                For the solution of the puzzle 8 game, we utilise the A* Algorithm
                to find the most optional path from S0 -- SF.
            </p>

            <p>
                The 8 puzzle game has 9! Total permutation (states), making the
                exploration of the entire game possible. Instead of blinding
                exploring branches, we utilise the estimation heuristic function
                F(S) = g(S) + h(S) where g = given past cost from S0 -to- S (i.e.
                depth) and h = estimated cost from S —to- SF. Here h(s) = [number
                of tiles out of space] + [ number of moves from S —- SF ]
            </p>

            <p>
                Given the heuristic function h, start, and designation board
                configuration, we formulate the solution through a breadth-first
                descend, starting at S0. We initialise a priority-Queue used in
                conjunction with the heuristic estimation function F(S) to prioritise
                branches with high degree of potential, that is by lowest value,
                (shortest distance). We also initialise the set ‘ALLNodes’, which
                contains the set of all states already explored to prevent further
                exploration.
            </p>

            <p>
                The branches of S0 are expanded through the expansion function
                ‘ExpandState’, which returns all States that are neighbours to S0.
                These states are inserted into the priority queue with its
                associated heuristic. This process is repeated, and the highest
                prioritised state is removed at each iteration of BFS and
                explored until a solution is found, that is : S = SF.

            </p>

            <p style = {{textDecoration:'underline'}}> We define the Heuristic function H(s) = CompletitionDistance(s)  + TileDifferences(s) as follows :</p>

            <div style = {{boxSizing:'border-box'}}>
                <p style = {{ marginLeft :'5px', textDecoration:'underline', color :"grey"}}>
                    (NOTE : H(s1) is prioritised over h(s2) IFF H(s1) LESSTHAN H(s2); that is H(s1) has a better distance to win)
                </p>
                <ol>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  CompletitionDistance(s) : </span>
                            The estimated distance by the number of moves
                            needed to transform ’s’ into SF by calculating the sum
                            of vertical and horizontal distances of every tile from
                            its goal position.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> TileDifferences :</span>
                            Number of tiles not it in their final configuration
                            excluding the empty tile. (i.e. using the example
                            above, TileDifference(S1) = 3)
                    </li>
                </ol>
              
            
            </div>


        </>,
    img_Url:"./images/Project_Images/puzzle_8.jpg",    
    file_List : [
        {
            key : 1,
            FileTitle : "Puzzle8Solver.java",
            content : 
            "<<white/><<<sp_0, br/><<lightblue/>import<<white/> java.util.*;"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> Puzzle8Solver {"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>// Map<Integer, LinkedList<Integer>> neighbours;"+
            "<<white/><<<sp_3, br/>LinkedList<Integer>[] neighbours = <<orange/>new<<white/> LinkedList[9];"+
            "<<<sp_3, br/>State StartState, DestinationState;     <<grey/>// represent the current and destination state"+
            "<<white/><<<sp_3, br/><<orange/>private<<white/> <<orange/>void<<white/> InitialiseNeighbours(){"+
            "<<<sp_6, br/><<lightblue/>for<<white/> (int i=0; i < neighbours.length; i++) neighbours[i] = <<orange/>new<<white/> LinkedList<Integer>();"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>neighbours[0].add(1);"+
            "<<<sp_6, br/>neighbours[0].add(3);"+
            "<<<sp_6, br/>neighbours[1].add(0);"+
            "<<<sp_6, br/>neighbours[1].add(2);"+
            "<<<sp_6, br/>neighbours[1].add(4);"+
            "<<<sp_6, br/>neighbours[2].add(1);"+
            "<<<sp_6, br/>neighbours[2].add(5);"+
            "<<<sp_6, br/>neighbours[3].add(4);"+
            "<<<sp_6, br/>neighbours[3].add(0);"+
            "<<<sp_6, br/>neighbours[3].add(6);"+
            "<<<sp_6, br/>neighbours[4].add(3);"+
            "<<<sp_6, br/>neighbours[4].add(5);"+
            "<<<sp_6, br/>neighbours[4].add(1);"+
            "<<<sp_6, br/>neighbours[4].add(7);"+
            "<<<sp_6, br/>neighbours[5].add(4);"+
            "<<<sp_6, br/>neighbours[5].add(2);"+
            "<<<sp_6, br/>neighbours[5].add(8);"+
            "<<<sp_6, br/>neighbours[6].add(7);"+
            "<<<sp_6, br/>neighbours[6].add(3);"+
            "<<<sp_6, br/>neighbours[7].add(8);"+
            "<<<sp_6, br/>neighbours[7].add(6);"+
            "<<<sp_6, br/>neighbours[7].add(4);"+
            "<<<sp_6, br/>neighbours[8].add(7);"+
            "<<<sp_6, br/>neighbours[8].add(5);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>Puzzle8Solver(State StartState, State DestinationState){"+
            "<<<sp_6, br/>this.StartState = StartState;"+
            "<<<sp_6, br/>this.DestinationState = DestinationState;"+
            "<<<sp_6, br/>InitialiseNeighbours();"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>};"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Expand the state and return all its possible arrangements */<<white/>"+
            "<<<sp_3, br/><<orange/>private<<white/> List<State> ExpandState(State currentState){"+
            "<<<sp_6, br/>List<State> expandedList = <<orange/>new<<white/> LinkedList<>();"+
            "<<<sp_6, br/>int emptyTile_Indices = currentState.getEmptyTile();"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>// Iterate all neighbours of currentState (indices)"+
            "<<white/><<<sp_6, br/><<lightblue/>for<<white/> (int neighbourIndices : neighbours[emptyTile_Indices]) {"+
            "<<<sp_9, br/>State expansion = <<orange/>new<<white/> State(currentState);              <<grey/>// copy of current state"+
            "<<white/><<<sp_9, br/>expansion.swap(emptyTile_Indices, neighbourIndices);    <<grey/>// swap empty tile with neighbour "+
            "<<white/><<<sp_9, br/>expandedList.add(expansion);                            <<grey/>// add into list"+
            "<<white/><<<sp_6, br/>}"+
            "<<<sp_11, br/>"+
            "<<<sp_6, br/><<orange/>return<<white/> expandedList;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<grey/>/* Heuristic function f : Estimation heuristic for a given node S = g(s) + h(s) */<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> int f(Node S){"+
            "<<<sp_6, br/><<orange/>return<<white/> (g(S) + h(S.state, DestinationState) );"+
            "<<<sp_3, br/>} "+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/* Heuristic function H :The estimated cost of the cheapest path from S to SF */<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> int h(State S, State SF){"+
            "<<<sp_6, br/><<orange/>return<<white/> CompletitionDistance(S, SF) + TileD<<lightblue/>if<<white/>ferences(S, SF);"+
            "<<<sp_6, br/>"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/* Heuristic function G : Path cost to reach node S, that is : its depth.  */<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> int g(Node S){"+
            "<<<sp_6, br/><<orange/>return<<white/> S.depth;"+
            "<<<sp_6, br/><<grey/>// return ManhattanCost(S, SF) + HammingCost(S, SF);"+
            "<<white/><<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* The estimated distance by the number of moves needed to transform 'evaluatingState'"+
            "<<<sp_4, br/>* into 'DestinationState' by calculating the sum of vertical and horizontal distances of "+
            "<<<sp_4, br/>* every tile from its goal position"+
            "<<<sp_4, br/>* "+
            "<<<sp_4, br/>* @param evaluatingState"+
            "<<<sp_4, br/>* @param DestinationState"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> int CompletitionDistance(State evaluatingState, State DestinationState)"+
            "<<<sp_3, br/>{"+
            "<<<sp_6, br/>int totalCost = 0; <<grey/>// Initialize the total cost = 0"+
            "<<white/><<<sp_6, br/>int[] reverse_DestinationConfiguration = DestinationState.Generate_ReverseConfiguration();"+
            "<<<sp_9, br/>"+
            "<<<sp_6, br/><<grey/>// Iteration configuration of the evaluation state"+
            "<<white/><<<sp_6, br/><<lightblue/>for<<white/> (int i=0; i < DestinationState.configuration.length; i++)"+
            "<<<sp_6, br/>{"+
            "<<<sp_9, br/>int v = evaluatingState.configuration[i];       <<grey/>// Get  value at the current position"+
            "<<white/><<<sp_9, br/><<lightblue/>if<<white/> (v ==0) continue;                            <<grey/>// skip empty tile"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_9, br/><<grey/>// Calculate the current position (currentX, currentY) and the target position (targetX, targetY) for the tile with value 'v'"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_9, br/>int currentX = i%3;     <<grey/>// current position X"+
            "<<white/><<<sp_9, br/>int currentY = i/3;     <<grey/>// current position Y"+
            "<<white/><<<sp_9, br/>int targetX = reverse_DestinationConfiguration[v] % 3;  <<grey/>// target state position X"+
            "<<white/><<<sp_9, br/>int targetY = reverse_DestinationConfiguration[v] / 3;  <<grey/>// target state position Y"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_9, br/><<grey/>// calculate distance and sum total cost"+
            "<<white/><<<sp_9, br/>int dx = Math.abs(currentX - targetX);"+
            "<<<sp_9, br/>int dy = Math.abs(currentY - targetY);"+
            "<<<sp_9, br/>totalCost = totalCost + (dx+dy);"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<orange/>return<<white/> totalCost;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Number of tiles not in their final configuration excluding the empty tile, that is: number of different tile"+
            "<<<sp_4, br/>* configuration between evaluatingState, DestinationState"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> int TileD<<lightblue/>if<<white/>ferences(State evaluatingState, State DestinationState){"+
            "<<<sp_6, br/>int totalCost =0;"+
            "<<<sp_6, br/><<grey/>// iterate all tiles"+
            "<<white/><<<sp_6, br/><<lightblue/>for<<white/> (int i =0; i < evaluatingState.configuration.length; i++){"+
            "<<<sp_9, br/><<lightblue/>if<<white/> ( ! (evaluatingState.configuration[i] == 0))                                 <<grey/>// if not empty tile "+
            "<<white/><<<sp_11, br/><<lightblue/>if<<white/> (evaluatingState.configuration[i] != DestinationState.configuration[i] ) <<grey/>// if not in position"+
            "<<white/><<<sp_15, br/>totalCost++;                                                            <<grey/>// increase cost          "+
            "<<white/><<<sp_6, br/>}"+
            "<<<sp_6, br/>"+
            "<<<sp_6, br/><<orange/>return<<white/> totalCost;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>class<<white/> Node{"+
            "<<<sp_6, br/>Node parent;"+
            "<<<sp_6, br/>int depth; "+
            "<<<sp_6, br/>State state; "+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>Node(Node parent, int depth, State state)"+
            "<<<sp_6, br/>{"+
            "<<<sp_9, br/>this.parent = parent;"+
            "<<<sp_9, br/>this.depth = depth;"+
            "<<<sp_9, br/>this.state = state;"+
            "<<<sp_6, br/>}"+
            "<<<sp_6, br/><<grey/>/** Return a unique hash value of this node defined by the state */<<white/>"+
            "<<<sp_6, br/>int getHash(){"+
            "<<<sp_9, br/><<orange/>return<<white/> state.getHash();"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>class<<white/> State{"+
            "<<<sp_6, br/>int[] configuration;  "+
            "<<<sp_6, br/>State(int[] copy)"+
            "<<<sp_6, br/>{"+
            "<<<sp_9, br/>configuration = <<orange/>new<<white/> int[9];"+
            "<<<sp_9, br/><<lightblue/>for<<white/> (int i=0; i < configuration.length; i++) configuration[i] = copy[i]; "+
            "<<<sp_6, br/>}"+
            "<<<sp_6, br/><<grey/>/** Compare current state with target. T : Identical, else false */<<white/>"+
            "<<<sp_6, br/>boolean compare(State target)"+
            "<<<sp_6, br/>{"+
            "<<<sp_9, br/><<lightblue/>for<<white/> (int i =0; i < 9; i++) "+
            "<<<sp_11, br/><<lightblue/>if<<white/> (configuration[i] != target.configuration[i]) "+
            "<<<sp_15, br/><<orange/>return<<white/> false;"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<orange/>return<<white/> true;"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>/** Return the indices of configuration in reverseo order, that is "+
            "<<<sp_7, br/>*  configuration[0] = 3 IFF ReverseConfigration[3] = 0;"+
            "<<<sp_7, br/>*/<<white/>"+
            "<<<sp_6, br/>int[] Generate_ReverseConfiguration()"+
            "<<<sp_6, br/>{   "+
            "<<<sp_9, br/>int[] ReverseConfiguration = <<orange/>new<<white/> int[9];"+
            "<<<sp_9, br/><<lightblue/>for<<white/> (int i=0; i < 9; i++ ) ReverseConfiguration[configuration[i]] = i;"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<orange/>return<<white/> ReverseConfiguration;"+
            "<<<sp_6, br/>}"+
            "<<<sp_6, br/><<grey/>/** return a unique hash of this state st. h1 = h2 IFF h1.configuration = h2.configuration */<<white/>"+
            "<<<sp_6, br/>int getHash(){"+
            "<<<sp_9, br/>int hash = 17;                  <<grey/>// Initialize with a prime number as a base"+
            "<<white/><<<sp_9, br/><<lightblue/>for<<white/> (int element : configuration) {"+
            "<<<sp_11, br/>hash = 31 * hash + element; <<grey/>// Multiply by a prime and add the element's hash"+
            "<<white/><<<sp_9, br/>}"+
            "<<<sp_9, br/><<orange/>return<<white/> hash;"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>/** Create copy */<<white/>"+
            "<<<sp_6, br/>State(State copy) {"+
            "<<<sp_9, br/>configuration = <<orange/>new<<white/> int[9];"+
            "<<<sp_9, br/><<lightblue/>for<<white/> (int i=0; i < configuration.length; i++) configuration[i] = copy.configuration[i];"+
            "<<<sp_6, br/>}"+
            "<<<sp_3, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>State(){"+
            "<<<sp_9, br/>configuration = <<orange/>new<<white/> int[9];"+
            "<<<sp_9, br/><<lightblue/>for<<white/> (int i=0; i < 9; i++) configuration[i] = i; <<grey/>// default configuration 0,..8"+
            "<<white/><<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>boolean swap(int tile1, int tile2)"+
            "<<<sp_6, br/>{"+
            "<<<sp_9, br/><<lightblue/>if<<white/> ( !( (tile1 >= 0 && tile1 <= 8)  || (tile2 >= 0 && tile2 <= 8)) ) {"+
            "<<<sp_11, br/><<orange/>return<<white/> false;"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/>int swap = configuration[tile1];"+
            "<<<sp_9, br/>configuration[tile1] = configuration[tile2];"+
            "<<<sp_9, br/>configuration[tile2] = swap;"+
            "<<<sp_9, br/><<orange/>return<<white/> true;"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>/** Return the empty tile on board, else -1 on error (i.e. 0 not established) */<<white/>"+
            "<<<sp_6, br/>int getEmptyTile(){"+
            "<<<sp_9, br/><<lightblue/>for<<white/> (int i=0; i < configuration.length;i++)"+
            "<<<sp_11, br/><<lightblue/>if<<white/> (configuration[i] == 0) <<orange/>return<<white/> i;"+
            "<<<sp_9, br/>"+
            "<<<sp_9, br/><<orange/>return<<white/> -1;"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<orange/>public<<white/> <<orange/>void<<white/> print(){"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println('\n');"+
            "<<<sp_9, br/><<lightblue/>for<<white/> (int i=0; i < configuration.length;i++) {"+
            "<<<sp_11, br/><<lightblue/>if<<white/> (i%3 == 0 && i !=0)  <<orange/>system<<white/>.out.println();  "+
            "<<<sp_11, br/><<orange/>system<<white/>.out.print(configuration[i]  + ',');"+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println('\n');"+
            "<<<sp_6, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<orange/>public<<white/> int[] getConfiguration() {"+
            "<<<sp_9, br/><<orange/>return<<white/> configuration;"+
            "<<<sp_6, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** traverse back to start via parent node */<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> printSolution(Node destination)"+
            "<<<sp_3, br/>{"+
            "<<<sp_6, br/>Node currentNode = destination;"+
            "<<<sp_6, br/>int i =0;"+
            "<<<sp_6, br/><<lightblue/>white<<white/> (currentNode != null){"+
            "<<<sp_9, br/>currentNode.state.print();"+
            "<<<sp_9, br/>currentNode = currentNode.parent;"+
            "<<<sp_16, br/>i++;"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>}"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println(i);"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> solve(){"+
            "<<<sp_6, br/><<grey/>// Initialise priority queue of nodes, sorted by lowest value (i.e. shortest path)"+
            "<<white/><<<sp_6, br/>Queue<Node> treeNodes = <<orange/>new<<white/> PriorityQueue<>("+
            "<<<sp_9, br/><<orange/>new<<white/> Comparator<Node>(){"+
            "<<<sp_11, br/>@Override"+
            "<<<sp_11, br/><<orange/>public<<white/> int compare(Node n1, Node n2) {"+
            "<<<sp_15, br/><<orange/>return<<white/> Integer.compare(f(n1), f(n2));                       <<grey/>// arrange by heuristic value"+
            "<<white/><<<sp_11, br/>}"+
            "<<<sp_9, br/>}"+
            "<<<sp_6, br/>);"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>Set<Integer> allNodes = <<orange/>new<<white/> HashSet<>();                                <<grey/>// set of all considered nodes"+
            "<<white/><<<sp_6, br/>"+
            "<<<sp_6, br/>Node currentNode = <<orange/>new<<white/> Node(null, 0, StartState) ;         				<<grey/>// Initialise start node"+
            "<<white/><<<sp_6, br/>treeNodes.add(currentNode);                                             <<grey/>// insert start state"+
            "<<white/><<<sp_6, br/>allNodes.add(currentNode.getHash());                                    <<grey/>// insert into allNodes (considered nodes / states)"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_6, br/><<grey/>// DFS traversal"+
            "<<white/><<<sp_6, br/><<lightblue/>white<<white/> (!treeNodes.isEmpty()){"+
            "<<<sp_9, br/>currentNode = treeNodes.poll();                                     <<grey/>// return highest priority node (state)"+
            "<<white/><<<sp_9, br/>List<State> adjacenStates = ExpandState(currentNode.state);         <<grey/>// expand all neighbours reachable by currentNode"+
            "<<white/><<<sp_9, br/><<lightblue/>if<<white/> (currentNode.state.compare(DestinationState)){                   <<grey/>// check if destination is reached"+
            "<<white/><<<sp_11, br/><<orange/>system<<white/>.out.println('Found a solution!');"+
            "<<<sp_11, br/>printSolution(currentNode);                                     <<grey/>// print solution"+
            "<<white/><<<sp_11, br/><<orange/>break<<white/>;"+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/><<grey/>// iterate all branches"+
            "<<white/><<<sp_9, br/><<lightblue/>for<<white/> (State k : adjacenStates) { "+
            "<<<sp_11, br/>Node New_Node = <<orange/>new<<white/> Node(currentNode, currentNode.depth+1, k);  <<grey/>// create new node"+
            "<<white/><<<sp_11, br/>int nodeHash = New_Node.getHash();                              <<grey/>// hash value of node (determined by state configuration)"+
            "<<white/><<<sp_11, br/><<lightblue/>if<<white/> (!allNodes.contains(nodeHash)){                              <<grey/>// if not considered state"+
            "<<white/><<<sp_15, br/>treeNodes.add(New_Node);                                    <<grey/>// insert into treeNode PQ"+
            "<<white/><<<sp_15, br/>allNodes.add(nodeHash);                                     <<grey/>// insert into considered states"+
            "<<white/><<<sp_11, br/>}"+
            "<<<sp_9, br/>}"+
            "<<<sp_6, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(String[] args)"+
            "<<<sp_3, br/>{"+
            "<<<sp_6, br/>State startState = <<orange/>new<<white/> State(<<orange/>new<<white/> int[]{6,5,7,4,2,1,3,8,0});"+
            "<<<sp_6, br/>State destinationState = <<orange/>new<<white/> State(<<orange/>new<<white/> int[] {1,2,3,8,0,4,7,6,5});"+
            "<<<sp_6, br/>Puzzle8Solver puzzle = <<orange/>new<<white/> Puzzle8Solver(startState, destinationState);"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>puzzle.solve();"+
            "<<<sp_3, br/>}"+
            "<<<sp_0, br/>}"
            
        },
    ]
}

export default CaroselItem;
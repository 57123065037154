import  {items} from '../../../../../contentCarousel/contentCarosel';
import DemoWrapper from "../../../../../DemoWrapper/DemoWrapper";
import React, { MouseEvent, useRef } from 'react';
import IframeWrapper from "../../../../../IFrame_Container/IFrameContainer";
import MySkills from "../../../../../../BaseProject/MyResume/MySkills/MySkills";
import AnimationBanner, {AnimationBannerInformation} from "../../../../../../BaseProject/Components/animations/AnimationBannerProfile/AnimationBanner";

let profileBanner_Info : AnimationBannerInformation = 
{
  title_Right: "</Coder>",
  title_Left : "Design",
  desc_Right : "Front end developer who writes clean, elegant and efficient code.",
  desc_Left : "Designer specialising in printable graphic.",
  image_RightURL : "./images/myresume_Content/lap_2.png",
  image_LeftURL : "./images/myresume_Content/lap_1.png"



}

let CaroselItem : items =    {
    key : 1,
    Subject : "Animation Banner",
    Description : 
        <>
            <p>  
                The "AnimationBanner" component is designed to render an interactive banner with dynamic scaling 
                based on its parent container's dimensions. It comprises two sides, left and right, which are 
                focused based on the mouse location relative to the component container. The focus state coordinates
                 with the cropping of two image URLs and side content provided through props.
            </p>
            <h3 style = {{textDecoration:'underline'}}> Usage : </h3>
            <p style = {{margin:'0'}}>   
                {'<AnimationBanner'}<br />
            </p>
            <p style={{paddingLeft:"2%", margin:'0'}}>
                {'    AnimationBarInfo={{'}<br />
            </p>
            <p style ={{margin:'0', paddingLeft:"5%"}}>
                {'          title_Left: "Designer",'}<br />
                {'          title_Right: "Coder",'}<br />
                {'    desc_Left: "Description for Left Side",'}<br />
                {'    desc_Right: "Description for Right Side",'}<br />
                {'    image_LeftUrl: "URL for Image on Left Side",'}<br />
                {'    image_RightUrl: "URL for Image on Right Side",'}<br />
            </p>
            <p style={{paddingLeft:"2%", margin:'0'}}>

                {'  }}'}<br />
            </p>
            <p style={{marginBottom:"50px"}}>
                {'/>'}
            </p>

            <DemoWrapper Title = {"Animation Banner"}>
                <div style = {{width:'100%', height:'500px', background:'white', color:'black', overflow:"hidden", padding:'3%', boxSizing:'border-box'}}>
                    <AnimationBanner AnimationBarInfo={profileBanner_Info}/>
                </div>
            </DemoWrapper> 
        </>,
    img_Url:"./images/Project_Images/AnimationBanner.jpg",
    show_Image : false,
    file_List : [
        {
            key : 1,
            FileTitle : "AnimationBanner.tsx",
            content :
            "<<white/><<<sp_0, br/><<lightblue/>import<<white/> React, { <<orange/>useLayoutEffect<<white/>, <<orange/>useRef<<white/>, <<orange/>useState<<white/> } from 'react';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> myBrowser from '../../../Handlers/myBrowser';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> StateWrapper from '../../../Interfaces/stateObjectInterface'"+
            "<<<sp_0, br/><<lightblue/>import<<white/> ImageFrame from '../../layouts/Others/imageFrame';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> DynamicHeading from '../../layouts/Others/dynamicText';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> ScrollableFrame from '../../layouts/Others/scrollableFrame';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> './Styling.css';"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/* ------------------------------------------ Component Description ------------------------------------------"+
            "<<<sp_2, br/>Renders the animation banner inheriting 100% of the x,y, dimensions of the direct parent container, and hence"+
            "<<<sp_2, br/>dynamic scaling is subject to the parent container scaling bejaviour. "+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>This component consist of 2 side (left, right) which is focused by the mouse location relative to the component"+
            "<<<sp_2, br/>container, and focus (visual reflection) is coordinated with the cropping of the two image_URL and side "+
            "<<<sp_2, br/>content provided by props based on the focused state (side). "+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>This component takes as input by props :"+
            "<<<sp_4, br/>AnimationBannerInformation = Object of : { title_Left : String, title_Right : String, desc_Left : "+
            "<<<sp_4, br/>String, desc_Right : String, image_LeftUrl :String, image_RightUrl : String }"+
            "<<<sp_0, br/>------------------------------------------------------------------------------------------------------------*/<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>let<<white/> Directions : {right : string, left : string, none : <<purple/>undefined<<white/>} = {"+
            "<<<sp_2, br/>right : 'right',"+
            "<<<sp_2, br/>left : 'left',"+
            "<<<sp_2, br/>none : <<purple/>undefined<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>export <<orange/>interface<<white/> AnimationBannerInformation {"+
            "<<<sp_2, br/>title_Left : <<orange/>String<<white/>,"+
            "<<<sp_2, br/>title_Right : <<orange/>String<<white/>,"+
            "<<<sp_2, br/>desc_Left : <<orange/>String<<white/>,"+
            "<<<sp_2, br/>desc_Right : <<orange/>String<<white/>,"+
            "<<<sp_2, br/>image_LeftURL : <<orange/>String<<white/>,"+
            "<<<sp_2, br/>image_RightURL : <<orange/>String<<white/>,"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Shift centerImage.ref by percentageToShift ￢ﾈﾈ {0.00, .., 1.00}  amount in the direction given by directionState. */<<white/>"+
            "<<<sp_0, br/><<orange/>let<<white/> ShiftCenterImage = ( CenterImageRef : React.RefObject<HTMLDivElement>, containerRef : React.RefObject<HTMLDivElement>, directionState : StateWrapper<string|<<purple/>undefined<<white/>> , percentageToShift : <<orange/>number<<white/> ) =>"+
            "<<<sp_0, br/>{"+
            "<<<sp_2, br/><<lightblue/>if<<white/> (CenterImageRef.current != <<orange/>null<<white/>)"+
            "<<<sp_2, br/>{"+
            "<<<sp_4, br/><<orange/>let<<white/> CenterImageWidth = myBrowser.RefHandling.getRefWidth(CenterImageRef);"+
            "<<<sp_4, br/><<orange/>let<<white/> ContainerWidth = myBrowser.RefHandling.getRefWidth(containerRef);"+
            "<<<sp_4, br/><<orange/>let<<white/> gapWidth = (ContainerWidth - CenterImageWidth);                                                         <<grey/>// this is the amount of emptySpace in Container (x-axis) with CenterImage centered."+
            "<<white/><<<sp_4, br/><<orange/>let<<white/> amountToShift = ((1 - percentageToShift) * (gapWidth/2));"+
            "<<<sp_4, br/>"+
            "<<<sp_4, br/><<lightblue/>import<<white/>(directionState.item)"+
            "<<<sp_4, br/>{"+
            "<<<sp_7, br/><<lightblue/>case<<white/> Directions.left : "+
            "<<<sp_9, br/>CenterImageRef.current.style.left = `${(ContainerWidth - (CenterImageWidth/2)) - amountToShift }px`;"+
            "<<<sp_9, br/>CenterImageRef.current.style.transition = `2s`;"+
            "<<<sp_7, br/><<orange/>break<<white/>;"+
            "<<<sp_7, br/><<lightblue/>case<<white/> Directions.right :"+
            "<<<sp_9, br/>CenterImageRef.current.style.left = `${  ((CenterImageWidth/2) + anountToShift ) }px`;"+
            "<<<sp_9, br/>CenterImageRef.current.style.transition = `2s`;"+
            "<<<sp_7, br/><<orange/>return<<white/>;     "+
            "<<<sp_7, br/>default : "+
            "<<<sp_9, br/>CenterImageRef.current.style.left = `${  ((CenterImageWidth/2) + anountToShift ) }px`;"+
            "<<<sp_7, br/><<orange/>return<<white/>;     "+
            "<<<sp_4, br/>}"+
            "<<<sp_2, br/>}"+
            "<<<sp_0, br/>}  "+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/* Initialise the directContainer div to be that of the full Width,Height of its ancestor container 'CenterImage'  */<<white/>"+
            "<<<sp_0, br/><<orange/>let<<white/> initialiseImageContainerSize = (  ImageContainerRefs : {  DirecterContainerRef : React.RefObject<HTMLDivElement>, UpperContainerRef :React.RefObject<HTMLDivElement> }[], CenterImageRef :  React.RefObject<HTMLDivElement> ) =>"+
            "<<<sp_0, br/>{"+
            "<<<sp_3, br/><<grey/>/* Initial direct container Size */<<white/>"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(<<orange/>let<<white/> i =0; i < ImageContainerRefs.length; i++)"+
            "<<<sp_3, br/><<lightblue/>if<<white/> ( (ImageContainerRefs[i].DirecterContainerRef.current != <<orange/>null<<white/>))"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>let<<white/> direct_Node = (ImageContainerRefs[i].DirecterContainerRef.current as HTMLDivElement); "+
            "<<<sp_5, br/>direct_Node.style.width = `${myBrowser.RefHandling.getRefWidth(CenterImageRef)}px`"+
            "<<<sp_5, br/>direct_Node.style.height = `${myBrowser.RefHandling.getRefHeight(CenterImageRef)}px`    "+
            "<<<sp_3, br/>}"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>let<<white/> RenderAnimationBanner : React.FC <{AnimationBarInfo : AnimationBannerInformation}> = (props) =>"+
            "<<<sp_0, br/>{"+
            "<<<sp_2, br/><<orange/>let<<white/> [windowDimensions, setWindowDimensions] = <<orange/>useState<<white/> < <<orange/>number<<white/>>(1);"+
            "<<<sp_2, br/><<orange/>let<<white/> containerRef = React.<<orange/>useRef<<white/><HTMLDivElement>(<<orange/>null<<white/>);"+
            "<<<sp_2, br/><<orange/>let<<white/> CenterImageRef = React.<<orange/>useRef<<white/><HTMLDivElement>(<<orange/>null<<white/>);"+
            "<<<sp_2, br/><<orange/>let<<white/> [direction , setDirection] = <<orange/>useState<<white/><string | <<purple/>undefined<<white/>> (Directions.none);                                    <<grey/>// s.t. directon ￢ﾈﾈ {left, right, undefined} , "+
            "<<white/><<<sp_2, br/><<orange/>let<<white/> directionStateWrapper : StateWrapper<string | <<purple/>undefined<<white/>> = {"+
            "<<<sp_4, br/>item : direction,"+
            "<<<sp_4, br/>setItem : setDirection"+
            "<<<sp_2, br/>};"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<orange/>let<<white/> [shiftPercentage, setShiftPercentage] = <<orange/>useState<<white/> < <<orange/>number<<white/>> (0);"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<orange/>useLayoutEffect<<white/>("+
            "<<<sp_4, br/>() => { "+
            "<<<sp_7, br/><<grey/>/** Set MouseListener function for component container, container.ref must be defined on call!  */<<white/>"+
            "<<<sp_7, br/><<orange/>let<<white/> Current_setContainerListener = (event : MouseEvent) : <<orange/>void<<white/> =>  {"+
            "<<<sp_9, br/><<orange/>let<<white/> percentageX = myBrowser.RefHandling.getMousePerecentageX(containerRef, event.x);"+
            "<<<sp_9, br/>"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (percentageX != <<purple/>undefined<<white/>)"+
            "<<<sp_9, br/>{"+
            "<<<sp_10, br/><<lightblue/>if<<white/> (percentageX < 0.50 && direction != Directions.left) {"+
            "<<<sp_12, br/>setDirection(Directions.left);"+
            "<<<sp_10, br/>} <<lightblue/>else<<white/> <<lightblue/>if<<white/> (percentageX >= 0.50 && direction != Directions.right) {"+
            "<<<sp_12, br/>setDirection(Directions.right);"+
            "<<<sp_10, br/>}<<lightblue/>else<<white/> <<lightblue/>if<<white/> (percentageX != shiftPercentage"+
            "<<<sp_10, br/>{"+
            "<<<sp_12, br/>percentageX = (direction === Directions.left)  ? (1 - (percentageX / 0.5)) : ( 1-((1 - percentageX) / 0.5) );"+
            "<<<sp_12, br/>setShiftPercentage(percentageX);"+
            "<<<sp_10, br/>}                      "+
            "<<<sp_9, br/>}"+
            "<<<sp_7, br/>};"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/><<grey/>/** Reset image to center on mouse_component exit */<<white/>"+
            "<<<sp_7, br/><<orange/>let<<white/> mouseExit_Handler = (event : MouseEvent) => { "+
            "<<<sp_9, br/>setDirection(Directions.none);"+
            "<<<sp_9, br/>setShiftPercentage(0);"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (containerRef.current != <<orange/>null<<white/>)"+
            "<<<sp_7, br/>{"+
            "<<<sp_9, br/><<grey/>/* (Ref :3129) : Removal and setup of actionListeners */<<white/>"+
            "<<<sp_9, br/>containerRef.current.addEventListener('mousemove', Current_setContainerListener);"+
            "<<<sp_9, br/>containerRef.current.addEventListener('mouseleave', mouseExit_Handler);"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<orange/>return<<white/> () => {"+
            "<<<sp_9, br/>containerRef.current?.removeEventListener('mousemove', Current_setContainerListener);"+
            "<<<sp_9, br/>containerRef.current?.removeEventListener('mouseleave', mouseExit_Handler);"+
            "<<<sp_7, br/>}"+
            "<<<sp_4, br/>},"+
            "<<<sp_4, br/>"+
            "<<<sp_4, br/>[[], direction, containerRef]);"+
            "<<<sp_4, br/>"+
            "<<<sp_2, br/>"+
            "<<<sp_2, br/><<orange/>useLayoutEffect<<white/>( "+
            "<<<sp_4, br/>()=> {"+
            "<<<sp_7, br/>ShiftCenterImage(CenterImageRef, containerRef, directionStateWrapper, shiftPercentage;"+
            "<<<sp_4, br/>}"+
            "<<<sp_2, br/>);"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<grey/>/** Initial render handlers */<<white/>"+
            "<<<sp_2, br/><<orange/>useLayoutEffect<<white/>("+
            "<<<sp_4, br/>() => {"+
            "<<<sp_7, br/><<orange/>let<<white/> Resize_Handler = () => {"+
            "<<<sp_9, br/>initialiseImageContainerSize(ImageContainerRefs, CenterImageRef);           <<grey/>/* Re-Initialise imageContainer sizing on resize */<<white/>"+
            "<<<sp_9, br/>setWindowDimensions(window.innerWidth * window.innerHeight);                <<grey/>/* Re-Render on window size change for change update */<<white/>"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (containerRef.current != <<orange/>null<<white/>)"+
            "<<<sp_7, br/>{"+
            "<<<sp_9, br/>window.addEventListener('resize', Resize_Handler);"+
            "<<<sp_9, br/>initialiseImageContainerSize(ImageContainerRefs, CenterImageRef);               <<grey/>/* Initial imageContainer sizing */<<white/>"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<orange/>return<<white/> () => {"+
            "<<<sp_9, br/>window.removeEventListener('resize', Resize_Handler);"+
            "<<<sp_7, br/>} "+
            "<<<sp_4, br/>}"+
            "<<<sp_2, br/>,[]);"+
            "<<<sp_4, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<orange/>let<<white/> ImageContainerRefs : {  DirecterContainerRef : React.RefObject<HTMLDivElement>, UpperContainerRef :React.RefObject<HTMLDivElement> }[] = ["+
            "<<<sp_4, br/>{ <<grey/>// Image 1 containers"+
            "<<white/><<<sp_7, br/>DirecterContainerRef : React.<<orange/>useRef<<white/><HTMLDivElement>(<<orange/>null<<white/>),"+
            "<<<sp_7, br/>UpperContainerRef : React.<<orange/>useRef<<white/><HTMLDivElement> (<<orange/>null<<white/>)"+
            "<<<sp_4, br/>},"+
            "<<<sp_4, br/>{ <<grey/>// Image 2 containers"+
            "<<white/><<<sp_7, br/>DirecterContainerRef : React.<<orange/>useRef<<white/><HTMLDivElement> (<<orange/>null<<white/>),"+
            "<<<sp_7, br/>UpperContainerRef : React.<<orange/>useRef<<white/><HTMLDivElement> (<<orange/>null<<white/>)"+
            "<<<sp_4, br/>},"+
            "<<<sp_2, br/>];"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<orange/>return<<white/> ("+
            "<<<sp_4, br/><>"+
            "<<<sp_7, br/><div style = {{width :'100%', height :'100%',  position:'relative'}}>"+
            "<<<sp_9, br/><div ref = {containerRef}  id = 'containerRef'>"+
            "<<<sp_10, br/>{<<grey/>/* Coder Component */<<white/>}"+
            "<<<sp_10, br/><div id = 'leftContainer'>"+
            "<<<sp_10, br/><div className='HeadingContainer' style = {{ width: `${ (direction === Directions.left ?  ( (100*shiftPercentage > 54) ? 100*shiftPercentage : '45' ) : '45')}%` , opacity :`${ ((direction === Directions.left || direction === Directions.none) ? 100 : 0) } ` }}>"+
            "<<<sp_13, br/><div className='headerContainer' style = {{ position:'relative'}} >"+
            "<<<sp_14, br/><h1> {props.AnimationBarInfo.title_Left}</h1>"+
            "<<<sp_13, br/></div>"+
            "<<<br,hidden/>br"+
            "<<<sp_13, br/>{<<grey/>/* <DynamicHeading Text={`${props.AnimationBarInfo.title_Left}`} Styling= {{fontWeight :'700',display:'grid', alignContent:'end', textAlign:'center'}}/>  */<<white/>}"+
            "<<<sp_13, br/><div className='descriptionContainer' >"+
            "<<<sp_14, br/><ScrollableFrame showOverflowNotification ={true}>"+
            "<<<sp_15, br/><p className = 'description'>{props.AnimationBarInfo.desc_Left}</p>"+
            "<<<sp_14, br/></ScrollableFrame>"+
            "<<<sp_13, br/></div>"+
            "<<<sp_12, br/></div>"+
            "<<<sp_10, br/></div>"+
            "<<<sp_10, br/><div id  = 'rightContainer' >"+
            "<<<sp_12, br/><div className='HeadingContainer' style = {{ width: `${ (direction === Directions.right ?  ( (100*shiftPercentage > 54) ? 100*shiftPercentage : '45' ) : '45')}%` , opacity :`${ ((direction === Directions.right || direction === Directions.none) ? 100 : 0) } `}}>"+
            "<<<sp_13, br/><div className='headerContainer' >"+
            "<<<sp_14, br/><h1> {props.AnimationBarInfo.title_Right}</h1>"+
            "<<<sp_13, br/></div>"+
            "<<<sp_13, br/><div className='descriptionContainer' >"+
            "<<<sp_14, br/><ScrollableFrame showOverflowNotification ={true}>"+
            "<<<sp_15, br/><p className = 'description'>{props.AnimationBarInfo.desc_Right }</p>"+
            "<<<sp_14, br/></ScrollableFrame>"+
            "<<<sp_13, br/></div>"+
            "<<<sp_12, br/>"+
            "<<<sp_12, br/></div>"+
            "<<<br,hidden/>br"+
            "<<<sp_10, br/></div>"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_10, br/>{<<grey/>/* CenterImage Component */<<white/>}"+
            "<<<sp_10, br/><div ref = {CenterImageRef} className='imageContainer' > "+
            "<<<sp_12, br/><div ref = {ImageContainerRefs[0].UpperContainerRef} style={{position :'absolute', bottom :'0%', "+
            "<<<sp_13, br/>width:`${(direction === Directions.right) ? (50+ (shiftPercentage *50)) : 50}%`, zIndex : `${ (direction === Directions.right ? 1 : 0)}`, height:'100%', overflow :'hidden',}}> "+
            "<<<sp_13, br/><div ref = {ImageContainerRefs[0].DirecterContainerRef} style={{ width:'0%', height:'0%'}}> "+
            "<<<sp_14, br/><ImageFrame src= {`${props.AnimationBarInfo.image_LeftURL}`} container_styling={{  position:'absolute', bottom : '0px', width:'100%', height :'100%'}} image_Styling ={{objectPosition:'center'}} />"+
            "<<<sp_13, br/></div>"+
            "<<<sp_12, br/></div>"+
            "<<<br,hidden/>br"+
            "<<<sp_12, br/><div ref = {ImageContainerRefs[1].UpperContainerRef} style={{position :'absolute', right:'0px', bottom :'0%', "+
            "<<<sp_13, br/>width:`${(direction === Directions.left) ? (50+ (shiftPercentage *50)) : 50}%`, zIndex : `${direction === Directions.left ? 1 : 0}` , height:'100%', overflow :'hidden',}}> "+
            "<<<sp_13, br/><div ref = {ImageContainerRefs[1].DirecterContainerRef} style={{position :'absolute', right:'0px', width:'0%', height:'0%'}}> "+
            "<<<sp_14, br/><ImageFrame src= {`${props.AnimationBarInfo.image_RightURL}`}  container_styling={{  position:'absolute', bottom : '0px', width:'100%', height :'100%'}} image_Styling ={{objectPosition:'center'}} />"+
            "<<<sp_13, br/></div>"+
            "<<<sp_12, br/></div>"+
            "<<<sp_10, br/></div>"+
            "<<<sp_10, br/>{<<grey/>/* CenterImage Component End */<<white/>}"+
            "<<<sp_9, br/></div>"+
            "<<<sp_7, br/></div> "+
            "<<<sp_4, br/></>"+
            "<<<sp_2, br/>);"+
            "<<<sp_0, br/>}"
        },
        {
            key : 1,
            FileTitle : "styling.css",
            content :

            "<<white/><<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>@media only screen and (min-width: 1051px) {"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>.description{"+
            "<<<sp_3, br/>font-size: clamp(10px, 0.9vw, 100px);"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/><<grey/>/* .HeadingContainer{"+
            "<<<sp_3, br/>width:40%;"+
            "<<<sp_2, br/>} */<<white/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_0, br/>@media only screen and (max-width: 1050px) {"+
            ""+
            "<<<sp_2, br/>.description{"+
            "<<<sp_3, br/>font-size: 17px;"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<grey/>/* .HeadingContainer{"+
            "<<<sp_3, br/>width:50%;"+
            "<<<sp_2, br/>} */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>@media only screen and (max-width: 600px) {"+
            ""+
            "<<<sp_2, br/>.description{"+
            "<<<sp_3, br/>text-align: center;"+
            "<<<sp_3, br/>font-size: 13px;"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/><<grey/>/* .HeadingContainer{"+
            "<<<sp_3, br/>width:50%;        "+
            "<<<sp_2, br/>} */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>@media only screen and (max-width: 400px) {"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>.description{"+
            "<<<sp_3, br/>text-align: center;"+
            "<<<sp_3, br/>font-size: 10px;"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/><<grey/>/* .HeadingContainer{"+
            "<<<sp_3, br/>width:50%;"+
            "<<<sp_2, br/>} */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>@media only screen and (max-width: 200px) {"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>.description{"+
            "<<<sp_3, br/>text-align: center;"+
            "<<<sp_3, br/>font-size: 6px;"+
            "<<<sp_2, br/>}"+
            "<<<sp_2, br/><<grey/>/* .HeadingContainer{"+
            "<<<sp_3, br/>width:53%;"+
            "<<<sp_2, br/>} */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.descriptionContainer{"+
            "<<<sp_2, br/>width: 100%;"+
            "<<<sp_2, br/><<grey/>/* height: 100%; */<<white/>"+
            "<<<sp_2, br/>height: 100%;"+
            "<<<sp_2, br/>overflow: hidden;"+
            "<<<sp_2, br/>transition: all 1s;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.descriptionContainer p{"+
            "<<<sp_2, br/>color: rgba(0, 0, 0, 0.7);    "+
            "<<<sp_2, br/>font-family: 'Geist Regular', 'Geist Regular Placeholder', sans-serif;"+
            "<<<sp_2, br/>letter-spacing: 0em;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.headerContainer{"+
            "<<<sp_2, br/>font-size: clamp(2px, 1vw, 100px);"+
            "<<<sp_2, br/>text-align: center;"+
            "<<<sp_2, br/><<grey/>/* border-bottom: 0.1px solid grey; */<<white/>"+
            "<<<sp_2, br/>margin-bottom: 4%;"+
            "<<<sp_2, br/>background:none;"+
            "}"+
            "<<<sp_0, br/>.headerContainer h1{"+
            "<<<sp_2, br/>border: 0.5px solid white;"+
            "<<<sp_2, br/>margin-bottom: 0.3rem;"+
            "<<<sp_2, br/><<grey/>/* font-family: 'proxima nova', 'Helvetica Neue', Helvetica, Arial, Sans-serif !important; */<<white/>"+
            "<<<sp_2, br/>font-weight: 700;"+
            "<<<sp_2, br/>letter-spacing: -0.03em;"+
            "<<<sp_2, br/>line-height: 1.2;"+
            "<<<sp_2, br/><<grey/>/* color: rgb(237, 238, 240); */<<white/>"+
            "<<<sp_2, br/>color: rgba(0, 0, 0, 0.7);    "+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>font-family: 'Bluu Next Bold', 'Bluu Next Bold Placeholder', sans-serif;"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/><<grey/>/* font-weight: normal; */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>#containerRef{"+
            "<<<sp_2, br/>overflow : hidden;"+
            "<<<sp_2, br/>position: relative;"+
            "<<<sp_2, br/>bottom: 0;"+
            "<<<sp_2, br/>width:100%;"+
            "<<<sp_2, br/>height:100%;"+
            "<<<sp_2, br/>box-sizing: content-box;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.imageContainer{"+
            "<<<br,hidden/>br"+
            "<<<sp_2, br/>width: 50%;"+
            "<<<sp_2, br/>height: 100%;"+
            "<<<sp_2, br/>left: 50%;"+
            "<<<sp_2, br/>position: absolute;"+
            "<<<sp_2, br/>transform: translate(-50%,0);"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.HeadingContainer{"+
            "<<<sp_2, br/><<grey/>/* position:absolute; */<<white/>"+
            "<<<sp_2, br/>transition : all 2s;"+
            "<<<sp_2, br/><<grey/>/* max-height: 100%; */<<white/>"+
            "<<<sp_2, br/>max-height: 100%;"+
            "}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>#rightContainer{"+
            "<<<sp_2, br/>z-Index: 10;"+
            "<<<sp_2, br/>position:absolute;"+
            "<<<sp_2, br/>right: 0;"+
            "<<<sp_2, br/>width :55%;"+
            "<<<sp_2, br/>height:100%;"+
            "<<<sp_2, br/><<grey/>/* background-color: blueviolet; */<<white/>"+
            "<<<sp_2, br/><<grey/>/* padding :5%; */<<white/>"+
            "<<<sp_2, br/>box-Sizing:border-box;"+
            "<<<sp_2, br/>display: flex;"+
            "<<<sp_2, br/>flex-direction: column;"+
            "<<<sp_2, br/>justify-content: center;"+
            "<<<sp_2, br/>align-items: flex-end;"+
            "<<<sp_2, br/>word-break: break-all;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>#leftContainer{"+
            "<<<sp_2, br/><<grey/>/* justify-items: center; */<<white/>"+
            "<<<sp_2, br/>z-Index: 10;"+
            "<<<sp_2, br/>position:absolute;"+
            "<<<sp_2, br/>width :55%;"+
            "<<<sp_2, br/>height:100%;"+
            "<<<sp_2, br/>left: 0;"+
            "<<<sp_2, br/><<grey/>/* background-color: blueviolet; */<<white/>"+
            "<<<sp_2, br/><<grey/>/* padding :5%; */<<white/>"+
            "<<<sp_2, br/>box-Sizing:border-box;"+
            "<<<sp_2, br/>display: flex;"+
            "<<<sp_2, br/>flex-direction: column;"+
            "<<<sp_2, br/>justify-content: center;"+
            "<<<sp_2, br/>word-break: break-all;"+
            "<<<sp_2, br/>"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.description{"+
            "<<<sp_2, br/>margin:0;"+
            "<<<sp_2, br/>color :#757575;"+
            "<<<sp_2, br/>line-Height :1;"+
            "<<<sp_2, br/>font-Family :proxima nova light;"+
            "<<<sp_2, br/>text-align: center;"+
            "}"



        }
    ]
}

export default CaroselItem;
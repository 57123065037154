
import Styling from "./Style.module.css";
import AnimationBar from "../../../AnimationBar/animationBar"
import myBrowser from "../../../../BaseProject/Handlers/myBrowser";



let RenderPage : React.FC<{}> = () => { 
    let mySkills : {percentage:number, title:String}[] = [
        {
            percentage : 85,
            title : "Java/C++/JS (TypeScript)"
        },
        {
            percentage : 70,
            title : "HTML/CSS"
        },
        {
            percentage : 100,
            title : "DSA Princples"
        }
        ,
        {
            percentage : 100,
            title : "OOP Principles"
        },
        {
            percentage : 65,
            title : "React-Js"
        },
        {
            percentage : 10,
            title : "Spring-Boot"
        },
        {
            percentage : 70,
            title : "Graphic / Print Design"
        },
        {
            percentage : 40,
            title : "UX/UI Design"
        },
    ]


    // custom styling
    const PageQuery : String = `
    @media only screen and (max-width: ${myBrowser.mobile_Width()}px) {
        #Skill_BarContainer{
            width: 100%;
        }

    }

    @media only screen and (min-width: ${myBrowser.mobile_Width()}px) {
        #Skill_BarContainer{
            width: 50%;
        

        }
        
    } 

    `;



    return(
        <>
            <style> {PageQuery} </style>
            <div className={Styling.container}>
                {
                    mySkills.map( (item) =>  {
                        return(
                            <>
                                <div id ="Skill_BarContainer" className={Styling.barContainer}>
                                    <AnimationBar Title = {item.title} percentage={item.percentage} />
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}


export default RenderPage;
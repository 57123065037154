import Styling from "./Styling.module.css";
import TitleWrapper from "../../TitleWrapper/TitleWrapper";
import myBrowser from "../../../BaseProject/Handlers/myBrowser";
import ImageAnimate from "./imageAnimate/ImageAnimate";
import FrameLayout from "../../../BaseProject/Components/layouts/FrameLayout/FrameLayout";
import { ImagesDir } from "../../../Details";
import DescriptionStyling from "../../../BaseProject/MyResume/MyDescription/MyDescriptionStyling.module.css";

let RenderPage : React.FC<{}> = (props) => { 



    // custom styling
    const PageQuery : String = `
        @media only screen and (max-width: ${600}px) {
            #mobile_PI{
                height : 200px !important;
            }
        }

        @media only screen and (max-width: ${myBrowser.mobile_Width()}px) {
            #profileContainer{
                width:100%;
                // grid-template-columns: 100%; 
                display:flex;
                flex-direction:column;
                flex-direction: column-reverse;
            }

            #description_Container{
                padding-left:0;
                text-align: center;
            }

            #desktop_PI{
                display:none;
            }

            #mobile_PI{
                display:block;
                border-radius: 0px;
                height : 400px;
            }            
   
            #mainProfile_Container{
                padding-top:50px;
                padding-bottom: 50px;
            }


            #description_Marker{
                display:none;
            }
            #mainTitle{
                font-Size:30px !important;
            }
        }

        @media only screen and (min-width: ${myBrowser.mobile_Width()}px) {
            #profileContainer{
                width:90%;
                min-width:700px;
            } 

            #description_Container{
                padding-left:10%;
                text-align : justify;
            }

            #desktop_PI{
                display:block;
            }

            #mobile_PI{
                display:none;
            }
           
            #mainProfile_Container{
                padding-top: 100px;
                padding-bottom: 100px;
            }

            #description_Marker{
                display:block;
            }
        } 
      
    `;


    return(
        <>
            <style> {PageQuery} </style>
            <div id = "mainProfile_Container" className={Styling.container} >
                <div id = "profileContainer" className={Styling.profileContainer}>
                    <div id ="desktop_PI" className={Styling.profileImageContainer}>
                            {/* <img style = {{width:'100%', height:'100%', objectFit:'cover'}} src = "./images/profilePic.png" /> */}
                            < ImageAnimate/>
                    </div>

                    <div id = "description_Container" className={Styling.descriptionContainer}>
                        <div className={Styling.roleDescription}>
                            <h2> Front End Developer / Designer  </h2>
                        </div>
                        <div className={Styling.desktopNaming} >   
                            {/* <FrameLayout isDarkMode ={true}> */}
                                <h1 id = "mainTitle" style = {{ margin:'0%', color:"black", marginTop:"5px", marginBottom:"5px", fontSize:"40px"}}> Hi! I'm Gordon. </h1>
                                <img id = "description_Marker" className = {DescriptionStyling.NameHighlight}  src = {ImagesDir + "marker.svg"}/>
                                
                            {/* </FrameLayout> */}
                        </div>

                        <div id = "mobile_PI" className={Styling.profileImageContainer} style = {{marginBottom:'15px',  marginTop:"35px"}}>
                            <ImageAnimate/>
                            {/* <img style = {{width:'100%', height:'100%', objectFit:'cover'}} src = "./images/profilePic.png" /> */}
                        </div>
                        <div style = {{width:"100%"}}>
                            <p style = {{textAlign:"left"}} >                         
                                Experienced Web Developer with a profound passion for coding and problem-solving. 
                                Possessing a solid foundation in Computer Science and keen eye for design, 
                                I bring a unique combination of technical expertise and creative innovation to every project,
                                harnessing my problem-solving abilities to analyze complex problems and to develop 
                                elegant and efficient solutions.
                            </p>
                            {/* <p style = {{textAlign:"left"}}>
                                With a relentless drive for excellence, I am committed to delivering high-quality work that exceeds expectations.
                            </p> */}

                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}



export default RenderPage;


import "./Styling.css"

let renderPage : React.FC<{}> = () => {


    return(
        <>
            <div style = {{height :"100%", width:'100%', position:'relative'}}>
                <nav className="menu">
                <input type="checkbox" className="menu-open" name="menu-open" id="menu-open"/>
                <label className="menu-open-button">
                    <span style = {{width:'50%', height :'1px', background:'black'}}></span>
                    <span style = {{width:'50%', height :'1px', background:'black'}}></span>
                    <span style = {{width:'50%', height :'1px', background:'black'}}></span>

                    {/* <span className="lines line-2"></span>
                    <span className="lines line-3"></span> */}
                </label>
                </nav>


            </div>

        </>
    )


}


export default renderPage
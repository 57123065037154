import  {items} from '../../../../contentCarousel/contentCarosel';

 
let CaroselItem : items =    {
    key : 1,
    Subject : "BackwardChaining (AI)",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}

            <p>  
                This project implements an  propositional backward chaining system, a crucial reasoning mechanism in knowledge-based systems utilised
                to make inferences based on some given knowledge-base comprising of finite sets of boolean formulas.

                Upon running the program, users input their knowledge base(KB), and the system prompts for testing primitive facts. 
                The system employs a search tree to perform backward chaining. The user can inquire if a primitive fact 
                Q can be inferred, that is : KB entails Q, then receiving responses "yes" / or "no."
            </p>

            <h3 style = {{textDecoration:'underline'}}> Example : </h3>
            <div style = {{boxSizing:'border-box', marginLeft:'5%'}}>
                <span style = {{textDecoration:'underline'}}>  Input : </span>
                <br></br>
                <div style = {{paddingLeft:'15px'}}>
                    <span>
                        a^b=&gt;c
                        <br></br>
                        cvd=&gt;f
                        <br></br>
                        fvgvhvp=&gt;x
                        <br></br>
                        x^y=&gt;z
                        <br></br>                        
                    </span>
                </div>
                <br></br>
                <span style = {{textDecoration:'underline'}}>  Textcase </span>
                <span> : prove z? (yes) </span>
            </div>
        </>,
    img_Url:"./images/Project_Images/B_Chaining.jpg",        
    file_List : [
        {
            key : 1,
            FileTitle : "BackwardChaining.java",
            content : 
            "<<white/><<<sp_0, br/><<lightblue/>import<<white/> java.util.*;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> BackwardChaining {"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>private<<white/> <<orange/>class<<white/> fact{"+
            "<<<sp_5, br/>LinkedList<Character> AtomicStatements;"+
            "<<<sp_5, br/>char implication;                   <<grey/>// implication of atomic statements, i.e. AtomicStatements => implication"+
            "<<white/><<<sp_5, br/>char connectives = '1';             <<grey/>// logical conneective of this fact (i.e. ^(conjunct) / v (disjunct)), where 1 = Atomic fact (not implication)."+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_5, br/><<grey/>/** translate facts */<<white/>"+
            "<<<sp_5, br/>fact(String fact){"+
            "<<<sp_7, br/>AtomicStatements = <<orange/>new<<white/> LinkedList<>();"+
            "<<<sp_7, br/>try{ "+
            "<<<sp_9, br/><<lightblue/>for<<white/>(int i=0; i < fact.length();i++) {"+
            "<<<sp_12, br/>char c = fact.charAt(i);"+
            "<<<sp_12, br/><<lightblue/>if<<white/> (c == 'v' || c == 'V' || c == '^')  connectives = c;                 <<grey/>// is a connective"+
            "<<white/><<<sp_12, br/><<lightblue/>else<<white/> {                                                                  <<grey/>// is some fact OR implication symbol"+
            "<<white/><<<sp_13, br/><<lightblue/>if<<white/> (c == '=' && fact.charAt(i+1) == '>' || fact.length() == 1) {    <<grey/>// fact is atomic or reached implication atom"+
            "<<white/><<<sp_14, br/>implication = (fact.length() == 1) ? c : fact.charAt(i+2);      "+
            "<<<sp_14, br/><<orange/>break<<white/>;"+
            "<<<sp_13, br/>}"+
            "<<<sp_13, br/>AtomicStatements.addLast(c);"+
            "<<<sp_12, br/>}"+
            "<<<sp_9, br/>}"+
            "<<<sp_7, br/>}<<lightblue/>catch<<white/> (Exception c){"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println(c.getMessage() + '\nError processing fact :' + fact + '\nEnsure fact is of correct for mat!' );"+
            "<<<sp_9, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_7, br/>}"+
            "<<<sp_5, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<orange/>public<<white/> <<orange/>void<<white/> printFact(){"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (connectives != '1'){                                            <<grey/>// if is NOT atomic fact"+
            "<<white/><<<sp_9, br/><<lightblue/>for<<white/>(int i=0; i < AtomicStatements.size();i++) {"+
            "<<<sp_12, br/>char c = AtomicStatements.get(i);"+
            "<<<sp_12, br/><<orange/>system<<white/>.out.print(c + '' + (( i == AtomicStatements.size()-1) ? '' : connectives));"+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.print('=>');"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.print(implication+'\n');"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>private<<white/> Map<Character, LinkedList<fact>> knowledgeBase;         <<grey/>// kb"+
            "<<white/><<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>public<<white/> BackwardChaining() {"+
            "<<<sp_5, br/>knowledgeBase = <<orange/>new<<white/> HashMap<>();"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Assuming given rule is correct (i.e. format), insert fact into KB */<<white/>"+
            "<<<sp_3, br/><<orange/>private<<white/> <<orange/>void<<white/> insertKnowledge(String x){"+
            "<<<sp_5, br/>fact f = <<orange/>new<<white/> fact(x);"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (!knowledgeBase.containsKey(f.implication)) knowledgeBase.put(f.implication, <<orange/>new<<white/> LinkedList<fact>()); <<grey/>// create new fact "+
            "<<white/><<<sp_5, br/>knowledgeBase.get(f.implication).push(f);"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print('KB insertion success : ');"+
            "<<<sp_5, br/>f.printFact();"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> addToKnowledgeBase(String x) {        "+
            "<<<sp_5, br/><<lightblue/>if<<white/> (x.contains('=>') || x.length() == 1)   insertKnowledge(x);    "+
            "<<<sp_5, br/><<lightblue/>else<<white/>{"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println('Invalid fact. Knowledge base system only accepts facts of the following form :');"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println('  P1^P2^,....^Pk => P, OR\n  P1vP2v,...,Pk => P,  OR\n  P');"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>public<<white/>  <<orange/>void<<white/> printKnowledgeBase(){"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(Map.Entry<Character, LinkedList<fact>> entry : knowledgeBase.entrySet()) {"+
            "<<<sp_7, br/>LinkedList<fact> facts = entry.getValue();"+
            "<<<sp_7, br/><<lightblue/>for<<white/>(fact f : facts) f.printFact();"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Prove inference */<<white/>"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> prove(char inference){"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('-------------------------- Backward chain trace --------------------------');"+
            "<<<sp_5, br/>Map<Character, Boolean> Visited = <<orange/>new<<white/> HashMap<>();"+
            "<<<sp_5, br/>boolean truth = backwardChain(inference, Visited);"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('---------------------------------- END -------------------------------------');"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('The <<orange/>system<<white/> have evaluated ' + inference + ' to be ' + ((truth) ? 'yes' : 'no' ) + '\n\n');"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Backwardchain algorithm */<<white/>"+
            "<<<sp_3, br/><<orange/>private<<white/> boolean backwardChain(char inference,  Map<Character, Boolean> Visited){"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (!knowledgeBase.containsKey(inference)){"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println('Cannot prove : ' + inference);"+
            "<<<sp_7, br/><<orange/>return<<white/> false;                                           <<grey/>// cannot derive knowledge"+
            "<<white/><<<sp_5, br/>} "+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<grey/>// insert / or return visited nodes and its truth"+
            "<<white/><<<sp_5, br/><<lightblue/>if<<white/> (Visited.containsKey(inference)){"+
            "<<<sp_7, br/>boolean truth = Visited.get(inference);"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.println(inference + ' already visited / or is a current branch root');"+
            "<<<sp_7, br/><<orange/>return<<white/> truth;"+
            "<<<sp_5, br/>} "+
            "<<<sp_5, br/><<lightblue/>else<<white/> Visited.put(inference, false);    "+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/>LinkedList<fact> expansion = knowledgeBase.get(inference);"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(fact f: expansion){"+
            "<<<sp_7, br/>f.printFact();"+
            "<<<sp_7, br/><<grey/>// ATOMIC FACT (BASE)"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/> (f.connectives == '1'){"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println(inference + ' proven!');"+
            "<<<sp_9, br/><<orange/>return<<white/> true; <<grey/>//base fact reached"+
            "<<white/><<<sp_7, br/>} "+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<grey/>// ITERATE ALL IMPLICATION ATOMS"+
            "<<white/><<<sp_7, br/><<lightblue/>for<<white/>(int i=0; i < f.AtomicStatements.size();i++){"+
            "<<<sp_9, br/>char c = f.AtomicStatements.get(i);"+
            "<<<sp_9, br/>boolean truth = backwardChain(c, Visited);"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (truth) {"+
            "<<<sp_12, br/>Visited.put(c, true);"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (f.connectives == 'v' || f.connectives == 'V') {             <<grey/>// OR connective, we need only one atom to hold true"+
            "<<white/><<<sp_12, br/><<lightblue/>if<<white/> (truth) <<orange/>return<<white/> true;                "+
            "<<<sp_10, br/>}<<lightblue/>else<<white/> <<lightblue/>if<<white/> (truth == false)  <<orange/>break<<white/>;                              <<grey/>// AND Connective, we need all atoms to hold."+
            "<<white/><<<sp_9, br/>"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (i == f.AtomicStatements.size()-1 && truth)  <<orange/>return<<white/> true;    <<grey/>// ALL connectives (AND) are satisfied!"+
            "<<white/><<<sp_7, br/>}"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('A^B^C^D=>F'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('BvEvG=>F'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('H^I=>A'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('J^K=>B'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('UvTvW=>J'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('I^J^T=>P'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('I^J^F=>Q'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('H'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('I'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('K'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('D'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('W'); "+
            "<<white/><<<sp_3, br/><<grey/>// bc.addToKnowledgeBase('C'); "+
            "<<white/><<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(String[] args) {"+
            "<<<sp_5, br/>BackwardChaining bc = <<orange/>new<<white/> BackwardChaining();"+
            "<<<sp_5, br/>Scanner scanner = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
            "<<<sp_5, br/>String input;"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('This is an extended propositional backward chaining system.');"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('Your knowledge base can only accept facts like P1^P2^...^Pk=>P, or P1vP2v...vPk=>P, or P.');"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('Now please input your knowledge base. When you finish your input, just type 'nil'.');"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<lightblue/>while<<white/> (true) {"+
            "<<<sp_7, br/>input = scanner.nextLine();"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (input.equals('nil')) <<orange/>break<<white/>;"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/>bc.addToKnowledgeBase(input);"+
            "<<<sp_5, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('You have finished your input. This is your knowledgebase.\n----------------------------------');"+
            "<<<sp_5, br/>bc.printKnowledgeBase();"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('-----------------------------------\n');"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<lightblue/>while<<white/> (true) {"+
            "<<<sp_7, br/><<orange/>system<<white/>.out.print('Enter Fact / inferences to test (or 'quit' to exit): ');"+
            "<<<sp_7, br/>input = scanner.nextLine();"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (input.equals('quit')) {"+
            "<<<sp_9, br/><<orange/>break<<white/>;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>bc.prove(input.charAt(0));"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_0, br/>}"
        }
    ]
}

export default CaroselItem;
import  {items} from '../../../Components/layouts/contentCarousel/contentCarosel';

 
let CaroselItem : items =    {
    key : 1,
    Subject : "NumberedTicTacToe (AI)",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}

            <p>  
                This program implements an intelligent and strategic solution for playing the Number Tic-Tac-Toe game. 

                Number Tic-Tac-Toe is a variation of the classic Tic-Tac-Toe game where players aim to create a row of three 
                numbers in any direction (vertically, horizontally, or diagonally) with a sum of 15. The game is played on 
                a 3x3 grid, and each player is assigned either odd numbers (1, 3, 5, 7, 9) or even numbers (2, 4, 6, 8). 
                Players take turns placing their numbers on the grid, and the first player to form a valid row with a sum 
                of 15 wins the game. The objective adds a numerical challenge to the traditional Tic-Tac-Toe, r
                equiring strategic thinking to achieve the desired sums.
            </p>
            
            
            <h3 style = {{textDecoration:'underline'}}> Program structure and justification : </h3>
            <p>
                
            </p> 

            <p>
                The NumberTicTacToe is initialised by the main method, where a
                drive method is called to guide gameplay.
            </p>

            <p>
                After selection, the AI player is created, playing against the human
                player. The AI employs the minimax algorithm, systematically
                exploring the entire decision tree comprising of all combinations
                of possible moves. During this process, the algorithm evaluates
                the consequences of maximising and minimising losses during its
                recursive descend down the game tree, and assigning values to
                different branches.
            </p>

            <p>
                To enhance the efficiency of the minimax algorithm, we implement
                alpha-beta pruning to reduce the number of nodes to explore by
                pruning and eliminating branches that are unlikely to yield
                significant benefits for the player. Such branches are left
                unexplored.
            </p>

            <p>
                Alternatively, we introduce a cutoff value, denoted as ‘depth’ to
                discontinue further exploration after a certain depth has been
                reached in the decision tree. To estimate the likelihood of a win at
                the current game state, we may utilise the evaluation function.
                However, the utility function (base case) is utilised instead because 
                the depth of the search is small in the numberedTicTacToe game, and we need not assume
                the games outcome.
            </p>


            <p>
                The combination of minimax and alpha-beta
                pruning suffices to expore all branches in the
                simple NumberTicTacToe Game. As a result, the evaluation
                function is not always necessary, as the depth would never exceed
                extremely large complexities. However, it is worth nothing that the
                evaluation function can be utilised for expansion, to prioritise
                branches (similar to its employment in the A* algorithm), although
                that is omitted in this program.
            </p>

            <p style = {{textDecoration:'underline'}}> We define the evaluation function 'f' as follows :</p>
            <div style = {{boxSizing:'border-box'}}>
                <ol>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  f(s) = 1 </span>
                        <span>
                            IFF ∃ 'k' in row, column, or diagonal s.t. ∃ 'x' ∈ playerSet : x + k = 15 OR ∃ x, x’ ∈ playerSet ⋀ x+x’+k = 15; for x != x’
                        </span>
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  f(s) = 0 </span>
                        <span>
                            IFF f(s) != 1. That is, property satisfying f(s) = 1 does not hold for state ’s’.
                        </span>
                    </li>
                </ol>
            </div>

            <p style = {{textDecoration:'underline'}}> We define the evaluation function 'u' as follows : </p>
            <ol>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  u(s) = 1 </span>
                        <span> if State ’s’ results in a win, assign a score of 1. </span>
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  u(s) = -1 </span>
                        <span> If state s results in a loss , assign a score of -1. </span>
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  u(s) = f(s) </span>
                        <span> If state s results in a draw, use the evaluation function f(s) to assign a score. </span>
                    </li>
            </ol>
        </>,
    img_Url:"https://www.researchgate.net/profile/Dalpat-Songara/publication/309775540/figure/fig2/AS:876185475100673@1585910304785/Tic-tac-toe-Board-with-Positions.ppm",
    file_List : [
        {
            key : 1,
            FileTitle : "NumberTicTacToe.java",
            content : 
                "<<white/><<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;  <<grey/>// Import the Scanner class"+
                "<<white/><<<sp_0, br/><<lightblue/>import<<white/> java.util.Set;"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.HashSet; <<grey/>// Import the HashSet class"+
                "<<white/><<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> NumberTicTacToe{"+
                "<<<sp_3, br/><<orange/>final<<white/> <<orange/>static<<white/> int width = 3, height = 3;"+
                "<<<sp_3, br/><<orange/>final<<white/> <<orange/>static<<white/> int SUM = 15; <<grey/>// WININNG SUM VALUE"+
                "<<white/><<<sp_3, br/><<orange/>private<<white/> int[<<grey/>/* Y */<<white/>][<<grey/>/* X */<<white/>] board;"+
                "<<<sp_3, br/><<orange/>private<<white/> Scanner InputScanner;"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/>NumberTicTacToe(){"+
                "<<<sp_5, br/>InputScanner = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
                "<<<sp_5, br/>board = <<orange/>new<<white/> int[3][3];"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<lightblue/>for<<white/> (int x=0; x < width; x++)"+
                "<<<sp_7, br/><<lightblue/>for<<white/> (int y=0; y < height; y++)"+
                "<<<sp_8, br/>board[y][x] = 0;"+
                "<<<sp_3, br/>}"+
                "<<<sp_2, br/>"+
                "<<<sp_3, br/><<grey/>/**"+
                "<<<sp_4, br/>* @return A new board representing the current game"+
                "<<<sp_4, br/>*/<<white/>"+
                "<<<sp_3, br/><<orange/>public<<white/> int[][] getBoard()"+
                "<<<sp_3, br/>{"+
                "<<<sp_5, br/>int[][] <<orange/>new<<white/>Board = <<orange/>new<<white/> int[width][height];"+
                "<<<sp_5, br/><<lightblue/>for<<white/> (int x=0; x < width; x++)"+
                "<<<sp_7, br/><<lightblue/>for<<white/> (int y=0; y < height; y++)"+
                "<<<sp_8, br/><<orange/>new<<white/>Board[y][x] = board[y][x];"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<orange/>return<<white/> <<orange/>new<<white/>Board;"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> printBoard(int[][] board) {"+
                "<<<sp_5, br/><<lightblue/>for<<white/> (int row = 0; row < 3; row++) {"+
                "<<<sp_7, br/><<lightblue/>for<<white/> (int col = 0; col < 3; col++) {"+
                "<<<sp_8, br/><<orange/>system<<white/>.out.print(board[row][col]);"+
                "<<<sp_8, br/><<lightblue/>if<<white/> (col < 2) {"+
                "<<<sp_9, br/><<orange/>system<<white/>.out.print(' | ');"+
                "<<<sp_8, br/>}"+
                "<<<sp_7, br/>}"+
                "<<<sp_7, br/><<orange/>system<<white/>.out.println();"+
                "<<<sp_7, br/><<lightblue/>if<<white/> (row < 2) {"+
                "<<<sp_8, br/><<orange/>system<<white/>.out.println('---------');"+
                "<<<sp_7, br/>}"+
                "<<<sp_5, br/>}"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>private<<white/> boolean isInt(String val)"+
                "<<<sp_3, br/>{"+
                "<<<sp_5, br/>boolean isInteger = true;"+
                "<<<sp_5, br/><<lightblue/>for<<white/> (int i = 0; i < val.length(); i++) {"+
                "<<<sp_7, br/>char c = val.charAt(i);"+
                "<<<sp_7, br/><<lightblue/>if<<white/> (!Character.isDigit(c)) {"+
                "<<<sp_8, br/>isInteger = false;"+
                "<<<sp_8, br/><<orange/>break<<white/>;"+
                "<<<sp_7, br/>}"+
                "<<<sp_5, br/>}"+
                "<<<sp_5, br/><<orange/>return<<white/> isInteger;"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> int ODDPLAYER = 1, EVENPLAYER = 2;"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>public<<white/> <<orange/>void<<white/> play(){"+
                "<<<sp_5, br/><<orange/>system<<white/>.out.println('Select your associated number set :\n  1 = {1,3,5,7,9} \n  2 = {2,4,6,8} ');"+
                "<<<sp_5, br/>"+
                "<<<sp_5, br/>int Human_Player = 0; <<grey/>// 2 = even, 1 = odd;"+
                "<<white/><<<sp_5, br/><<grey/>// initialise player set values"+
                "<<white/><<<sp_5, br/>Set<Integer> evenSet = <<orange/>new<<white/> HashSet<>(); "+
                "<<<sp_5, br/>Set<Integer> oddSet = <<orange/>new<<white/> HashSet<>();"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<lightblue/>for<<white/> (int i=1; i <= 9; i++){"+
                "<<<sp_7, br/><<lightblue/>if<<white/> ((i % 2) == 0) evenSet.add(i);"+
                "<<<sp_7, br/><<lightblue/>else<<white/> oddSet.add(i);"+
                "<<<sp_5, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<grey/>// Get User Set selection "+
                "<<white/><<<sp_5, br/><<lightblue/>do<<white/> {"+
                "<<<sp_7, br/>String input = getUserInput();"+
                "<<<sp_7, br/><<lightblue/>if<<white/> (isInt(input)) <<grey/>// valid input"+
                "<<white/><<<sp_7, br/>{"+
                "<<<sp_8, br/><<grey/>// Associate Human player with set"+
                "<<white/><<<sp_8, br/><<lightblue/>if<<white/> ( Integer.parseInt(input) == ODDPLAYER) Human_Player = ODDPLAYER;"+
                "<<<sp_8, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (Integer.parseInt(input) == EVENPLAYER) Human_Player = EVENPLAYER;"+
                "<<<sp_7, br/>}"+
                "<<<sp_5, br/>}<<lightblue/>white<<white/>(Human_Player == 0);"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<grey/>// Prompt users"+
                "<<white/><<<sp_5, br/><<orange/>system<<white/>.out.println('\nYou are playing the ' + ((Human_Player == EVENPLAYER) ? 'even' : 'odd') + ' player'); "+
                "<<<sp_5, br/><<orange/>system<<white/>.out.print('Here are your available numbers : \n    '   );"+
                "<<<sp_5, br/>printSet( ((Human_Player == EVENPLAYER ) ? evenSet : oddSet) );"+
                "<<<sp_5, br/><<orange/>system<<white/>.out.println('\n\n\n----- Game Begins -----');"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<grey/>// ITERATE UNTIL GAME CONCLUDES"+
                "<<white/><<<sp_5, br/>int current_Player = 1; "+
                "<<<sp_5, br/>int state = 1;"+
                "<<<sp_5, br/>Minimax_Player MinimaxPlayer = <<orange/>new<<white/> Minimax_Player( ((Human_Player % 2)+1) );"+
                "<<<sp_5, br/><<lightblue/>white<<white/>(true)"+
                "<<<sp_5, br/>{"+
                "<<<sp_7, br/><<orange/>system<<white/>.out.println('\n' + ((current_Player == ODDPLAYER) ? '[ODD' : '[EVEN') + ' players move]');"+
                "<<<br,hidden/>br"+
                "<<<sp_7, br/>int x =-1, y = -1, selectedNumber;"+
                "<<<br,hidden/>br"+
                "<<<sp_7, br/><<grey/>// Get & validate user selection"+
                "<<white/><<<sp_6, br/>"+
                "<<<sp_7, br/><<lightblue/>if<<white/> (current_Player != Human_Player)"+
                "<<<sp_7, br/>{"+
                "<<<sp_8, br/>int[] bestMove = MinimaxPlayer.get_BestMove(this, evenSet, oddSet);"+
                "<<<sp_8, br/>x = bestMove[0];"+
                "<<<sp_8, br/>y = bestMove[1];"+
                "<<<sp_8, br/>selectedNumber = bestMove[2];"+
                "<<<sp_8, br/>printSet( ((current_Player == EVENPLAYER ) ? evenSet : oddSet) );"+
                "<<<sp_8, br/><<orange/>system<<white/>.out.println('\n[AI Player have selected move x :' + x + ', y :' + y +', number :' + selectedNumber +']');"+
                "<<<sp_7, br/>}<<lightblue/>else<<white/>{ "+
                "<<<br,hidden/>br"+
                "<<<sp_8, br/><<lightblue/>white<<white/>(true){"+
                "<<<sp_9, br/><<lightblue/>try<<white/>{"+
                "<<<sp_10, br/>printSet( ((current_Player == EVENPLAYER ) ? evenSet : oddSet) );"+
                "<<<sp_10, br/><<orange/>system<<white/>.out.println();"+
                "<<<sp_10, br/><<orange/>system<<white/>.out.print('Input x : ');"+
                "<<<sp_10, br/>x = InputScanner.nextInt();"+
                "<<<sp_10, br/><<orange/>system<<white/>.out.print('Input y : ');"+
                "<<<sp_10, br/>y = InputScanner.nextInt();"+
                "<<<sp_10, br/><<orange/>system<<white/>.out.print('Input one of your number ');"+
                "<<<sp_10, br/><<orange/>system<<white/>.out.print(' : ');"+
                "<<<sp_5, br/>"+
                "<<<sp_10, br/>selectedNumber = InputScanner.nextInt();"+
                "<<<sp_5, br/>"+
                "<<<sp_10, br/><<orange/>system<<white/>.out.println('[You have selected move x :' + x + ', y :' + y +', number :' + selectedNumber +']');"+
                "<<<sp_10, br/><<grey/>// check input (x,y) && (selectedNumber) is valid"+
                "<<white/><<<sp_10, br/><<lightblue/>if<<white/> (!isValidMove(x, y, board) || !((current_Player == EVENPLAYER ) ? evenSet.contains(selectedNumber) : oddSet.contains(selectedNumber)) )"+
                "<<<sp_11, br/><<orange/>system<<white/>.out.println( '[Invalid move OR selected number, Make Selection again!]' );"+
                "<<<sp_10, br/><<lightblue/>else<<white/> <<orange/>break<<white/>;"+
                "<<<sp_9, br/>}<<lightblue/>catch<<white/> (Exception i) {"+
                "<<<sp_10, br/><<orange/>system<<white/>.out.println('Invalid input, integers only accepted');"+
                "<<<sp_10, br/>InputScanner.nextLine(); <<grey/>// clear previous line off buffer"+
                "<<white/><<<sp_9, br/>} "+
                "<<<sp_8, br/>}"+
                "<<<sp_7, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_7, br/>insertMove(x, y, selectedNumber);   <<grey/>// insert move onto board"+
                "<<white/><<<sp_7, br/><<lightblue/>if<<white/> (current_Player == EVENPLAYER) evenSet.remove(selectedNumber); "+
                "<<<sp_7, br/><<lightblue/>else<<white/> oddSet.remove(selectedNumber);"+
                "<<<sp_7, br/>"+
                "<<<sp_7, br/><<grey/>// check game state"+
                "<<white/><<<sp_7, br/><<lightblue/>import<<white/>(check_GameState(board))"+
                "<<<sp_7, br/>{"+
                "<<<sp_8, br/><<lightblue/>case<<white/> 1: "+
                "<<<sp_9, br/>printBoard(board);  "+
                "<<<sp_9, br/><<orange/>system<<white/>.out.println('Congralation' + ( (current_Player == Human_Player) ?  ' HumanPlayer ' : ' AI Player ')  + 'has won!');"+
                "<<<sp_9, br/><<orange/>return<<white/>;"+
                "<<<sp_8, br/><<lightblue/>case<<white/> 2 :"+
                "<<<sp_9, br/>printBoard(board);"+
                "<<<sp_9, br/><<orange/>system<<white/>.out.println('game is draw!');"+
                "<<<sp_9, br/><<orange/>return<<white/>;"+
                "<<<sp_7, br/>};"+
                "<<<br,hidden/>br"+
                "<<<sp_7, br/><<orange/>system<<white/>.out.println('\nGame State ' + state);"+
                "<<<sp_7, br/>printBoard(board);"+
                "<<<br,hidden/>br"+
                "<<<sp_7, br/>state++;"+
                "<<<sp_7, br/>current_Player = (current_Player % 2)+1; <<grey/>// rotate player"+
                "<<white/><<<sp_5, br/>}"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>private<<white/> <<orange/>void<<white/> insertMove(int x, int y, int number){"+
                "<<<sp_5, br/><<lightblue/>if<<white/> (isValidMove(x, y, board)) {"+
                "<<<sp_7, br/>board[y][x] = number;"+
                "<<<sp_5, br/>}"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>public<<white/> boolean isValidMove(int x, int y, int[][]board){"+
                "<<<sp_5, br/><<orange/>return<<white/> (x >= 0 && x < 3) && (y >=0 && y <3) && board[y][x] == 0;"+
                "<<<sp_3, br/>}"+
                "<<<sp_3, br/>"+
                "<<<sp_3, br/><<orange/>private<<white/> <<orange/>void<<white/> printSet(Set<Integer> numberSet){"+
                "<<<sp_5, br/><<orange/>system<<white/>.out.print('{');"+
                "<<<sp_5, br/>int index = 0;        "+
                "<<<sp_5, br/><<lightblue/>for<<white/> (Integer number : numberSet) "+
                "<<<sp_7, br/><<orange/>system<<white/>.out.print(number + ( (index++ < numberSet.size()-1) ?  ',' : '') );"+
                "<<<sp_5, br/>"+
                "<<<sp_5, br/><<orange/>system<<white/>.out.print('}');"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<grey/>/**"+
                "<<<sp_4, br/>* check the state of the game based on the given board."+
                "<<<sp_4, br/>* @return 0 = came running, 1 = player won, 2 = draw "+
                "<<<sp_4, br/>*/<<white/>"+
                "<<<sp_3, br/><<orange/>public<<white/> int check_GameState(int[][] board){"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<grey/>// check column and rows fow win"+
                "<<white/><<<sp_5, br/><<lightblue/>for<<white/> (int i = 0; i <width; i++) {"+
                "<<<sp_7, br/><<grey/>// Check rows for a win"+
                "<<white/><<<sp_7, br/><<lightblue/>if<<white/> ( (board[i][0] + board[i][1] + board[i][2] )  == SUM ) <<orange/>return<<white/> 1;"+
                "<<<sp_7, br/><<grey/>// Check columns for a win"+
                "<<white/><<<sp_7, br/><<lightblue/>if<<white/> ( (board[0][i] + board[1][i] + board[2][i] )  == SUM ) <<orange/>return<<white/> 1;"+
                "<<<sp_5, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<grey/>//Check diagonals for a win"+
                "<<white/><<<sp_5, br/><<lightblue/>if<<white/> ( (board[0][0] + board[1][1] + board[2][2] ) == SUM || (board[2][0] + board[1][1] + board[0][2] ) == SUM ) "+
                "<<<sp_7, br/><<orange/>return<<white/> 1;"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<lightblue/>for<<white/> (int i=0; i < width; i++)"+
                "<<<sp_7, br/><<lightblue/>for<<white/> (int y=0; y < height; y++) "+
                "<<<sp_8, br/><<lightblue/>if<<white/> (board[i][y] == 0) <<orange/>return<<white/> 0; <<grey/>// game continues"+
                "<<white/><<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/><<orange/>return<<white/> 2; <<grey/>// 1draw"+
                "<<white/><<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>private<<white/> String getUserInput()"+
                "<<<sp_3, br/>{"+
                "<<<sp_5, br/>Scanner scan = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
                "<<<sp_5, br/><<orange/>return<<white/> (scan.next());"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_2, br/>"+
                "<<<sp_3, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(String [] arghs){"+
                "<<<sp_5, br/>NumberTicTacToe game = <<orange/>new<<white/> NumberTicTacToe();"+
                "<<<br,hidden/>br"+
                "<<<sp_5, br/>game.play();"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"            
        },
        {
            key : 2,
            FileTitle : "Minimax_Player.java",
            content : 
                "<<white/><<<sp_0, br/><<lightblue/>import<<white/> java.util.HashSet;"+
                "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Set;"+
                "<<<br,hidden/>br"+
                "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> Minimax_Player{ "+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<orange/>private<<white/> int player; <<grey/>// 1 = odd player, 2 = even player"+
                "<<white/><<<sp_3, br/>Minimax_Player(int player)"+
                "<<<sp_3, br/>{"+
                "<<<sp_6, br/><<orange/>system<<white/>.out.println('Heuristic player is playing the ' +  ( (player == 1) ? 'Odd' : 'Even') + ' Player');"+
                "<<<sp_6, br/>this.player = player;"+
                "<<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<grey/>/**"+
                "<<<sp_4, br/>* Evaluation function to evalate the chance of winning for the set 'playerSet'. "+
                "<<<sp_4, br/>* The Evaluation function F(X) is calculated as follows : "+
                "<<<sp_4, br/>*      1 :  "+
                "<<<sp_4, br/>*          IFF ￢ﾈﾃ k in row, column, or diagonal s.t. ￢ﾈﾃ x ￢ﾈﾈ playerSet : x + k = 15 "+
                "<<<sp_4, br/>*           OR ￢ﾈﾃ x, x￢ﾀﾙ ￢ﾈﾈ playerSet ￢ﾋﾀ x+x￢ﾀﾙ+k = 15; for x != x￢ﾀﾙ"+
                "<<<sp_4, br/>*      0 : if f(s) != 1. That is, property satisfying f(s) = 1 does not hold for state ￢ﾀﾙs￢ﾀﾙ."+
                "<<<sp_4, br/>* @param board"+
                "<<<sp_4, br/>* @param playerSet"+
                "<<<sp_4, br/>* @return"+
                "<<<sp_4, br/>*/<<white/>"+
                "<<<sp_3, br/><<orange/>public<<white/> int evaluate(int[][] board, Set<Integer> playerSet){"+
                "<<<sp_6, br/>"+
                "<<<sp_6, br/>int leftDiag =0, rightDiag = 0;                                         <<grey/>// sum of diagonal values"+
                "<<white/><<<sp_6, br/>int amountEmptyLefTDiag = 0, amountEmptyRightDiag =0;                   <<grey/>// amount of empty spot diagnoally "+
                "<<white/><<<br,hidden/>br"+
                "<<<sp_6, br/><<grey/>// ITERATE DIAGONAL "+
                "<<white/><<<sp_6, br/><<lightblue/>for<<white/> (int i=0; i <3; i++)"+
                "<<<sp_6, br/>{"+
                "<<<sp_10, br/>int column =0, row = 0;                                             <<grey/>// Sum of column, row"+
                "<<white/><<<sp_10, br/>int amountEmptyRow =0, amountEmptyCol=0;                            <<grey/>// amount of empty spots in row, column"+
                "<<white/><<<sp_10, br/>int incrementedLeftDiag = leftDiag + board[i][i];                   <<grey/>// temp sum value"+
                "<<white/><<<sp_10, br/>int incrementedRightDiag = rightDiag + board[i][2-i];           "+
                "<<<br,hidden/>br"+
                "<<<sp_10, br/><<lightblue/>if<<white/> (incrementedLeftDiag == leftDiag) amountEmptyLefTDiag++;         <<grey/>// empty spot found"+
                "<<white/><<<sp_10, br/><<lightblue/>if<<white/> (incrementedRightDiag == rightDiag) amountEmptyRightDiag++; "+
                "<<<br,hidden/>br"+
                "<<<sp_10, br/>leftDiag = incrementedLeftDiag;                                     <<grey/>// sum diag value"+
                "<<white/><<<sp_10, br/>rightDiag = incrementedRightDiag; "+
                "<<<br,hidden/>br"+
                "<<<sp_10, br/><<grey/>// ITERATE ROW, COLUMN"+
                "<<white/><<<sp_10, br/><<lightblue/>for<<white/> (int j =0; j < 3; j++) {"+
                "<<<sp_11, br/>int incrementedRow = row + board[i][j];                         <<grey/>// temp sum value           "+
                "<<white/><<<sp_11, br/>int incrementedCol = column + board[j][i];"+
                "<<<br,hidden/>br"+
                "<<<sp_11, br/><<lightblue/>if<<white/> (incrementedRow == row) amountEmptyRow++;                    <<grey/>// empty spot found"+
                "<<white/><<<sp_11, br/><<lightblue/>if<<white/> (incrementedCol == column) amountEmptyCol++;"+
                "<<<br,hidden/>br"+
                "<<<sp_11, br/>row = incrementedRow;"+
                "<<<sp_11, br/>column = incrementedCol;"+
                "<<<sp_11, br/><<grey/>// END REACHED"+
                "<<white/><<<sp_11, br/><<lightblue/>if<<white/> (j == 2){"+
                "<<<sp_13, br/>column = 15 - column;                                        <<grey/>// find remainder to reach 15. "+
                "<<white/><<<sp_13, br/>row = 15 - row;   "+
                "<<<sp_7, br/>"+
                "<<<sp_13, br/><<grey/>// ITERATE PLAYER SET."+
                "<<white/><<<sp_13, br/><<lightblue/>for<<white/> (int k : playerSet) {                       "+
                "<<<sp_16, br/>int amountCol = column - k;                              <<grey/>// (remainder to 15) - x; for x in playerSet"+
                "<<white/><<<sp_16, br/>int amountRow = row - k; "+
                "<<<sp_16, br/><<grey/>// IF sum to 15 found"+
                "<<white/><<<sp_16, br/><<lightblue/>if<<white/> (amountCol == 0 && amountEmptyCol > 0 || amountCol !=k && amountEmptyCol >1 && "+
                "<<<sp_20, br/>playerSet.contains(amountCol) ) <<orange/>return<<white/> 1;"+
                "<<<sp_3, br/>"+
                "<<<sp_16, br/><<lightblue/>if<<white/> (amountRow == 0 && amountEmptyRow >0 || amountCol !=k && amountEmptyRow > 1 && "+
                "<<<sp_20, br/>playerSet.contains(amountRow) ) <<orange/>return<<white/> 1;"+
                "<<<sp_16, br/>"+
                "<<<sp_13, br/>}"+
                "<<<sp_11, br/>}"+
                "<<<sp_10, br/>}"+
                "<<<sp_6, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_6, br/><<grey/>// CHECK DIAGONALLY "+
                "<<white/><<<sp_6, br/>leftDiag = 15 - leftDiag;                                               <<grey/>// find remainder to reach 15. "+
                "<<white/><<<sp_6, br/>rightDiag = 15 - rightDiag;"+
                "<<<sp_6, br/><<grey/>// ITERATE PLAYER SET"+
                "<<white/><<<sp_6, br/><<lightblue/>for<<white/> (int k : playerSet) {"+
                "<<<sp_10, br/>int amountLeftDiag = leftDiag - k;                                  <<grey/>// (remainder to 15) - x; for x in playerSet"+
                "<<white/><<<sp_10, br/>int amountRightDiag = rightDiag - k;"+
                "<<<sp_10, br/><<grey/>// IF sum to 15 found"+
                "<<white/><<<br,hidden/>br"+
                "<<<sp_10, br/><<lightblue/>if<<white/> (amountLeftDiag == 0 && amountEmptyLefTDiag >0|| amountLeftDiag !=k && amountEmptyLefTDiag > 1 && "+
                "<<<sp_11, br/>playerSet.contains(amountLeftDiag) ) <<orange/>return<<white/> 1;"+
                "<<<br,hidden/>br"+
                "<<<sp_10, br/><<lightblue/>if<<white/> (amountRightDiag == 0 && amountEmptyRightDiag > 0 || amountRightDiag !=k && amountEmptyRightDiag > 1 && "+
                "<<<sp_11, br/>playerSet.contains(amountRightDiag) ) <<orange/>return<<white/> 1;"+
                "<<<sp_6, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_6, br/><<orange/>return<<white/> 0;                                                                               <<grey/>// NO potential win found"+
                "<<white/><<<sp_3, br/>}"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_3, br/><<grey/>/**"+
                "<<<sp_4, br/>* Return best move"+
                "<<<sp_4, br/>* @param game"+
                "<<<sp_4, br/>* @param evenSet"+
                "<<<sp_4, br/>* @param oddSet"+
                "<<<sp_4, br/>* @return int[0] = x, int[1] = y, int[2] = Number"+
                "<<<sp_4, br/>*/<<white/>"+
                "<<<sp_3, br/><<orange/>public<<white/> int[] get_BestMove(NumberTicTacToe game, Set<Integer> evenSet, Set<Integer> oddSet){"+
                "<<<br,hidden/>br"+
                "<<<sp_6, br/>int bestScore = -2;"+
                "<<<sp_6, br/>int bestDepth = -1;"+
                "<<<sp_6, br/>int[] bestMove = <<orange/>new<<white/> int[]{-1,-1,-1};"+
                "<<<sp_6, br/>Set<Integer> maxSet;                                                                    <<grey/>// Maximiser player set"+
                "<<white/><<<sp_6, br/>Set<Integer> minSet;                                                                    <<grey/>// Minimiser player set"+
                "<<white/><<<br,hidden/>br"+
                "<<<sp_6, br/><<grey/>// Initialise playerSets "+
                "<<white/><<<sp_6, br/><<lightblue/>if<<white/> (player ==1)"+
                "<<<sp_6, br/>{"+
                "<<<sp_10, br/>maxSet = oddSet;"+
                "<<<sp_10, br/>minSet = evenSet; "+
                "<<<sp_6, br/>}<<lightblue/>else<<white/>{"+
                "<<<sp_10, br/>maxSet = evenSet;"+
                "<<<sp_10, br/>minSet = oddSet;"+
                "<<<sp_6, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_6, br/>int[][] currentBoard = game.getBoard();                                                 <<grey/>// get current board state"+
                "<<white/><<<sp_6, br/>Integer[] maxChoices = maxSet.toArray(<<orange/>new<<white/> Integer[maxSet.size()]);                      <<grey/>// Array of Max Player choices"+
                "<<white/><<<br,hidden/>br"+
                "<<<sp_6, br/><<grey/>// iterate all rows of game board"+
                "<<white/><<<sp_6, br/><<lightblue/>for<<white/> (int y =0; y < game.height; y++ ) {"+
                "<<<sp_10, br/><<grey/>// iterate all columns of game board"+
                "<<white/><<<sp_10, br/><<lightblue/>for<<white/> (int x =0; x < game.width; x++)"+
                "<<<sp_10, br/>{"+
                "<<<sp_11, br/><<lightblue/>if<<white/> (game.isValidMove(x,y, currentBoard))                                        <<grey/>// if position is valid"+
                "<<white/><<<sp_11, br/>{"+
                "<<<sp_13, br/><<grey/>// Iterate the possible Max player inputs"+
                "<<white/><<<sp_13, br/><<lightblue/>for<<white/> (int i=0; i < maxChoices.length; i++)"+
                "<<<sp_13, br/>{"+
                "<<<sp_16, br/>currentBoard[y][x] = maxChoices[i];"+
                "<<<sp_16, br/>maxSet.remove(maxChoices[i]);                                           <<grey/>// remove player choice "+
                "<<white/><<<sp_16, br/>depth = 0;                                                              <<grey/>// depth of tree"+
                "<<white/><<<sp_16, br/><<grey/>// NOTE FOR NEXT TIME, MAKE DEPTH MORE EFFICIENT, THAT IS, CUT OF DEPTH AT CERTAIN "+
                "<<white/><<<sp_16, br/>int score = minimax(game, currentBoard, false, maxSet , minSet, Integer.MIN_VALUE, Integer.MAX_VALUE, bestDepth); <<grey/>// MIN-PLAYER TURN"+
                "<<white/><<<br,hidden/>br"+
                "<<<sp_16, br/><<grey/>// new MAX value"+
                "<<white/><<<sp_16, br/><<lightblue/>if<<white/>(score > bestScore || (score == bestScore && depth < bestDepth) )     <<grey/>// if better score is found OR equalivant but with shorted depth search for score"+
                "<<white/><<<sp_16, br/>{"+
                "<<<sp_20, br/>bestScore = score;                                                  <<grey/>// new max score"+
                "<<white/><<<sp_20, br/>bestMove = <<orange/>new<<white/> int[]{x, y, maxChoices[i]};                          <<grey/>// new best move"+
                "<<white/><<<sp_20, br/>bestDepth = depth;                                                  <<grey/>// depth for best score"+
                "<<white/><<<sp_16, br/>}                    "+
                "<<<sp_16, br/>maxSet.add(maxChoices[i]);                                              <<grey/>// re-insert player choice"+
                "<<white/><<<sp_13, br/>} "+
                "<<<sp_13, br/>currentBoard[y][x] = 0;                                                     <<grey/>// undo previous move"+
                "<<white/><<<sp_11, br/>}"+
                "<<<sp_10, br/>}"+
                "<<<sp_6, br/>};"+
                "<<<sp_6, br/><<orange/>system<<white/>.out.println('Minimax Maximum Score : ' + bestScore);"+
                "<<<sp_6, br/><<orange/>return<<white/> bestMove;"+
                "<<<sp_3, br/>}"+
                "<<<sp_2, br/>"+
                "<<<sp_3, br/><<orange/>private<<white/> <<orange/>static<<white/> int depth;                                                                   <<grey/>// CURRENT DEPTH"+
                "<<white/><<<sp_3, br/><<orange/>private<<white/> <<orange/>static<<white/> int MAXDEPTH = 500000;                                                       <<grey/>// MAX DEPTH"+
                "<<white/><<<sp_3, br/><<grey/>/**"+
                "<<<sp_4, br/>* We utilise the score 1 = win, -1 = lose, 0 = tie.  "+
                "<<<sp_4, br/>* Minimax algorithm explores the entire game tree, alpha-beta pruning is employed to eliminite "+
                "<<<sp_4, br/>* redundant branches. We utilise evaluation function when depth is reached, and utility function when"+
                "<<<sp_4, br/>* game is concluded (end of search)."+
                "<<<sp_4, br/>* "+
                "<<<sp_4, br/>* @param game"+
                "<<<sp_4, br/>* @param board"+
                "<<<sp_4, br/>* @param player"+
                "<<<sp_4, br/>* @param MaxSet"+
                "<<<sp_4, br/>* @param MinSet"+
                "<<<sp_4, br/>* @param alpha"+
                "<<<sp_4, br/>* @param beta"+
                "<<<sp_4, br/>* @return"+
                "<<<sp_4, br/>*/<<white/>"+
                "<<<sp_3, br/><<orange/>private<<white/> int minimax(NumberTicTacToe game,  int[][] board, boolean player, Set<Integer> MaxSet, Set<Integer> MinSet, "+
                "<<<sp_6, br/>int alpha, int beta, int bestDepth)"+
                "<<<sp_3, br/>{"+
                "<<<sp_6, br/>int gameState = game.check_GameState(board);"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_6, br/><<grey/>// UTILITY FUNCTION : Represent desirability for player"+
                "<<white/><<<sp_6, br/><<lightblue/>if<<white/> (gameState == 1)                                                         <<grey/>// some player won"+
                "<<white/><<<sp_6, br/>{   "+
                "<<<sp_10, br/><<orange/>return<<white/> (!player) ? 1 : -1;                                              <<grey/>// 1 IFF maximising player wins, else -1"+
                "<<white/><<<sp_6, br/>}<<lightblue/>else<<white/> <<lightblue/>if<<white/> (gameState == 2)                                                   <<grey/>// game draw"+
                "<<white/><<<sp_6, br/>{"+
                "<<<sp_10, br/><<orange/>return<<white/> evaluate(board, MaxSet)/2;                                       <<grey/>// even score, we use heuristic to estimate state potential"+
                "<<white/><<<sp_6, br/>}"+
                "<<<sp_6, br/>"+
                "<<<sp_6, br/><<grey/>// EVAULATION FUNCTION"+
                "<<white/><<<sp_6, br/><<lightblue/>if<<white/> (depth > MAXDEPTH) <<orange/>return<<white/> evaluate(board, MaxSet);                       <<grey/>// evaluation function, estimate state potential."+
                "<<white/><<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<br,hidden/>br"+
                "<<<sp_6, br/>depth++;                                                                    <<grey/>// increment depth"+
                "<<white/><<<br,hidden/>br"+
                "<<<sp_6, br/><<lightblue/>if<<white/> (player) <<grey/>// Maximising player"+
                "<<white/><<<sp_6, br/>{"+
                "<<<sp_10, br/>int max_Score = -1; "+
                "<<<sp_10, br/>Integer[] maxChoices = MaxSet.toArray(<<orange/>new<<white/> Integer[MaxSet.size()]);      <<grey/>// New Array of Max Player choices"+
                "<<white/><<<sp_10, br/><<grey/>// Iterate all game board"+
                "<<white/><<<sp_10, br/><<lightblue/>for<<white/> (int y=0; y < game.height; y++)"+
                "<<<sp_11, br/><<lightblue/>for<<white/> (int x = 0; x < game.width; x++)"+
                "<<<sp_11, br/>{"+
                "<<<sp_13, br/><<lightblue/>if<<white/> (game.isValidMove(x, y, board)){                             <<grey/>// IF VALID MOVE"+
                "<<white/><<<sp_16, br/><<grey/>// Iterate all max-choices "+
                "<<white/><<<sp_16, br/><<lightblue/>for<<white/> (int i = 0; i < maxChoices.length ; i++)"+
                "<<<sp_16, br/>{"+
                "<<<sp_20, br/>board[y][x] = maxChoices[i];                            <<grey/>// make move"+
                "<<white/><<<sp_20, br/>MaxSet.remove(maxChoices[i]);                           <<grey/>// remove choice from set "+
                "<<white/><<<sp_16, br/>"+
                "<<<sp_20, br/>int score = minimax(game, board, !player, MaxSet, MinSet, alpha, beta,bestDepth); <<grey/>// MIN-PLAYER TURN"+
                "<<white/><<<sp_20, br/><<lightblue/>if<<white/> (score > max_Score){"+
                "<<<sp_23, br/>alpha = score;                                      <<grey/>// new alpha value"+
                "<<white/><<<sp_23, br/>max_Score = score;                                  <<grey/>// NEW MAX SCORE"+
                "<<white/><<<sp_20, br/>}  "+
                "<<<br,hidden/>br"+
                "<<<sp_20, br/><<lightblue/>if<<white/> (alpha >= beta)                                      <<grey/>// MINPlayer will never consider this path, since alpha(maxScore) >= beta."+
                "<<white/><<<sp_20, br/>{"+
                "<<<sp_23, br/>MaxSet.add(maxChoices[i]);"+
                "<<<sp_23, br/>board[y][x] = 0;            "+
                "<<<sp_23, br/><<orange/>return<<white/> max_Score;                                   <<grey/>// end branch  "+
                "<<white/><<<sp_20, br/>}"+
                "<<<br,hidden/>br"+
                "<<<sp_20, br/>MaxSet.add(maxChoices[i]);                              <<grey/>// re-insert player choice"+
                "<<white/><<<sp_16, br/>}"+
                "<<<sp_16, br/>board[y][x] = 0;                                            <<grey/>// undo previous board move"+
                "<<white/><<<sp_13, br/>}"+
                "<<<sp_11, br/>}"+
                "<<<sp_10, br/><<orange/>return<<white/> max_Score;                                                       <<grey/>// return maximised score"+
                "<<white/><<<sp_6, br/>}<<lightblue/>else<<white/>{ <<grey/>// Minimising player"+
                "<<white/><<<sp_10, br/>int min_Score = Integer.MAX_VALUE; "+
                "<<<sp_10, br/>Integer[] minChoices = MinSet.toArray(<<orange/>new<<white/> Integer[MinSet.size()]);"+
                "<<<br,hidden/>br"+
                "<<<sp_10, br/><<lightblue/>for<<white/> (int y=0; y < game.height; y++)"+
                "<<<sp_11, br/><<lightblue/>for<<white/> (int x = 0; x < game.width; x++)"+
                "<<<sp_11, br/>{"+
                "<<<sp_13, br/><<lightblue/>if<<white/> (game.isValidMove(x, y, board)){"+
                "<<<br,hidden/>br"+
                "<<<sp_16, br/><<lightblue/>for<<white/> (int i = 0; i < minChoices.length ; i++)"+
                "<<<sp_16, br/>{"+
                "<<<sp_20, br/>board[y][x] = minChoices[i];"+
                "<<<sp_20, br/>MinSet.remove(minChoices[i]);"+
                "<<<sp_16, br/>"+
                "<<<sp_20, br/>int score = minimax(game, board, !player, MaxSet, MinSet, alpha, beta,bestDepth); <<grey/>// MAX-PLAYER TURN"+
                "<<white/><<<sp_20, br/><<grey/>// new maxScore"+
                "<<white/><<<sp_20, br/><<lightblue/>if<<white/> (score < min_Score){"+
                "<<<sp_23, br/>beta = score;                                       <<grey/>// new beta value."+
                "<<white/><<<sp_23, br/>min_Score = score;"+
                "<<<sp_20, br/>}  "+
                "<<<br,hidden/>br"+
                "<<<sp_20, br/><<lightblue/>if<<white/> ( beta <= alpha )                                    <<grey/>// MAXPlayer will never consider this path since beta(minSCore) <= alpha. "+
                "<<white/><<<sp_20, br/>{"+
                "<<<sp_23, br/>MinSet.add(minChoices[i]);"+
                "<<<sp_23, br/>board[y][x] = 0;            "+
                "<<<sp_23, br/><<orange/>return<<white/> min_Score;                                   <<grey/>// end branch  "+
                "<<white/><<<sp_20, br/>}"+
                "<<<sp_20, br/>MinSet.add(minChoices[i]);"+
                "<<<sp_16, br/>}"+
                "<<<sp_16, br/>board[y][x] = 0; "+
                "<<<sp_13, br/>}"+
                "<<<sp_11, br/>} "+
                "<<<sp_10, br/><<orange/>return<<white/> min_Score;                                                       <<grey/>// return the Minimised score"+
                "<<white/><<<sp_6, br/>}"+
                "<<<sp_3, br/>}"
        }
    ]
}

export default CaroselItem;
import  {items} from '../../../../../contentCarousel/contentCarosel';
import DemoWrapper from "../../../../../DemoWrapper/DemoWrapper";
import React, { MouseEvent, useRef } from 'react';
import IframeWrapper from "../../../../../IFrame_Container/IFrameContainer";
import ContentCarosel_Demo from "../../../../../../ComponentDemo/ContentCaroselDemo";

let CaroselItem : items =    {
    key : 1,
    Subject : "Content carousel ",
    Description : 
        <>
            <p>
                The content carousel component renders content provided via props and arranges it in a grid layout. It inherits the full width and height of the parent component, dynamically adjusting its layout based on the available space.
                It supports both static and dynamic content rendering, allowing developers to populate the carousel with content from various data sources or APIs.
            </p>


            <h3 style = {{textDecoration:'underline'}}> Usage : </h3>
            <p style = {{margin:'0'}}>   
                {'<ContentCarousel'}<br />
            </p>
          
            <p style ={{margin:'0', paddingLeft:"5%"}}>
                {'          TitleStyling = {{color:"white"}}",'}<br />
                {'          contentHeight =  {2},'}<br />
                {'    popup_ZIndex = {100},'}<br />
                {'    Title = "My Projects",'}<br />
                {'    Description = "A Selection of recent projects.",'}<br />
                {'    Items =  {CaroselItems}'}<br />
            </p>
            <p style = {{marginBottom:"50px"}}>
                {'/>'}
            </p>
    

            <DemoWrapper Title={"Content Carosel"}>
                <div style = {{width:'100%'}}>
                    {/* <IframeWrapper PageURL={"/demo_ContentCarosel"}/> */}
                    <ContentCarosel_Demo/>
                </div>
            </DemoWrapper> 
        </>,
    img_Url:"./images/Project_Images/ContentCarosel.jpg",
    show_Image : false,
    file_List : [
        {
            key : 1,
            FileTitle : "Contentcarousel.tsx",
            content :
            "<<white/><<<sp_0, br/><<lightblue/>import<<white/> ScrollableFrame from '../../BaseProject/Components/layouts/Others/scrollableFrame';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> ImageFrame from '../../BaseProject/Components/layouts/Others/imageFrame';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> styling from './styling.module.css';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> myBrowser from '../../BaseProject/Handlers/myBrowser';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> {StateWrapper} from '../../BaseProject/Interfaces/stateObjectInterface';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> React, { CSSProperties, ReactElement, ReactText, <<orange/>useEffect<<white/>, <<orange/>useLayoutEffect<<white/>, <<orange/>useRef<<white/>, <<orange/>useState<<white/> } from 'react';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> AppleCloseButtom from '../../BaseProject/Components/buttons/closeButtonApple/CloseButtonApple';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> {ObjectRef} from '../../BaseProject/Interfaces/RefObjectInterface';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> CodeWindow, {WindowState, FileList} from '../../BaseProject/Components/layouts/codeDemoWindow/CodeWindow';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> { reduceEachTrailingCommentRange } from 'typescript';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> DynamicText from '../../BaseProject/Components/layouts/Others/dynamicText';"+
            "<<<sp_0, br/><<lightblue/>import<<white/> FrameLayout from '../../BaseProject/Components/layouts/FrameLayout/FrameLayout';"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>// import DynamicStringHandler from '../Dynamic_StringContent/DynamicString'; "+
            "<<white/><<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/* ------------------------------------------ Component Description ------------------------------------------"+
            "<<<sp_3, br/>Renders a content carosel component inheriting the full width,height of the parenting component. Content "+
            "<<<sp_3, br/>is rendered dynamically based on these values."+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>The contents of the elements given will utilise the 'Dynamic_StringContent' class to format / structure a"+
            "<<<sp_3, br/>string of text into its HTMLElement (with styling applied), this is applied to the content description "+
            "<<<sp_3, br/>(i.e. props.Description)."+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>The content_Carosel has two important properties that must be satisfied, this is :"+
            "<<<sp_7, br/>1)  Parent component must have min-height of 1000px, else overflow will occur. "+
            "<<<sp_7, br/>2)  Parent component must have well-defined width, height. "+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>The Component take as arguments through props  : "+
            "<<<sp_7, br/>Text : String "+
            "<<<sp_7, br/>Styling : React.CSSProperties "+
            "<<<sp_7, br/>onChangeAction : onChange callback action handler"+
            "<<<sp_7, br/>isSimpleContainer : boolean, s.t. isSimpleContainer = true IFF its direct parent container and styling given by props"+
            "<<<sp_18, br/>is not a flex-box nor grid-box, else isSimpleContainer = false.  This is set to false by default. "+
            "<<<sp_6, br/>popup_ZIndex : z_index specified for popup.  "+
            "<<<sp_3, br/>Note : The following conditions must be met for components proper functionality "+
            "<<<sp_7, br/>1) Padding in direct parent container is not yet supported by this compoment, we use additional div-wrapping as solution.           "+
            "<<<sp_7, br/>2) Direct parentNode container must inherit the overflow = hidden property to ensure proper container scalling values "+
            "<<<sp_8, br/>conducted in --> (Ref : 2107)"+
            "<<<sp_7, br/>3) isSimpleContainer must be specified IFF parent container OR styling given is : "+
            "<<<sp_11, br/>A) A flex box"+
            "<<<sp_11, br/>B) Grid Box"+
            "<<<sp_0, br/>------------------------------------------------------------------------------------------------------------*/<<white/>"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** "+
            "* Iterate to a new row by settingState to new row value"+
            "* @direction : 1) 'forward', 2) 'backward'; to specify which direcition to iterate. "+
            "*/<<white/>"+
            "<<<sp_0, br/><<orange/>let<<white/> iterateRow = (event :React.MouseEvent, direction : <<orange/>String<<white/>, currentRow : StateWrapper<<<orange/>number<<white/>>, total_ElementSize : <<orange/>number<<white/>, ElementSize : <<orange/>number<<white/>,  Horizontal_ElementSize : <<orange/>number<<white/>, contentWrapper_Ref : React.RefObject<HTMLDivElement> ) => {"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (contentWrapper_Ref.current != <<purple/>undefined<<white/>) "+
            "<<<sp_3, br/>{"+
            "<<<sp_9, br/><<orange/>let<<white/> totalnumber_Rows = Math.ceil(total_ElementSize / Horizontal_ElementSize); "+
            "<<<sp_9, br/><<orange/>let<<white/> prev_RowSize = currentRow.item;"+
            "<<<sp_9, br/><<orange/>let<<white/> activeRowsize = Math.floor(contentWrapper_Ref.current.clientHeight / ElementSize); <<grey/>// currently active rows "+
            "<<white/><<<sp_9, br/><<orange/>let<<white/> rowsOverflown = ((totalnumber_Rows - prev_RowSize) - activeRowsize) ;"+
            "<<<sp_9, br/>"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (direction === 'forward')"+
            "<<<sp_9, br/>{"+
            "<<<sp_11, br/><<lightblue/>if<<white/> (rowsOverflown > 0 )"+
            "<<<sp_11, br/>{"+
            "<<<sp_14, br/>console.log( rowsOverflown + ' row is overflown');"+
            "<<<sp_14, br/>currentRow.setItem(currentRow.item + activeRowsize);"+
            "<<<sp_11, br/>}"+
            "<<<sp_9, br/>}<<lightblue/>else<<white/> <<lightblue/>if<<white/> (direction === 'backward'){"+
            "<<<sp_11, br/><<orange/>let<<white/> decrement_Val = currentRow.item - activeRowsize;"+
            "<<<sp_11, br/><<lightblue/>if<<white/> (decrement_Val < 0) decrement_Val = 0;"+
            "<<<sp_11, br/>currentRow.setItem(decrement_Val);"+
            "<<<sp_9, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** position elements after currentRow change (or on rescale) */<<white/>"+
            "<<<sp_0, br/><<orange/>let<<white/> positionElements = (contentContainer_Ref :React.RefObject<HTMLDivElement>, contentSize: <<orange/>number<<white/>, currentRowWrapper: StateWrapper<<<orange/>number<<white/>>, Horizontal_ElementSize: <<orange/>number<<white/> ) => {"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (contentContainer_Ref.current!= <<purple/>undefined<<white/>)"+
            "<<<sp_3, br/>{"+
            "<<<sp_7, br/><<orange/>let<<white/> x = contentSize * currentRowWrapper.item;"+
            "<<<sp_7, br/>contentContainer_Ref.current.style.transform = `translate(0,-${x+0.4}px)`;"+
            "<<<sp_3, br/>}"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/** Handle Carosel element Click : Open code window */<<white/>"+
            "<<<sp_0, br/><<orange/>let<<white/> Toggle_CodeWindow = (event :React.MouseEvent | <<purple/>undefined<<white/>, action : <<orange/>String<<white/>,  Horizontal_ElementSize :<<orange/>number<<white/>,  Items : items[], window_States : WindowState[], overlay_Wrapper : StateWrapper<{ item : items, visible :boolean, windowState?: WindowState | <<purple/>undefined<<white/> } | <<purple/>undefined<<white/>>) => {    "+
            "<<<sp_3, br/><<lightblue/>import<<white/>(action){"+
            "<<<sp_7, br/><<lightblue/>case<<white/> 'close' : "+
            "<<<sp_9, br/>document.body.style.overflowY = 'auto'; <<grey/>//  default page scroll"+
            "<<white/><<<sp_9, br/>console.log('window closing!');"+
            "<<<sp_9, br/>overlay_Wrapper.setItem(<<purple/>undefined<<white/>); "+
            "<<<sp_7, br/><<orange/>break<<white/>;"+
            "<<<sp_7, br/><<lightblue/>case<<white/> 'open' :"+
            "<<<sp_9, br/>document.body.style.overflowY = 'hidden'; <<grey/>// stop default page scroll"+
            "<<white/><<<sp_9, br/><<lightblue/>if<<white/> (event != <<purple/>undefined<<white/>){"+
            "<<<sp_11, br/><<orange/>let<<white/> item : HTMLDivElement = event.currentTarget as HTMLDivElement;"+
            "<<<sp_11, br/><<orange/>let<<white/> itemNumber : <<orange/>number<<white/>  = parseInt( ( (item.dataset.key != <<orange/>null<<white/>) ? item.dataset.key : '-1'));"+
            "<<<sp_11, br/><<orange/>let<<white/> state : WindowState | <<purple/>undefined<<white/>;"+
            "<<<br,hidden/>br"+
            "<<<sp_11, br/><<lightblue/>if<<white/> (itemNumber != -1) {"+
            "<<<sp_14, br/>state = window_States[itemNumber];"+
            "<<<sp_11, br/>}"+
            "<<<sp_11, br/>console.log('window ' + itemNumber + ' has been opened');"+
            "<<<sp_11, br/>overlay_Wrapper.setItem({item : Items[itemNumber],  visible : true, windowState :state}); "+
            "<<<sp_9, br/>}"+
            "<<<sp_7, br/><<orange/>break<<white/>;"+
            "<<<sp_2, br/>}"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>export <<orange/>interface<<white/> items {"+
            "<<<sp_3, br/>key : <<orange/>number<<white/>;"+
            "<<<sp_3, br/>Subject : <<orange/>String<<white/>;"+
            "<<<sp_3, br/>Description : React.ReactNode;"+
            "<<<sp_3, br/>img_Url : <<orange/>String<<white/>;"+
            "<<<sp_3, br/>file_List : FileList[];"+
            "<<<sp_3, br/>navigationUrl ?: <<orange/>String<<white/>;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>let<<white/> RenderComponent : React.FC<{ isdarkNode ?:boolean,  Items : items[], contentHeight : <<orange/>number<<white/>, Title : <<orange/>String<<white/>, Description :<<orange/>String<<white/>, popup_ZIndex : <<orange/>number<<white/>, TitleStyling? : React.CSSProperties}> = (props) => { "+
            "<<<sp_3, br/><<orange/>let<<white/> Horizontal_ElementSize = <<orange/>useRef<<white/> < <<orange/>number<<white/>>(0);"+
            "<<<sp_3, br/><<orange/>let<<white/> spacing : <<orange/>number<<white/> = 1; <<grey/>// gap percentage between elements"+
            "<<white/><<<sp_3, br/><<orange/>let<<white/> total_elementSize = props.Items.length;  "+
            "<<<sp_3, br/><<orange/>let<<white/> content_BoxSizing :<<orange/>number<<white/> = (100/Horizontal_ElementSize.current) ;              "+
            "<<<sp_3, br/><<orange/>let<<white/> contentContainer_Ref  = React.<<orange/>useRef<<white/><HTMLDivElement>(<<orange/>null<<white/>);"+
            "<<<sp_3, br/><<orange/>let<<white/> container_Ref = React.<<orange/>useRef<<white/><HTMLDivElement> (<<orange/>null<<white/>);"+
            "<<<sp_3, br/><<orange/>let<<white/> contentWrapper_Ref = React.<<orange/>useRef<<white/><HTMLDivElement>(<<orange/>null<<white/>);"+
            "<<<sp_3, br/><<orange/>let<<white/> [currentRow, set_currentRow] = React.<<orange/>useState<<white/> < <<orange/>number<<white/>>(0); "+
            "<<<sp_3, br/><<orange/>let<<white/> [contentSize, set_contentSize] = <<orange/>useState<<white/> < <<orange/>number<<white/>>(0);"+
            "<<<sp_3, br/><<orange/>let<<white/> [contentContainer_Sizing, set_contentContainerSizing] = <<orange/>useState<<white/><{w : <<orange/>number<<white/>, h : <<orange/>number<<white/>}>( {w:0, h :0});"+
            "<<<sp_3, br/><<orange/>let<<white/> currentRowWrapper :StateWrapper< <<orange/>number<<white/>> = {"+
            "<<<sp_7, br/>item : currentRow,"+
            "<<<sp_7, br/>setItem : set_currentRow       "+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>let<<white/> [overlayState, set_OverlayState] = <<orange/>useState<<white/><{item : items, visible :boolean, windowState?: WindowState}> ();"+
            "<<<sp_3, br/><<orange/>let<<white/> overlayState_Wrapper : StateWrapper<{ item : items, visible :boolean, windowState?: WindowState } | <<purple/>undefined<<white/>> = {"+
            "<<<sp_7, br/>item : overlayState,"+
            "<<<sp_7, br/>setItem : set_OverlayState,"+
            "<<<sp_3, br/>} "+
            "<<<sp_3, br/><<orange/>let<<white/> max_VerticalSizing = props.contentHeight;"+
            "<<<sp_3, br/><<orange/>let<<white/> [globalFontSize, set_GlobalFontSize] = <<orange/>useState<<white/> < <<orange/>number<<white/>> (10); <<grey/>// global FontSize. "+
            "<<white/><<<sp_3, br/><<orange/>let<<white/> Dynamic_globalFontRef:ObjectRef<HTMLParagraphElement> ={"+
            "<<<sp_7, br/>setFunc(ref, toRef) {"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (toRef.current != <<purple/>undefined<<white/>) {"+
            "<<<sp_11, br/>ref.ref = toRef;"+
            "<<<sp_9, br/>}"+
            "<<<sp_7, br/>},"+
            "<<<sp_7, br/>ref : <<purple/>undefined<<white/>"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<grey/>/** CodeWindow initialisation & States, that is : the CodeDemo component */<<white/>"+
            "<<<sp_3, br/><<orange/>let<<white/> CodeWindow_Elements : { windowStates:WindowState[], windowElement : React.ReactElement[] } = React.useMemo( () => { "+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<orange/>let<<white/> states :{ windowStates:WindowState[], windowElement : React.ReactElement[] }  = {"+
            "<<<sp_9, br/>windowStates : [],"+
            "<<<sp_9, br/>windowElement : []"+
            "<<<sp_7, br/>};"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>for<<white/>(<<orange/>let<<white/> i=0; i < props.Items.length; i++)"+
            "<<<sp_7, br/>{"+
            "<<<sp_9, br/>states.windowStates.push( {windowState :0, setWindowState : <<purple/>undefined<<white/>});     <<grey/>// Initialise all codeWindow to closed. "+
            "<<white/><<<sp_9, br/>states.windowElement.push( <CodeWindow popup_ZIndex={(props.popup_ZIndex+1)} Window_State = {states.windowStates[i]} Title = {props.Items[i].Subject} fileList = {props.Items[i].file_List} />);"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/><<orange/>return<<white/> states;"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}, props.Items);"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Listeners setup */<<white/>"+
            "<<<sp_3, br/><<orange/>useLayoutEffect<<white/>( "+
            "<<<sp_7, br/>()=>{"+
            "<<<sp_9, br/><<orange/>let<<white/> timeout_ID: NodeJS.Timeout | <<orange/>null<<white/> = <<orange/>null<<white/>;"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/><<grey/>// rescale carosel content sizing"+
            "<<white/><<<sp_9, br/><<lightblue/>const<<white/> re_scale = () => {"+
            "<<<sp_11, br/><<lightblue/>if<<white/> (myBrowser.isMobileWidth())                      Horizontal_ElementSize.current = 3;"+
            "<<<sp_11, br/><<lightblue/>else<<white/> <<lightblue/>if<<white/> (myBrowser.getBrowserWidth() < 1500)        Horizontal_ElementSize.current = 4;"+
            "<<<sp_11, br/><<lightblue/>else<<white/>                                                Horizontal_ElementSize.current = 5;"+
            "<<<sp_11, br/>"+
            "<<<sp_11, br/><<grey/>/** Dynamically rescale container & element */<<white/>"+
            "<<<sp_11, br/><<lightblue/>if<<white/> (container_Ref.current != <<purple/>undefined<<white/>){"+
            "<<<sp_14, br/><<orange/>let<<white/> container_Width = container_Ref.current.clientWidth;                                <<grey/>// current container width"+
            "<<white/><<<sp_14, br/><<orange/>let<<white/> new_ContainerState = {w: container_Width, h: 0};                <<grey/>// set new container size"+
            "<<white/><<<sp_14, br/><<orange/>let<<white/> new_ElementSize= (new_ContainerState.w / Horizontal_ElementSize.current)-1;      <<grey/>// set new element size to fit container"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_14, br/><<grey/>// calculate container heigh size [MAKE MORE EFFICIENT]"+
            "<<white/><<<sp_14, br/><<lightblue/>for<<white/>(<<orange/>let<<white/> i =1 ; i <= max_VerticalSizing ; i++) {"+
            "<<<sp_16, br/><<lightblue/>if<<white/> (Horizontal_ElementSize.current * i >= props.Items.length || i == max_VerticalSizing ){"+
            "<<<sp_18, br/>new_ContainerState.h = i * <<orange/>new<<white/>_ElementSize;"+
            "<<<sp_18, br/><<orange/>break<<white/>;"+
            "<<<sp_16, br/>}"+
            "<<<sp_14, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_14, br/><<grey/>// initialise container size"+
            "<<white/><<<sp_14, br/><<lightblue/>if<<white/> (new_ContainerState.w != contentContainer_Sizing.w || new_ContainerState.h != contentContainer_Sizing.h) {"+
            "<<<sp_16, br/>set_contentContainerSizing(new_ContainerState);"+
            "<<<sp_14, br/>}"+
            "<<<sp_14, br/><<grey/>// initialise element size"+
            "<<white/><<<sp_14, br/><<lightblue/>if<<white/> (new_ElementSize!= contentSize) set_contentSize(<<orange/>new<<white/>_ElementSize);"+
            "<<<sp_12, br/>}"+
            "<<<sp_9, br/>};"+
            "<<<sp_9, br/>"+
            "<<<sp_9, br/><<lightblue/>const<<white/> rescale_Handler = () => {"+
            "<<<sp_11, br/><<lightblue/>if<<white/> (timeout_ID) {"+
            "<<<sp_13, br/>clearTimeout(timeout_ID); <<grey/>// cancel previous setTimeout Call if it has not been executed."+
            "<<white/><<<sp_11, br/>}"+
            "<<<sp_11, br/>"+
            "<<<sp_11, br/>timeout_ID = setTimeout(re_scale, 0.1 ); <<grey/>// initialise setTImeout call"+
            "<<white/><<<sp_9, br/>};"+
            "<<<sp_9, br/>"+
            "<<<sp_9, br/>rescale_Handler();"+
            "<<<sp_9, br/>window.addEventListener('resize', rescale_Handler);"+
            "<<<sp_9, br/><<orange/>return<<white/> () => {"+
            "<<<sp_11, br/>window.removeEventListener('resize', rescale_Handler);"+
            "<<<sp_11, br/><<lightblue/>if<<white/> (timeout_ID) clearTimeout(timeout_ID);                           <<grey/>// previous setTimeout Call"+
            "<<white/><<<sp_9, br/>}"+
            "<<<sp_7, br/>},[contentSize, contentContainer_Sizing]"+
            "<<<sp_3, br/>)"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Reposition elements on re-render */<<white/>"+
            "<<<sp_3, br/><<orange/>useLayoutEffect<<white/>("+
            "<<<sp_7, br/>() =>{ positionElements(contentWrapper_Ref, contentSize, currentRowWrapper, Horizontal_ElementSize.current); }"+
            "<<<sp_3, br/>);"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>let<<white/> viewMore_Handler = (event :React.MouseEvent, direction : <<orange/>String<<white/> ) => {"+
            "<<<sp_7, br/>iterateRow( event, direction, currentRowWrapper, total_elementSize, contentSize, Horizontal_ElementSize.current, contentContainer_Ref);"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>let<<white/> dynamicFontChange_Handler = () => {"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (Dynamic_globalFontRef.ref != <<purple/>undefined<<white/>){"+
            "<<<sp_11, br/>set_GlobalFontSize(myBrowser.RefHandling.getRefFontSize(Dynamic_globalFontRef.ref));"+
            "<<<sp_7, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>/** Open code window handler */<<white/>"+
            "<<<sp_3, br/><<orange/>let<<white/> OpenCodeWindow = () => {"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (overlayState != <<purple/>undefined<<white/>) {"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (overlayState.windowState?.setWindowState != <<purple/>undefined<<white/>) {"+
            "<<<sp_11, br/>overlayState.windowState.setWindowState(1);"+
            "<<<sp_9, br/>}"+
            "<<<sp_7, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<grey/>// custom styling"+
            "<<white/><<<sp_3, br/><<lightblue/>const<<white/> PageQuery : <<orange/>String<<white/> = `"+
            "<<<sp_7, br/>@media only screen and (max-width: ${myBrowser.mobile_Width()}px) {"+
            "<<<sp_9, br/>#contentOverlay_Wrapper{    "+
            "<<<sp_11, br/>flex-direction: column;"+
            "<<<sp_11, br/>align-items: center;"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/>#sticky_Container{"+
            "<<<sp_11, br/>display : none;"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/>#overlayDescription{"+
            "<<<sp_11, br/>width:100% !important;"+
            "<<<sp_11, br/>padding-left: 5%;"+
            "<<<sp_11, br/>padding-right: 5%;"+
            "<<<sp_11, br/>text-align: center;"+
            "<<<sp_9, br/>}"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>@media only screen and (min-width: ${myBrowser.mobile_Width()}px) {"+
            "<<<sp_9, br/>#contentOverlay_Wrapper{"+
            "<<<sp_11, br/>flex-direction: row;"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/>#sticky_Container{"+
            "<<<sp_11, br/>display : block;"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/>#overlayDescription{"+
            "<<<sp_11, br/>width:60% !important;"+
            "<<<sp_11, br/>padding-left: 2%;"+
            "<<<sp_11, br/>min-width: 800px;"+
            "<<<sp_11, br/>text-align: left;"+
            "<<<sp_9, br/>}"+
            "<<<sp_7, br/>}      "+
            "<<<sp_3, br/>`;"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>return<<white/>("+
            "<<<sp_7, br/><>"+
            "<<<sp_9, br/><style> {PageQuery} </style>"+
            "<<<sp_9, br/>{ CodeWindow_Elements.windowElement <<grey/>/** Render WindowElements */<<white/>}"+
            "<<<sp_9, br/>{ <<grey/>// RENDER SELECTED CODE WINDOW "+
            "<<white/><<<sp_11, br/>(overlayState!= <<purple/>undefined<<white/> && overlayState.visible) &&"+
            "<<<sp_11, br/><div className={styling.codeWindow_Container} style = {{zIndex:`${props.popup_ZIndex}`}}>"+
            "<<<sp_3, br/>"+
            "<<<sp_19, br/>{<<grey/>/* <div className= {styling.close_OverlayButtonContainer} onMouseDown={() => {Toggle_CodeWindow(undefined, 'close',  Horizontal_ElementSize.current, props.Items, CodeWindow_Elements.windowStates, overlayState_Wrapper)}}>"+
            "<<<sp_20, br/><svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='100%' height='100%' viewBox='0 0 50 50' fill='white'>"+
            "<<<sp_21, br/><path d='M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z'></path>"+
            "<<<sp_20, br/></svg>"+
            "<<<sp_19, br/></div> */<<white/>}"+
            "<<<sp_14, br/><div className={styling.overlayScaling_Container}>"+
            "<<<sp_16, br/><ScrollableFrame>"+
            "<<<sp_18, br/><div id = 'contentOverlay_Wrapper' className={styling.contentOverlay_Wrapper}>"+
            "<<<sp_19, br/><div id = 'overlayDescription' style = {{  boxSizing:'border-box', wordBreak:'<<orange/>break<<white/>-all'}}>"+
            "<<<sp_20, br/><div  className={styling.StickyDescription_Container} style = {{position:'sticky', top:'0', left:'0', zIndex:'9999'}}>"+
            "<<<sp_21, br/><h1 style = {{margin:'0', marginBottom:'10px', textAlign:'center'}}> {overlayState.item.Subject} </h1>"+
            "<<<sp_21, br/><div style = {{width:'100%', boxSizing:'border-box', textAlign:'center', marginBottom:'10px'}}>"+
            "<<<sp_23, br/><div className={styling.ActionContainer}   onMouseDown={() => { Toggle_CodeWindow(<<purple/>undefined<<white/>, 'close',  Horizontal_ElementSize.current, props.Items, CodeWindow_Elements.windowStates, overlayState_Wrapper)}}> "+
            "<<<sp_24, br/><span> Back </span>"+
            "<<<sp_23, br/></div>"+
            "<<<sp_23, br/>{"+
            "<<<sp_24, br/><<grey/>// IF CODE DEMO"+
            "<<white/><<<sp_24, br/>overlayState.item.navigationUrl === <<purple/>undefined<<white/> &&"+
            "<<<sp_25, br/><div className={styling.ActionContainer} style = {{borderLeft:'1px solid black'}} onMouseDown={ () => { OpenCodeWindow() }} > "+
            "<<<sp_26, br/>View Code"+
            "<<<sp_25, br/></div>"+
            "<<<sp_24, br/><<grey/>// IF LINK DEMO"+
            "<<white/><<<sp_24, br/>||"+
            "<<<sp_25, br/><div className={styling.ActionContainer} style = {{borderLeft:'1px solid black'}} onMouseDown={ () => {window.open(overlayState!.item.navigationUrl!.to<<orange/>String<<white/>(), '_blank', 'noopener,noreferrer,width=1500,height=900'); }} > "+
            "<<<sp_26, br/>Live Demo"+
            "<<<sp_25, br/></div>"+
            "<<<sp_23, br/>}"+
            "<<<sp_21, br/></div> "+
            "<<<sp_20, br/></div>"+
            "<<<sp_20, br/><img className = {styling.viewProject_Image} src = {`${overlayState.item.img_Url}`}/>"+
            "<<<sp_20, br/><div style = {{width:'100%' , padding:'30px', boxSizing:'border-box'}}>"+
            "<<<br,hidden/>br"+
            "<<<sp_21, br/>{overlayState.item.Description}"+
            "<<<sp_20, br/></div>"+
            "<<<sp_20, br/><div style = {{height:'150px'}}/>"+
            "<<<sp_19, br/></div>"+
            "<<<br,hidden/>br"+
            "<<<sp_18, br/></div>"+
            "<<<sp_16, br/></ScrollableFrame>"+
            "<<<sp_14, br/></div>"+
            "<<<sp_11, br/></div>"+
            "<<<sp_9, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/>{ <<grey/>// RENDER CAROSEL"+
            "<<white/><<<sp_11, br/><div className= {styling.container} >"+
            "<<<sp_14, br/><div style = {{  width :'100%'}}> "+
            "<<<sp_16, br/><div className={styling.contentContainer} >"+
            "<<<sp_19, br/><div ref = {container_Ref} className = {styling.contentWrapper} > "+
            "<<<sp_21, br/><div ref = {contentContainer_Ref} style ={{position:'relative', overflow :'hidden', height : `${contentContainer_Sizing.h}px`, width : `${contentContainer_Sizing.w}px`, transition :'transform 1s'}} >"+
            "<<<sp_22, br/><div ref ={contentWrapper_Ref} className = {styling.contentContainer} style = {{padding:'0'}}>"+
            "<<<sp_24, br/>{"+
            "<<<sp_25, br/>(() => {"+
            "<<<sp_26, br/><<lightblue/>const<<white/> listItems = [];"+
            "<<<sp_26, br/><<lightblue/>for<<white/>(<<orange/>let<<white/> i = 0; i < props.Items.length; i++) {"+
            "<<<sp_27, br/>listItems.push("+
            "<<<sp_28, br/><div className={styling.contentItems} key ={i} style = {{  width:`${contentSize}px`, height :`${contentSize}px`, paddingLeft:`${(i % Horizontal_ElementSize.current == 0 ) ?  '0' : {spacing}}%`, paddingRight:`${spacing/2}%`, paddingTop:`${spacing}%`}}> "+
            "<<<sp_29, br/><div className= {styling.elementContainer} style ={{ backgroundImage : `url(${props.Items[i].img_Url}`}} "+
            "<<<sp_30, br/>onMouseDown={ (event : React.MouseEvent) => { Toggle_CodeWindow (event, 'open',  Horizontal_ElementSize.current, props.Items, CodeWindow_Elements.windowStates, overlayState_Wrapper)} } data-key ={i}  > "+
            "<<<sp_30, br/><div className={styling.titleContainer} > {props.Items[i].Subject} </div>"+
            "<<<sp_30, br/><div className = { styling.ElementInformationContainer }>"+
            "<<<sp_31, br/><div style = {{position:'relative', display:'grid',  width:'100%', height :'100%', gridTemplateRows :'15% 60% 15%', gap :'5%', fontSize :`${globalFontSize}px`} }> "+
            "<<<sp_32, br/><div className= {styling.Element_Title} >"+
            "<<<sp_33, br/>{ "+
            "<<<sp_34, br/><<grey/>// Dynamic FontSize Initialisor"+
            "<<white/><<<sp_34, br/>i==0 &&"+
            "<<<sp_34, br/><div style = {{width:'100%', height:'100%', position:'absolute' ,  visibility:'hidden'}}>"+
            "<<<sp_35, br/><DynamicText Text={'A'} textRef = {Dynamic_globalFontRef}  onChangeAction = {dynamicFontChange_Handler}/>"+
            "<<<sp_34, br/></div>"+
            "<<<sp_33, br/>}"+
            "<<<sp_33, br/>{props.Items[i].Subject}"+
            "<<<sp_32, br/></div>"+
            "<<<sp_23, br/>"+
            "<<<sp_32, br/><div className= {styling.Element_Description} style = {{position:'relative', width :'100%', height :'100%', whiteSpace:'normal'}}>"+
            "<<<sp_33, br/><ScrollableFrame showOverflowNotification= {true}> "+
            "<<<sp_34, br/><div  className= {styling.Element_Description}>"+
            "<<<sp_35, br/>{props.Items[i].Description}"+
            "<<<sp_34, br/></div>"+
            "<<<sp_33, br/></ScrollableFrame>                                                                    "+
            "<<<sp_32, br/></div>"+
            "<<<sp_32, br/><div className={styling.Element_LearnMore}>"+
            "<<<sp_33, br/>Learn More"+
            "<<<sp_32, br/></div>"+
            "<<<sp_31, br/></div>"+
            "<<<sp_30, br/></div>"+
            "<<<sp_29, br/></div>"+
            "<<<sp_28, br/></div>"+
            "<<<sp_27, br/>);"+
            "<<<sp_26, br/>}"+
            "<<<sp_26, br/><<orange/>return<<white/> listItems;"+
            "<<<sp_25, br/>})()"+
            "<<<sp_24, br/>}"+
            "<<<sp_22, br/></div>"+
            "<<<sp_21, br/></div>"+
            "<<<sp_19, br/></div >"+
            "<<<sp_16, br/></div>"+
            "<<<sp_16, br/><div id = 'viewMoreContent_Container' style ={{zIndex:'1', width:'100%', overflow :'hidden',  minHeight:'30px', marginTop:'1%'  }}>"+
            "<<<sp_18, br/><div className = {styling.viewMore_Container}>"+
            "<<<sp_19, br/>{"+
            "<<<sp_20, br/>((currentRowWrapper.item + Math.floor(contentContainer_Sizing.h / contentSize)) * Horizontal_ElementSize.current < total_elementSize) &&"+
            "<<<sp_20, br/><div className={styling.button_boxcontainer} onMouseDown = { (event:React.MouseEvent) => {viewMore_Handler(event, 'forward')} }   > "+
            "<<<sp_21, br/>view more "+
            "<<<sp_20, br/></div>  "+
            "<<<sp_19, br/>}"+
            "<<<sp_19, br/>{"+
            "<<<sp_20, br/>currentRowWrapper.item >0 &&"+
            "<<<sp_20, br/><div className={styling.button_boxcontainer} onMouseDown ={ (event:React.MouseEvent) => {viewMore_Handler(event,'backward')} } > "+
            "<<<sp_21, br/>view Less "+
            "<<<sp_20, br/></div>  "+
            "<<<br,hidden/>br"+
            "<<<sp_19, br/>}"+
            "<<<sp_18, br/></div>"+
            "<<<sp_16, br/></div>"+
            "<<<sp_14, br/></div>"+
            "<<<sp_11, br/></div>"+
            "<<<sp_9, br/>}"+
            "<<<sp_7, br/></>"+
            "<<<sp_3, br/>);"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            ""


            
        },
        {
            key : 2,
            FileTitle : "Styling.css",
            content :
            "<<white/><<<br,hidden/>br"+
            "<<<sp_0, br/>.contentItems{"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>box-sizing: border-box;"+
            "<<<sp_3, br/><<grey/>/* background-color: brown; */<<white/>"+
            "<<<sp_3, br/>padding-left: 0.5%;"+
            "<<<sp_3, br/>padding-right: 0.5%;"+
            "<<<sp_3, br/>padding-top: 1%;"+
            "<<<sp_3, br/>position: relative;"+
            "<<<sp_3, br/><<grey/>/* background-color: orange; */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/* .contentItems > div{"+
            "<<<sp_3, br/>background-color: pincontainer;"+
            "<<<sp_3, br/>height: 100%;"+
            "<<<sp_3, br/>width: 100%;"+
            "<<<sp_3, br/>padding: 2%;"+
            "<<<sp_3, br/>box-sizing: border-box;"+
            "<<<sp_0, br/>} */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.titleContainer{"+
            "<<<sp_3, br/>position: absolute;"+
            "<<<sp_3, br/>bottom: 0;"+
            "<<<sp_3, br/>left: 0;"+
            "<<<sp_3, br/>z-index: 5;"+
            "<<<sp_3, br/>font-size: 13px;"+
            "<<<sp_3, br/>width: 100%;"+
            "<<<sp_3, br/>text-align: center;"+
            "<<<sp_3, br/>padding: 3%;"+
            "<<<sp_3, br/>color:white;"+
            "<<<sp_3, br/>box-sizing: border-box;"+
            "<<<sp_3, br/>background: rgba(5, 1, 18, 0.89);"+
            "<<<sp_3, br/>backdrop-filter: blur(11px);"+
            "<<<sp_3, br/>-webkit-backdrop-filter: blur(11px);"+
            "<<<sp_3, br/>border: 1px solid rgba(5, 1, 18, 1);"+
            "<<<sp_3, br/>transition: all 0.5s;"+
            "<<<sp_3, br/>max-width: 100%;"+
            "<<<sp_3, br/>max-height: 100%;"+
            "<<<sp_3, br/>word-break: break-all;"+
            "}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.elementContainer:hover .titleContainer{"+
            "<<<sp_3, br/>transform: translate(0,100%);"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.codeWindow_Container{"+
            "<<<sp_3, br/>width: 100%;"+
            "<<<sp_3, br/>height: 100%;"+
            "<<<sp_3, br/>position: absolute;"+
            "<<<sp_3, br/>top: 0;"+
            "<<<sp_3, br/>left: 0;"+
            "<<<sp_3, br/>padding: 9%;"+
            "<<<sp_3, br/>box-sizing: border-box;"+
            "<<<sp_3, br/>background-color: black;"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.codeWindow_SubContainer{"+
            "<<<sp_3, br/>width: 100%;"+
            "<<<sp_3, br/>height: 100%;"+
            "<<<sp_3, br/>position: relative;"+
            "<<<sp_3, br/>color :white;"+
            "<<<sp_3, br/>display: grid;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.container{"+
            "<<<sp_3, br/>width :100%;"+
            "<<<sp_3, br/><<grey/>/* background-Color:blue; */<<white/>"+
            "<<<sp_3, br/>display: flex;"+
            "<<<sp_3, br/>justify-content: center;"+
            "<<<sp_3, br/><<grey/>/* align-items: flex-end; */<<white/>    "+
            "<<<sp_3, br/><<grey/>/* align-items: center; */<<white/>"+
            "<<<sp_3, br/>overflow: hidden;"+
            "<<<sp_3, br/>position: relative;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.contentWrapper{"+
            "<<<sp_3, br/>width :100%;"+
            "<<<sp_3, br/>display: flex;"+
            "<<<sp_3, br/>justify-content: center;"+
            "<<<sp_3, br/>overflow: hidden;"+
            "<<<sp_3, br/>position: relative;"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.caroselDescription_Container{"+
            "<<<sp_3, br/>display:flex;"+
            "<<<sp_3, br/>flex-direction: column;"+
            "<<<sp_3, br/>position: relative;"+
            "<<<sp_3, br/>width: 100%;"+
            "<<<sp_3, br/>align-items: center;"+
            "<<<sp_3, br/>padding: 1%;"+
            "<<<sp_3, br/>box-sizing: border-box;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.elementContainer{"+
            "<<<sp_3, br/>position:relative;"+
            "<<<sp_3, br/>width:100%;"+
            "<<<sp_3, br/>height:100%; "+
            "<<<sp_3, br/><<grey/>/* background-Color :yellow; */<<white/>"+
            "<<<sp_3, br/>background-Repeat:no-repeat;"+
            "<<<sp_3, br/>background-Size:cover;"+
            "<<<sp_3, br/>overflow: hidden;"+
            "<<<sp_3, br/>transition: all .3s;"+
            "<<<sp_3, br/>cursor: pointer;"+
            "<<<sp_3, br/>border: 1px solid black;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.viewProject_Image{"+
            "<<<sp_3, br/>width: 100%;"+
            "<<<sp_3, br/>height:100%;"+
            "<<<sp_3, br/>max-height: 35vh;"+
            "<<<sp_3, br/>object-fit: contain;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.button_boxcontainer {"+
            "<<<sp_2, br/>border: 0.5px solid white; "+
            "<<<sp_2, br/>display: inline-block;"+
            "<<<sp_2, br/>padding: 5px;"+
            "<<<sp_2, br/>box-sizing: border-box;"+
            "<<<sp_2, br/>margin-left: 5px;"+
            "<<<sp_2, br/>cursor: pointer;"+
            "<<<sp_2, br/>transition: all 1s;"+
            "<<<sp_2, br/>color:white;"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.button_boxcontainer:hover{"+
            "<<<sp_3, br/>font-size: large;"+
            "<<<sp_3, br/>"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.viewMore_Container{"+
            "<<<sp_3, br/>width:100%;"+
            "<<<sp_3, br/>height: 50%;"+
            "<<<sp_3, br/>text-align: center;"+
            "<<<sp_3, br/><<grey/>/* background-color: aqua; */<<white/>"+
            "<<<sp_3, br/>overflow: hidden;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/* FirstElement = Title */<<white/>"+
            "<<<sp_0, br/>.Element_Title{"+
            "<<<sp_3, br/>text-Align:left;"+
            "<<<sp_3, br/><<grey/>/* background-Color: orange; */<<white/>"+
            "<<<sp_3, br/>color :white;"+
            "<<<sp_3, br/>text-Overflow:ellipsis;"+
            "<<<sp_3, br/>white-Space:nowrap; "+
            "<<<sp_3, br/>overflow:hidden;"+
            "<<<sp_3, br/>padding: 0;"+
            "<<<sp_3, br/>margin: 0;"+
            "<<<sp_3, br/>font-weight: 700;"+
            "<<<sp_3, br/><<grey/>/* font-size: clamp(5px, 1.5vw, 20px); */<<white/>"+
            "<<<sp_3, br/>width :100%;"+
            "<<<sp_3, br/>height: 100%;"+
            "<<<sp_3, br/>position: inherit;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.ElementInformationContainer{"+
            "<<<sp_3, br/>transform: translate(0, 100%);"+
            "<<<sp_3, br/>padding:5%;"+
            "<<<sp_3, br/>box-Sizing:border-box;"+
            "<<<sp_3, br/>position:absolute;"+
            "<<<sp_3, br/>bottom :0;"+
            "<<<sp_3, br/>width :100%;"+
            "<<<sp_3, br/>height :40%;"+
            "<<<sp_3, br/>background: rgba(5, 1, 18, 0.89);"+
            "<<<sp_3, br/>backdrop-filter: blur(11px);"+
            "<<<sp_3, br/>-webkit-backdrop-filter: blur(11px);"+
            "<<<sp_3, br/>border: 1px solid rgba(5, 1, 18, 1);"+
            "<<<sp_3, br/>transition : all 1s;"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.elementContainer:hover .ElementInformationContainer{"+
            "<<<sp_3, br/>transform: translate(0,0%);"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.mainTitle{"+
            "<<<sp_3, br/>text-Overflow:ellipsis;"+
            "<<<sp_3, br/>white-Space:nowrap; "+
            "<<<sp_3, br/>font-weight: 500;"+
            "<<<sp_3, br/>overflow:hidden;"+
            "<<<sp_3, br/><<grey/>/* font-size: clamp(5px, 4.5vw, 100px); */<<white/>"+
            "<<<sp_3, br/>width:100%;"+
            "<<<sp_3, br/>text-align: center;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.contentContainer{"+
            "<<<sp_3, br/>width: 100%;"+
            "<<<sp_3, br/><<grey/>/* padding: 1%; */<<white/>"+
            "<<<sp_3, br/>box-sizing: border-box;"+
            "<<<sp_3, br/>display :flex;"+
            "<<<sp_3, br/><<grey/>/* position: absolute; */<<white/>"+
            "<<<sp_3, br/>transition: transform 1s ;"+
            "<<<sp_3, br/>flex-wrap: wrap;"+
            "<<<sp_3, br/>flex-Direction:row;"+
            "<<<sp_3, br/>align-content :flex-start;"+
            "<<<sp_3, br/>position: relative;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.textOverflow{"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>display: -webkit-box;"+
            "<<<sp_3, br/>-webkit-line-clamp: 2;"+
            "<<<sp_3, br/>white-space: wrap; "+
            "<<<sp_3, br/>text-overflow: ellipsis;"+
            "<<<sp_3, br/>-webkit-box-orient: vertical;"+
            "<<<sp_3, br/>overflow: hidden;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.mainDescription{"+
            "<<<sp_3, br/><<grey/>/* color:black; */<<white/>"+
            "<<<sp_3, br/>width:100%;"+
            "<<<sp_3, br/>box-sizing: border-box;"+
            "<<<sp_3, br/>text-align: center;"+
            "<<<sp_3, br/>margin-top: 5px;"+
            "<<<sp_3, br/><<grey/>/* color: rgb(128, 131, 141) !important ; */<<white/>"+
            "}"+
            ".divider{ "+
            "<<<sp_3, br/>border-bottom: 1px solid grey;"+
            "}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.Element_Description{"+
            "<<<sp_3, br/>text-Align:left;"+
            "<<<sp_3, br/>color :white;"+
            "<<<sp_3, br/>white-space: normal;"+
            "<<<sp_3, br/>display: -webkit-box;"+
            "<<<sp_3, br/>-webkit-box-orient: vertical;"+
            "<<<sp_3, br/>text-Overflow:ellipsis;"+
            "<<<sp_3, br/><<grey/>/* -webkit-line-clamp: 4;  */<<white/>"+
            "<<<sp_3, br/><<grey/>/* overflow:hidden; */<<white/>"+
            "<<<sp_3, br/><<grey/>/* overflow:hidden; */<<white/>"+
            "<<<sp_3, br/><<grey/>/* -webkit-line-clamp: 2; */<<white/>"+
            "<<<sp_3, br/><<grey/>/* width:100%; */<<white/>"+
            "<<<sp_3, br/><<grey/>/* height: 100%; */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.Element_LearnMore{"+
            "<<<sp_3, br/>position: relative;"+
            "<<<sp_3, br/><<grey/>/* align-content: center; */<<white/>"+
            "<<<sp_3, br/>width:'100%';"+
            "<<<sp_3, br/>height :'100%';"+
            "<<<sp_3, br/><<grey/>/* background-color: orange; */<<white/>"+
            "<<<sp_3, br/>text-align: right;"+
            "<<<sp_3, br/>color:white;"+
            "<<<sp_0, br/>}"+
            "<<grey/><<<sp_0, br/>/* FirstElement = Title */<<white/>"+
            "<<grey/><<<sp_0, br/>/* .element_DescriptionContainer :nth-child(2){"+
            "<<<sp_3, br/>text-Align:left;"+
            "<<<sp_3, br/>background-Color: orange;"+
            "<<<sp_3, br/>color :white;"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>white-space: normal; */<<white/>"+
            "<<<sp_3, br/><<grey/>/* display: -webkit-box;"+
            "<<<sp_3, br/>-webkit-box-orient: vertical;"+
            "<<<sp_3, br/>text-Overflow:ellipsis;"+
            "<<<sp_3, br/>overflow:hidden;"+
            "<<<sp_3, br/>-webkit-line-clamp: 2; */<<white/>"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/* "+
            "<<<sp_3, br/>padding: 0;"+
            "<<<sp_3, br/>margin: 0;"+
            "<<<sp_3, br/>height : 50%;"+
            "<<<sp_0, br/>} */<<white/>"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.overlay_Container{"+
            "<<<sp_3, br/>width:100%;"+
            "<<<sp_3, br/>height:100%;"+
            "<<<sp_3, br/>padding :1%;"+
            "<<<sp_3, br/>box-Sizing: border-box;"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.close_OverlayButtonContainer{"+
            "<<<sp_3, br/>position :absolute;"+
            "<<<sp_3, br/>z-index: 15;"+
            "<<<sp_3, br/>left: 2%;"+
            "<<<sp_3, br/>top: 2%;"+
            "<<<sp_3, br/>cursor: pointer;"+
            "<<<sp_3, br/>width: 50px;"+
            "<<<sp_3, br/>height: ;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.overlay_ContentContainer{"+
            "<<<sp_3, br/>width :100%; "+
            "<<<sp_3, br/>height:100%; "+
            "<<<sp_3, br/><<grey/>/* grid-template-columns :50% 50%; */<<white/>"+
            "<<<sp_3, br/><<grey/>/* grid-template-rows :50%;  */<<white/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>display :flex;"+
            "<<<sp_3, br/>flex-direction: column;"+
            "<<<sp_3, br/>transition : flex 2s;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.overlayImageContainer{"+
            "<<<sp_3, br/><<grey/>/* background-Color:pink; */<<white/>"+
            "<<<sp_3, br/>display:flex; "+
            "<<<sp_4, br/><<grey/>/* align-Content:center; */<<white/>"+
            "<<<sp_3, br/>flex-direction: column;"+
            "<<<sp_3, br/>width:100%; "+
            "<<<sp_3, br/>color: white;"+
            "<<<sp_3, br/>padding: 5%;"+
            "<<<sp_3, br/>box-sizing: border-box;"+
            "<<<sp_3, br/>position: relative;"+
            "<<<sp_3, br/>justify-content: center;"+
            "<<<sp_3, br/>align-items: center;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.icon_Container{ "+
            "<<<sp_3, br/>position:relative;"+
            "<<<sp_3, br/>display: flex;"+
            "<<<sp_3, br/>justify-content: center;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.DescriptionContainer{ "+
            "<<<sp_3, br/>color: inherit;"+
            "<<<sp_3, br/>text-align: justify;"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.SubjectContainer{"+
            "<<<sp_3, br/><<grey/>/* font-size: clamp(1px, 1.5vw, 100px); */<<white/>"+
            "<<<sp_3, br/>margin-bottom: 15px;"+
            "<<<sp_3, br/>color: inherit;"+
            "<<<sp_3, br/>font-weight:700;"+
            "<<<sp_3, br/>text-align: left;"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>.overlayDescriptionContainer{"+
            "<<<sp_3, br/>width:100%; "+
            "<<<sp_3, br/>height:100%;"+
            "<<<sp_3, br/>box-Sizing:border-box;"+
            "<<<sp_3, br/>display: grid;"+
            "<<<sp_3, br/>justify-content: center;"+
            "<<<sp_3, br/>align-items: center;"+
            "<<<sp_3, br/>padding: 5%;"+
            "<<<sp_3, br/>padding-right: 15%;"+
            "<<<sp_3, br/>box-sizing: border-box;"+
            "<<<sp_3, br/>word-break: break-all;"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.viewProjectWrapper{"+
            "<<<sp_3, br/>z-index: 5;"+
            "<<<sp_3, br/>background-color: black;"+
            "<<<sp_3, br/>font-size: 15px;"+
            "<<<sp_3, br/><<grey/>/* font-size: clamp(1px, 1vw, 50px); */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.viewProjectWrapper > p{"+
            "<<<sp_3, br/>color: white;"+
            "<<<sp_3, br/>font-weight: 700;"+
            "<<<sp_3, br/>cursor: pointer;"+
            "<<<sp_3, br/>opacity: 50%;"+
            "<<<sp_3, br/>transition: opacity 0.5s;"+
            "<<<sp_3, br/><<grey/>/* text-decoration: underline; */<<white/>"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>.viewProjectWrapper > p:hover{"+
            "<<<sp_3, br/>text-decoration: underline;"+
            "<<<sp_3, br/>opacity: 100%;"+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>@media only screen and (min-width: 1000px) {"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>"+
            "<<<sp_3, br/>.divider{"+
            "<<<sp_5, br/>width :50%;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>@media only screen and (max-width: 1000px ) and (min-width : 500px)  {"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>.divider{"+
            "<<<sp_5, br/>width :97%;"+
            "<<<sp_3, br/>}"+
            ""+
            "<<<sp_0, br/>}"+
            "<<<sp_0, br/>@media only screen and (max-width:500px )  {"+
            "<<<br,hidden/>br"+
            ""+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>@media only screen and (orientation: portrait) {"+
            "<<<sp_3, br/>.close_OverlayButtonContainer{"+
            "<<<sp_5, br/>width: 7vw !important;"+
            "<<<sp_5, br/>height: 7vw !important;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.codeWindow_SubContainer{"+
            "<<<sp_5, br/>display: flex;"+
            "<<<sp_5, br/>flex-direction: column-reverse;"+
            "<<<sp_5, br/>direction:ltr;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>.viewProjectWrapper{"+
            "<<<sp_5, br/>font-size: clamp(10px, 2vw, 500px);"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>.overlayDescriptionContainer{"+
            "<<<sp_5, br/>align-items: start;"+
            "<<<sp_5, br/>padding: 5%;"+
            "<<<sp_5, br/>"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>.SubjectContainer{"+
            "<<<sp_5, br/>font-size: clamp(30px, 2vw, 500px);"+
            "<<<sp_5, br/>text-align: center;"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>.DescriptionContainer{"+
            "<<<sp_5, br/><<grey/>/* text-align: center; */<<white/>"+
            "<<<sp_5, br/><<grey/>/* text-align: left !important; */<<white/>"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.DescriptionContainer{"+
            "<<<sp_5, br/>font-size: clamp(15px, 2vw, 500px);"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>.close_OverlayButtonContainer{"+
            "<<<sp_5, br/>width: 40px;"+
            "<<<sp_5, br/>height: 40px;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.titleContainer{"+
            "<<<sp_5, br/>font-size: clamp(10px, 2vw, 500px);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.mainTitle{"+
            "<<<sp_5, br/>font-size: clamp(15px, 9vw, 500px);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.mainDescription{"+
            "<<<sp_5, br/>font-size: clamp(15px,3vw, 1000px) !important;"+
            "<<<sp_5, br/>margin-bottom: 2vw;"+
            "<<<sp_5, br/>padding-Left:5%;"+
            "<<<sp_5, br/>padding-Right:5%;"+
            "<<<sp_3, br/>}"+
            "<<<sp_0, br/>} "+
            "<<<br,hidden/>br"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>@media only screen and (orientation: landscape) {"+
            "<<<sp_3, br/>.close_OverlayButtonContainer{"+
            "<<<sp_5, br/>width: 3vw !important;"+
            "<<<sp_5, br/>height: 3vw !important;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.SubjectContainer{"+
            "<<<sp_5, br/>font-size: clamp(15px, 1vw, 1000px);"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.DescriptionContainer{"+
            "<<<sp_5, br/>font-size: clamp(15px,0.5vw, 500px);"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.codeWindow_SubContainer{"+
            "<<<sp_5, br/>grid-template-columns: 70% 30%;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.titleContainer{"+
            "<<<sp_5, br/>font-size: clamp(9px, 0.3vw, 500px);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.mainTitle{"+
            "<<<sp_5, br/>font-size: clamp(15px, 1vw, 1000px) !important;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>.mainDescription{"+
            "<<<sp_5, br/>font-size: clamp(15px,1vw, 1000px) !important;"+
            "<<<sp_5, br/>padding-Left:20%;"+
            "<<<sp_5, br/>padding-Right:20%;"+
            ""+
            "<<<sp_3, br/>}"
        }
    ]
}

export default CaroselItem;
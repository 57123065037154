import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
// import App from './BaseProject/MyResume/myResume';
// import App from "./ComponentDemo/MyResume"
// import App from './AppV1';
// import App from './AppV2';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import  {items} from '../../../../contentCarousel/contentCarosel';
import ComponentDemo from "../../../../DemoWrapper/DemoWrapper"; 


let CaroselItem : items =    {
    key : 1,
    Subject : "GIT BackUp Clone",
    Description : 
        <>
            {/* <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Compile With : Javac Puzzle8Solver.java Run </p>
            <p style ={{padding:'0', margin:'0', fontWeight:'700'}}> Run Compilation with : Java Puzzle8Solver </p> */}
            <p>  

                This program serves as a comprehensive backup utility, specifically designed for managing and synchronizing 
                files between local and remote directories using the SFTP (SSH File Transfer Protocol) protocol. The program utilizes 
                a directory tree structure to represent the file organization both locally and on the SFTP server and utilises
                the CLI to listen for user inputs for various operation.                
                <br></br> <br></br>
                The program provides a list of commands, including options for updating directory structures, uploading new files, comparing changes, and exiting the program.
            </p>
            <p style = {{fontWeight:"800", textDecoration:"underline"}}> The program addresses the following functionalities : </p>

            <ol>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  File Documentation (Metadata): </span>
                        Upon execution, the program scans and documents all files within the root directory (current executing/working directory),
                        establishing their relationships and structure in the form of a tree which is stored for future processing.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> Upload to Remote Server : </span>
                        On request (via input flags), the program re-creates the file structure of the local directory 
                        in a designated SFTP (SSH File Transfer Protocol) directory if it hasn't been initialized previously whilst
                        ensuring  parent-child relationships of directories are preserved from the root down to the leaves of the tree.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> Handling Modifications : </span>
                        In the event of subsequent changes to the local directory, such as file or directory modifications, 
                        the program identifies these alterations by comparing file timestamps (specifically the 'st_mtime' parameter). 
                        This parameter serves as an indicator of file modification.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> Update : </span>
                        The program detects modifications made to local files and replaces corresponding files in the SFTP directory 
                        to ensure synchronization between the two locations
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> Encryption : </span>
                        (Not implemented) The files could be encrypted first, for sensitive files on untrusted remote servers. 
                    </li>
            </ol> 
             


            <p style = {{fontWeight:"800", textDecoration:"underline"}}> The main components of the project include : </p>

            <ul>
                <li>
                    <span style = {{textDecoration:'underline'}}>  Main Class (main) : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Entry point of the program.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Parses command-line arguments for the password required to decrypt SFTP information.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Displays a list of available commands for interacting with the backup utility
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Handles user input and initiates backup operations based on selected commands
                        </li>
                    </ol>
                </li>


                <li>
                    <span style = {{textDecoration:'underline'}}>  IO Class : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Handles input/output operations, including reading and writing objects to/from files.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Provides methods for reading text files and checking the existence of files.
                        </li>
                    </ol>
                </li>



                <li>
                    <span style = {{textDecoration:'underline'}}>  SFTP Class : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Manages the SFTP connection to the remote server.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Handles authentication using provided credentials (host, port, username, password).
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Provides functionality for creating directories, uploading files, and removing files/directories from the server.
                        </li>
                    </ol>
                </li>

                <li>
                    <span style = {{textDecoration:'underline'}}>  DirTree Class : </span>
                    <ol>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Represents the directory structure, including folders and files.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Defines nested classes for directories, files, and tree nodes.                        
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Implements methods for generating and traversing the directory tree.
                        </li>
                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Facilitates the comparison of directory structures between the local directory and the remote server.                        
                        </li>

                        <li style={{margin:'5px', boxSizing:'border-box'}}>
                            Identifies changes (such as file modifications or additions) between two directory trees (objects).                        
                        </li>

                    </ol>
                </li>




            </ul>







            <p>
                Note: This program requires proper configuration of SFTP credentials and file paths for seamless backup operations. 
                It is intended for use in scenarios where automated or manual file synchronization between local and remote directories is necessary.
            </p>           

        </>,
    img_Url:"./images/Project_Images/git_c.jpg",
    file_List : [
        {
            key : 1,
            FileTitle : "Main.java",
            content :  

            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.ArrayList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Arrays;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Comparator;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Iterator;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.LinkedList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.List;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> org.Encryptor.Encryption;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> org.Encryptor.FileProcessor;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> backUper.dirTree.directory;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> backUper.dirTree.directory.file;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> backUper.dirTree.file_directoryContainer;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> backUper.dirTree.node;"+
            "<<<br,hidden/>br"+
            "<<grey/><<<sp_0, br/>/**"+
            "* This program is devoted to the implementation of a backup program resolving the following tasks:"+
            "*  	1) 	This program re-creates the filestructure of the resting local-directory "+
            "*    		in the sftp-directory (if not initialised)."+
            "*      2)  This program replaces files in the sftp directory if local-files has been modified. *"+
            "* @author assgetit*"+
            "*/<<white/>"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> main {"+
            "<<<sp_1, br/><<orange/>static<<white/> Comparator<Long> directoryComparator = <<orange/>new<<white/> Comparator<>() "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>@Override"+
            "<<<sp_3, br/><<orange/>public<<white/> <<orange/>int<<white/> compare(Long o1, Long o2) {"+
            "<<<sp_4, br/><<lightblue/>if<<white/> (o1.equals(o2))"+
            "<<<sp_5, br/><<orange/>return<<white/> 0;"+
            "<<<sp_4, br/>"+
            "<<<sp_4, br/><<orange/>return<<white/> -1;"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>};"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> main(<<orange/>String<<white/>[]args) throws Exception"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(args.length != 1)"+
            "<<<sp_3, br/>{"+
            "<<<sp_4, br/><<orange/>system<<white/>.out.println('Enter password as args to decrypt sftp information');"+
            "<<<sp_4, br/><<orange/>system<<white/>.out.println(' --> Note : If ftpInfo.txt has not been encrypted prior,'"+
            "<<<sp_6, br/>+ 'the pass is now used to generate the encryptionmetadata, '"+
            "<<<sp_6, br/>+ 'hence being the password for future decryptions.');"+
            "<<<sp_4, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>sftp server = <<orange/>new<<white/> sftp(args[0]); <<grey/>//create the sftp instance."+
            "<<white/><<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>//[0] command "+
            "<<white/><<<sp_3, br/><<orange/>String<<white/>[] commandList = {  '-u  : Update/add old-tree structure reference based on the current directory',"+
            "<<<sp_9, br/>'-ua : delete all files & directores(on server), upload all newtree structure, then write as old-tree structure (-u)', "+
            "<<<sp_9, br/>'-uc : delete file(on server) changes made through comparing new vs old tree structure',"+
            "<<<sp_9, br/>'-x  : exit program'};"+
            "<<<sp_3, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('Command List : ');"+
            "<<<sp_3, br/>List command = Arrays.asList(commandList);"+
            "<<<sp_3, br/>command.forEach(  (s) -> <<orange/>system<<white/>.out.println('  ' + s ) );"+
            "<<<sp_3, br/>dirTree nt;"+
            "<<<sp_3, br/><<lightblue/>while<<white/>(true)"+
            "<<<sp_3, br/>{"+
            "<<<sp_4, br/>Scanner sc = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
            "<<<sp_4, br/><<lightblue/>import<<white/>(sc.next())"+
            "<<<sp_4, br/>{"+
            "<<<sp_4, br/>"+
            "<<<sp_4, br/><<lightblue/>case<<white/> '-u' :"+
            "<<<sp_5, br/>IO.writeObject(<<orange/>new<<white/> dirTree(), 'oldTree.dat');"+
            "<<<sp_5, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> '-ua':"+
            "<<<sp_5, br/>nt = <<orange/>new<<white/> dirTree();"+
            "<<<sp_5, br/>uploadDirTree(server, nt);"+
            "<<<sp_5, br/>IO.writeObject(nt, 'oldTree.dat');"+
            "<<<sp_5, br/><<orange/>break<<white/>;"+
            "<<<sp_4, br/><<lightblue/>case<<white/> '-uc':"+
            "<<<sp_5, br/><<lightblue/>if<<white/>(IO.doesExist('oldTree.dat'))"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>nt = <<orange/>new<<white/> dirTree();"+
            "<<<sp_6, br/>List<<<orange/>String<<white/>> fileList = nt.findChanges(directoryComparator, IO.<dirTree>readObject('oldTree.dat'));"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(fileList == null) <<orange/>break<<white/>;"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println('Do you want to upload these changes? y/n');"+
            "<<<sp_6, br/>sc = <<orange/>new<<white/> Scanner(<<orange/>system<<white/>.in);"+
            "<<<sp_6, br/><<lightblue/>if<<white/> (sc.next().equals('y'))"+
            "<<<sp_6, br/>{"+
            "<<<sp_7, br/><<orange/>String<<white/> removePath = nt.root.getPath().substring( 0, (nt.root.getPath().length() - nt.root.getName().length() ));<<grey/>//start at root path"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_7, br/>for(<<orange/>String<<white/> file : fileList )"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/><<orange/>String<<white/> serverPath = file.substring( removePath.length(), file.length());"+
            "<<<sp_8, br/>server.upload(serverPath, file);"+
            "<<<sp_8, br/>IO.writeObject(nt, 'oldTree.dat');"+
            "<<<sp_7, br/>}"+
            "<<<sp_6, br/>}"+
            "<<<sp_6, br/>"+
            "<<<sp_5, br/>}<<lightblue/>else<<white/>"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println('Old-tree Structure reference does not exist meaning files has yet to be uploaded, initialise structure on server first using -ua');"+
            "<<<sp_5, br/><<orange/>break<<white/>;"+
            "<<<sp_5, br/>"+
            "<<<sp_4, br/><<lightblue/>case<<white/> '-x' : <<orange/>system<<white/>.exit(0);"+
            "<<<sp_4, br/>default : "+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('Invalid Command, use the following valid commands :  ');"+
            "<<<sp_5, br/>command.forEach(  (s) -> <<orange/>system<<white/>.out.println('  ' + s ) );"+
            "<<<sp_5, br/><<orange/>break<<white/>;"+
            "<<<br,hidden/>br"+
            "<<<sp_4, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>//PROBLEM , FOLDER SIZE IS INFULENCED BY OTHEER CHILD FOLDEERS, THEREFORE CHANGE CONDITION TO ONLY REECORD SIZEE FOR NON-FOLDER ELEMENTS"+
            "<<white/><<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>//uploadDirTree(new sftp(), new dirTree());"+
            "<<white/><<<sp_3, br/><<grey/>//System.out.println( (server.removeFile(server.remoteRootDir + '/DataStructure&Algorithms/backuper.jar')) ? 'Removed' : 'not removed!');"+
            "<<white/><<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>//IO.writeObject(new dirTree(), 'referenceTree.dat');"+
            "<<white/><<<sp_3, br/>"+
            "<<grey/><<<sp_0, br/>//		new dirTree().findChanges(directoryComparator, IO.readObject('referenceTree.dat'));"+
            "<<white/><<grey/><<<sp_0, br/>//		"+
            "<<white/><<grey/><<<sp_0, br/>//		"+
            "<<white/><<grey/><<<sp_0, br/>//		System.exit(0);"+
            "<<white/><<grey/><<<sp_0, br/>//		dirTree oldtree = null;"+
            "<<white/><<grey/><<<sp_0, br/>//		dirTree t = IO.<dirTree>readObject( 'referenceTree.dat');"+
            "<<white/><<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>//dirTree newTree;"+
            "<<white/><<grey/><<<sp_0, br/>//		while(true)"+
            "<<white/><<grey/><<<sp_0, br/>//		{"+
            "<<white/><<grey/><<<sp_0, br/>//			Thread.sleep(5000);"+
            "<<white/><<grey/><<<sp_0, br/>//			"+
            "<<white/><<grey/><<<sp_0, br/>//			newTree = new dirTree();"+
            "<<white/><<grey/><<<sp_0, br/>//			if (newTree.root.directoryByte != oldtree.root.directoryByte)"+
            "<<white/><<grey/><<<sp_0, br/>//			{"+
            "<<white/><<grey/><<<sp_0, br/>//				System.out.println('File has been modified');"+
            "<<white/><<grey/><<<sp_0, br/>//				System.exit(0);"+
            "<<white/><<grey/><<<sp_0, br/>//			}"+
            "<<white/><<grey/><<<sp_0, br/>//		}"+
            "<<white/><<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> uploadDirTree(sftp server, dirTree tree)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>String<<white/> removePath = tree.root.getPath().substring( 0, (tree.root.getPath().length() - tree.root.getName().length() ));"+
            "<<<sp_3, br/>Iterator <dirTree.directory>treeItereator =  tree.iteartor().iterator(); <<grey/>//generate iterator"+
            "<<white/><<<sp_3, br/>"+
            "<<<sp_3, br/>server.removeDir(tree.root.getName()); <<grey/>//remove folder from server before upload"+
            "<<white/><<<sp_3, br/><<grey/>//iterate all directories"+
            "<<white/><<<sp_3, br/><<lightblue/>while<<white/>(treeItereator.hasNext())"+
            "<<<sp_3, br/>{"+
            "<<<sp_4, br/>dirTree.directory d = treeItereator.next();"+
            "<<<sp_4, br/><<orange/>String<<white/> serverPath = d.getPath().substring(removePath.length(), d.getPath().length());"+
            "<<<sp_4, br/>server.mkdir(serverPath); <<grey/>//create directory in server"+
            "<<white/><<<sp_4, br/><<lightblue/>if<<white/>(d.elementSize > 0)				"+
            "<<<sp_5, br/>for(node<file_directoryContainer> f : d.iteartor())"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(!f.element.isDirectory())<<grey/>//verify is file"+
            "<<white/><<<sp_7, br/>server.upload(serverPath+f.element.getName(), f.element.getPath()); <<grey/>//upload File to server"+
            "<<white/><<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_10, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>void<<white/> print(<<orange/>int<<white/> spaces, <<orange/>String<<white/> p)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>for(<<orange/>int<<white/> i =0; i < spaces; i++)"+
            "<<<sp_4, br/><<orange/>system<<white/>.out.print(' ');"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println(p);"+
            "<<<sp_1, br/>}"+
            "<<<sp_0, br/>}"
          
        },
        {
            key : 2,
            FileTitle : "IO.java",
            content :  
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.BufferedInputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.BufferedOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.File;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileInputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileNotFoundException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.FileReader;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.IOException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.ObjectInputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.ObjectOutputStream;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Scanner;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> IO {"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Writes the object T into the current rested path"+
            "<<<sp_2, br/>* @param class-object"+
            "<<<sp_2, br/>* @param fileName. e.g : class1.dat"+
            "<<<sp_2, br/>* @return true of success, else false."+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <T> boolean writeObject(T o, <<orange/>String<<white/> fileName) "+
            "<<<sp_1, br/>{"+
            "<<<sp_4, br/>try(ObjectOutputStream locFile = <<orange/>new<<white/> ObjectOutputStream(  <<orange/>new<<white/> BufferedOutputStream(<<orange/>new<<white/> FileOutputStream(fileName))  )){          "+
            "<<<sp_6, br/>locFile.writeObject(o);"+
            "<<<sp_4, br/>} <<lightblue/>catch<<white/> (Exception e) {"+
            "<<<sp_5, br/>e.printStackTrace();"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_4, br/>} "+
            "<<<sp_4, br/><<orange/>system<<white/>.out.println(fileName + ' written successfully!');"+
            "<<<sp_4, br/><<orange/>return<<white/> true;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Read .txt from given path and return an array representation of it, delimitered by the char ','"+
            "<<<sp_2, br/>* @param path (e.g, file.txt) where file.txt is in the currentDir "+
            "<<<sp_2, br/>* @return StringArray[] where index 0 = line 1, .. , index max"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>String<<white/>[] readFile(<<orange/>String<<white/> path) throws Exception"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>path = dirTree.getPath() + path;"+
            "<<<sp_1, br/>"+
            "<<<sp_3, br/><<lightblue/>if<<white/> (!doesExist(path))"+
            "<<<sp_5, br/>throw <<orange/>new<<white/> Exception('file ' + path  + 'does not exist');"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>Scanner scan = <<orange/>new<<white/> Scanner(<<orange/>new<<white/> FileReader(path)); "+
            "<<<sp_3, br/><<orange/>String<<white/>Builder b = <<orange/>new<<white/> <<orange/>String<<white/>Builder();"+
            "<<<sp_3, br/><<lightblue/>while<<white/>(scan.hasNext())"+
            "<<<sp_5, br/>b.append(scan.next());"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> b.to<<orange/>String<<white/>().split(',');"+
            "<<<sp_3, br/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <T> T readObject(<<orange/>String<<white/> path)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>T obj = null;"+
            "<<<sp_3, br/>try(ObjectInputStream locFile = <<orange/>new<<white/> ObjectInputStream(<<orange/>new<<white/> BufferedInputStream(<<orange/>new<<white/> FileInputStream(path))))"+
            "<<<sp_3, br/>{"+
            "<<<sp_7, br/>obj = (T)locFile.readObject();"+
            "<<<sp_3, br/>}<<lightblue/>catch<<white/> (Exception e) {"+
            "<<<sp_5, br/>e.printStackTrace();"+
            "<<<sp_3, br/>} "+
            "<<<sp_3, br/><<orange/>return<<white/> obj;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* "+
            "<<<sp_2, br/>* @param path, this path is not based on current directory, and can reside anywhere on system e.g '/uses/etc/it.txt"+
            "<<<sp_2, br/>* @return true if path exist, else false"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> boolean doesExist(<<orange/>String<<white/> path)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>return<<white/> <<orange/>new<<white/> File(path).exists();"+
            "<<<sp_1, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/>}"


        },
     
        {
            key : 3,
            FileTitle : "sftp.java",
            content :  
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.File;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.Serializable;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Vector;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> org.Encryptor.Encryption;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> com.jcraft.jsch.Channel;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> com.jcraft.jsch.ChannelSftp;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> com.jcraft.jsch.JSch;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> com.jcraft.jsch.JSchException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> com.jcraft.jsch.Session;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> com.jcraft.jsch.SftpException;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> backUper.dirTree.node;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> sftp {"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<grey/>/** Error message **/<<white/>"+
            "<<<sp_1, br/><<orange/>String<<white/>[] ftpInfoSyntax = {'  Create a file ftpInfo.txt in the executing directory with the following structure :',"+
            "<<<sp_5, br/>'    Syntax :  REMOTE_HOST,REMOTE_PORT,USERNAME,PASSWORD,REMOTEROOTDIR,SSH_KNOWNHOSTLOC \n'"+
            "<<<sp_5, br/>+ '    I.e    :  192.168.1.112,22,root,password123,/DataVolume/shares/memorials/SFTPBACKUP,~/.ssh/known_hosts'};"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<orange/>private<<white/> Session jschSession = null;"+
            "<<<sp_1, br/><<orange/>private<<white/> ChannelSftp channelSftp = null;"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/><<orange/>private<<white/>  <<orange/>String<<white/> REMOTE_HOST;"+
            "<<<sp_3, br/><<orange/>private<<white/>  <<orange/>int<<white/> REMOTE_PORT;"+
            "<<<sp_3, br/><<orange/>private<<white/>  <<orange/>String<<white/> USERNAME;"+
            "<<<sp_3, br/><<orange/>private<<white/>  <<orange/>String<<white/> PASSWORD;"+
            "<<<sp_3, br/><<orange/>private<<white/>  <<orange/>String<<white/> knownHostloc;"+
            "<<<sp_3, br/><<orange/>public<<white/>   <<orange/>String<<white/> remoteRootDir;"+
            "<<<sp_3, br/><<orange/>private<<white/> <<orange/>static<<white/> <<orange/>final<<white/> <<orange/>int<<white/> SESSION_TIMEOUT = 10000;"+
            "<<<sp_3, br/><<orange/>private<<white/> <<orange/>static<<white/> <<orange/>final<<white/> <<orange/>int<<white/> CHANNEL_TIMEOUT = 5000;"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* Constructor to create sftp instance, password used to decrypt/encrypt ftpInfo.txt for secure storage"+
            "<<<sp_4, br/>* If ftpInfo.txt has not been encrypted prior, the pass passed is now used to generate the encryptionmetadata, hence being the password for future decryption"+
            "<<<sp_4, br/>* @param pass"+
            "<<<sp_4, br/>* @throws Exception caused by ftpinfo.txt encryption "+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_1, br/>sftp(<<orange/>String<<white/> pass) throws Exception"+
            "<<<sp_1, br/>{		"+
            "<<<sp_3, br/>Encryption ec = Encryptor.generateEncryptor(pass);"+
            "<<<sp_6, br/>try {"+
            "<<<sp_7, br/>try {"+
            "<<<sp_8, br/>ec.DecryptFolder(dirTree.getPath()+'/', <<orange/>new<<white/> <<orange/>String<<white/>[]{'ftpinfo.txt.enc'});"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/>}<<lightblue/>catch<<white/>(Exception e3)"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/><<lightblue/>if<<white/> (e3.getMessage().equals('Incorrect Password for decryption!'))"+
            "<<<sp_10, br/><<orange/>system<<white/>.out.println(e3.getMessage() + ' Please re-entry password to decrypt ftpinfo.txt, Else delete encryptionMetaData.txt and re-create ftpinfo.txt');"+
            "<<<sp_8, br/><<lightblue/>else<<white/>"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println('encryptionMetaData.txt not found');"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/><<orange/>String<<white/>[] info = IO.readFile('/ftpInfo.txt');"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (info.length != 6) throw <<orange/>new<<white/> Exception('ftpInfo structure incorrect');"+
            "<<<sp_5, br/>"+
            "<<<sp_7, br/>try {"+
            "<<<sp_8, br/>REMOTE_HOST = info[0];"+
            "<<<sp_8, br/>REMOTE_PORT = <<orange/>char<<white/>.parseInt(info[1]);"+
            "<<<sp_8, br/>USERNAME = info[2];"+
            "<<<sp_8, br/>PASSWORD = info[3];"+
            "<<<sp_8, br/>remoteRootDir = info[4];"+
            "<<<sp_8, br/>knownHostloc = info[5];"+
            "<<<sp_7, br/>}<<lightblue/>catch<<white/>(Exception e2)"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/><<orange/>system<<white/>.out.println(e2);"+
            "<<<sp_8, br/>throw <<orange/>new<<white/> Exception('Cannot retrieve portnumber');"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (Exception e1) {"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('Cannot Compute ftpInfo.txt to initiate server');"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(<<orange/>String<<white/> b : ftpInfoSyntax)"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println(b);"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println(e1.getMessage() + ', system<<white/> now exiting!');"+
            "<<<sp_5, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>ec.EncryptFolder(dirTree.getPath() +'/', <<orange/>new<<white/> <<orange/>String<<white/>[]{'ftpinfo.txt'});"+
            "<<<sp_6, br/>"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println('Initialising Sftp server : ' + USERNAME +'@' + REMOTE_HOST + ':' + REMOTE_PORT);"+
            "<<<sp_6, br/>try "+
            "<<<sp_6, br/>{"+
            "<<<sp_7, br/>"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/>JSch jsch = <<orange/>new<<white/> JSch();"+
            "<<<sp_8, br/>jsch.setKnownHosts(knownHostloc); <<grey/>//host key for security --> https://stackoverflow.com/questions/2003419/com-jcraft-jsch-jschexception-unknownhostkey "+
            "<<white/><<<sp_8, br/>jschSession = jsch.getSession(USERNAME, REMOTE_HOST, REMOTE_PORT);"+
            "<<<sp_8, br/>jschSession.setConfig('StrictHostKeyChecking', 'yes');"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/><<grey/>//jschSession.setConfig('StrictHostKeyChecking', 'no'); //removes the need for host-key, introduces security issues"+
            "<<white/><<<sp_8, br/><<grey/>// authenticate using private key"+
            "<<white/><<<sp_8, br/><<grey/>// jsch.addIdentity('/home/mkyong/.ssh/id_rsa');"+
            "<<white/><<<br,hidden/>br"+
            "<<<sp_8, br/><<grey/>// authenticate using password"+
            "<<white/><<<sp_8, br/>jschSession.setPassword(PASSWORD);"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/><<grey/>// 10 seconds session timeout"+
            "<<white/><<<sp_8, br/>jschSession.connect(SESSION_TIMEOUT);"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/>Channel sftp = jschSession.openChannel('sftp');"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/><<grey/>// 5 seconds timeout"+
            "<<white/><<<sp_8, br/>sftp.connect(CHANNEL_TIMEOUT);"+
            "<<<br,hidden/>br"+
            "<<<sp_8, br/>channelSftp = (ChannelSftp) sftp;"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>} <<lightblue/>catch<<white/> (JSchException e) {"+
            "<<<sp_8, br/>e.printStackTrace();"+
            "<<<sp_8, br/><<orange/>system<<white/>.out.println('\n --> If UnknownHostKeyException, ssh into command line first to initiate <<orange/>public<<white/>-key host (https:<<grey/>//stackoverflow.com/questions/2003419/com-jcraft-jsch-jschexception-unknownhostkey)');"+
            "<<white/><<<sp_8, br/><<orange/>system<<white/>.out.println('\n --> SFTP_Server Setup Error, Sys.exit');"+
            "<<<sp_8, br/><<orange/>system<<white/>.exit(0);"+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/>} "+
            "<<<br,hidden/>br"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.print(' --> Setup Complete\n');"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>}"+
            "<<<sp_3, br/><<orange/>void<<white/> disConnect()"+
            "<<<sp_3, br/>{"+
            "<<<sp_6, br/><<lightblue/>if<<white/> (jschSession != null) {"+
            "<<<sp_7, br/>channelSftp.exit(); <<grey/>//close pipline"+
            "<<white/><<<sp_9, br/>jschSession.disconnect();"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println(REMOTE_HOST + ' has been disconnected');"+
            "<<<sp_7, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* Make a directory based on the given path.  rootPath to the sftp server is added to the beginning of the path entered."+
            "<<<sp_4, br/>* @param dirPath (i.e, /new folder') "+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/><<orange/>void<<white/> mkdir(<<orange/>String<<white/> dirPath)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>try {"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print('Creating Server Path : ' + remoteRootDir + dirPath);"+
            "<<<sp_5, br/>channelSftp.mkdir(remoteRootDir + dirPath);"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print(' --> Path Created Successfully\n');"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (SftpException e) {"+
            "<<<sp_5, br/>e.printStackTrace();"+
            "<<<sp_5, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* Remove a file 'fPath' on the server"+
            "<<<sp_4, br/>* @param fPath (i.e, /file.txt')"+
            "<<<sp_4, br/>* @return true if success"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/>boolean removeFile(<<orange/>String<<white/> fPath)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print('\nRemoving Server File : ' + remoteRootDir + fPath);"+
            "<<<sp_1, br/>"+
            "<<<sp_5, br/>try {"+
            "<<<sp_5, br/>channelSftp.rm(fPath);"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print( ' --> File Removed!\n' );"+
            "<<<sp_5, br/><<orange/>return<<white/> true;"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (SftpException e) {"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print( ' --> ' + e + '\n');"+
            "<<<sp_5, br/><<orange/>return<<white/> false;"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* Remove directory of a server path; rootPath to the sftp server is added to the beginning of the path entered."+
            "<<<sp_4, br/>* @param dirPath (i.e, /new folder') where all child directories and files of 'new folder' is recursively deleted"+
            "<<<sp_4, br/>* @return true on success"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/>boolean removeDir(<<orange/>String<<white/> dirPath)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('\n\nRemoving Server Path : ' + remoteRootDir + dirPath);"+
            "<<<sp_5, br/>try {"+
            "<<<sp_6, br/>recursiveDirectoryDelete( remoteRootDir + dirPath +'/');"+
            "<<<sp_5, br/>} <<lightblue/>catch<<white/> (Exception e) {"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println(remoteRootDir + dirPath + ' --> Removal unsuccessful');"+
            "<<<sp_6, br/><<orange/>return<<white/> false;"+
            "<<<br,hidden/>br"+
            "<<<sp_5, br/>}	"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println( remoteRootDir + dirPath + ' --> Directory Successfully Removed \n\n');"+
            "<<<sp_5, br/><<orange/>return<<white/> true;"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>private<<white/> <<orange/>void<<white/> recursiveDirectoryDelete(<<orange/>String<<white/> remoteDir) throws Exception"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>try"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(isDirectory(remoteDir))"+
            "<<<sp_6, br/>{"+
            "<<<sp_7, br/>Vector<ChannelSftp.LsEntry> dirList = channelSftp.ls(remoteDir);"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/>for(ChannelSftp.LsEntry entry : dirList)"+
            "<<<sp_7, br/>{"+
            "<<<sp_8, br/><<lightblue/>if<<white/>(!(entry.getFilename().equals('.') || entry.getFilename().equals('..')))"+
            "<<<sp_8, br/>{"+
            "<<<sp_9, br/><<lightblue/>if<<white/>(entry.getAttrs().isDir())"+
            "<<<sp_9, br/>{"+
            "<<<sp_10, br/>recursiveDirectoryDelete(remoteDir + entry.getFilename() + File.separator);"+
            "<<<sp_9, br/>}"+
            "<<<sp_9, br/><<lightblue/>else<<white/>"+
            "<<<sp_9, br/>{"+
            "<<<sp_10, br/>channelSftp.rm(remoteDir + entry.getFilename());"+
            "<<<sp_10, br/><<orange/>system<<white/>.out.println('  ' +remoteDir + entry.getFilename() + ' --> Deleted');"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/>}"+
            "<<<sp_8, br/>}"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/>channelSftp.cd('..');"+
            "<<<sp_6, br/><<orange/>system<<white/>.out.println('  ' + remoteDir + ' --> Deleted');"+
            "<<<sp_7, br/>channelSftp.rmdir(remoteDir);"+
            "<<<sp_6, br/>}"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<lightblue/>catch<<white/> (SftpException e)"+
            "<<<sp_5, br/>{"+
            "<<<sp_6, br/>throw <<orange/>new<<white/> Exception(e);"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}	"+
            "<<<br,hidden/>br"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>private<<white/> boolean isDirectory(<<orange/>String<<white/> remoteDirectory) throws Exception"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>try {"+
            "<<<sp_6, br/><<orange/>return<<white/> channelSftp.stat(remoteDirectory).isDir();"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (SftpException e) {"+
            "<<<sp_5, br/>throw <<orange/>new<<white/> Exception(e);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_4, br/>* Upload file to sftp directory. fileName will indicate if file resides within subfolders"+
            "<<<sp_4, br/>* @param fileName (i.e, /file1.txt OR /folder1/file1.txt)"+
            "<<<sp_4, br/>* @param localFile (i.e, '/Users/assgetit/Desktop/file1.txt')"+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_2, br/><<orange/>void<<white/> upload(<<orange/>String<<white/> fileName, <<orange/>String<<white/> localFile)"+
            "<<<sp_1, br/>{"+
            "<<<sp_4, br/><<orange/>system<<white/>.out.print('Uplading Local File : ' + localFile );"+
            "<<<sp_3, br/><<grey/>// transfer file from local to remote server"+
            "<<white/><<<sp_6, br/>try {"+
            "<<<sp_5, br/>channelSftp.put(localFile, remoteRootDir + fileName);"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.print(' --> Transfer Complete\n');"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (Exception e) {"+
            "<<<sp_5, br/>e.printStackTrace();"+
            "<<<sp_5, br/><<orange/>system<<white/>.exit(0);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_0, br/>}"
        },
    
        {
            key : 4,
            FileTitle : "dirTree.java",
            content :  
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.File;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.io.Serializable;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.nio.file.Files;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.nio.file.Path;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.ArrayDeque;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.ArrayList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Arrays;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Comparator;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Deque;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Iterator;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.LinkedList;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.List;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.NoSuchElementException;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Queue;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.Stack;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.concurrent.ArrayBlockingQueue;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.util.concurrent.LinkedBlockingQueue;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> backUper.dirTree.directory;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> backUper.dirTree.file_directoryContainer;"+
            "<<<sp_0, br/><<lightblue/>import<<white/> backUper.dirTree.node;"+
            "<<<br,hidden/>br"+
            "<<<sp_0, br/><<lightblue/>import<<white/> java.nio.file.Paths;"+
            "<<grey/><<<sp_0, br/>/*"+
            "* Class is devoted in the representation of directory structure including folders and files"+
            "*/<<white/>"+
            "<<<sp_0, br/><<orange/>public<<white/> <<orange/>class<<white/> dirTree implements Serializable "+
            "<<<sp_0, br/>{"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>static<<white/> <<orange/>final<<white/> long serialVersionUID = -3680750866596546245L;"+
            "<<<sp_1, br/><<grey/>/* information interface for files and directory structure */<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>int<<white/>erface file_directoryContainer"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>String<<white/> getName(); <<grey/>//get name of the element  "+
            "<<white/><<<sp_3, br/><<orange/>String<<white/> getPath(); <<grey/>//get path of the element "+
            "<<white/><<<sp_3, br/>boolean isDirectory(); <<grey/>//true if is directory, else false (is file) "+
            "<<white/><<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/* Private node class */<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>class<<white/> node<T> implements Serializable"+
            "<<<sp_1, br/>{"+
            "<<<sp_5, br/><<orange/>private<<white/> <<orange/>static<<white/> <<orange/>final<<white/> long serialVersionUID = -3680750866596546245L;"+
            "<<<sp_5, br/>T element;"+
            "<<<sp_5, br/><<orange/>private<<white/> node<T> next;"+
            "<<<sp_5, br/><<grey/>//initialise node"+
            "<<white/><<<sp_5, br/>node() { }"+
            "<<<sp_5, br/>node(T element){ this.element = element; }"+
            "<<<sp_5, br/>node(node<T> n , T element)"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/>this(element);"+
            "<<<sp_7, br/>setNext(n);"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<grey/>//set next node"+
            "<<white/><<<sp_5, br/><<orange/>private<<white/> <<orange/>void<<white/> setNext(node<T> n){ next = n; }"+
            "<<<sp_5, br/><<grey/>//getNext node"+
            "<<white/><<<sp_5, br/><<orange/>private<<white/> node<T> getNext() { <<orange/>return<<white/> next;}"+
            "<<<sp_5, br/>"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/* Private directory class */<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>class<<white/> directory implements file_directoryContainer,  Serializable"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>private<<white/> <<orange/>static<<white/> <<orange/>final<<white/> long serialVersionUID = 4100804824896525083L;"+
            "<<<sp_5, br/><<grey/>//file class implementation"+
            "<<white/><<<sp_6, br/><<orange/>class<<white/> file implements file_directoryContainer, Serializable"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/><<orange/>private<<white/> <<orange/>static<<white/> <<orange/>final<<white/> long serialVersionUID = 3602830634677460137L;"+
            "<<<sp_7, br/><<orange/>private<<white/> <<orange/>String<<white/> path;"+
            "<<<sp_7, br/><<orange/>private<<white/> <<orange/>String<<white/> name;"+
            "<<<sp_7, br/><<orange/>public<<white/> long fileByteSize;"+
            "<<<sp_7, br/>file(<<orange/>String<<white/> path, long fsize) throws Exception"+
            "<<<sp_7, br/>{"+
            "<<<sp_9, br/>fileByteSize = fsize;"+
            "<<<sp_9, br/>this.path = path;"+
            "<<<sp_9, br/>this.name = parseDirName(path);	"+
            "<<<sp_7, br/>}"+
            "<<<sp_5, br/>"+
            "<<<sp_7, br/>@Override"+
            "<<<sp_7, br/><<orange/>public<<white/> <<orange/>String<<white/> getName() { <<orange/>return<<white/> name; }"+
            "<<<sp_7, br/>@Override"+
            "<<<sp_7, br/><<orange/>public<<white/> <<orange/>String<<white/> getPath() { <<orange/>return<<white/> path; }"+
            "<<<sp_7, br/>@Override"+
            "<<<sp_7, br/><<orange/>public<<white/> boolean isDirectory() { <<orange/>return<<white/> false; }"+
            "<<<sp_5, br/>} <<grey/>/* End of file class */<<white/>"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<grey/>/* Directory iterator class implementation */<<white/>"+
            "<<<sp_5, br/><<orange/>private<<white/> <<orange/>class<<white/> iter implements Iterator, Iterable, Serializable"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/>node<file_directoryContainer> initialiser;"+
            "<<<sp_7, br/>iter()"+
            "<<<sp_7, br/>{ "+
            "<<<sp_9, br/>initialiser = firstElement; "+
            "<<<sp_7, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_7, br/>@Override"+
            "<<<sp_7, br/><<orange/>public<<white/> boolean hasNext() { <<orange/>return<<white/> (initialiser.next.element != null); }"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/>@Override"+
            "<<<sp_7, br/><<orange/>public<<white/> Object next() "+
            "<<<sp_7, br/>{"+
            "<<<sp_10, br/>initialiser = initialiser.next;"+
            "<<<sp_10, br/><<orange/>return<<white/> initialiser;"+
            "<<<sp_7, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_7, br/>@Override"+
            "<<<sp_7, br/><<orange/>public<<white/> Iterator<node<file_directoryContainer>> iterator() { <<orange/>return<<white/> this; }			"+
            "<<<sp_5, br/>} <<grey/>/* Iterator class end */<<white/>"+
            "<<<sp_5, br/><<orange/>private<<white/> node<file_directoryContainer> firstElement; <<grey/>//first element in directory"+
            "<<white/><<<sp_5, br/><<orange/>private<<white/> node<file_directoryContainer> currentElement; <<grey/>//last element in directory  "+
            "<<white/><<<sp_5, br/><<orange/>private<<white/> directory parent; <<grey/>//parent directory if any"+
            "<<white/><<<sp_5, br/><<orange/>int<<white/> elementSize =0; <<grey/>//elements including folders and elements"+
            "<<white/><<<sp_5, br/><<orange/>int<<white/> depth; <<grey/>//depth of the directory in tree "+
            "<<white/><<<sp_5, br/>long directoryByte =0;"+
            "<<<sp_5, br/><<orange/>private<<white/> <<orange/>String<<white/> dirPath; <<grey/>//path of directory"+
            "<<white/><<<sp_5, br/>directory(<<orange/>String<<white/> dirPath)"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/>this.dirPath = dirPath;"+
            "<<<sp_7, br/>firstElement = <<orange/>new<<white/> node<>(null); <<grey/>//initialises first element	"+
            "<<white/><<<sp_5, br/>}"+
            "<<<sp_5, br/><<orange/>private<<white/> <<orange/>void<<white/> setDepth(<<orange/>int<<white/> d) { depth = d; }"+
            "<<<sp_5, br/><<orange/>private<<white/> <<orange/>void<<white/> setParent(directory p) { parent = p;}"+
            "<<<sp_5, br/><<orange/>public<<white/> directory getParent() { <<orange/>return<<white/> parent; } "+
            "<<<sp_5, br/><<orange/>private<<white/> <<orange/>void<<white/> addElement(file_directoryContainer f)"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/><<lightblue/>if<<white/>(elementSize == 0 ) <<grey/>//Initialises at 0 element"+
            "<<white/><<<sp_7, br/>{"+
            "<<<sp_9, br/>currentElement = <<orange/>new<<white/> node<>(firstElement,f);"+
            "<<<sp_9, br/>firstElement.setNext(currentElement);"+
            "<<<sp_9, br/>elementSize++;"+
            "<<<sp_9, br/><<orange/>return<<white/>;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>node<file_directoryContainer> <<orange/>new<<white/>Element = <<orange/>new<<white/> node<>(firstElement, f);"+
            "<<<sp_7, br/>currentElement.setNext(<<orange/>new<<white/>Element);"+
            "<<<sp_7, br/>currentElement = <<orange/>new<<white/>Element;"+
            "<<<sp_7, br/>elementSize++;"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>"+
            "<<<sp_5, br/><<grey/>/* return an iterable collection of the directorys file */<<white/>"+
            "<<<sp_5, br/>Iterable<node<file_directoryContainer>> iteartor(){ "+
            "<<<sp_7, br/><<orange/>return<<white/> <<orange/>new<<white/> iter(); "+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>@Override"+
            "<<<sp_5, br/><<orange/>public<<white/> <<orange/>String<<white/> getName() { "+
            "<<<sp_7, br/>try {"+
            "<<<sp_9, br/><<orange/>return<<white/> parseDirName(dirPath);"+
            "<<<sp_7, br/>} <<lightblue/>catch<<white/> (Exception e) {"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println(e.getMessage());"+
            "<<<sp_7, br/>} "+
            "<<<sp_7, br/><<orange/>return<<white/> null;"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>@Override"+
            "<<<sp_5, br/><<orange/>public<<white/> <<orange/>String<<white/> getPath() {"+
            "<<<sp_7, br/><<orange/>return<<white/> dirPath; "+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/>@Override"+
            "<<<sp_5, br/><<orange/>public<<white/> boolean isDirectory() { "+
            "<<<sp_7, br/><<orange/>return<<white/> (this.getClass() == dirTree.directory.<<orange/>class<<white/>); "+
            "<<<sp_5, br/>}	"+
            "<<<sp_5, br/><<orange/>public<<white/> boolean isEmpty() {  <<orange/>return<<white/> ( !(elementSize > 0)); }"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* @param String directory name"+
            "<<<sp_2, br/>* @return extracted filename from directory path"+
            "<<<sp_2, br/>* @throws Exception if '/' is not found"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> <<orange/>static<<white/> <<orange/>String<<white/> parseDirName(<<orange/>String<<white/> dir) throws Exception"+
            "<<<sp_1, br/>{ "+
            "<<<sp_3, br/><<orange/>String<<white/>Builder b = <<orange/>new<<white/> <<orange/>String<<white/>Builder();"+
            "<<<sp_4, br/>for(<<orange/>int<<white/> i=dir.length()-1;;i--)"+
            "<<<sp_4, br/>{"+
            "<<<sp_6, br/>b.append(dir.<<orange/>char<<white/>At(i));"+
            "<<<sp_6, br/><<lightblue/>if<<white/>(i ==0 || dir.<<orange/>char<<white/>At(i) == '/')<<orange/>break<<white/>;"+
            "<<<sp_4, br/>}"+
            "<<<sp_4, br/><<lightblue/>if<<white/>(b.length() == dir.length()) throw <<orange/>new<<white/> Exception('invalid directory');"+
            "<<<sp_4, br/><<orange/>return<<white/> b.reverse().to<<orange/>String<<white/>();"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* @return current directory path "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> <<orange/>static<<white/> <<orange/>String<<white/> getPath(){ <<orange/>return<<white/> <<orange/>system<<white/>.getProperty('user.dir'); }"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* generateElement for a directory, this does not set the depth for the given directory, but initializes the elements within a given dir."+
            "<<<sp_2, br/>* @param directory directory "+
            "<<<sp_2, br/>* @return directory object"+
            "<<<sp_2, br/>* @throws Exception "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> directory generateElement(directory dir) throws Exception"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>File f = <<orange/>new<<white/> File(dir.getPath());"+
            "<<<sp_3, br/><<lightblue/>for<<white/>(File b : f.listFiles())"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>long fileSize = Files.size( Paths.get(b.getPath()));"+
            "<<<sp_5, br/><<lightblue/>if<<white/>(b.isFile() )<<grey/>//filter files  starting with '/.' (i.e, : /.Ds_Store) "+
            "<<white/><<<sp_5, br/>{"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (b.getName().<<orange/>char<<white/>At(0) != '.') "+
            "<<<sp_7, br/>{"+
            "<<<sp_9, br/>dir.addElement( dir.<<orange/>new<<white/> file(b.getPath(), fileSize) );"+
            "<<<sp_9, br/>dir.directoryByte += fileSize;"+
            "<<<sp_7, br/>}"+
            "<<<br,hidden/>br"+
            "<<<sp_7, br/>"+
            "<<<sp_5, br/>}"+
            "<<<sp_5, br/><<lightblue/>else<<white/> "+
            "<<<sp_7, br/>dir.addElement( <<orange/>new<<white/> directory(b.getPath() ));"+
            "<<<sp_3, br/>}"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/>"+
            "<<<sp_3, br/>"+
            "<<<sp_1, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> dir;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_3, br/><<grey/>/**"+
            "<<<sp_2, br/>* Initialises and populate the directoryTree based on the given rootDirectory. This generates the depth, "+
            "<<<sp_2, br/>* returns directory byteSize for all subsequent childs : Note this does not add/update parent directory "+
            "<<<sp_2, br/>* of all subsequent child size  (meaning all directory sizes are sepearate) "+
            "<<<sp_2, br/>* Method Links all directory together into a structured tree."+
            "<<<sp_2, br/>* @param (directory) rootDir "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> long generateTree(directory rootDir, <<orange/>int<<white/> depth) throws Exception"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>generateElement(rootDir);"+
            "<<<sp_3, br/>long b = rootDir.directoryByte;"+
            "<<<sp_3, br/>rootDir.setDepth(depth);"+
            "<<<sp_3, br/><<lightblue/>if<<white/>(!rootDir.isEmpty())"+
            "<<<sp_7, br/>for(node<file_directoryContainer> s : rootDir.iteartor())"+
            "<<<sp_7, br/>{"+
            "<<<sp_9, br/><<lightblue/>if<<white/>(s.element.isDirectory())"+
            "<<<sp_9, br/>{"+
            "<<<sp_10, br/>directory child = (directory)s.element;"+
            "<<<sp_10, br/>child.setParent(rootDir);"+
            "<<<sp_10, br/>b += generateTree(child, (rootDir.depth + 1));"+
            "<<<sp_9, br/>} "+
            "<<<sp_7, br/>}	"+
            "<<<sp_3, br/><<orange/>return<<white/> b;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>long treeByteSize =0;"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/>directory root = <<orange/>new<<white/> directory(getPath());	"+
            "<<<br,hidden/>br"+
            "<<<sp_1, br/><<orange/>public<<white/> dirTree() "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>try "+
            "<<<sp_3, br/>{			"+
            "<<<sp_5, br/>treeByteSize = generateTree(root, 0);"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println('Tree Generated for rootDir -> ' + root.getPath() + ' --> ByteSize : ' + treeByteSize);"+
            "<<<sp_3, br/>}<<lightblue/>catch<<white/>(Exception i)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<lightblue/>import<<white/>(i.getMessage())"+
            "<<<sp_5, br/>{"+
            "<<<sp_7, br/><<lightblue/>case<<white/> 'invalid directory' : "+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println('invalid directory. <<orange/>system<<white/>Exit');"+
            "<<<sp_9, br/><<orange/>system<<white/>.exit(0);					"+
            "<<<sp_9, br/><<orange/>break<<white/>;"+
            "<<<sp_7, br/>default :"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println(i.getMessage());"+
            "<<<sp_9, br/><<orange/>break<<white/>;"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Return a directory object of the directed path; depth is the maximum depth to search, if iteration > depth then directory does not exist"+
            "<<<sp_2, br/>* @param path"+
            "<<<sp_2, br/>* @param Depth"+
            "<<<sp_2, br/>* @apiNote O(n2) worse case scenario"+
            "<<<sp_2, br/>* @return directory object of path, else null is returned"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> directory getDirectory(<<orange/>String<<white/> path, <<orange/>int<<white/> Depth)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<grey/>//iteerate through all root childs"+
            "<<white/><<<sp_3, br/>for(directory c : getTree(root, Depth))"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (c.getPath().equals(path))"+
            "<<<sp_7, br/><<orange/>return<<white/> c;"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> null;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Generates structured contents of a tree rooted at a the given directory"+
            "<<<sp_2, br/>* @param directory rootdir"+
            "<<<sp_2, br/>* @param depth ; maximum depth to return where depth 0 = root && -1 = no depth"+
            "<<<sp_2, br/>* @return Iterator representation of a given root directory "+
            "<<<sp_2, br/>* @throws Exception"+
            "<<<sp_2, br/>* 		//code for toString print"+
            "<<<sp_2, br/>*  	int currentDepth = -1;"+
            "<<<sp_2, br/>* 		if (folderElementHolder.depth != currentDepth)"+
            "<<<sp_2, br/>*		{"+
            "<<<sp_2, br/>*			currentDepth = folderElementHolder.depth;"+
            "<<<sp_2, br/>*			System.out.println('Depth : ' + currentDepth);"+
            "<<<sp_2, br/>*		}"+
            "<<<sp_2, br/>*		main.print(folderElementHolder.depth*2, (folderElementHolder.depth == 0 ) ? '(root)' + folderElementHolder.getName() : folderElementHolder.getName() );"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> Iterable<directory> getTree(directory rootd, <<orange/>int<<white/> depth) "+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/>LinkedList<directory> l = <<orange/>new<<white/> LinkedList<>();"+
            "<<<sp_3, br/>l.add(rootd);"+
            "<<<sp_3, br/><<orange/>int<<white/> increment = 0;"+
            "<<<sp_3, br/><<lightblue/>while<<white/>(increment != l.size())"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/>directory folderElementHolder = l.get(increment);	"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (depth != -1 && folderElementHolder.depth >= depth) <<orange/>break<<white/>;"+
            "<<<sp_5, br/>increment++;"+
            "<<<sp_5, br/><<lightblue/>if<<white/> (folderElementHolder.elementSize > 0)"+
            "<<<sp_7, br/>for(node<file_directoryContainer> c : folderElementHolder.iteartor())"+
            "<<<sp_9, br/><<lightblue/>if<<white/> (c.element.isDirectory()) "+
            "<<<sp_10, br/>l.add((directory)c.element);"+
            "<<<sp_3, br/>}"+
            "<<<sp_3, br/><<orange/>return<<white/> l;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* "+
            "<<<sp_2, br/>* @return an iterable structure (by tree depth) collection of the tree "+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> Iterable<directory> iteartor(){ "+
            "<<<sp_3, br/><<orange/>return<<white/> getTree(root, -1); "+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Compare the current dirTree instance with the input old-dirTree 'oT'"+
            "<<<sp_2, br/>* @param c (comparator)"+
            "<<<sp_2, br/>* @param cT "+
            "<<<sp_2, br/>* @return List of changed directories on success, else null."+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>public<<white/> List<<<orange/>String<<white/>> findChanges(Comparator<Long> c, dirTree oT)"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<orange/>system<<white/>.out.println('Locating Changes');"+
            "<<<sp_3, br/>try {"+
            "<<<sp_5, br/>"+
            "<<<sp_5, br/><<orange/>return<<white/> findChanges(c, oT , this);"+
            "<<<sp_3, br/>} <<lightblue/>catch<<white/> (Exception e) {"+
            "<<<sp_5, br/>e.printStackTrace();"+
            "<<<sp_5, br/><<orange/>return<<white/> null;"+
            "<<<sp_3, br/>}"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/><<grey/>/**"+
            "<<<sp_2, br/>* Find changes by comparing old and new tree. Return a list representation of all file changed"+
            "<<<sp_2, br/>* @param c (comparator)"+
            "<<<sp_2, br/>* @param oldTree "+
            "<<<sp_2, br/>* @param newTree"+
            "<<<sp_2, br/>* @return List or Null if new folder has been added"+
            "<<<sp_2, br/>* @throws Exception"+
            "<<<sp_2, br/>*/<<white/>"+
            "<<<sp_1, br/><<orange/>private<<white/> List<<<orange/>String<<white/>> findChanges(Comparator<Long> c, dirTree oldTree, dirTree <<orange/>new<<white/>Tree) throws Exception"+
            "<<<sp_1, br/>{"+
            "<<<sp_3, br/><<grey/>/*"+
            "<<<sp_4, br/>* func findChanges(rootPath a, rootPath b)"+
            "<<<sp_4, br/>*  	if current directory a.bytesize != b.bytesize we do"+
            "<<<sp_4, br/>*   		recursively iterate down through all child 'ch' --> findChanges(c, "+
            "<<<sp_4, br/>*/<<white/>"+
            "<<<sp_3, br/>List<<<orange/>String<<white/>> toRet = <<orange/>new<<white/> ArrayList<>(); <<grey/>//Array list of changees"+
            "<<white/><<<sp_3, br/><<lightblue/>if<<white/> (c.compare(oldTree.treeByteSize , <<orange/>new<<white/>Tree.treeByteSize) == -1)"+
            "<<<sp_3, br/>{"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println(' --> Tree Structure have been modified [' + oldTree.treeByteSize +'_byte]' + '[' + <<orange/>new<<white/>Tree.treeByteSize +'_byte]');"+
            "<<<sp_5, br/><<lightblue/>for<<white/>(directory d : <<orange/>new<<white/>Tree.iteartor()) <<grey/>//iterate though new tree directories"+
            "<<white/><<<sp_5, br/>{"+
            "<<<sp_7, br/>dirTree.directory dHolder = oldTree.getDirectory(d.getPath(), d.depth); <<grey/>//return old-tree directory on matches"+
            "<<white/><<<sp_7, br/><<lightblue/>if<<white/>(dHolder == null) <<grey/>//non-matches in oldTree"+
            "<<white/><<<sp_7, br/>{"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println(' --> A <<orange/>new<<white/> folder has been added : ' + d.getPath());"+
            "<<<sp_9, br/><<orange/>return<<white/> null;"+
            "<<<sp_7, br/>}"+
            "<<<sp_7, br/>"+
            "<<<sp_7, br/><<lightblue/>if<<white/> (c.compare(dHolder.directoryByte, d.directoryByte) != 0) <<grey/>//directory byte mis-match"+
            "<<white/><<<sp_7, br/>{"+
            "<<<sp_9, br/><<orange/>system<<white/>.out.println('     --> Content Change located : ' + dHolder.getPath());"+
            "<<<br,hidden/>br"+
            "<<<sp_9, br/>Iterator nDirElementIterator = d.iteartor().iterator(); <<grey/>//new directory element iterator (new)"+
            "<<white/><<<sp_9, br/><<lightblue/>while<<white/>(nDirElementIterator.hasNext()) <<grey/>//iterate through elements of current dir "+
            "<<white/><<<sp_9, br/>{"+
            "<<<sp_10, br/>dirTree.node<file_directoryContainer> nElement = (node<file_directoryContainer>) nDirElementIterator.next();"+
            "<<<sp_10, br/><<lightblue/>if<<white/>(nElement.element.isDirectory()) continue; <<grey/>//continue to next iteration if current element is directory"+
            "<<white/><<<sp_10, br/><<lightblue/>if<<white/>(dHolder.elementSize == 0) <<grey/>//add then continue to next iteration if old directory element is empty"+
            "<<white/><<<sp_10, br/>{"+
            "<<<sp_11, br/>toRet.add(nElement.element.getName());"+
            "<<<sp_11, br/>continue;"+
            "<<<sp_10, br/>}"+
            "<<<sp_10, br/>"+
            "<<<sp_10, br/>"+
            "<<<sp_10, br/>Iterator oDirElementIterator = dHolder.iteartor().iterator(); <<grey/>//create oldTree element iterator"+
            "<<white/><<<sp_10, br/><<lightblue/>while<<white/>(true) <<grey/>//loop through old tree file to locate match"+
            "<<white/><<<sp_10, br/>{"+
            "<<<sp_11, br/>dirTree.node<file_directoryContainer> oElement = (node<file_directoryContainer>)oDirElementIterator.next();"+
            "<<<sp_11, br/><<lightblue/>if<<white/>(!oElement.element.isDirectory())  "+
            "<<<sp_12, br/><<lightblue/>if<<white/>( oElement.element.getName().equals( nElement.element.getName() ) ) <<grey/>//if matching file-name"+
            "<<white/><<<sp_12, br/>{"+
            "<<<sp_13, br/><<lightblue/>if<<white/>((c.compare(((dirTree.directory.file) nElement.element).fileByteSize ,  <<grey/>//we compare file size"+
            "<<white/><<<sp_15, br/>((dirTree.directory.file) oElement.element).fileByteSize) !=0)) "+
            "<<<sp_13, br/>{"+
            "<<<sp_14, br/>toRet.add(nElement.element.getPath()); <<grey/>//add to list if file size mismatch"+
            "<<white/><<<sp_14, br/><<orange/>system<<white/>.out.println('         --> ' + nElement.element.getName());"+
            "<<<sp_13, br/>}"+
            "<<<sp_13, br/><<orange/>break<<white/>; <<grey/>//break through while-loop once file has been located & compared"+
            "<<white/><<<sp_12, br/>}"+
            "<<<sp_11, br/><<lightblue/>if<<white/>(!oDirElementIterator.hasNext())<<grey/>//break if last element is reacheed"+
            "<<white/><<<sp_11, br/>{"+
            "<<<sp_12, br/>toRet.add(nElement.element.getPath());"+
            "<<<sp_12, br/><<orange/>system<<white/>.out.println('         --> ' + nElement.element.getName());"+
            "<<<sp_12, br/><<orange/>break<<white/>;"+
            "<<<sp_11, br/>}"+
            "<<<sp_10, br/>}"+
            "<<<sp_9, br/>}"+
            "<<<sp_7, br/>}"+
            "<<<sp_5, br/>}"+
            "<<<sp_3, br/>}<<lightblue/>else<<white/>"+
            "<<<sp_5, br/><<orange/>system<<white/>.out.println(' --> Tree structured identical!');"+
            "<<<sp_3, br/>"+
            "<<<sp_3, br/><<orange/>return<<white/> (toRet.size() <1 ) ? null : toRet;"+
            "<<<sp_1, br/>}"+
            "<<<sp_1, br/>"+
            "<<<sp_0, br/>}"+
            "<<<br,hidden/>br"
        },
      


    ]
}

export default CaroselItem;
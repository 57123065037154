// http://example2.igli.site/


import  {items} from '../../../../contentCarousel/contentCarosel';
import DemoWrapper from "../../../../DemoWrapper/DemoWrapper";
import React, { MouseEvent, useRef } from 'react';
import IframeWrapper from "../../../../IFrame_Container/IFrameContainer";
import myBrowser from '../../../../../BaseProject/Handlers/myBrowser';

let CaroselItem : items =    {
    key : 1,
    navigationUrl : "http://example2.igli.site",
    Subject : "Shopping Website (Demo)",
    Description : 
        <>
            <p style = {{}}>
          
                This project serves as a demonstration of a comprehensive full-stack shopping web-application. 
                The web application is built on Spring Boot, leveraging its robust REST-API capabilities to orchestrate user, 
                categories, subcategories, and item management.
            </p>

            <p>
                <span style = {{textDecoration:'underline'}}> Note </span>  : This project only showcases the foundational aspects of a full-stack shopping application and is not intended to represent production code. 
            </p>


            <p style = {{fontWeight:"800", textDecoration:"underline"}}> Features: </p>

            <ol>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  User Authentication and Authorization : </span>
                        Implemented user and admin privileges to ensure secure access and management of the application
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> Category and Subcategory Management : </span>
                        Enabled users to upload, organize, and delete categories and subcategories.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> Item Management with Image Handling : </span>
                        Image handling capabilities to facilitate the uploading, organization and association of data.
                    </li>
            </ol>

            <p style = {{fontWeight:"800", textDecoration:"underline"}}> Testing Credentials : </p>

            <ul>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  Admin Account : </span>
                        <ul>
                            <li> Username : root</li>
                            <li> Password : password</li>
                        </ul>
                    </li>

                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  User Account : </span>
                        <ul>
                            <li> Username : user</li>
                            <li> Password : password</li>
                        </ul>
                    </li>
            </ul>

            {/* <div style = {{display:`${myBrowser.getBrowserWidth()}`}}>
                <DemoWrapper Title={"Demo Project"}>
                    <div style = {{width:'100%', height :'550px', overflow:"hidden"}}>
                        <IframeWrapper PageURL={"http://example2.igli.site"}/>
                    </div>
                </DemoWrapper> 
            </div> */}
        </>,
    img_Url:"./images/Project_Images/shopping_app.jpg", 
    show_Image: false,           
    file_List : [
        {
            key : 1,
            FileTitle : "",
            content : ""
        }
    ]
}

export default CaroselItem;
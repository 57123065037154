
import ContentCarosel, {items} from '../../Components/layouts/contentCarousel/contentCarosel';
import HtmlTextConverter from '../projectFiles/htmlTextConverter';
import Puzzle from "../projectFiles/AI/PuzzleSolver";
import TicTacToe from '../projectFiles/AI/TickTacToe';
import BackwardChaining from '../projectFiles/AI/BackwardChaining';
import CaphalopodGame from "../DSA/CephalopodGame";
import CatalogueSale from "../DSA/CatalogueSale";
import '../styling.css';
import { useState } from 'react';



let RenderProject : React.FC <{}> = (props) => { 
    let Items : items[] = [
        Puzzle,
        TicTacToe,
        BackwardChaining,
        CaphalopodGame,
        CatalogueSale,
        CatalogueSale,
        CatalogueSale,
 
    ]



    return (
        <>   
            <div  style = {{width:'100%', paddingTop:'3%',  paddingBottom:'3%',  position:'relative',  boxSizing:'border-box'}}> 
                <ContentCarosel  TitleStyling={ {color:'white'}} contentHeight={2} popup_ZIndex={1000} Title ={"My Projects"} Description = {"A selection of recent projects."} Items={Items}/>
            </div>
        </>
    );
}


export default RenderProject;


import Styling from "./MyDescriptionStyling.module.css";
import myBrowser from "../../Handlers/myBrowser";
import MySkills from "../MySkills/MySkills";
import Experiences from "../Experiences/Experiences";
import ImageAnimate from "../imageAnimate/ImageAnimate";
import { rootDir, ImagesDir } from "../../../Details";
import FrameLayout from "../../Components/layouts/FrameLayout/FrameLayout";
import MyProjects from "../MyProjects/Projects";


let RenderProject : React.FC <{}> = (props) => { 

    const rootSelectors: String = 
        `
            :root 
            {
                --color:red
        `;
    return (
        <>
            {/* <style>{myBrowser.getMobileWidth_CSSSelector()}</style> insert the root selectors */}
            <div id = "descriptionContaier" className={Styling.mainContainer} >
                <div className={Styling.contentContainer}> 
                    <div style = {{width:'100%', display:'flex',flexDirection:'column' , justifyContent:'center'}}>
                        <div className={Styling.desktopNaming}>   
                            {/* <FrameLayout isDarkMode ={true}> */}
                                <h1 className={Styling.Titles} style = {{margin:'2%'}}> Hi! I'm Gordon. </h1>
                                <img className = {Styling.NameHighlight}  src = {ImagesDir + "marker.svg"}/>
                            {/* </FrameLayout> */}
                        </div>
                       
                        <p className={Styling.informationContent} > 
                            I am passionate about UI/UX design and Web Design. I am a skilled front-end developer and master of graphic 
                            design tools such as Photoshop and Sketch. I am a quick learner and a team worker that gets the job done. 
                            I can easily capitalize on low hanging fruits and quickly maximize timely deliverables for real-time schemas. 
                        </p>
                        <div className={Styling.topContainer}>
                                <div className={Styling.informationContainer}>
                                    <div style = {{width:'100%'}}> <p className={Styling.informationFont}> Email: </p>  </div>
                                    <div style = {{width:'100%'}}> 
                                        <a className={Styling.link} href={`mailto:gordonlis@outlook.com`}> Gordonlis@outlook.com </a>
                                    </div>
                                </div>


                                <div className={Styling.informationContainer}>
                                    <div style = {{width:'100%'}}> <p className={Styling.informationFont}> Phone: </p>  </div>
                                    <div style = {{width:'100%'}}> 
                                        <a className={Styling.link} href={`tel:0416915923`}> 0416 915 923 </a>
                                    </div>
                                </div>
                                <div className={Styling.informationContainer}>
                                    <div style = {{width:'100%'}}> <p className={Styling.informationFont}> Location: </p>  </div>
                                    <div style = {{width:'100%'}}> 
                                        <a className={Styling.link} href={`https://maps.app.goo.gl/RxmfNcKSxowpbWpC6`} target="_blank" > Sydney, Nsw </a>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div style = {{width:'100%', position:'relative'}}>
                    {/* <FrameLayout isDarkMode ={true}> */}
                        <ImageAnimate/>

                    {/* </FrameLayout> */}
                    </div>
                    <div className={Styling.mobileNaming} style = {{marginTop:'5%', marginBottom : '10%'}}> 
                        <FrameLayout isDarkMode ={true}>
                            <div className={Styling.mobileNaming}> 
                                <h1 className={Styling.Titles} style = {{margin:'0', padding:'0'}}> Hi im gordon </h1>
                                <img className = {Styling.NameHighlight} style={{marginTop:'20px'}} src = {ImagesDir + "marker.svg"}/>
                            </div>
                        </FrameLayout>
                    </div>
                
                </div>
                
                <div className={Styling.skillContainer} style = {{width :"100%", paddingBottom:'90px'}}>
                    <h1 className={Styling.Titles}> My Skill </h1>
                    <p className={Styling.informationContent} > I am passionate about UI/UX design and Web Design. I am a skilled front-end developer and master of graphic 
                            design tools such as Photoshop and Sketch. I am a quick learner and a team worker that gets the job done. 
                            I can easily capitalize on low hanging fruits and quickly maximize timely deliverables for real-time schemas. 
                            ....
                            ...
                            ..
                            .
                    </p>
                    <MySkills/>


{/* 
                    <h1> Experiences </h1>
                    <Experiences/> */}

{/*                     
                    <p style = {{color:'white', textAlign:"justify"}}> 
                            ssssk
                    </p> */}


                </div>




            </div>        
        </>
    )

}


export default RenderProject;
import { useLayoutEffect, useRef, useState } from "react";
import Styling from "./Styling.module.css"
import myBrowser from "../../Handlers/myBrowser";


let RenderPage : React.FC <{  }> = (props) => { 


    // let firstImage = 
    // https://i.ibb.co/ScgyYp2/Code-typing.gif

    let containerRef = useRef<HTMLDivElement>(null);
    const gifRef = useRef<HTMLImageElement>(null);
    const gif_2Ref = useRef<HTMLImageElement>(null);
    let [defaultOpacity, setDefaultOpacity] = useState<number>(1);

    useLayoutEffect(
        ()=>{

            let scrollHandler = () => { 
                if (gifRef.current != null && gif_2Ref.current != null && containerRef.current != null){
                    let rect = gifRef.current.getBoundingClientRect();
                    let pos = rect.top - myBrowser.getBrowserHeight()/2;

                    if (pos < 0 )setDefaultOpacity(0);
                    else setDefaultOpacity(1);
                }
            }

            document.addEventListener("scroll", scrollHandler);
            return () => {
                document.removeEventListener("scroll", scrollHandler);
            }

        },[]
    )


    return(
        <>  
            <div className={Styling.mainContainer}>
                <div ref ={containerRef} style = {{position:'absolute', width:'100%', height :"100%", zIndex:'500'}}/>
                <div className={Styling.imageContainer}> 
                    <img ref = {gifRef} className={Styling.imageContainer} style ={{width:'100%', height:'100%', objectFit:'contain', opacity:`${(defaultOpacity === 1) ? '0' : '1'}`, zIndex:'1'}} src="./images/myresume_Content/myimage_2.png" alt="Your GIF" />
                    <img ref={ gif_2Ref}  style ={{width:'100%', height:'100%', objectFit:'contain', zIndex:'50', opacity:`${defaultOpacity}`}} src="./images/myresume_Content/myimage.png"/>
                </div>

                <div className={Styling.codeWindow} >
                    <div className={Styling.test} >
                        <img  src="./images/myresume_Content/animationWeb.gif" />
                    </div>
                </div>


            </div>
        </>
    )
}


export default RenderPage;